import React, { Component } from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import AutoPsiForm from '../views/AutoPsi/Form';
import ReviewForm from '../views/AutoPsi/ReviewForm';
import Enrollment from '../views/AutoPsi/Enrollment';

class AutoPsi extends Component {
  state = {
    loading: false,
    dealerNumber: '',
    requestType: null,
    dealername: '',
    contactname: '',
    contactemail: '',
    contactphone: '',
    product: null,
    cascade: null,
    flatrate: null,
    purchaseChannel: null,
    location: null,
    miles: null,
    startDate: null,
    openReviewModal: false,
    modify: false,
    cancel: false
  };
  componentDidMount() {}

  onCheckboxHandler = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onSelectHandler = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onNewFormSubmitHandler = values => {
    this.setState({
      ...values
    });
  };

  onCloseHandler = () => {
    this.setState({ openReviewModal: false });
  };

  modifyCloseHandler = () => {
    this.setState({ modify: false });
  };

  redirectToAutoPsiTab = () => {
    this.setState({ openReviewModal: false });
  };

  render() {
    const { loading, openReviewModal, modify } = this.state;

    return (
      <div className="auto-psi">
        {' '}
        {loading && (
          <Dimmer active inverted>
            <Loader size="large"> Loading </Loader>{' '}
          </Dimmer>
        )}{' '}
        {!modify && (
          <AutoPsiForm
            onSelectHandler={this.onSelectHandler}
            onCheckboxHandler={this.onCheckboxHandler}
            onSubmitHandler={this.onNewFormSubmitHandler}
            {...this.state}
          />
        )}{' '}
        {openReviewModal && (
          <ReviewForm
            onCloseHandler={this.onCloseHandler}
            {...this.state}
            redirectToAutoPsiTab={this.redirectToAutoPsiTab}
            modifyCloseHandler={this.modifyCloseHandler}
          />
        )}{' '}
        {modify && (
          <Enrollment
            {...this.state}
            onSubmitHandler={this.onNewFormSubmitHandler}
            modifyCloseHandler={this.modifyCloseHandler}
          />
        )}{' '}
      </div>
    );
  }
}

export default AutoPsi;
