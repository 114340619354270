import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  Modal,
  Header,
  Icon,
  Message,
  Select
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import { isEmpty, get, filter } from 'lodash';
import Validator from 'validatorjs';
import client from '../apollo';

import Search from '../views/Shared/Search';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import ReturnInvoicesList from '../views/CanceledReturnInvoices/List';
import auctionLocationsQuery from '../graphql/auctionLocationsQuery';
import returnInvoicesQuery from '../graphql/canceledReturnInvoicesQuery';
import reinstateReturnInvoiceMutation from '../graphql/reinstateReturnInvoiceMutation';
import { setSearch } from '../data/searchField/actions';
import APP_PATH from '../constants/paths';
import { setPage } from '../data/page/actions';

class CanceledReturnInvoices extends Component {
  state = {
    selectedReturnInvoice: null,
    isConfirmationOpen: false,
    formSubmitting: false,
    returnLocation: '',
    errors: {},
    locations: [],
    flashMessage: ''
  };

  componentWillMount() {
    this.locationQuery();
  }

  onSelectReturnInvoice = (action, returnInvoice) => {
    this.setState({
      selectedReturnInvoice: returnInvoice
    });

    const { history } = this.props;
    switch (action) {
      case 'Edit':
        break;
      case 'Reinstate Return':
        this.setState({
          isConfirmationOpen: true,
          returnLocation: '',
          errors: {}
        });
        break;
      case 'Vehicle Status Tracker':
        history.push(
          APP_PATH.VEHICLE_STATUS_TRACKER.replace(
            ':purchaseId',
            returnInvoice.guaranteePurchaseId
          )
        );
        break;
      default:
        this.setState({
          selectedReturnInvoice: null
        });
    }
  };

  locationQuery = () => {
    client
      .query({
        query: auctionLocationsQuery,
        variables: {
          sortBy: 'initials'
        }
      })
      .then(({ data }) => {
        const auctionLocations = get(data, 'locations');
        this.setState({
          locations: filter(auctionLocations, val => val.acceptsReturns)
        });
      })
      .catch(error => {
        this.setState({ locations: [] });
      });
  };

  handleConfirmationClose = () => {
    this.setState({ isConfirmationOpen: false });
  };

  handleConfirm = () => {
    this.reinstateReturnInvoice();
  };

  validationRules = {
    returnLocation: 'required'
  };

  reinstateReturnInvoice = () => {
    const { reinstateMutation } = this.props;
    const { selectedReturnInvoice, returnLocation } = this.state;
    const input = {
      returnInvoiceId: selectedReturnInvoice.id,
      returnLocationId: returnLocation
    };

    const validation = new Validator(this.state, this.validationRules);
    if (validation.fails()) {
      this.setState({ ...validation.errors });
      return false;
    } else {
      this.setState({
        formSubmitting: true,
        isConfirmationOpen: false
      });

      reinstateMutation({
        variables: { input }
      })
        .then(({ data }) => {
          this.props.data.refetch();
          this.setState({
            selectedReturnInvoice: null,
            formSubmitting: false,
            flashMessage: 'Successfully Reinstated the VRA'
          });
          this.props.setPage({});
        })
        .catch(error => {
          this.setState({
            formSubmitting: false,
            error: 'Error while reinstating return invoice'
          });
        });
    }
  };

  onSearchSubmitHandler = event => {
    const { vin, vra } = event.target;
    const variableArguments = {
      vin: vin.value.trim() || null,
      vra: vra.value || null
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({
      cancelFormSubmitting: true
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  handleDismiss = () => {
    this.setState({ flashMessage: null });
  };

  render() {
    const { data: { canceledReturnInvoices }, searchFields } = this.props;
    const {
      locations,
      errors,
      selectedReturnInvoice,
      flashMessage
    } = this.state;

    const returnLocationOptions =
      !isEmpty(locations) &&
      locations.map(function(location) {
        return {
          text: `${location.initials} - ${location.name}`,
          key: location.id,
          value: parseInt(location.id, 10)
        };
      });

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['vin', 'vra']}
        />
        <ReturnInvoicesList
          onSelectReturnInvoice={this.onSelectReturnInvoice}
          returnInvoices={canceledReturnInvoices}
        />
        <Modal
          open={this.state.isConfirmationOpen}
          closeIcon
          onClose={this.handleConfirmationClose}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
        >
          <Modal.Header>
            Reinstate VRA -{' '}
            {isEmpty(selectedReturnInvoice) ? null : selectedReturnInvoice.id}
          </Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Form>
                <Header size="small" color="blue" />
                <Form.Group widths="equal">
                  <Form.Field required error={!isEmpty(errors.returnLocation)}>
                    <label>Please select the Return Location</label>
                    <Select
                      placeholder="Select Return Location"
                      options={returnLocationOptions}
                      value={this.state.returnLocation}
                      onChange={onSelectHandler('returnLocation')}
                    />
                    {errors.returnLocation && (
                      <span style={{ color: 'red' }}>
                        {errors.returnLocation}
                      </span>
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleConfirm} inverted>
              <Icon name="checkmark" /> Submit
            </Button>
            <Button color="red" onClick={this.handleConfirmationClose} inverted>
              <Icon name="checkmark" />
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const { data: { searchFields } } = state;
  return {
    searchFields: searchFields
  };
};

export default compose(
  withRouter,
  withPagination(returnInvoicesQuery, 'canceledReturnInvoices'),
  graphql(reinstateReturnInvoiceMutation, { name: 'reinstateMutation' }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(CanceledReturnInvoices);
