import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class FlatRateOfferBatchesList extends Component {
  render() {
    const { onSelectFlatRateOfferBatch, flatRateOfferBatches } = this.props;
    const listRow = ListRow(onSelectFlatRateOfferBatch);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Date Offered</Table.HeaderCell>
        <Table.HeaderCell>Initiating User(s)</Table.HeaderCell>
        <Table.HeaderCell>Accepted?</Table.HeaderCell>
        <Table.HeaderCell>State</Table.HeaderCell>
        <Table.HeaderCell>Auction Access #</Table.HeaderCell>
        <Table.HeaderCell>Dealer Name</Table.HeaderCell>
        <Table.HeaderCell>Dealer Email</Table.HeaderCell>
        <Table.HeaderCell>Dealer 5m#s</Table.HeaderCell>
        <Table.HeaderCell>Sales Rep Email</Table.HeaderCell>
        <Table.HeaderCell>Accepting User Name</Table.HeaderCell>
        <Table.HeaderCell>Expiration Date</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Dealshield 360 offers</Header>
        <Table
          celled
          selectable
          padded
          renderBodyRow={listRow}
          tableData={flatRateOfferBatches}
          headerRow={headerRow}
          size="small"
          compact="very"
          style={{ fontSize: '90%' }}
          striped
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(FlatRateOfferBatchesList);
