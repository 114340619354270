import { gql } from 'react-apollo';

const supportRequestQuery = gql`
  mutation supportRequestQuery($input: PostSupportRequestInput!) {
    postSupportRequest(input: $input) {
      success
      support_request {
        requestMethod
        requestBody
        requestType
        requestUrl
        responseCode
        responseBody
        note
        success
      }
    }
  }
`;

export default supportRequestQuery;
