import { gql } from 'react-apollo';

const SearchGuaranteeChargeQuery = gql`
  query searchGuaranteeCharge($vin: String!, $dealer_no: String!) {
    searchGuaranteeCharge(vin: $vin, dealer_no: $dealer_no) {
      id
      guaranteePurchaseId
      consignmentId
      guaranteedAt
      guaranteeStatus
      auctionInformation {
        auctionLocation {
          initials
          name
        }
      }
      purchasePrice
      guaranteePrice
      vehicleInformation {
        make
        vin
      }
      dealerName
      dealerNo
      preselectedGuaranteeKind
      product
    }
  }
`;

export default SearchGuaranteeChargeQuery;
