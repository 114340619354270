import { gql } from 'react-apollo';

const subscriptionPreferenceQuery = gql`
  query subscriptionPreferenceQuery($subscriptionId: Int) {
    subscriptionPreference(subscriptionId: $subscriptionId) {
      preferenceType
      startDate
      endDate
      ruleSetList {
        uid
        defaultPreferenceRuleType
        items
      }
      innerRuleSetList {
        uid
        items
        ruleType
      }
      ruleList {
        uid
        ruleType
        facts
        operatorType
        include
      }
    }
  }
`;

export default subscriptionPreferenceQuery;