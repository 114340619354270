import React, { Component } from 'react';
import SearchForm from '../views/GuaranteeCharge/SearchForm';

class GuaranteeCharge extends Component {
  state = {
    loading: false,
    dealerNumber: ''
  };

  render() {
    return (
      <div className="guarantee-charge">
        <SearchForm></SearchForm>
      </div>
    );
  }
}

export default GuaranteeCharge;
