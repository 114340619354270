import { gql } from 'react-apollo';

const updateOdsAttributesMutation = gql`
  mutation updateOdsAttributes($input: UpdateOdsAttributesInput!) {
    updateOdsAttributes(input: $input) {
      success
      errors
    }
  }
`;

export default updateOdsAttributesMutation;
