import React, { Component } from 'react';
import { Table, Button, Confirm, Message } from 'semantic-ui-react';
import { map, isEmpty } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import deleteExternalVehicleDocumentsMutation from '../../graphql/deleteExternalVehicleDocumentsMutation';

class ViewOrRemoveAttachments extends Component {
  state = {
    isDeleteConfirmationOpen: false,
    chosenDocument: '',
    flashMessage: null
  };

  removeDocument = field => {
    this.setState({ chosenDocument: field });
    this.setState({ isDeleteConfirmationOpen: true });
  };

  handleDismiss = () => {
    this.setState({ flashMessage: null });
  };

  handleDeleteConfirm = () => {
    const chosenDoc = this.state.chosenDocument;
    const { deleteDocumentMutation } = this.props;
    const input = { id: parseInt(chosenDoc, 10) };
    deleteDocumentMutation({
      variables: { input }
    }).then(({ data }) => {
      const { deleteExternalVehicleDocuments: { success } } = data;
      if (success) {
        this.setState({
          flashMessage: 'Successfully Deleted'
        });
        window.location.reload();
      } else {
        this.setState({
          flashMessage: 'Deletion Failed'
        });
      }
      this.setState({ isDeleteConfirmationOpen: false });
    });
    this.setState({ isDeleteConfirmationOpen: true });
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  render() {
    const { externalVehicleDocuments } = this.props.batch;
    const { flashMessage } = this.state;
    const TableHeader = <Table.Header />;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}
        {!isEmpty(externalVehicleDocuments) && (
          <Table
            celled
            singleLine
            verticalAlign="top"
            style={{ width: '1250px' }}
          >
            {TableHeader}
            <Table.Body>
              {map(externalVehicleDocuments, file => (
                <Table.Row key={file.id}>
                  <Table.Cell style={{ width: '480px' }}>
                    {file.imageName}
                  </Table.Cell>
                  <Table.Cell style={{ width: '480px' }}>
                    <a href={file.document}> {` View `} </a>
                  </Table.Cell>
                  <Table.Cell style={{ width: '480px' }}>
                    <Button onClick={() => this.removeDocument(file.id)}>
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <Confirm
          open={this.state.isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

export default compose(
  graphql(deleteExternalVehicleDocumentsMutation, {
    name: 'deleteDocumentMutation'
  })
)(ViewOrRemoveAttachments);
