import { gql } from 'react-apollo';

const deleteExternalVehicleDocumentsMutation = gql`
  mutation deleteExternalVehicleDocuments(
    $input: deleteExternalVehicleDocumentsInput!
  ) {
    deleteExternalVehicleDocuments(input: $input) {
      success
      errors
    }
  }
`;

export default deleteExternalVehicleDocumentsMutation;
