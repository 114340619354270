import { gql } from 'react-apollo';

const flatRateOfferBatchesQuery = gql`
  query flatRateOfferBatches(
    $auctionAccessNumber: String
    $dealerNumber: String
    $size: Int
    $offset: Int
  ) {
    flatRateOfferBatches(
      auctionAccessNumber: $auctionAccessNumber
      dealerNumber: $dealerNumber
      offset: $offset
      size: $size
    ) {
      id
      auctionAccessNo
      userEmail
      createdAt
      offeringUsersEmails
      approved
      rejected
      state
      auctionAccessNo
      buyerInformation {
        name
        emails
        cellPhone
      }
      offeredDealerNumbers
      salesRepEmail
      expirationDate
      acceptingUserName
      flatRateOffers {
        id
        price
        dealerNo
        milesSelected
        psiEligible
        acceptingUserName
      }
    }
  }
`;

export default flatRateOfferBatchesQuery;
