import { gql } from 'react-apollo';

const deleteSubscriptionMutation = gql`
  mutation deleteSubscriber($input: DeleteSubscriberInput!) {
    deleteSubscriber(input: $input) {
      success
      errors
    }
  }
`;

export default deleteSubscriptionMutation;
