import React, { Component } from 'react';

import { Table, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import APP_PATH from '../../constants/paths';

class ListRow extends Component {
  render() {
    const {
      id,
      status,
      createdAt,
      user,
      externalVehiclesLoads,
      history
    } = this.props;

    let dealerNumbers = externalVehiclesLoads.map(item => item.dealerNo);
    dealerNumbers = _.uniq(dealerNumbers);
    const userName = user ? user.username : null;

    const gotoVehiclesPage = event => {
      history.push(APP_PATH.EXTERNAL_VEHICLE_BATCH.replace(':id', id));
    };

    return (
      <Table.Row key={id}>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell>{dealerNumbers.join(', ')}</Table.Cell>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell>{status}</Table.Cell>
        <Table.Cell>{createdAt}</Table.Cell>
        <Table.Cell>
          <Button onClick={gotoVehiclesPage}>List Vehicles</Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default withRouter(ListRow);
