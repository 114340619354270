import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import LoadingDataEnhancer from './LoadingData';
import withPagination from './WithPagination';

import featureFlagQuery from '../graphql/featureFlagQuery';
import updateFeatureFlagMutation from '../graphql/updateFeatureFlagMutation';
import FeatureFlagList from '../views/FeatureFlag/List';

class FeatureFlag extends Component {
  state = {
    featureFlag: false,
    setID: ''
  };
  render() {
    const { updateFeatureFlag } = this.props;
    const onCheckboxHandler = id => (event, eventData) => {
      const input = {
        featureFlag: { id: parseInt(id, 10), checked: eventData.checked }
      };

      updateFeatureFlag({
        variables: { input }
      }).then(({ data }) => {
        const {
          updateFeatureFlag: { success }
        } = data;
        if (success) {
          this.props.data.refetch();
          this.setState({ featureFlag: eventData.checked, setID: id });
        }
      });
    };
    const { featureFlag, setID } = this.state;

    const {
      data: { featureFlags }
    } = this.props;

    return (
      <div>
        <FeatureFlagList
          featureFlagList={featureFlags}
          checkboxHandler={onCheckboxHandler}
          featureFlag={featureFlag}
          setID={setID}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withPagination(featureFlagQuery, 'featureFlags'),
  graphql(updateFeatureFlagMutation, { name: 'updateFeatureFlag' }),
  LoadingDataEnhancer
)(FeatureFlag);
