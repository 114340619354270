import { createAction } from 'redux-actions';
import * as actionType from './actionTypes';

const addDupeExternalVehicle = createAction(actionType.ADD_DUPE_EXT_VEHICLE);
const removeDupeExternalVehicle = createAction(
  actionType.REMOVE_DUPE_EXT_VEHICLE
);
const clearDupeExternalVehicle = createAction(
  actionType.CLEAR_DUPE_EXT_VEHICLE
);

export {
  addDupeExternalVehicle,
  removeDupeExternalVehicle,
  clearDupeExternalVehicle
};
