import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { isEmpty, startCase } from 'lodash';

class SearchField extends Component {
  render() {
    const {
      handleInputChange,
      searchOption,
      searchFields,
      value,
      multipleSearches,
      changed
    } = this.props;
    const searchValue =
      isEmpty(value) &&
      searchFields &&
      !(multipleSearches && changed[searchOption])
        ? searchFields[searchOption]
        : value;

    return (
      <Form.Field>
        <Form.Input
          name={searchOption}
          placeholder={'Search by ' + startCase(searchOption)}
          value={searchValue}
          onChange={handleInputChange}
        />
      </Form.Field>
    );
  }
}

export default SearchField;
