import React, { Component } from 'react';
import { Table, Header, Form, Input, Button, Popup } from 'semantic-ui-react';
import _, { isEmpty } from 'lodash';
import { compose } from 'recompose';
import moment from 'moment';
class SaleInformationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eatTransactionId: props.eatTransactionId
    };
  }

  render() {
    const { externalAuctionTransactionsEventData } = this.props;

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Detail</Table.HeaderCell>
        <Table.HeaderCell>Event</Table.HeaderCell>
        <Table.HeaderCell>Last Updated</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Sale Price</Table.HeaderCell>
        <Table.HeaderCell>Buy Fee</Table.HeaderCell>
        <Table.HeaderCell>DealShield Fee</Table.HeaderCell>
        <Table.HeaderCell>BuyNet</Table.HeaderCell>
        <Table.HeaderCell>Buyer Adjustments</Table.HeaderCell>
        <Table.HeaderCell>Payment Amount1</Table.HeaderCell>
        <Table.HeaderCell>VRA</Table.HeaderCell>
      </Table.Row>
    );

    var updatedAt =
      externalAuctionTransactionsEventData &&
      externalAuctionTransactionsEventData
        .map(e => e.updatedAt)
        .sort()
        .pop();

    const updateDateTime = isEmpty(updatedAt)
      ? null
      : moment(updatedAt)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
    const rowHtml = [];
    if (!isEmpty(externalAuctionTransactionsEventData)) {
      externalAuctionTransactionsEventData.forEach((value, index) => {
        const {
          id,
          announcement,
          transReqDate,
          event,
          vehicleSalePrice,
          buyFee,
          dealshieldPrice,
          buynet,
          buyerAdjustments,
          floorPlan,
          floorPlanDescription,
          howPaidDescription1,
          paymentAmount1,
          howPaid2,
          howPaidDescription2,
          paymentAmount2,
          howPaid3,
          howPaidDescription3,
          paymentAmount3,
          dsDepositFlag,
          paidDate,
          imsVehicleStatus,
          vehicleSecured,
          imsSecuredDate,
          partnerAuctionTitleStatus,
          imsTitleStatusFromAuction,
          imsTitleReceivedStatus,
          imsTitleReceived,
          holdType,
          titleIssueDate,
          imsTitleStatus,
          imsTitleNumber,
          clearedForSale,
          imsRecordDate,
          imsTitleStatusLoc,
          createdAt,
          updatedAt,
          vraId,
          splitPay,
          eatTransaction: {
            vin,
            saleDate,
            purchaseLocation,
            auction,
            runNumber,
            buyer,
            buyerName,
            seller,
            sellerName,
            guaranteeDate,
            vraInitiatedDate,
            returnLocation,
            modelYear,
            make,
            model,
            body,
            miles,
            channel,
            salvage,
            closedSale,
            arb,
            arbStatus,
            arbDate,
            returnWoNumber,
            meridianSoldDate,
            uniqueId
          }
        } = value;
        const vehicle = `${_.toString(modelYear)} ${_.toString(
          make
        )} ${_.toString(model)}`;
        let isOpen = false;
        let toggleStyle = {
          display: isOpen ? 'table-row' : 'none',
          backgroundColor: '#D3D3D3'
        };

        let showToggleStyle = {
          display: 'table-row'
        };
        let hideToggleStyle = {
          display: 'none'
        };

        const handleShowToggle = selectedId => () => {
          isOpen = !isOpen;
          toggleStyle = {
            display: isOpen ? 'table-row' : 'none'
          };
          hideToggleStyle = {
            display: 'none'
          };
          if (
            document.querySelector(`[data-detail="${selectedId}"]`).style
              .display === 'none'
          ) {
            document.querySelector(
              `[data-detail="${selectedId}"]`
            ).style.display = 'table-row';
          }
          if (
            document.querySelector(`[button-hide="${selectedId}"]`).style
              .display === 'none'
          ) {
            document.querySelector(
              `[button-hide="${selectedId}"]`
            ).style.display = 'table-row';
            document.querySelector(
              `[button-show="${selectedId}"]`
            ).style.display = 'none';
          }

          externalAuctionTransactionsEventData.forEach((value, index) => {
            const { id } = value;
            if (
              id !== selectedId &&
              document.querySelector(`[data-detail="${id}"]`).style.display ===
                'table-row'
            ) {
              document.querySelector(`[data-detail="${id}"]`).style.display =
                'none';
              document.querySelector(`[button-show="${id}"]`).style.display =
                'table-row';
              document.querySelector(`[button-hide="${id}"]`).style.display =
                'none';
            }
          });
        };

        const handleHideToggle = selectedId => () => {
          isOpen = !isOpen;
          toggleStyle = {
            display: isOpen ? 'table-row' : 'none'
          };
          showToggleStyle = {
            display: 'table-row'
          };
          if (
            document.querySelector(`[data-detail="${selectedId}"]`).style
              .display === 'table-row'
          ) {
            document.querySelector(
              `[data-detail="${selectedId}"]`
            ).style.display = 'none';
          }
          if (
            document.querySelector(`[button-show="${selectedId}"]`).style
              .display === 'none'
          ) {
            document.querySelector(
              `[button-show="${selectedId}"]`
            ).style.display = 'table-row';
            document.querySelector(
              `[button-hide="${selectedId}"]`
            ).style.display = 'none';
          }
        };

        const formatValue = (value, applyTo = 'currency') => {
          if (value === null || value === '') {
            return '';
          } else if (applyTo === 'currency') {
            return parseFloat(value)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          } else if (applyTo === 'miles') {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        };

        rowHtml.push(
          <Table.Row key={id} colSpan={10}>
            <Table.Cell collapsing>
              <Button.Group>
                <Popup
                  trigger={
                    <Button
                      button-show={id}
                      icon="plus"
                      onClick={handleShowToggle(id)}
                      style={showToggleStyle}
                    />
                  }
                  content="Click to view  Sale Transaction details"
                  wide={40}
                  position={'top center'}
                />
                <Popup
                  trigger={
                    <Button
                      button-hide={id}
                      icon="minus"
                      onClick={handleHideToggle(id)}
                      style={hideToggleStyle}
                    />
                  }
                  content="Click to hide Sale Transaction details"
                  wide={40}
                  position={'top center'}
                />
              </Button.Group>
            </Table.Cell>
            <Table.Cell>{event}</Table.Cell>
            <Table.Cell>{updatedAt}</Table.Cell>
            <Table.Cell>{formatValue(vehicleSalePrice)}</Table.Cell>
            <Table.Cell>{formatValue(buyFee)}</Table.Cell>
            <Table.Cell>{formatValue(dealshieldPrice)}</Table.Cell>
            <Table.Cell>{formatValue(buynet)}</Table.Cell>
            <Table.Cell>{buyerAdjustments}</Table.Cell>
            <Table.Cell>{formatValue(paymentAmount1)}</Table.Cell>
            <Table.Cell>{vraId}</Table.Cell>
          </Table.Row>
        );
        rowHtml.push(
          <Table.Row
            key={`'detail'-${id}`}
            data-detail={id}
            style={toggleStyle}
            colSpan={10}
          >
            <Table.Cell colSpan={10} style={{ backgroundColor: '#f1f1f1' }}>
              <div style={{ padding: '2em 2em' }}>
                <Header>Transaction Information</Header>
              </div>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="VIN"
                      name="vin"
                      value={vin}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Auction"
                      name="auction"
                      value={auction}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Auction (name/run)"
                      name="auctionName"
                      value={[purchaseLocation, runNumber].join(' / ')}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Sale Date"
                      name="saleDate"
                      value={saleDate}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Buyer #"
                      name="buyer"
                      value={buyer}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Buyer Name"
                      name="buyerName"
                      value={buyerName}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Seller"
                      name="seller"
                      value={seller}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Seller Name"
                      name="sellerName"
                      value={sellerName}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Guarantee Date"
                      name="guaranteeDate"
                      value={guaranteeDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="VRA"
                      name="vra"
                      value={vraId}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="VRA Initiated Date"
                      name="vraInitiatedDate"
                      value={vraInitiatedDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Return Location"
                      name="returnLocation"
                      value={returnLocation}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="How Paid Description1"
                      name="howPaidDescription1"
                      value={howPaidDescription1}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Paid Date"
                      name="paidDate"
                      value={paidDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Payment Amount1"
                      name="paymentAmount1"
                      value={formatValue(paymentAmount1)}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field style={{ display: 'inline-flex' }}>
                    <Input
                      fluid
                      label="Ds Deposit Flag"
                      name="dsDepositFlag"
                      value={dsDepositFlag}
                      readOnly={true}
                    />
                    <Input
                      fluid
                      label="Split Pay"
                      name="splitPay"
                      value={splitPay}
                      readOnly={true}
                      style={{ marginLeft: '10px' }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Floor Plan"
                      name="floorPlan"
                      value={floorPlan}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Floor Plan Description"
                      name="floorPlanDescription"
                      value={floorPlanDescription}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="BuyNet"
                      name="buynet"
                      value={formatValue(buynet)}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Buyer Adjustments"
                      name="buyerAdjustments"
                      value={buyerAdjustments}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="Vehicle Sale Price"
                      name="vehicleSalePrice"
                      value={formatValue(vehicleSalePrice)}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Buy Fee"
                      name="buyFee"
                      value={formatValue(buyFee)}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="DS Price"
                      name="dealshieldPrice"
                      value={formatValue(dealshieldPrice)}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Announcement"
                      name="announcement"
                      value={announcement}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="Vehicle"
                      name="vehicle"
                      value={vehicle}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Body"
                      name="body"
                      value={body}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Miles"
                      name="miles"
                      value={formatValue(miles, 'miles')}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Channel"
                      name="channel"
                      value={channel}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input fluid label="Event" name="event" value={event} />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Created At"
                      name="createdAt"
                      value={createdAt}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Updated At"
                      name="updatedAt"
                      value={updatedAt}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Hold Type"
                      name="holdType"
                      value={holdType}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="ARB"
                      name="arb"
                      value={arb}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="ARB Status"
                      name="arbStatus"
                      value={arbStatus}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="ARB Date"
                      name="arbDate"
                      value={arbDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Sold Location Title Status"
                      name="partnerAuctionTitleStatus"
                      value={partnerAuctionTitleStatus}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="Salvage"
                      name="salvage"
                      value={salvage}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Closed Sale"
                      name="closedSale"
                      value={closedSale}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Title Received Date"
                      name="imsTitleReceived"
                      value={imsTitleReceived}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Title Issue Date"
                      name="titleIssueDate"
                      value={titleIssueDate}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="Vehicle Secured"
                      name="vehicleSecured"
                      value={vehicleSecured}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="IMS Vehicle Status"
                      name="imsVehicleStatus"
                      value={imsVehicleStatus}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="IMS Secured Date"
                      name="imsSecuredDate"
                      value={imsSecuredDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Return WoNumber"
                      name="returnWoNumber"
                      value={returnWoNumber}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="IMS Record Date"
                      name="imsRecordDate"
                      value={imsRecordDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Trans Req Date"
                      name="transReqDate"
                      value={transReqDate}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Cleared For Sale"
                      name="clearedForSale"
                      value={clearedForSale}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Meridian SoldDate"
                      name="meridianSoldDate"
                      value={meridianSoldDate}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Meridian Title Status"
                      name="imsTitleStatusFromAuction"
                      value={imsTitleStatusFromAuction}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Meridian/IMS Title Status"
                      name="imsTitleStatus"
                      value={imsTitleStatus}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="IMS Title Received Status"
                      name="imsTitleReceivedStatus"
                      value={imsTitleReceivedStatus}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="IMS Title Status Loc"
                      name="imsTitleStatusLoc"
                      value={imsTitleStatusLoc}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      fluid
                      label="IMS Title Number"
                      name="imsTitleNumber"
                      value={imsTitleNumber}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="How Paid2"
                      name="How Paid2"
                      value={howPaid2}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="How Paid Description2"
                      name="howPaidDescription2"
                      value={howPaidDescription2}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Payment Amount2"
                      name="paymentAmount2"
                      value={formatValue(paymentAmount2)}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field with="equal">
                    <Input
                      fluid
                      label="Unique ID"
                      name="uniqueId"
                      value={uniqueId}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="How Paid3"
                      name="howPaid3"
                      value={howPaid3}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="How Paid Description3"
                      name="howPaidDescription3"
                      value={howPaidDescription3}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      label="Payment Amount3"
                      name="paymentAmount3"
                      value={formatValue(paymentAmount3)}
                      readOnly={true}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <br />
            </Table.Cell>
          </Table.Row>
        );
      });
    } else {
      rowHtml.push(
        <Table.Row key={'No-Record'} colSpan={10}>
          <Table.Cell colSpan={10} textAlign="center">
            <div> No Records Found this this Sale Transaction</div>
          </Table.Cell>
        </Table.Row>
      );
    }
    return (
      <div>
        {!isEmpty(externalAuctionTransactionsEventData) && (
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              marginBottom: '1em',
              float: 'right'
            }}
          >
            Last Updated On: <span>{updateDateTime}</span>
          </div>
        )}
        <div
          style={{ overflowX: 'auto', whiteSpace: 'no-wrap', clear: 'both' }}
        >
          <Table
            celled
            selectable
            padded
            sortable
            size="small"
            compact="very"
            striped
          >
            <Table.Header>{headerRow}</Table.Header>
            <Table.Body>{rowHtml}</Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

export default compose()(SaleInformationList);
