import {
  Table,
  Header,
  Form,
  Checkbox,
  Button,
  Icon,
  Message,
  Input
} from 'semantic-ui-react';
import { isEmpty, get, map, find } from 'lodash';
import React, { Component } from 'react';
import Validator from 'validatorjs';

class FlatRatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedflatRates: [],
      dealerPhone: null,
      dealerName: null,
      dealerEmail: null,
      salesRepEmail: null,
      userEmail: null,
      auctionAccessNumber: null,
      flatRates: null,
      flatRatesValidate: null,
      milesSelected: null,
      psiEligible: false,
      palv: false,
      daysPsi: null,
      daysSelected: null,
      palvTier: null,
      errors: {},
      dealers: null,
      productId: null,
      productType: null,
      productName: null
    };
  }

  componentDidMount() {
    const {
      flatRates,
      auctionAccessNumber,
      milesSelected,
      psiEligible,
      palv,
      daysSelected,
      palvTier,
      buyerInformation: { name, cellPhone, emails },
      dealers,
      govtLast4,
      daysPsi,
      productId,
      productType,
      productName
    } = this.props;

    this.setState({
      flatRates,
      auctionAccessNumber,
      govtLast4,
      milesSelected,
      psiEligible,
      palv,
      daysSelected,
      palvTier,
      dealerPhone: cellPhone,
      dealerName: name,
      dealerEmail: emails,
      dealers,
      daysPsi,
      productId,
      productType,
      productName
    });
  }
  generateFlatRateBySelected() {
    const { selectedflatRates, flatRates } = this.state;
    const flatRatesValidate = flatRates.filter(flatRates =>
      selectedflatRates.includes(flatRates.dealerNo)
    );
    this.setState({
      flatRatesValidate: flatRatesValidate
    });
  }
  render() {
    const {
      onSubmitHandler,
      milesSelected,
      psiEligible,
      dealers,
      error
    } = this.props;

    const {
      selectedflatRates,
      flatRates,
      flatRatesValidate,
      errors
    } = this.state;

    const onRateCheckboxHandler = field => (event, data) => {
      let selectedflatRates = this.state.selectedflatRates;

      if (data.checked) {
        selectedflatRates.push(data.value);
      } else {
        selectedflatRates = selectedflatRates.filter(
          selected => selected !== data.value
        );
      }
      this.setState({ selectedflatRates });
    };

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const onChangeTargetPriceHandler = field => event => {
      let flatRates = Object.assign([], this.state.flatRates);
      let flatRate = Object.assign({}, find(flatRates, ['dealerNo', field]));
      let index = flatRates.indexOf(find(flatRates, ['dealerNo', field]));
      flatRate.targetPrice = event.target.value;
      flatRates[index] = flatRate;
      this.setState({ flatRates });
    };

    const validationRules = {
      userEmail: 'required|email',
      salesRepEmail: 'required|email',
      dealerEmail: 'required|email',
      dealerName: 'required',
      'flatRatesValidate.*.targetPrice': 'required|numeric'
    };
    const customErrorMessage = {
      numeric: 'Invalid format number',
      required: 'This field is required'
    };
    const submitForm = async e => {
      this.setState({ ...this.state, errors: {} });
      await this.generateFlatRateBySelected();
      const {
        auctionAccessNumber,
        govtLast4,
        userEmail,
        salesRepEmail,
        dealerPhone,
        dealerName,
        dealerEmail,
        flatRates,
        selectedflatRates,
        milesSelected,
        psiEligible,
        palv,
        daysSelected,
        daysPsi,
        productId,
        productType,
        productName
      } = this.state;

      const validation = new Validator(
        this.state,
        validationRules,
        customErrorMessage
      );

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        let flatRateOffers = [];
        selectedflatRates.map(dealerNo => {
          const flatRate = find(flatRates, ['dealerNo', dealerNo]);
          flatRateOffers.push({
            flatRateId: flatRate.id,
            dealerNo: parseInt(dealerNo, 10),
            price: parseFloat(flatRate.targetPrice),
            milesSelected: milesSelected,
            psiEligible: psiEligible,
            daysPsi: daysPsi,
            palv: palv,
            daysSelected: daysSelected,
            productId: productId,
            productType: productType,
            productName: productName,
            exceptionsProductName:
              milesSelected < 360 ? 'purchaseprotect' : 'extendedprotect'
          });
          return flatRateOffers;
        });

        onSubmitHandler({
          auctionAccessNumber,
          govtLast4,
          userEmail,
          salesRepEmail,
          buyerInformation: {
            name: dealerName,
            emails: dealerEmail,
            cellPhone: dealerPhone
          },
          flatRateOffers
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Form>
        <br />
        <hr />
        <br />
        <Header>Automatic DealShield Offer Generator</Header>
        <br />
        {psiEligible && (
          <div>Dealshield Offer Parameter: {milesSelected} Miles & PSI</div>
        )}
        {!psiEligible && (
          <div>Dealshield Offer Parameter: {milesSelected} Miles</div>
        )}
        <br />
        <br />
        Below is the suggested pricing for the dealers you have selected. Please
        review and provide a final amount to be offered to the dealer.
        <br />
        For any questions please contact automatic@dealshield.com
        <br />
        <Table celled padded size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine textAlign="center">
                Select Dealers/Price.
              </Table.HeaderCell>
              <Table.HeaderCell>Dealer Information</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(flatRates, flatRate => {
              let indexError = !isEmpty(flatRatesValidate)
                ? flatRatesValidate.findIndex(
                    f => f.dealerNo === flatRate.dealerNo
                  )
                : -1;
              return (
                <Table.Row key={flatRate.id}>
                  {
                    <Table.Cell textAlign="center">
                      <Checkbox
                        value={flatRate.dealerNo}
                        checked={get(
                          this.state.selectedflatRates,
                          flatRate.dealerNo
                        )}
                        onChange={onRateCheckboxHandler(
                          'selectedflatRates'
                        ).bind(this)}
                      />
                      <br />
                      {flatRate.ineligibilityReason}
                    </Table.Cell>
                  }
                  <Table.Cell>
                    {flatRate.dealerNo +
                      ' - ' +
                      find(dealers, ['id', flatRate.dealerNo]).name}
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Field
                      required
                      error={
                        !isEmpty(
                          errors[`flatRatesValidate.${indexError}.targetPrice`]
                        )
                      }
                    >
                      {
                        <Input
                          name="targetPrice"
                          value={flatRate.targetPrice}
                          onChange={onChangeTargetPriceHandler(
                            flatRate.dealerNo
                          ).bind(this)}
                        />
                      }
                      {errors[
                        `flatRatesValidate.${indexError}.targetPrice`
                      ] && (
                        <span style={{ color: 'red' }}>
                          {
                            errors[
                              `flatRatesValidate.${indexError}.targetPrice`
                            ]
                          }
                        </span>
                      )}
                    </Form.Field>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        Default price is imported from standard pricing file.
        <br /> Final offer price may be edited at discretion or, for example, to
        override a custom group rate for individual dealerships.
        {!isEmpty(selectedflatRates) && (
          <div>
            <br />
            <hr />
            <br />
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <br />
            <Header>Confirm Automatic DealShield Offer</Header>
            <br />
            <br />
            Please review and confirm the information below regarding the offer
            you are requesting.
            <br />
            <br />
            <Form.Group widths="equal">
              <Form.Field required error={!isEmpty(errors.dealerName)}>
                <label>Dealer Name</label>
                <Input
                  placeholder="Dealer Name"
                  name="dealerName"
                  value={this.state.dealerName}
                  onChange={onChangeHandler('dealerName').bind(this)}
                />
                {errors.dealerName && <span>{errors.dealerName}</span>}
              </Form.Field>
              <Form.Field required error={!isEmpty(errors.dealerEmail)}>
                <label>Dealer Email</label>
                <Input
                  placeholder="Dealer Email"
                  name="dealerEmail"
                  value={this.state.dealerEmail}
                  onChange={onChangeHandler('dealerEmail').bind(this)}
                />
                {errors.dealerEmail && <span>{errors.dealerEmail}</span>}
              </Form.Field>
              <Form.Field required>
                <label>Dealer Phone#</label>
                <Input
                  placeholder="Dealer Phone#"
                  name="dealerPhone"
                  value={this.state.dealerPhone}
                  onChange={onChangeHandler('dealerPhone').bind(this)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required error={!isEmpty(errors.userEmail)}>
                <label>
                  Please provide an email address to receive updates on this
                  offer.
                </label>
                <Input
                  placeholder="User Email"
                  name="userEmail"
                  value={this.state.userEmail}
                  onChange={onChangeHandler('userEmail').bind(this)}
                />
                {errors.userEmail && <span>{errors.userEmail}</span>}
              </Form.Field>
              <Form.Field required error={!isEmpty(errors.salesRepEmail)}>
                <label>Sales Rep Email</label>
                <Input
                  placeholder="Sales Rep Email"
                  name="salesRepEmail"
                  value={this.state.salesRepEmail}
                  onChange={onChangeHandler('salesRepEmail').bind(this)}
                />
                {errors.salesRepEmail && <span>{errors.salesRepEmail}</span>}
              </Form.Field>
            </Form.Group>
            <br />
            <br />
            <Form.Group>
              <Form.Field>
                <Button color="green" onClick={submitForm} inverted>
                  <Icon name="checkmark" /> Create
                </Button>
              </Form.Field>
            </Form.Group>
          </div>
        )}
      </Form>
    );
  }
}

export default FlatRatesList;
