import { gql } from 'react-apollo';

const createSubscriptionMutation = gql`
  mutation createSubscriber($input: CreateSubscriberInput!) {
    createSubscriber(input: $input) {
      success
      errors
    }
  }
`;

export default createSubscriptionMutation;
