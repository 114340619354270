export const UNAUTHORIZED = 'UNAUTHORIZED';
const eventize = name => {
  try {
    return new Event(name);
  } catch (e) {
    const event = document.createEvent('CustomEvent');
    event.initEvent(name, true, true);
    return event;
  }
};

export default {
  unauthorized: eventize(UNAUTHORIZED)
};
