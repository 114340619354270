import React from 'react';
import { Table } from 'semantic-ui-react';
import { Checkbox } from 'semantic-ui-react';

const ListRow = (checkboxHandler, featureFlag, setID) => (
  featureFlagList,
  index
) => {
  const { id, flagName, description, checked } = featureFlagList;

  return (
    <Table.Row key={index}>
      <Table.Cell style={{ textAlign: 'center' }}>
        <Checkbox
          name="featureFlag"
          checked={
            setID === id ? featureFlag : checked === 'true' ? true : false
          }
          onChange={checkboxHandler(id).bind(this)}
        />
      </Table.Cell>
      <Table.Cell>{flagName}</Table.Cell>
      <Table.Cell>{description}</Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
