import { gql } from 'react-apollo';

const ftbLocationsQuery = gql`
  query ftbLocationsQuery {
    ftbLocations {
      name
    }
  }
`;

export default ftbLocationsQuery;
