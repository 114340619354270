import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AuditSearch = ({
  onDateChangeHandler,
  onSearchHandler,
  clearDates,
  startDate,
  endDate
}) => {
  return (
    <div>
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Start Date</label>
            <DatePicker
              style={{ padding: '0', width: '100%' }}
              selected={startDate}
              onChange={date => onDateChangeHandler('startDate', date)}
              dateFormat="YYYY-MM-DD"
            />
          </Form.Field>
          <Form.Field>
            <label>End Date</label>
            <DatePicker
              style={{ padding: '0', width: '100%' }}
              selected={endDate}
              onChange={date => onDateChangeHandler('endDate', date)}
              dateFormat="YYYY-MM-DD"
            />
          </Form.Field>
          <Form.Field>
            <label>&nbsp;&nbsp;</label>
            <Button color="green" onClick={onSearchHandler} inverted>
              Search
            </Button>
            <Button color="green" onClick={clearDates} inverted>
              Clear
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <br />
    </div>
  );
};

export default AuditSearch;
