import React, { Component } from 'react';

import UserSearch from '../views/Users/UserSearch';

class Users extends Component {
  render() {
    return <UserSearch />;
  }
}

export default Users;
