import React, { Component } from 'react';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class ExternalTransactionsList extends Component {
  render() {
    const { externalAuctionTransactions } = this.props;
    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>VRA</Table.HeaderCell>
        <Table.HeaderCell>VRA Initiated Date</Table.HeaderCell>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>Buyer #</Table.HeaderCell>
        <Table.HeaderCell>Buyer Name</Table.HeaderCell>
        <Table.HeaderCell>Seller</Table.HeaderCell>
        <Table.HeaderCell>Seller Name</Table.HeaderCell>
        <Table.HeaderCell>Sale Date</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Date</Table.HeaderCell>
        <Table.HeaderCell>HowPaidDescription1</Table.HeaderCell>
        <Table.HeaderCell>DS Deposit Flag</Table.HeaderCell>
        <Table.HeaderCell>Split Pay</Table.HeaderCell>
        <Table.HeaderCell>Paid Date</Table.HeaderCell>
        <Table.HeaderCell>Floor Plan</Table.HeaderCell>
        <Table.HeaderCell>Floor Plan Description</Table.HeaderCell>
        <Table.HeaderCell>PaymentAmount1</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Sale Price</Table.HeaderCell>
        <Table.HeaderCell>Buy Fee</Table.HeaderCell>
        <Table.HeaderCell>DS Price</Table.HeaderCell>
        <Table.HeaderCell>Buynet</Table.HeaderCell>
        <Table.HeaderCell>Buyer Adjustments</Table.HeaderCell>
        <Table.HeaderCell>ARB</Table.HeaderCell>
        <Table.HeaderCell>ARB Status</Table.HeaderCell>
        <Table.HeaderCell>ARB Date</Table.HeaderCell>
        <Table.HeaderCell>Auction (name/run)</Table.HeaderCell>
        <Table.HeaderCell>Auction</Table.HeaderCell>
        <Table.HeaderCell>Return Location</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Secured</Table.HeaderCell>
        <Table.HeaderCell>IMS SecuredDate</Table.HeaderCell>
        <Table.HeaderCell>IMS VehicleStatus</Table.HeaderCell>
        <Table.HeaderCell>Sold Location Title Status</Table.HeaderCell>
        <Table.HeaderCell>Meridian Title Status</Table.HeaderCell>
        <Table.HeaderCell>Title Received Date</Table.HeaderCell>
        <Table.HeaderCell>Meridian/IMS Title Status</Table.HeaderCell>
        <Table.HeaderCell>Title Issue Date</Table.HeaderCell>
        <Table.HeaderCell>Meridian SoldDate</Table.HeaderCell>
        <Table.HeaderCell>ClearedForSale</Table.HeaderCell>
        <Table.HeaderCell>TransReqDate</Table.HeaderCell>
        <Table.HeaderCell>Announcement</Table.HeaderCell>
        <Table.HeaderCell>Return WoNumber</Table.HeaderCell>
        <Table.HeaderCell>CreatedAt</Table.HeaderCell>
        <Table.HeaderCell>UpdatedAt</Table.HeaderCell>
        <Table.HeaderCell>Event</Table.HeaderCell>
        <Table.HeaderCell>IMSTitleReceivedStatus</Table.HeaderCell>
        <Table.HeaderCell>IMSTitleNumber</Table.HeaderCell>
        <Table.HeaderCell>IMSRecordDate</Table.HeaderCell>
        <Table.HeaderCell>IMSTitleStatusLoc</Table.HeaderCell>
        <Table.HeaderCell>Unique ID</Table.HeaderCell>
        <Table.HeaderCell>Body</Table.HeaderCell>
        <Table.HeaderCell>Vehicle</Table.HeaderCell>
        <Table.HeaderCell>Miles</Table.HeaderCell>
        <Table.HeaderCell>Channel</Table.HeaderCell>
        <Table.HeaderCell>Salvage</Table.HeaderCell>
        <Table.HeaderCell>Closed Sale</Table.HeaderCell>
        <Table.HeaderCell>HowPaid2</Table.HeaderCell>
        <Table.HeaderCell>HowPaidDescription2</Table.HeaderCell>
        <Table.HeaderCell>PaymentAmount2</Table.HeaderCell>
        <Table.HeaderCell>HowPaid3</Table.HeaderCell>
        <Table.HeaderCell>HowPaidDescription3</Table.HeaderCell>
        <Table.HeaderCell>PaymentAmount3</Table.HeaderCell>
        <Table.HeaderCell>Hold Type</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div className="external_auction_transactions_table">
        <Header>External Auction Transactions</Header>
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          <Table
            celled
            selectable
            padded
            renderBodyRow={ListRow}
            tableData={externalAuctionTransactions}
            headerRow={headerRow}
            size="small"
            striped
            singleLine
          />
        </div>
      </div>
    );
  }
}

export default ExternalTransactionsList;
