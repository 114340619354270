import gql from 'graphql-tag';

const updatePreferencesMutation = gql`
  mutation updatePreferences($preferenceInput: UpdatePreferencesInput!) {
    updatePreferences(input: $preferenceInput) {
      success
      errors
    }
  }
`;

export default updatePreferencesMutation;