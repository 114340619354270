import { createAction } from 'redux-actions';
import * as actionType from './actionTypes';

const addDupeVehicleIndex = createAction(actionType.ADD_DUPE_VEHICLE_INDEX);
const removeDupeVehicleIndex = createAction(
  actionType.REMOVE_DUPE_VEHICLE_INDEX
);
const cleanDupeVehicleIndex = createAction(actionType.CLEAN_DUPE_VEHICLE_INDEX);

export { addDupeVehicleIndex, removeDupeVehicleIndex, cleanDupeVehicleIndex };
