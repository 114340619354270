import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';

import {
  Form,
  Button,
  Modal,
  Message,
  Icon,
  Input,
  TextArea,
  Select
} from 'semantic-ui-react';

import { MARKET_FACTOR_COLORS, MARKET_FACTOR_STATUS } from '../../constants';

class MarketFactorForm extends Component {
  state = {
    id: null,
    title: '',
    description: '',
    color: '',
    status: '',
    errors: {}
  };

  componentDidMount() {
    const { marketFactor } = this.props;
    if (!isEmpty(marketFactor)) {
      const { id, title, description, color, status } = marketFactor;

      this.setState({
        id: parseInt(id, 10),
        title,
        description,
        color,
        status
      });
    }
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors } = this.state;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const validationRules = {
      title: 'required',
      description: 'required',
      color: 'required',
      status: 'required'
    };

    const submitForm = () => {
      const { title, description, color, status } = this.state;

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          title,
          description,
          color,
          status
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        {this.state.id && <Modal.Header>Edit Market Factor</Modal.Header>}
        {!this.state.id && <Modal.Header>New Market Factor</Modal.Header>}
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.title)}>
                  <label>Title</label>
                  <Input
                    name="title"
                    value={this.state.title}
                    onChange={onChangeHandler('title').bind(this)}
                  />
                  {errors.title && <span>{errors.title}</span>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.title)}>
                  <label>Description</label>
                  <TextArea
                    name="description"
                    value={this.state.description}
                    onChange={onChangeHandler('description').bind(this)}
                  />
                  {errors.description && <span>{errors.description}</span>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.color)}>
                  <label>Color</label>
                  <Select
                    placeholder="Select color"
                    options={MARKET_FACTOR_COLORS}
                    onChange={onSelectHandler('color')}
                    value={this.state.color}
                  />
                  {errors.color && <span>{errors.color}</span>}
                </Form.Field>
                <Form.Field required error={!isEmpty(errors.status)}>
                  <label>Status</label>
                  <Select
                    placeholder="Select status"
                    options={MARKET_FACTOR_STATUS}
                    onChange={onSelectHandler('status')}
                    value={this.state.status}
                  />
                  {errors.status && <span>{errors.status}</span>}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}

          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default MarketFactorForm;
