import gql from 'graphql-tag';

const createSubscriptionsMutation = gql`
  mutation createSubscriptions($subscriptionInput: CreateSubscriptionsInput!) {
    createSubscriptions(input: $subscriptionInput) {
      success
      errors
      subscription {
        id
        dealerName
        dealerNumber
        productId
        productName
        subscriptionType
        priceDs
        pricePsiLpi
        notifyEmail
        networkPlusRate
        active
        sameDayPriceUpdate
        startDate
        endDate
      }
    }
  }
`;

export default createSubscriptionsMutation;
