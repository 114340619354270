import React, { Component } from 'react';
import { isEmpty, find } from 'lodash';
import Validator from 'validatorjs';
import { EMAIL_FREQUENCY_OPTIONS } from '../../constants';

import { Form, Button, Input, Icon, Select } from 'semantic-ui-react';

class NotificationSettingsForm extends Component {
  state = {
    errors: {},
    emailFrequency: '',
    dealerNumber: ''
  };

  render() {
    const { onSubmitHandler, notificationSettings } = this.props;

    const { errors, dealerNumber, emailFrequency } = this.state;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const validationRules = {
      dealerNumber: 'required|numeric|digits:7|duplicate',
      emailFrequency: 'required'
    };

    Validator.register(
      'duplicate',
      function(value, requirement, attribute) {
        // requirement parameter defaults to null
        return (
          notificationSettings.indexOf(
            find(notificationSettings, ['dealerNumber', value])
          ) === -1
        );
      },
      'The :attribute already existing'
    );

    const submitForm = () => {
      const { emailFrequency, dealerNumber } = this.state;

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          emailFrequency: emailFrequency,
          dealerNumber: dealerNumber
        });
        this.setState({
          ...validation.errors,
          emailFrequency: '',
          dealerNumber: ''
        });
      }
    };

    return (
      <Form.Group widths="equal">
        <Form.Field required error={!isEmpty(errors.dealerNumber)}>
          <label>Dealer Number</label>
          <Input
            value={dealerNumber}
            onChange={onChangeHandler('dealerNumber').bind(this)}
          />
          {errors.dealerNumber && <span>{errors.dealerNumber}</span>}
        </Form.Field>
        <Form.Field required error={!isEmpty(errors.emailFrequency)}>
          <label>Email frequency</label>
          <Select
            placeholder="Select frequency"
            options={EMAIL_FREQUENCY_OPTIONS}
            onChange={onSelectHandler('emailFrequency')}
            value={emailFrequency}
            className="email-frequency-selector"
          />
          <Button icon size="mini" color="green" onClick={submitForm}>
            <Icon name="add" />
          </Button>
          {errors.emailFrequency && <span>{errors.emailFrequency}</span>}
        </Form.Field>
      </Form.Group>
    );
  }
}

export default NotificationSettingsForm;
