import { gql } from 'react-apollo';

const updateSubscriptionMutation = gql`
  mutation modifySubscriber($input: ModifySubscriberInput!) {
    modifySubscriber(input: $input) {
      success
      errors
    }
  }
`;

export default updateSubscriptionMutation;
