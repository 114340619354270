import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Message, Confirm, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';

import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import pendingGuaranteesQuery from '../graphql/pendingGuaranteesQuery';
import PendingGuaranteesList from '../views/PendingGuarantees/List';
import PendingGuaranteeDetails from '../views/PendingGuarantees/Details';
import RejectPendingForm from '../views/PendingGuarantees/RejectPendingForm';
import BulkRejectPendingForm from '../views/PendingGuarantees/BulkRejectPendingForm';
import rejectPendingOfferMutation from '../graphql/rejectPendingOfferMutation';
import bulkRejectPendingOfferMutation from '../graphql/bulkRejectPendingOfferMutation';
import allowPendingOfferMutation from '../graphql/allowPendingOfferMutation';
import bulkAllowPendingOfferMutation from '../graphql/bulkAllowPendingOfferMutation';
import updateOdsAttributesMutation from '../graphql/updateOdsAttributesMutation';
import Search from '../views/Shared/Search';
import { clearSearch, setSearch } from '../data/searchField/actions';
import { setRefreshSearch } from '../data/refreshSearch/actions';
import { setPage } from '../data/page/actions';

const rowMenuOptions = [
  { key: 1, text: 'Allow', value: 'Allow' },
  { key: 2, text: 'Invalidate', value: 'Invalidate' }
];

const dropDownAlign = {
  float: 'right'
};

class PendingGuarantees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPendingGuarantee: null,
      isDetailsModalOpen: false,
      isPendingRejectModalOpen: false,
      formSubmitting: false,
      isConfirmationOpen: false,
      error: null,
      flashMessage: null,
      isEligibleForBulkUpdate: false,
      bulkSelectedPendingOfferIds: [],
      isBulkPendingRejectModalOpen: false,
      bulkFormSubmitting: false,
      bulkSelectedGuaranteePurchaseIds: [],
      pendingGuarantees: [],
      column: '',
      direction: 'descending'
    };

    const { clearSearchFields, dispatchRefreshSearch, refreshSearch } = props;

    if (refreshSearch) {
      dispatchRefreshSearch(false);
      clearSearchFields({});
      const variableArguments = {
        vin: null,
        dealerNumbers: []
      };
      this.props.data.refetch({
        ...variableArguments
      });
    }
  }

  // creating an array of state with guarantees which will update the state.
  bulkSelectHandler = (guaranteeOfferId, guaranteePurchaseId) => {
    const {
      bulkSelectedPendingOfferIds,
      bulkSelectedGuaranteePurchaseIds
    } = this.state;
    if (bulkSelectedPendingOfferIds.includes(guaranteeOfferId)) {
      // remove the existing guarantee offer id from bulkSelectedPendingOfferIds
      var localCopyOffer = bulkSelectedPendingOfferIds;
      var values = localCopyOffer.indexOf(guaranteeOfferId);
      localCopyOffer.splice(values, 1);
      this.setState({ bulkSelectedPendingOfferIds: localCopyOffer });
      // remove the guarantee purchase id from the bulkSelectedGuaranteePurchaseIds
      var localCopyGuarantee = bulkSelectedGuaranteePurchaseIds;
      var value = localCopyGuarantee.indexOf(guaranteePurchaseId);
      localCopyGuarantee.splice(value, 1);
      this.setState({ bulkSelectedGuaranteePurchaseIds: localCopyGuarantee });
    } else {
      this.setState({
        bulkSelectedPendingOfferIds: [
          ...bulkSelectedPendingOfferIds,
          guaranteeOfferId
        ]
      });
      this.setState({
        bulkSelectedGuaranteePurchaseIds: [
          ...bulkSelectedGuaranteePurchaseIds,
          guaranteePurchaseId
        ]
      });
    }
  };

  handleSort = clickedColumn => () => {
    const { column, pendingGuarantees, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        pendingGuarantees: _.sortBy(pendingGuarantees, [clickedColumn]),
        direction: 'ascending'
      });
      return;
    }

    let en = Object.assign([], pendingGuarantees);
    const dir = direction === 'ascending' ? 'descending' : 'ascending';
    this.setState({
      pendingGuarantees: en.reverse(),
      direction: dir
    });
  };

  componentDidMount() {
    const {
      data: { pendingGuarantees }
    } = this.props;
    this.setState({ pendingGuarantees });
  }

  // newProps got the latest props and this.props got only the old data
  componentWillReceiveProps(newProps) {
    const {
      data: { pendingGuarantees }
    } = newProps;
    this.setState({ pendingGuarantees });
  }

  onSelectGuarantee = (action, guarantee) => {
    this.setState({
      selectedPendingGuarantee: guarantee
    });

    switch (action) {
      case 'Details':
        this.setState({
          isDetailsModalOpen: true
        });
        break;
      case 'Invalidate':
        this.setState({
          isPendingRejectModalOpen: true
        });
        break;
      case 'Allow':
        this.setState({
          isConfirmationOpen: true
        });
        break;
      case 'UpdateOdsAttributes':
        this.updateOdsAttributes(guarantee);
        break;
      default:
        this.setState({
          selectedPendingGuarantee: null
        });
    }
  };

  onPendingRejectFormSubmitHandler = values => {
    this.setState({
      formSubmitting: true
    });
    const { rejectPendingMutation } = this.props;
    const rejectInput = { ...values };
    rejectPendingMutation({
      variables: { rejectInput }
    })
      .then(({ data }) => {
        this.props.data.refetch().then(({ data }) => {
          this.setState({ pendingGuarantees: data.pendingGuarantees });
        });
        this.setState({
          isPendingRejectModalOpen: false,
          selectedGuaranteePurchase: null,
          formSubmitting: false,
          isDetailsModalOpen: false,
          flashMessage: 'Successfully Invalidated Guarantee Offer'
        });
      })
      .catch(error => {
        this.setState({
          formSubmitting: false,
          error: 'Error while Invalidating Guarantee Offer'
        });
      });
  };

  onBulkPendingRejectFormSubmitHandler = values => {
    this.setState({
      bulkFormSubmitting: true
    });
    const { bulkRejectPendingMutation } = this.props;
    const input = { ...values };
    bulkRejectPendingMutation({
      variables: { input }
    })
      .then(({ data }) => {
        this.props.data.refetch().then(({ data }) => {
          this.setState({ pendingGuarantees: data.pendingGuarantees });
        });
        this.setState({
          isBulkPendingRejectModalOpen: false,
          bulkSelectedPendingOfferIds: [],
          bulkSelectedGuaranteePurchaseIds: [],
          bulkFormSubmitting: false,
          flashMessage: 'Successfully Invalidated Guarantee Offers'
        });
      })
      .catch(error => {
        this.setState({
          bulkFormSubmitting: false,
          error: 'Error while Invalidating Guarantee Offers'
        });
      });
  };

  handleConfirmationClose = () => {
    this.setState({ isConfirmationOpen: false });
  };

  handleConfirm = () => {
    this.allowPendingGuarantee();
  };

  handleMessageDismiss = () => {
    this.setState({ flashMessage: null });
  };

  onDetailsModalCloseHandler = () => {
    this.setState({
      isDetailsModalOpen: false,
      selectedPendingGuarantee: null
    });
  };

  onPendingRejectFormCloseHandler = () => {
    this.setState({
      isPendingRejectModalOpen: false
    });
  };

  onBulkPendingRejectFormCloseHandler = () => {
    this.setState({
      isBulkPendingRejectModalOpen: false
    });
  };

  invalidatePendingGuarantee = () => {
    this.setState({
      isPendingRejectModalOpen: true
    });
  };

  allowPendingGuarantee = () => {
    const { allowMutation } = this.props;
    const { selectedPendingGuarantee } = this.state;
    const guaranteeOfferId = selectedPendingGuarantee.id;
    const input = { guaranteeOfferId };

    this.setState({
      formSubmitting: true,
      isConfirmationOpen: false
    });

    allowMutation({
      variables: { input }
    })
      .then(({ data }) => {
        this.props.data.refetch().then(({ data }) => {
          this.setState({ pendingGuarantees: data.pendingGuarantees });
        });

        let { allowPendingOffer: success } = data;
        let msg = success
          ? 'Successfully Validated Guarantee Offer'
          : 'Error while Validating Guarantee Offer';
        this.setState({
          selectedGuaranteePurchase: null,
          isDetailsModalOpen: false,
          formSubmitting: false,
          flashMessage: msg
        });
        this.props.setPage({});
      })
      .catch(error => {
        this.setState({
          formSubmitting: false,
          error: 'Error while Validating Guarantee Offer'
        });
      });
  };

  bulkAllowPendingGuarantee = () => {
    // for allow, needs only the bulkSelectedPendingOfferIds, it has the guarantee offer ids
    const { bulkSelectedPendingOfferIds } = this.state;
    const { bulkAllowMutation } = this.props;
    const input = { guaranteeOfferIds: bulkSelectedPendingOfferIds };

    bulkAllowMutation({
      variables: { input }
    })
      .then(({ data }) => {
        this.props.data.refetch().then(({ data }) => {
          this.setState({ pendingGuarantees: data.pendingGuarantees });
        });
        this.setState({
          bulkSelectedPendingOfferIds: [],
          bulkSelectedGuaranteePurchaseIds: [],
          flashMessage: 'Successfully Validated Guarantee Offers'
        });
        this.props.setPage({});
      })
      .catch(error => {
        this.setState({
          error: 'Error while Validating Guarantee Offer'
        });
      });
  };

  updateOdsAttributes = guarantee => {
    const { updateOdsMutation } = this.props;
    const input = { guaranteeOfferId: guarantee.id };

    this.setState({
      formSubmitting: true
    });

    updateOdsMutation({
      variables: { input }
    })
      .then(({ data }) => {
        this.setState({
          selectedGuaranteePurchase: null,
          formSubmitting: false,
          flashMessage: 'Successfully updated ods attributes'
        });
      })
      .catch(error => {
        this.setState({
          formSubmitting: false,
          error: 'Error while updating ods attributes'
        });
      });
  };

  onSearchSubmitHandler = event => {
    const { vin, dealerNumbers, locationInitial } = event.target;
    const dealerNos = isEmpty(dealerNumbers.value)
      ? null
      : [dealerNumbers.value];
    const variableArguments = {
      vin: vin.value || null,
      locationInitial: locationInitial.value || null,
      dealerNumbers: dealerNos
    };
    this.props.data.refetch({
      ...variableArguments
    });
    this.setState({
      formSubmitting: true
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  handleDropdownChange = (e, { name, value }) => {
    e.preventDefault();
    const { bulkSelectedPendingOfferIds } = this.state;
    if (bulkSelectedPendingOfferIds.length === 0) {
      this.setState({
        error: 'Minimum 1 offer will be selected to continue',
        flashMessage: ''
      });
    } else {
      this.setState({ flashMessage: '', error: '' });
      switch (value) {
        case 'Invalidate':
          this.setState({
            isBulkPendingRejectModalOpen: true
          });
          break;
        case 'Allow':
          this.bulkAllowPendingGuarantee();
          break;
        default:
          this.setState({
            bulkSelectedGuaranteePurchaseIds: [],
            bulkSelectedPendingOfferIds: []
          });
      }
    }
  };

  render() {
    const { searchFields } = this.props;
    const {
      selectedPendingGuarantee,
      isDetailsModalOpen,
      error,
      isPendingRejectModalOpen,
      formSubmitting,
      flashMessage,
      isBulkPendingRejectModalOpen,
      bulkFormSubmitting,
      bulkSelectedGuaranteePurchaseIds,
      pendingGuarantees,
      direction,
      column
    } = this.state;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
          />
        )}
        {error && (
          <Message negative>
            <p>{error}</p>
          </Message>
        )}
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['dealerNumbers', 'vin', 'locationInitial']}
        />
        <div style={dropDownAlign}>
          <Dropdown
            text="Bulk Actions"
            options={rowMenuOptions}
            onChange={this.handleDropdownChange}
            selectOnBlur={false}
          />
        </div>
        <PendingGuaranteesList
          onSelectGuarantee={this.onSelectGuarantee}
          pendingGuarantees={pendingGuarantees}
          direction={direction}
          column={column}
          bulkSelectHandler={this.bulkSelectHandler}
          isEligibleForBulkUpdate={this.isEligibleForBulkUpdate}
          handleSort={this.handleSort}
        />
        {selectedPendingGuarantee && isDetailsModalOpen && (
          <PendingGuaranteeDetails
            submitting={formSubmitting}
            onSelectGuarantee={this.onSelectGuarantee}
            onCloseHandler={this.onDetailsModalCloseHandler}
            pendingGuarantee={selectedPendingGuarantee}
            isDetailsModalOpen={isDetailsModalOpen}
            invalidatePendingGuarantee={this.invalidatePendingGuarantee}
            allowPendingGuarantee={this.allowPendingGuarantee}
          />
        )}
        {selectedPendingGuarantee && isPendingRejectModalOpen && (
          <div>
            <RejectPendingForm
              isPendingRejectModalOpen={isPendingRejectModalOpen}
              submitting={formSubmitting}
              onCloseHandler={this.onPendingRejectFormCloseHandler}
              onSubmitHandler={this.onPendingRejectFormSubmitHandler}
              pendingGuarantee={selectedPendingGuarantee}
              error={error}
            />
          </div>
        )}
        {isBulkPendingRejectModalOpen && (
          <div>
            <BulkRejectPendingForm
              isBulkPendingRejectModalOpen={isBulkPendingRejectModalOpen}
              submitting={bulkFormSubmitting}
              onCloseHandler={this.onBulkPendingRejectFormCloseHandler}
              onSubmitHandler={this.onBulkPendingRejectFormSubmitHandler}
              bulkSelectedGuaranteePurchaseIds={
                bulkSelectedGuaranteePurchaseIds
              }
              error={error}
            />
          </div>
        )}
        <Confirm
          open={this.state.isConfirmationOpen}
          onCancel={this.handleConfirmationClose}
          onConfirm={this.handleConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields, refreshSearch }
  } = state;
  return {
    searchFields: searchFields,
    refreshSearch
  };
};

export default compose(
  withRouter,
  withPagination(pendingGuaranteesQuery, 'pendingGuarantees'),
  graphql(rejectPendingOfferMutation, { name: 'rejectPendingMutation' }),
  graphql(bulkRejectPendingOfferMutation, {
    name: 'bulkRejectPendingMutation'
  }),
  graphql(allowPendingOfferMutation, { name: 'allowMutation' }),
  graphql(bulkAllowPendingOfferMutation, { name: 'bulkAllowMutation' }),
  graphql(updateOdsAttributesMutation, { name: 'updateOdsMutation' }),
  LoadingDataEnhancer,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PendingGuarantees);
