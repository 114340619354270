import { gql } from 'react-apollo';

const GuaranteeChargeMutation = gql`
  mutation createPushCharge($input: CreatePushChargeInput!) {
    createPushCharge(input: $input) {
      success
      errors
    }
  }
`;

export default GuaranteeChargeMutation;
