import { gql } from 'react-apollo';

const flatRateOfferBatchQuery = gql`
  query flatRateOfferBatch($id: ID) {
    flatRateOfferBatch(id: $id) {
      id
      auctionAccessNo
      userEmail
      approved
      rejected
      buyerInformation {
        name
        emails
        cellPhone
      }
      salesRepEmail
      expirationDate
      flatRateOffers {
        id
        price
        dealerNo
        milesSelected
        psiEligible
        acceptingUserName
      }
    }
  }
`;

export default flatRateOfferBatchQuery;
