import * as actionType from './actionTypes';

const initialState = {};

const authTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_AUTH_TOKEN:
      return Object.assign({}, state, { authToken: true });
    case actionType.RESET_AUTH_TOKEN:
      return false;
    default:
      return state;
  }
};

export default authTokenReducer;
