import { gql } from 'react-apollo';

const deleteAutoCancellationExceptionMutation = gql`
  mutation deleteAutoCancellationException($id: Int!) {
    deleteAutoCancellationException(input: { id: $id }) {
      success
      msg
    }
  }
`;

export default deleteAutoCancellationExceptionMutation;
