import { gql } from 'react-apollo';

const canceledReturnInvoicesQuery = gql`
  query canceledReturnInvoices(
    $vin: String
    $size: Int
    $offset: Int
    $vra: String
  ) {
    canceledReturnInvoices(vin: $vin, offset: $offset, size: $size, vra: $vra) {
      id
      guaranteePurchaseId
      returningUsername
      cancellingUsername
      cancelledAt
      returnInitiatedAt
      expirationDate
      reasonForReturn
      vin
      odometer
      purchasePrice
      locationInitials
      returnLocationInitials
      status
      dealerNumber
    }
  }
`;

export default canceledReturnInvoicesQuery;
