import { gql } from 'react-apollo';

const eatTransactionWithEatEventsQuery = gql`
  query eatTransactionWithEatEventsQuery(
    $vin: String
    $dealerNumber: String
    $saleDate: String
    $purchaseLocation: String
    $auction: String
  ) {
    eatTransactionWithEatEventData(
      vin: $vin
      dealerNumber: $dealerNumber
      saleDate: $saleDate
      auction: $auction
      purchaseLocation: $purchaseLocation
    ) {
      id
      vin
      buyer
      buyerName
      seller
      sellerName
      saleDate
      modelYear
      make
      model
      miles
      auction
      purchaseLocation
      vra
      vraInitiatedDate
      returnLocation
      body
      channel
      arb
      arbStatus
      arbDate
      salvage
      closedSale
      returnWoNumber
      meridianSoldDate
      uniqueId
      runNumber
      guaranteeDate
      createdAt
      updatedAt

      eatEventsData {
        id
        announcement
        transReqDate
        event
        vehicleSalePrice
        buyFee
        dealshieldPrice
        buynet
        buyerAdjustments
        howPaid
        howPaidDescription
        floorPlan
        floorPlanDescription
        howPaid1
        howPaidDescription1
        paymentAmount1
        howPaid2
        howPaidDescription2
        paymentAmount2
        howPaid3
        howPaidDescription3
        paymentAmount3
        dsDepositFlag
        splitPay
        paidDate
        imsVehicleStatus
        vehicleSecured
        imsSecuredDate
        partnerAuctionTitleStatus
        imsTitleStatusFromAuction
        imsTitleReceivedStatus
        imsTitleReceived
        holdType
        titleIssueDate
        imsTitleStatus
        imsTitleNumber
        clearedForSale
        imsRecordDate
        imsTitleStatusLoc
        eatTransactionId
        createdAt
        updatedAt
      }
    }
  }
`;

export default eatTransactionWithEatEventsQuery;
