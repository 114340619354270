import React, { Component } from 'react';

import Search from '../views/ExternalAuctionTransactions/search';

class ExternalAuctionTransactions extends Component {
  render() {
    return (
      <div>
        <Search />
      </div>
    );
  }
}

export default ExternalAuctionTransactions;
