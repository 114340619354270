import { gql } from 'react-apollo';

const resendFlatRateOfferBatchMutation = gql`
  mutation resendFlatRateOfferBatch($input: ResendFlatRateOfferBatchInput!) {
    resendFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default resendFlatRateOfferBatchMutation;
