import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class GuaranteePurchasesList extends Component {
  render() {
    const { onSelectGuarantee, guaranteePurchases } = this.props;
    const listRow = ListRow(onSelectGuarantee);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>External ID</Table.HeaderCell>
        <Table.HeaderCell>Guaranteed At</Table.HeaderCell>
        <Table.HeaderCell>Dealer Number</Table.HeaderCell>
        <Table.HeaderCell>Auction (name/sale/lane/run)</Table.HeaderCell>
        <Table.HeaderCell>Vehicle</Table.HeaderCell>
        <Table.HeaderCell>Odometer (M)</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Purchase Price</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Product</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
        <Table.HeaderCell>Partner</Table.HeaderCell>
        <Table.HeaderCell>Payment Kind</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Guarantee Purchases</Header>
        <div>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={guaranteePurchases}
            headerRow={headerRow}
            size="small"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(GuaranteePurchasesList);
