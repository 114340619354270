import React, { Component } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import { get, map } from 'lodash';
import moment from 'moment';

import client from '../../apollo';
import guaranteePurchaseAuditsQuery from '../../graphql/guaranteePurchaseAuditsQuery';
import AuditSearch from './AuditSearch';
class GuaranteePurchaseAudit extends Component {
  state = {
    guaranteePurchaseAudits: [],
    startDate: null,
    endDate: null
  };

  componentDidMount() {
    this.fetchGuaranteePurchaseAudits();
  }

  fetchGuaranteePurchaseAudits = () => {
    const { guaranteePurchase } = this.props;
    const { startDate, endDate } = this.state;
    const { id } = guaranteePurchase;
    client
      .query({
        query: guaranteePurchaseAuditsQuery,
        variables: {
          guaranteePurchaseId: parseInt(id, 10),
          startDate,
          endDate
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const guaranteePurchaseAudits = get(data, 'guaranteePurchaseAudits');
        this.setState({
          guaranteePurchaseAudits: guaranteePurchaseAudits
        });
      });
  };

  render() {
    const { isAuditModalOpen, onCloseHandler } = this.props;

    const { guaranteePurchaseAudits, startDate, endDate } = this.state;
    const onDateChangeHandler = (field, date) => {
      this.setState({
        [field]: moment(new Date(date), 'YYYY-MM-DD')
      });
    };

    const clearDates = () => {
      this.setState({ startDate: null, endDate: null });
    };

    return (
      <Modal
        open={isAuditModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Status History - Guarantee Purchases</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <div celled padded size="big">
              <AuditSearch
                onDateChangeHandler={onDateChangeHandler}
                clearDates={clearDates}
                startDate={startDate}
                endDate={endDate}
                onSearchHandler={this.fetchGuaranteePurchaseAudits}
              />
              {map(guaranteePurchaseAudits, audit => (
                <Table celled padded size="big" style={{ margin: '0 0' }}>
                  <Table.Row
                    key={audit.id}
                    style={{ backgroundColor: 'LightGray' }}
                  >
                    <Table.Cell width={12}>
                      {audit.event === 'update' &&
                        `${
                          audit.versionAuthor
                        } updated the Guarantee Purchase - ${
                          audit.itemType
                        } details`}
                      {audit.event === 'create' &&
                        `${
                          audit.versionAuthor
                        } created the Guarantee Purchase - ${audit.itemType}`}
                      {audit.event === 'destroy' &&
                        `${
                          audit.versionAuthor
                        } Destroyed Guarantee Purchase - ${audit.itemType}`}
                    </Table.Cell>
                    <Table.Cell>{audit.timeStamp}</Table.Cell>
                  </Table.Row>
                  {map(JSON.parse(audit['changeset']), (change, key) => {
                    return (
                      <tr>
                        {key !== 'updated_at' &&
                          audit.event === 'update' &&
                          change[1] !== null &&
                          change[1] !== '' && (
                            <Table.Row key={change.id}>
                              <Table.Cell>
                                <span>
                                  set{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {key}{' '}
                                  </span>
                                  from{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {' '}
                                    {change[0] || 'nil'}{' '}
                                  </span>
                                  to{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {change[1]}{' '}
                                  </span>
                                </span>
                              </Table.Cell>
                            </Table.Row>
                          )}
                      </tr>
                    );
                  })}
                </Table>
              ))}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GuaranteePurchaseAudit;
