import React, { Component } from 'react';

class LogoutError extends Component {
  render() {
    return (
      <div>
        <p>Log out was unsuccessful.</p>
      </div>
    );
  }
}

export default LogoutError;
