import localStore from 'store';
import PreferencePatterns from '../constants/PreferencePatterns';
import RegexPatterns from '../constants/regexPatterns';

const innerRuleSetZIndex = (i, refId, ir) => {
  let preference = localStore.get('preference');
  let index = i;

  for (let i = 0; i < ir.items.length; i++) {
    let item = ir.items[i];
    index += 1;

    if (item === refId) {
      return { index: index, found: true };
    }

    let innerRuleSet = preference.innerRuleSetList.find(
      innerRule => innerRule.uid === item
    );

    if (typeof innerRuleSet !== 'undefined') {
      let res = innerRuleSetZIndex(index, refId, innerRuleSet);
      index += res.index;

      if (res.found) {
        return { index: index, found: true };
      }
    }
  }
  return { index: index, found: false };
};

const preferenceZIndex = refId => {
  let preference = localStore.get('preference');
  let index = 0;
  let a = 0;

  for (let i = 0; i < preference.ruleSetList.length; i++) {
    let ruleSet = preference.ruleSetList[i];
    index += 1;
    a += 100;

    if (ruleSet.uid === refId) {
      return index + a;
    }

    for (let j = 0; j < ruleSet.items.length; j++) {
      let item = ruleSet.items[j];

      index += 1;
      if (item === refId) {
        return index + a;
      }

      let innerRuleSet = preference.innerRuleSetList.find(
        innerRule => innerRule.uid === item
      );

      if (typeof innerRuleSet !== 'undefined') {
        let res = innerRuleSetZIndex(index, refId, innerRuleSet);
        index += res.index;

        if (res.found) {
          return index + a;
        }
      }
    }
  }
  return index + a;
};

export const isValidFacts = (facts, ruleType) => {
  return (
    (PreferencePatterns.RULE_TYPE_FACTS_NUMBER.includes(ruleType) &&
      RegexPatterns.REGEX_NUMBER.test(facts.join(''))) ||
    (PreferencePatterns.RULE_TYPE_FACTS_CHARACTER.includes(ruleType) &&
      RegexPatterns.REGEX_CHARACTER.test(facts.join(''))) ||
    (PreferencePatterns.RULE_TYPE_FACTS_CHARACTER_WITH_SPACE.includes(
      ruleType
    ) &&
      RegexPatterns.REGEX_CHARACTER_WITH_SPACE.test(facts.join('')))
  );
};
export const returnErrMsg = ruleType => {
  return PreferencePatterns.RULE_TYPE_FACTS_NUMBER.includes(ruleType)
    ? PreferencePatterns.MSG_FACT_NUMBER
    : PreferencePatterns.RULE_TYPE_FACTS_CHARACTER.includes(ruleType) ||
      PreferencePatterns.RULE_TYPE_FACTS_CHARACTER_WITH_SPACE.includes(ruleType)
    ? PreferencePatterns.MSG_FACT_CHARACTER
    : 'Please select Rule Type first.';
};

export default preferenceZIndex;
