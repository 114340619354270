import * as actionType from './actionTypes';

const initialState = {};

const nextPathReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NEXT_PATH:
      return action.payload;
    case actionType.CLEAR_NEXT_PATH:
      return {};
    default:
      return state;
  }
};

export default nextPathReducer;
