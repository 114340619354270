import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import withPagination from './WithSmallerPagination';
import LoadingDataEnhancer from './LoadingData';
import ExternalTransactionsList from '../views/ExternalTransactions/List';
import Search from '../views/Shared/Search';
import externalAuctionTransactionsQuery from '../graphql/externalAuctionTransactionsQuery';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';

import { clearSearch } from '../data/searchField/actions';
import { setRefreshSearch } from '../data/refreshSearch/actions';

class ExternalTransactions extends Component {
  constructor(props) {
    super(props);

    const { clearSearchFields, dispatchRefreshSearch, refreshSearch } = props;

    if (refreshSearch) {
      dispatchRefreshSearch(false);
      clearSearchFields({});
      const variableArguments = {
        vin: null,
        dealerNumber: null
      };
      this.props.data.refetch({
        ...variableArguments
      });
    }
  }

  onSearchSubmitHandler = event => {
    const { vin, dealerNumber } = event.target;
    const dealerNos = isEmpty(dealerNumber.value) ? null : dealerNumber.value;
    const variableArguments = {
      vin: vin.value || null,
      dealerNumber: dealerNos
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({
      cancelFormSubmitting: true
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  componentWillMount() {
    const { state } = this.props.location;
    if (!isEmpty(state)) {
      const vin = state.vin;
      const vinVariable = { vin };
      this.props.data.fetchMore({
        variables: {
          ...vinVariable
        },
        updateQuery: (previousResult, { fetchMoreResult, queryVariables }) => {
          return fetchMoreResult;
        }
      });
    }
  }

  render() {
    const { data: { externalAuctionTransactions }, searchFields } = this.props;
    const { state } = this.props.location;
    let vin = !isEmpty(state) ? state.vin : '';

    return (
      <div>
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['vin', 'dealerNumber']}
          vin={vin}
        />

        <ExternalTransactionsList
          externalAuctionTransactions={externalAuctionTransactions}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  setPage: fields => {
    dispatch(setPage());
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  }
});

const mapStateToProps = state => {
  const { data: { searchFields, refreshSearch } } = state;
  return {
    searchFields: searchFields,
    refreshSearch
  };
};

export default compose(
  withRouter,
  withPagination(
    externalAuctionTransactionsQuery,
    'externalAuctionTransactions'
  ),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(ExternalTransactions);
