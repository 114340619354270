export default {
  PRODUCTS: [
    { key: '1', value: '14 Day', text: '14 DAY, FULL PSI' },
    { key: '2', value: '14D250M', text: 'Dealshield 14 Day, 250 Miles' },
    { key: '3', value: '14D500M', text: 'Dealshield 14 Day, 500 Miles' },
    { key: '4', value: '21D250M', text: 'Dealshield 21 Day, 250 Miles' },
    { key: '5', value: '21D360M', text: 'Dealshield 21 Day, 360 Miles' },
    {
      key: '6',
      value: '21D360MLV',
      text: 'DealShield 360 LV, 21 days, 360 miles'
    },
    { key: '7', value: '21D500M', text: 'Dealshield 21 Day, 500 Miles' },
    {
      key: '8',
      value: '21D500MLV',
      text: 'DealShield 360 LV, 21 days, 500 miles'
    },
    {
      key: '9',
      value: '21N60D250M',
      text: 'Dealshield 21 and 60 Day, 250 Miles'
    },
    {
      key: '10',
      value: '21N60D500M',
      text: 'Dealshield 21 and 60 Day, 500 Miles'
    },
    { key: '11', value: '30D250M', text: 'DealShield 360, 30 days, 250 miles' },
    { key: '12', value: '30D360M', text: 'DealShield 360, 30 days, 360 miles' },
    { key: '13', value: '30D500M', text: 'DealShield 360, 30 days, 500 miles' },
    { key: '14', value: '332', text: 'POST SALE INSPECTIONS 332' },
    { key: '15', value: '374', text: 'SUBLET POST SALE INSPECTIONS' },
    { key: '16', value: '376', text: 'POST SALE INSPECTIONS 376' },
    { key: '17', value: '60D250M', text: 'Dealshield 60 Day, 250 Miles' },
    {
      key: '18',
      value: '67D360M',
      text: 'DealShield 360 Total Protect, 67 days, 360 miles'
    },
    {
      key: '19',
      value: '67D500M',
      text: 'DealShield 360 Total Protect, 67 days, 500 miles'
    },
    { key: '20', value: '7 Day', text: '7 DAY FULL PSI' },
    { key: '21', value: '7D250M', text: 'Dealshield 7 Day, 250 Miles' },
    { key: '22', value: '7D500M', text: 'Dealshield 7 Day, 500 Miles' },
    { key: '23', value: 'ASSURANCE REQUESTED LPI', text: 'Complimentary LPI' },
    { key: '24', value: 'ASSURANCE REQUESTED PSI', text: 'Complimentary PSI' },
    {
      key: '25',
      value: 'Dealshield Return Fee',
      text: 'Excessive Dealshield Return Fee'
    },
    { key: '26', value: 'Limited Powertrain', text: 'Limited Powertrain' },
    { key: '27', value: 'Other', text: 'PSI - OTHER' },
    { key: '28', value: 'PSI19', text: 'Limited Powertrain' }
  ]
};
