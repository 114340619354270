import gql from 'graphql-tag';

const subscriptionProductTypeQuery = gql`
  query subscriptionProductTypeQuery {
    subscriptionProductType {
      key
      text
      value
    }
  }
`;

export default subscriptionProductTypeQuery;
