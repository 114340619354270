import { Component } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { formatDateOnly, utcMoment } from '../../utilities/dateUtils';

class Form extends Component {
  constructor(props) {
    super(props);
    this.today = utcMoment(new Date());
    this.format = 'YYYY-MM-DD';
  }

  toggleEndDate = () => {
    const { exceptionEndTimeTemp, isNoEndDate } = this.state;
    this.setState({
      isNoEndDate: !isNoEndDate,
      exceptionEndTime: !isNoEndDate ? null : exceptionEndTimeTemp
    });
    this.setErrorByField('exceptionEndTime', '');
  };

  onChangeHandler = field => event => {
    this.setErrorByField(field, ''); // reset error
    this.setState({ [field]: event.target.value });
  };

  onDateChangeHandler = field => date => {
    const dateValue =
      !isEmpty(date) &&
      moment(date).isValid() &&
      moment(new Date(date._d)).format('YYYY-MM-DD');
    this.setState({
      [field]: dateValue,
      exceptionEndTimeTemp: dateValue,
      errors: {
        [field]: ''
      }
    });
  };

  onDateFocusHandler = field => event => {
    const value = event.target.value;
    this.setState({ [field]: value });
  };

  setErrorByField = (field, value) => {
    let newErrors = { ...this.state.errors };
    newErrors[field] = value;
    this.setState({
      errors: newErrors
    });
  };

  handleInput = maxLength => event => {
    const inputLength = event.target.value.length;
    if (inputLength > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
  };

  isPastDate = (date, format) => {
    const selectedMoment = moment(date, format, true).startOf('day');
    const today = this.today.startOf('day');
    return selectedMoment.isBefore(today);
  };
  isInvalidDate = (date, format) => {
    const selectedMoment = moment(date, format, true);
    return selectedMoment.isValid();
  };

  validateEndDateDate = date => {
    const { isNoEndDate } = this.state;
    if (!isNoEndDate) {
      if (isEmpty(date)) {
        return 'The Exception End Date field is required.';
      } else if (!this.isInvalidDate(date, this.format)) {
        return `Please input with a correct format value of ${this.format}`;
      } else if (this.isPastDate(date, this.format)) {
        return 'Please enter a date greater than or equal to current date';
      }
    }
  };

  onFormatInput = e => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  // receive from backend with format YYYY-MM-DD HH:mm:ss
  // -> so we need convert to YYYY-MM-DD to pass validate
  initExceptionEndTimeString = exceptionEndTime => {
    if (!isEmpty(exceptionEndTime)) {
      return formatDateOnly(exceptionEndTime);
    } else {
      return null;
    }
  };

  initIsNoEndDate = (id, exceptionEndTime) => {
    if (id > 0) {
      return !!!exceptionEndTime;
    } else {
      return false;
    }
  };
}
export default Form;
