import React, { Component } from 'react';
import { Table, Header, Popup } from 'semantic-ui-react';
import _, { get, isEmpty } from 'lodash';
import client from '../../apollo';

import SaleInformationForm from './saleInformationForm';
import externalAuctionTransactionsEventDataQuery from '../../graphql/externalAuctionTransactionsEventDataQuery';
import ExportEAT from './ExportEAT';
import { parseString2Fields } from './StringUtil';

class ExternalAuctionTransactionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      eatTransactionId: null,
      eatEvents: [],
      isOpenSaleInformation: false,
      isEditModalOpen: false,
      editFormError: null,
      editFormSubmitting: false,
      isMarketFactorModalOpen: false,
      query: false,
      vinHeader: null
    };
  }

  onClickSaleInformation = (id, vin) => () => {
    this.setState({
      eatTransactionId: id,
      vinHeader: vin,
      isOpenSaleInformation: true,
      eatEvents: [],
      isEditModalOpen: true,
      query: true,
      loading: true
    });
    this.handleData(id);
  };
  handleData = id => {
    client
      .query({
        query: externalAuctionTransactionsEventDataQuery,
        variables: {
          eatTransactionId: parseInt(id, 10)
        }
      })
      .then(({ data }) => {
        const result = get(data, 'externalAuctionTransactionsEventData');
        if (!isEmpty(result)) {
          this.setState({
            eatEvents: result,
            query: false,
            loading: false
          });
        } else {
          this.setState({
            query: false,
            loading: false
          });
        }
      });
  };

  onEditFormCloseHandler = () => {
    this.setState({
      editFormError: null,
      editFormSubmitting: null,
      isEditModalOpen: false,
      refreshSearch: false,
      query: false
    });
  };

  render() {
    const eatEvents = this.state.eatEvents;
    var eatEventsMap = [...eatEvents];
    const eatEventData = [...new Set(eatEventsMap)];
    const {
      externalAuctionTransactionsData,
      handleSort,
      column,
      direction
    } = this.props;
    const { editFormError, isEditModalOpen, query } = this.state;

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>Vehicle</Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'purchaseLocation' ? direction : null}
          onClick={handleSort('purchaseLocation')}
        >
          Auction Location
        </Table.HeaderCell>
        <Table.HeaderCell>Sale Date</Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'buyer' ? direction : null}
          onClick={handleSort('buyer')}
        >
          Buyer Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'seller' ? direction : null}
          onClick={handleSort('seller')}
        >
          Seller Name
        </Table.HeaderCell>
      </Table.Row>
    );
    const rowBody = [];
    if (!isEmpty(externalAuctionTransactionsData)) {
      externalAuctionTransactionsData.map((value, index) => {
        const {
          id,
          vin,
          buyer,
          buyerName,
          seller,
          sellerName,
          saleDate,
          modelYear,
          make,
          model,
          auction,
          purchaseLocation
        } = value;

        const vehicle = `${_.toString(modelYear)} ${_.toString(
          make
        )} ${_.toString(model)}`;

        rowBody.push(
          <Popup
            key={id}
            trigger={
              <Table.Row
                key={id}
                onClick={this.onClickSaleInformation(id, vin)}
              >
                <Table.Cell>{vin}</Table.Cell>
                <Table.Cell>{vehicle}</Table.Cell>
                <Table.Cell>
                  {parseString2Fields(purchaseLocation, auction)}{' '}
                </Table.Cell>
                <Table.Cell>{saleDate}</Table.Cell>
                <Table.Cell>{parseString2Fields(buyer, buyerName)}</Table.Cell>
                <Table.Cell>
                  {parseString2Fields(seller, sellerName)}
                </Table.Cell>
              </Table.Row>
            }
            content="Click to view Sales Transaction details"
            position={'top center'}
            wide={30}
          />
        );
      });
    } else {
      rowBody.push(
        <Table.Row key={'No-Record'} colSpan={6}>
          <Table.Cell colSpan={6} textAlign="center">
            <div> No Records Found</div>
          </Table.Cell>
        </Table.Row>
      );
    }
    return (
      <div>
        {!isEmpty(externalAuctionTransactionsData) ? (
          <ExportEAT
            vin={this.props.vin}
            dealerNumber={this.props.dealerNumber}
            auction={this.props.auction}
            purchaseLocation={this.props.purchaseLocation}
            saleDate={this.props.saleDate}
          />
        ) : null}
        <br />
        <Header>Sales Transactions</Header>
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          <Table celled selectable padded sortable size={'small'} striped>
            <Table.Header>{headerRow}</Table.Header>
            <Table.Body>{rowBody}</Table.Body>
          </Table>
        </div>
        <br />
        <br />
        {isEditModalOpen && (
          <div>
            <SaleInformationForm
              isModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              error={editFormError}
              query={query}
              eatTransactionId={this.state.eatTransactionId}
              externalAuctionTransactionsEventData={eatEventData}
              vinHeader={this.state.vinHeader}
              loading={this.state.loading}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ExternalAuctionTransactionsList;
