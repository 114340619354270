import * as actionType from './actionTypes';
import { isEmpty } from 'lodash';

const initialState = [];

const duplicateExternalVehicleIndexReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_DUPE_EXT_VEHICLE_INDEX:
      const existingVehicle = state.filter(
        item =>
          item.vin === action.payload.vin &&
          item.dealerNumber === action.payload.dealerNumber
      );
      let newStateArray = state.slice();
      if (isEmpty(existingVehicle)) {
        newStateArray.splice(state.length, 0, action.payload);
      }
      return newStateArray;

    case actionType.REMOVE_DUPE_EXT_VEHICLE_INDEX:
      newStateArray = state.filter(
        item =>
          !(
            item.vin === action.payload.vin &&
            item.dealerNumber === action.payload.dealerNumber
          )
      );
      return newStateArray;
    case actionType.CLEAN_DUPE_EXT_VEHICLE_INDEX:
      return [];
    default:
      return state;
  }
};

export default duplicateExternalVehicleIndexReducer;
