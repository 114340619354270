import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class ReturnInvoicesList extends Component {
  render() {
    const { onSelectGuarantee, cancelledPurchases } = this.props;
    const listRow = ListRow(onSelectGuarantee);
    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Buyer Email</Table.HeaderCell>
        <Table.HeaderCell>Auction Access#</Table.HeaderCell>
        <Table.HeaderCell>Dealer#</Table.HeaderCell>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>Universal#</Table.HeaderCell>
        <Table.HeaderCell>Days Selected</Table.HeaderCell>
        <Table.HeaderCell>Miles Selected</Table.HeaderCell>
        <Table.HeaderCell>If Bid?</Table.HeaderCell>
        <Table.HeaderCell>Seller Paid?</Table.HeaderCell>
        <Table.HeaderCell>Automatic Purchase?</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Price</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Price</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Inspection Price</Table.HeaderCell>
        <Table.HeaderCell>Cancelling User Name</Table.HeaderCell>
        <Table.HeaderCell>Cancellation Date </Table.HeaderCell>
        <Table.HeaderCell>Cancellation Reason</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Canceled Guarantee Purchases</Header>
        <div>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={cancelledPurchases}
            headerRow={headerRow}
            size="small"
            striped
            compact="very"
            style={{ fontSize: '80%' }}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(ReturnInvoicesList);
