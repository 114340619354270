import { gql } from 'react-apollo';

const createMarketFactorMutation = gql`
  mutation createMarketFactor($input: CreateMarketFactorInput!) {
    createMarketFactor(input: $input) {
      success
      errors
    }
  }
`;

export default createMarketFactorMutation;
