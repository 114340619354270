import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo, compose } from 'react-apollo';
import localStore from 'store';
import { get } from 'lodash';

import { clearUser } from '../data/user/actions';
import APP_PATH from '../constants/paths';
import pingRevokeTokenRequestQuery from '../graphql/pingRevokeTokenRequestQuery';
import USER from '../constants/appUser';

const Logout = ({ client, signOutUser, pingEnabled }) => {
  const authCode = localStore.get('authorizationCode');
  if (pingEnabled === true && authCode) {
    const user = USER.APP;
    const userId = localStore.get('userId');

    client
      .query({
        query: pingRevokeTokenRequestQuery,
        variables: {
          app_name: user,
          token: authCode,
          user_id: userId,
          target_resource: window.location.origin + APP_PATH.BASE,
          in_error_resource: window.location.origin + APP_PATH.LOGOUT_ERROR
        }
      })
      .then(({ data }) => {
        //const error = get(data, 'pingRevokeTokenRequest.error_message');
        const success = get(data, 'pingRevokeTokenRequest.success');
        signOutUser();
        client.resetStore();
        localStore.remove('jwt');
        localStore.remove('authorizationCode');
        if (success === true) {
          window.location = get(data, 'pingRevokeTokenRequest.ping_logout_url');
        } else {
          window.location = window.location.origin;
        }
      });
  } else {
    signOutUser();
    client.resetStore();
    localStore.remove('jwt');
    window.location = window.location.origin;
  }
};

Logout.propTypes = {
  client: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  signOutUser: () => {
    dispatch(clearUser());
  }
});

export default compose(withApollo, connect(null, mapDispatchToProps))(Logout);
