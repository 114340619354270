import { gql } from 'react-apollo';

const allowPendingOfferMutation = gql`
  mutation allowPendingOffer($input: AllowPendingOfferInput!) {
    allowPendingOffer(input: $input) {
      success
      errors
    }
  }
`;

export default allowPendingOfferMutation;
