import { gql } from 'react-apollo';

const cancelReturnInvoiceMutation = gql`
  mutation cancelReturnInvoice(
    $cancelInput: CancelReturnInvoiceInput!
  ) {
    cancelReturnInvoice(input: $cancelInput) {
      success
      errors
    }
  }
`;

export default cancelReturnInvoiceMutation;
