import { gql } from 'react-apollo';

const viewerQuery = gql`
  query{
    viewer {
      user {
        id
        username
        roles
        firstName
        lastName
        email
      }
    }
  }
`;
export default viewerQuery;
