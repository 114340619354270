import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import _, { get, isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import LoadingDataEnhancer from './LoadingData';
import withPagination from './WithPagination';
import Search from '../views/Subscription/Search';
import SubscriptionsList from '../views/Subscription/List';
import SubscriptionNew from '../views/Subscription/NewForm';
import SubscriptionEdit from '../views/Subscription/EditForm';

import subscriptionQuery from '../graphql/subscriptionQuery';
import createSubscriptionsMutation from '../graphql/createSubscriptionsMutation';
import updateSubscriptionsMutation from '../graphql/updateSubscriptionsMutation';
import {
  setSearch,
  resetSearch,
  clearSearch
} from '../data/searchField/actions';
import { clearPage, setPage } from '../data/page/actions';
import { setRefreshSearch } from '../data/refreshSearch/actions';
import { setDealerActive } from '../data/subscription/actions';
import { resetDealerActive } from '../data/subscription/actions';

import { setCustomerNotes } from '../data/customerNotes/actions';
import client from '../apollo';
import activeDealerTypeQuery from '../graphql/activeDealerTypeQuery';
import localStore from 'store';
import SubscriptionPreferences from '../views/Subscription/Preferences/Form';
import updatePreferencesMutation from '../graphql/updatePreferencesMutation';
import SubscriptionNotesForm from '../views/Subscription/SubscriptionNotesForm';

import notesQuery from '../graphql/notesQuery';
import createCustomerNotesMutation from '../graphql/createCustomerNotesMutation';
import updateCustomerNotesMutation from '../graphql/updateCustomerNotesMutation';
import deleteCustomerNotesMutation from '../graphql/deleteCustomerNotesMutation';

const CUSTOMER_NOTES_TYPES = 'SUBSCRIPTION';

const CREATE_CUSTOMER_NOTES_MUTATION_NAME = 'createCustomerNotes';
const UPDATE_CUSTOMER_NOTES_MUTATION_NAME = 'updateCustomerNotes';
const DELETE_CUSTOMER_NOTES_MUTATION_NAME = 'deleteCustomerNotes';

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscription: null,
      isEditModalOpen: false,
      editFormError: null,
      editFormSubmitting: null,
      flashMessage: null,
      isNewModalOpen: false,
      newFormError: null,
      newFormSubmitting: false,
      errors: {},
      isActiveSubscription: false,
      onOpenConfirmationPopup: false,
      isConfirmationPopup: true,
      invalidDealerNumber: false,
      isDisable: true,
      queryDealerSubscription: null,
      isOpenPopup: false,
      subscription: null,
      notFoundSubscription: false,
      isPreferencesModalOpen: false,
      preferencesFormError: null,
      preferencesFormSubmitting: false,
      isNotesModalOpen: false,
      notesFormSubmitting: null,
      notesFormError: null,
      isCustomerNotesFlashMessage: false,
      customerNotesAction: 'Create',
      dealerNumberTemp: ''
    };

    const { clearSearchFields, dispatchRefreshSearch, refreshSearch } = props;

    if (refreshSearch) {
      dispatchRefreshSearch(false);
      clearSearchFields({});
      const variableArguments = {
        dealerNumber: ''
      };
      this.props.data.refetch({
        ...variableArguments
      });
      localStore.remove('flashMessage');
    }
  }

  componentDidMount() {
    if (this.props.refreshSearch) {
      this.props.dispatchRefreshSearch(false);
      this.props.data.refetch({ dealerNumbers: '' });
      this.props.clearSearchFields({});
    }
    !isEmpty(localStore.get('flashMessage'))
      ? this.setState({ flashMessage: localStore.get('flashMessage') })
      : this.setState({ flashMessage: this.state.flashMessage });
  }

  handleMessageDismiss = () => {
    this.setState({ flashMessage: null, isCustomerNotesFlashMessage: false });
  };

  onSelectSubscription = (action, subscription) => {
    this.setState({
      selectedSubscription: subscription
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isEditModalOpen: true,
          isNewModalOpen: false
        });
        break;

      case 'Enable Subscription':
        this.enableOrDisableHandler('enabled', subscription);
        break;

      case 'Disable Subscription':
        this.enableOrDisableHandler('expired', subscription);
        break;

      case 'Preferences':
        this.setState({
          isPreferencesModalOpen: true,
          isNewModalOpen: false,
          isEditModalOpen: false,
          preferencesFormError: null
        });
        break;
      case 'Edit Notes':
        this.setState({
          isNotesModalOpen: true,
          isCustomerNotesFlashMessage: true
        });
        break;
      default:
        this.setState({
          selectedSubscription: null,
          isCustomerNotesFlashMessage: false
        });
    }
  };

  onNewFormCloseHandler = () => {
    this.setState({
      isNewModalOpen: false,
      newFormSubmitting: false,
      newFormError: null,
      isConfirmationPopup: false
    });
  };

  onEditFormCloseHandler = () => {
    this.setState({
      isEditModalOpen: false,
      selectedSubscription: null,
      editFormError: null,
      editFormSubmitting: null
    });
  };

  dateFormat = () => {
    return 'YYYY-MM-DD';
  };

  onPreferencesFormCloseHandler = () => {
    this.setState({
      isPreferencesModalOpen: false,
      preferencesFormError: null,
      preferencesFormSubmitting: false,
      selectedSubscription: null
    });
  };

  getYesterday = () => {
    const yesterday = new moment(new Date(), this.dateFormat).subtract(1, 'd');
    return yesterday.format(this.dateFormat());
  };

  getNextDay = () => {
    const nextDay = new moment(new Date(), this.dateFormat).add(1, 'd');
    return nextDay.format(this.dateFormat());
  };

  enableOrDisableHandler = (actionName, subscription) => {
    const { updateMutation } = this.props;
    const subscriptionInput = { subscription: { ...subscription } };
    subscriptionInput.subscription.sameDayPriceUpdate = false;
    subscriptionInput.subscription.priceStartDate = '';

    switch (actionName) {
      case 'enabled':
        subscriptionInput.subscription.startDate = new moment().format(
          this.dateFormat()
        );
        subscriptionInput.subscription.endDate = '';
        break;
      case 'expired':
        const startDate = new moment(subscription.startDate, this.dateFormat());
        if (
          moment(new Date(), this.dateFormat()).isSameOrBefore(startDate, 'day')
        ) {
          this.setState({
            flashMessage:
              'Unable to end a same-day or future inactive subscription'
          });
          return false;
        } else {
          subscriptionInput.subscription.startDate = startDate.format(
            this.dateFormat()
          );
          subscriptionInput.subscription.endDate = new moment().format(
            this.dateFormat()
          );
        }
        break;
      default:
        return false;
        break;
    }
    delete subscriptionInput.subscription.__typename;

    updateMutation({
      variables: { subscriptionInput }
    })
      .then(({ data }) => {
        const {
          updateSubscriptions: { success, errors }
        } = data;
        if (success) {
          this.setState({
            flashMessage: `Successfully ${actionName} Subscription.`,
            invalidDealerNumber: false,
            notFoundSubscription: false,
            isDisable: true
          });
          this.props.data.refetch();
          this.props.setPage({});
        } else {
          this.setState({
            flashMessage: errors,
            invalidDealerNumber: false,
            notFoundSubscription: true,
            isDisable: true
          });
        }
      })
      .catch(error => {
        this.setState({
          flashMessage: error.message
        });
      });
  };

  isExistedPartnerChannel = ({ ruleSetList, ruleList, innerRuleSetList }) => {
    const rsItems = ruleSetList
      .map(rs => rs.items)
      .join(',')
      .split(',');
    const uidIR = innerRuleSetList
      .filter(ir => rsItems.includes(ir.uid))
      .map(ir => ir.uid);
    const RSItemsNotIncludeIR = rsItems.filter(rsI => !uidIR.includes(rsI));
    const isExistedPartnerChannel = ruleList.filter(
      rl =>
        RSItemsNotIncludeIR.includes(rl.uid) &&
        rl.ruleType === 'PARTNER_CHANNEL'
    );
    return isExistedPartnerChannel.length > 0 ? true : false;
  };

  onPreferencesFormSubmitHandler = values => {
    const { selectedSubscription } = this.state;
    const { updatePreferencesMutation } = this.props;
    const preference = localStore.get('preference') || {};
    const preferenceInput = { preference: localStore.get('preference') } || {};

    if (this.isExistedPartnerChannel(preference) === false) {
      this.setState({
        preferencesFormError:
          'PARTNER_CHANNEL is required for a preference. Please assure it is set properly',
        preferencesFormSubmitting: false
      });
      return false;
    }

    delete preferenceInput.preference.__typename;
    preferenceInput.preference.ruleSetList = JSON.stringify(
      preferenceInput.preference.ruleSetList.map(rs => {
        return Object.keys(rs)
          .filter(rsKey => rsKey !== '__typename')
          .reduce((obj, key) => {
            obj[key] = rs[key];
            return obj;
          }, {});
      })
    );
    preferenceInput.preference.ruleList = JSON.stringify(
      preferenceInput.preference.ruleList.map(rs => {
        return Object.keys(rs)
          .filter(rsKey => rsKey !== '__typename')
          .reduce((obj, key) => {
            obj[key] = rs[key];
            return obj;
          }, {});
      })
    );
    preferenceInput.preference.innerRuleSetList = JSON.stringify(
      preferenceInput.preference.innerRuleSetList.map(rs => {
        return Object.keys(rs)
          .filter(rsKey => rsKey !== '__typename')
          .reduce((obj, key) => {
            obj[key] = rs[key];
            return obj;
          }, {});
      })
    );

    preferenceInput.preference['id'] = parseInt(
      selectedSubscription.subscription_id,
      10
    );

    this.setState({
      preferencesFormSubmitting: true
    });
    updatePreferencesMutation({
      variables: { preferenceInput }
    })
      .then(({ data }) => {
        const {
          updatePreferences: { success, errors }
        } = data;
        if (success) {
          this.setState({
            preferencesFormSubmitting: false,
            preferencesFormError: null,
            isPreferencesModalOpen: false,
            flashMessage: 'Successfully Updated Preferences.'
          });
          this.props.data.refetch();
          this.props.setPage({});
          this.onPreferencesFormCloseHandler();
        } else {
          const prefixErrs = 'Preference Rule has errors.';
          const convertPrefixErrsToArray = [...(prefixErrs + ' ')];
          const errs = this.handleDuplicatedErrorMsg(
            errors[0].replace(prefixErrs, '')
          );

          this.setState({
            preferencesFormSubmitting: false,
            preferencesFormError: [...convertPrefixErrsToArray, ...errs]
          });
        }
      })
      .catch(error => {
        this.setState({
          preferencesFormSubmitting: false,
          preferencesFormError: Array.isArray(error) ? error : error.message
        });
      });
  };

  handleDuplicatedErrorMsg(errors) {
    let splitMsg = errors.split('.. ');
    let filterMsg = [...new Set(splitMsg)];
    return filterMsg.map(item => item !== '' && item + '.. ');
  }

  onNewFormSubmitHandler = values => {
    const subscription = values.subscription;
    const activeSubscription = subscription.filter(
      item =>
        isEmpty(item.endDate) ||
        Date.parse(item.endDate.toString()) - Date.now() > 0
    );
    delete values['subscription'];
    const { createMutation } = this.props;
    const subscriptionInput = { subscription: { ...values } };
    this.setState({
      newFormSubmitting: true
    });
    createMutation({
      variables: { subscriptionInput }
    })
      .then(({ data }) => {
        const {
          createSubscriptions: { success, errors }
        } = data;
        if (success) {
          this.props.data.refetch();
          this.props.setPage({});
          this.onNewFormCloseHandler();
          client
            .query({
              query: activeDealerTypeQuery,
              variables: {
                dealerNo: values.dealerNumber.toString()
              },
              fetchPolicy: 'network-only'
            })
            .then(async ({ data }) => {
              await this.researchFunction(
                data,
                values.dealerNumber.toString(),
                'created'
              );
            })
            .catch(errors => {
              console.log(errors);
            });
        } else {
          this.setState({
            newFormSubmitting: false,
            newFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          newFormSubmitting: false,
          newFormError: Array.isArray(error) ? error : error.message
        });
      });
  };

  onEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    let subscriptionInput = { subscription: { ...values } };

    this.setState({
      editFormSubmitting: true
    });
    updateMutation({
      variables: { subscriptionInput }
    })
      .then(({ data }) => {
        const {
          updateSubscriptions: { success, errors }
        } = data;
        if (success) {
          this.setState({
            editFormSubmitting: false,
            flashMessage: 'Successfully Updated Subscription.'
          });
          this.props.data.refetch();
          this.props.setPage({});
          this.onEditFormCloseHandler();
        } else {
          this.setState({
            editFormSubmitting: false,
            editFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: Array.isArray(error) ? error : error.message
        });
      });
  };

  validDealerNoRange = dealerNo => {
    const REGEX_5MIL_DEALER_NUMBER = /^5[0-9]{6}$/i;
    const REGEX_100MIL_DEALER_NUMBER = /^1[0-9]{8}$/i;
    const regex =
      REGEX_5MIL_DEALER_NUMBER.test(dealerNo) ||
      REGEX_100MIL_DEALER_NUMBER.test(dealerNo);
    return regex;
  };

  onSearchSubmitHandler = async event => {
    event.preventDefault();
    const { dealerNumbers } = event.target;
    const dealerNo = isEmpty(dealerNumbers.value) ? '' : dealerNumbers.value;
    const variableArguments = {
      dealerNumbers: dealerNo
    };
    this.props.resetDealerActive();
    try {
      if (this.validDealerNoRange(dealerNo)) {
        const { data: activeDealer } = await client.query({
          query: activeDealerTypeQuery,
          variables: {
            dealerNo: dealerNo
          },
          fetchPolicy: 'network-only'
        });
        const { data: customerNotes } = await client.query({
          query: notesQuery,
          variables: {
            customerNo: dealerNo + '',
            type: CUSTOMER_NOTES_TYPES
          },
          fetchPolicy: 'network-only'
        });
        await this.researchFunction(activeDealer, dealerNo, 'searched');
        await this.updateCustomerNotes(customerNotes);
      } else {
        localStore.remove('flashMessage');
        this.props.setSearchFields(variableArguments);
        this.setState({
          isDisable: true,
          invalidDealerNumber: true,
          flashMessage: ''
        });
        return false;
      }
    } catch (e) {
      localStore.remove('flashMessage');
      this.props.setSearchFields(variableArguments);
      this.validDealerNoRange(dealerNo)
        ? this.setState({
            invalidDealerNumber: false,
            isDisable: false,
            notFoundSubscription: true,
            flashMessage: ''
          })
        : this.setState({
            invalidDealerNumber: true,
            isDisable: true,
            notFoundSubscription: false,
            flashMessage: ''
          });
    }
  };

  researchFunction = async (data, dealerNos, action) => {
    const result = get(data, 'activeDealer');

    const status = !isEmpty(result) ? result.subscriptionStatus : '';
    const dealerName = !isEmpty(result) ? result.dealerName : '';
    const dealerOnIsl =
      !isEmpty(result) && result.dealerOnIsl !== null
        ? result.dealerOnIsl
        : false;
    const activeOrInactive = status === 'ACTIVE' || status === 'INACTIVE';
    action === 'created'
      ? localStore.set('flashMessage', 'Successfully Created Subscription.')
      : localStore.remove('flashMessage');
    const variableArguments = {
      dealerNumbers: dealerNos + ''
    };
    this.setState({ dealerNumberTemp: dealerNos + '' });
    this.props.setDealerActive({
      isDealerOnIsl: dealerOnIsl,
      dealerName: dealerName,
      dealerNumber: dealerNos + ''
    });
    if (activeOrInactive) {
      this.props.data.refetch({
        ...variableArguments
      });

      this.props.setSearchFields(variableArguments);
      this.props.setPage({});
      this.setState({
        isDisable: true,
        invalidDealerNumber: false,
        notFoundSubscription: false,
        flashMessage: action === 'created' ? this.state.flashMessage : '',
        errors: {}
      });
    } else {
      this.props.setSearchFields(variableArguments);
      this.setState({
        isDisable: false,
        invalidDealerNumber: false,
        notFoundSubscription: true,
        flashMessage: action === 'created' ? this.state.flashMessage : ''
      });
      return false;
    }
  };

  onNewSubscription = () => {
    this.setState({
      isNewModalOpen: true,
      isEditModalOpen: false
    });
  };

  onCloseConfirmationHandler = () => {
    this.setState({
      isNewModalOpen: false,
      newFormSubmitting: false,
      newFormError: null,
      onOpenConfirmationPopup: false,
      isConfirmationPopup: false,
      query: false
    });
  };

  onContinueCreateSubscription = () => {
    this.setState({
      isNewModalOpen: true,
      newFormSubmitting: false,
      newFormError: null,
      onOpenConfirmationPopup: false,
      isConfirmationPopup: false
    });
  };

  // start process for customer notes
  buildCustomerInput = values => {
    return {
      customerNotes: {
        updatedBy: this.props.user.email,
        customerNo: this.props.searchFields.dealerNumbers,
        type: 'SUBSCRIPTION',
        ...values
      }
    };
  };

  onNotesFormCloseHandler = () => {
    this.setState({
      isNotesModalOpen: false,
      notesFormError: null,
      isCustomerNotesFlashMessage: false
    });
  };

  onNotesFormSubmitHandler = async values => {
    const {
      createCustomerNotesMutation,
      updateCustomerNotesMutation,
      deleteCustomerNotesMutation,
      customerNotes
    } = this.props;
    this.startSubmitNotes(); // reset some state for customer notes
    try {
      switch (this.state.customerNotesAction) {
        case 'Create':
          await this.onExecuteMutationustomerNotes(
            createCustomerNotesMutation,
            CREATE_CUSTOMER_NOTES_MUTATION_NAME,
            values,
            null
          );
          break;
        case 'Update':
          await this.onExecuteMutationustomerNotes(
            updateCustomerNotesMutation,
            UPDATE_CUSTOMER_NOTES_MUTATION_NAME,
            values,
            customerNotes
          );
          break;
        case 'Clear':
          await this.onExecuteMutationustomerNotes(
            deleteCustomerNotesMutation,
            DELETE_CUSTOMER_NOTES_MUTATION_NAME,
            values,
            customerNotes
          );
          break;
      }
    } catch (error) {
      this.onFailedSubmitNotes(Array.isArray(error) ? error : error.message);
    }
  };

  handleNotesFormStateChange = action => {
    if (this.state.customerNotesAction !== action) {
      this.setState({ customerNotesAction: action });
    }
  };

  startSubmitNotes = () => {
    this.setState({
      notesFormSubmitting: true,
      notesFormError: null
    });
  };

  successSubmitNotes = action => {
    this.setState({
      notesFormSubmitting: false,
      notesFormError: null,
      flashMessage: `Successfully ${action} Customer Notes.`
    });
  };

  onFailedSubmitNotes = errorMessage => {
    this.setState({
      notesFormSubmitting: false,
      notesFormError: errorMessage
    });
  };

  handleRefetchCustomerNotes = async () => {
    try {
      const { data: customerNotes } = await client.query({
        query: notesQuery,
        variables: {
          customerNo: this.props.searchFields.dealerNumbers + '',
          type: CUSTOMER_NOTES_TYPES
        },
        fetchPolicy: 'network-only'
      });
      this.updateCustomerNotes(customerNotes);
    } catch (error) {
      this.onFailedSubmitNotes(Array.isArray(error) ? error : error.message);
    }
  };

  updateCustomerNotes = data => {
    const result = get(data, 'customerNotes');
    this.props.setCustomerNotes(result);
  };

  getCustomerMutationInput = (values, customerNotes) => {
    switch (this.state.customerNotesAction) {
      case 'Create':
        return { CreateCustomerNotesInput: this.buildCustomerInput(values) };
      case 'Update':
        return {
          UpdateCustomerNotesInput: {
            ...this.buildCustomerInput({
              ...values,
              noteId: customerNotes.noteId
            })
          }
        };
      case 'Clear':
        return { DeleteCustomerNotesInput: { id: customerNotes.noteId || 0 } };
    }
  };

  getNotesButtonText = () => {
    if (this.state.customerNotesAction === 'Clear') {
      return 'Removed';
    } else {
      return `${this.state.customerNotesAction}d`;
    }
  };
  onExecuteMutationustomerNotes = async (
    mutation,
    mutationResultName,
    values,
    customerNotes
  ) => {
    let input = this.getCustomerMutationInput(values, customerNotes);
    await mutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { success, msg } = data[mutationResultName];
        if (success) {
          this.successSubmitNotes(this.getNotesButtonText());
          this.handleRefetchCustomerNotes();
          this.onNotesFormCloseHandler();
          if (mutationResultName === DELETE_CUSTOMER_NOTES_MUTATION_NAME) {
            this.setState({ customerNotesAction: 'Create' });
          }
        } else {
          this.onFailedSubmitNotes(msg);
        }
      })
      .catch(error => {
        this.onFailedSubmitNotes(Array.isArray(error) ? error : error.message);
      });
  };

  // end process for customer notes

  showDealer = () => {
    const dealerName = this.getDealerName();
    const dealerNumber = this.getDealerNumber();

    if (isEmpty(dealerNumber) && isEmpty(dealerName)) {
      return '';
    } else if (!isEmpty(dealerNumber) && isEmpty(dealerName)) {
      return dealerNumber;
    }
    return `${dealerNumber} - ${dealerName}`;
  };

  getDealerNumber = () => {
    if (!isEmpty(this.props.subscriptionDealer.dealerNumber)) {
      return this.props.subscriptionDealer.dealerNumber;
    } else if (!isEmpty(this.state.dealerNumberTemp)) {
      return this.state.dealerNumberTemp;
    }
  };

  getDealerName = () => {
    if (!isEmpty(this.props.subscriptionDealer.dealerName)) {
      return this.props.subscriptionDealer.dealerName;
    } else {
      return '';
    }
  };
  render() {
    const {
      selectedSubscription,
      isEditModalOpen,
      editFormError,
      editFormSubmitting,
      isNewModalOpen,
      newFormError,
      newFormSubmitting,
      flashMessage,
      invalidDealerNumber,
      isOpenPopup,
      isDisable,
      notFoundSubscription,
      isPreferencesModalOpen,
      preferencesFormError,
      preferencesFormSubmitting,
      isNotesModalOpen,
      notesFormSubmitting,
      notesFormError,
      isCustomerNotesFlashMessage
    } = this.state;

    const {
      data: { subscription },
      searchFields,
      subscriptionDealer,
      customerNotes
    } = this.props;
    const isDealerOnIsl = subscriptionDealer.isDealerOnIsl;
    const dealerName = subscriptionDealer.dealerName;
    const dealerNumber = subscriptionDealer.dealerNumber;

    let newSubscription = [];
    newSubscription.push({
      dealerNumber: dealerNumber + '',
      dealerName: dealerName + '',
      productId: 1,
      productName: '',
      subscriptionType: '',
      priceDs: null,
      pricePsiLpi: null,
      notifyEmail: '',
      networkPlusRate: null,
      startDate: null,
      endDate: null
    });
    const newSubscriptionData =
      !isEmpty(subscription) &&
      subscription[0].dealerNumber === parseInt(dealerNumber)
        ? subscription
        : newSubscription;

    let sortedSubscription = subscription
      .slice()
      .filter(subscription => subscription.active === false)
      .sort((a, b) =>
        moment(new Date(a.endDate)).format('YYYY-MM-DD') >
        moment(new Date(b.endDate)).format('YYYY-MM-DD')
          ? -1
          : 1
      );

    return (
      <div>
        {flashMessage &&
          !isCustomerNotesFlashMessage &&
          !isEmpty(subscription) && (
            <Message
              onDismiss={this.handleMessageDismiss}
              content={flashMessage}
              positive
            />
          )}
        {flashMessage && isCustomerNotesFlashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
          />
        )}
        {isEmpty(subscription) &&
          !isEmpty(dealerNumber) &&
          !invalidDealerNumber &&
          !notFoundSubscription && <Message>No Records found</Message>}

        {notFoundSubscription && !invalidDealerNumber && (
          <Message>No Records found</Message>
        )}

        {isDealerOnIsl && (
          <Message color={'red'}>
            This dealer is currently on the ISL. Please contact Audit team for
            further information.
          </Message>
        )}

        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['dealerNumbers']}
          onNewSubscription={this.onNewSubscription}
          isDisabledBtn={!!(isDealerOnIsl || isDisable)}
        />
        {invalidDealerNumber ? (
          <div>
            <p style={{ color: 'red' }}>Must be a valid Dealer Number</p>
            <SubscriptionsList
              subscriptions={[]}
              dealerInfo={this.showDealer()}
            />
            <div style={pagingStyle}>
              <div>
                <span>
                  Showing <b>0 - 0</b>&nbsp;&nbsp;
                </span>
                <button
                  className="ui green icon inverted disabled button"
                  disabled="disabled"
                  role="button"
                  tabIndex="-1"
                >
                  <i aria-hidden="true" className="caret left icon" />
                </button>
                <button
                  className="ui green icon inverted disabled button"
                  disabled="disabled"
                  role="button"
                  tabIndex="-1"
                >
                  <i aria-hidden="true" className="caret right icon" />
                </button>
                <br />
              </div>
            </div>
          </div>
        ) : notFoundSubscription ? (
          <div>
            <SubscriptionsList
              isDisable={!!isDealerOnIsl}
              dealerInfo={this.showDealer()}
            />
            <div style={pagingStyle}>
              <div>
                <span>
                  Showing <b>0 - 0</b>&nbsp;&nbsp;
                </span>
                <button
                  className="ui green icon inverted disabled button"
                  disabled="disabled"
                  role="button"
                  tabIndex="-1"
                >
                  <i aria-hidden="true" className="caret left icon" />
                </button>
                <button
                  className="ui green icon inverted disabled button"
                  disabled="disabled"
                  role="button"
                  tabIndex="-1"
                >
                  <i aria-hidden="true" className="caret right icon" />
                </button>
                <br />
              </div>
            </div>
          </div>
        ) : (
          <SubscriptionsList
            onSelectSubscription={this.onSelectSubscription}
            subscriptions={subscription}
            isDisable={!!isDealerOnIsl}
            customerNotes={customerNotes}
            dealerInfo={this.showDealer()}
          />
        )}

        {(isOpenPopup || isNewModalOpen) && (
          <SubscriptionNew
            isModalOpen={isNewModalOpen}
            onCloseHandler={this.onNewFormCloseHandler}
            onSubmitHandler={this.onNewFormSubmitHandler}
            error={newFormError}
            submitting={newFormSubmitting}
            subscription={newSubscriptionData}
            onOpenConfirmationPopup={isOpenPopup}
            onNewSubscription={this.onNewSubscription}
            onCloseConfirmationPopup={this.onCloseConfirmationHandler}
            onContinueCreateSubscription={this.onContinueCreateSubscription}
            isConfirmationPopup={this.state.isConfirmationPopup}
            dealerInfo={this.showDealer()}
          />
        )}

        {selectedSubscription && isEditModalOpen && (
          <div>
            <SubscriptionEdit
              isModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              onSubmitHandler={this.onEditFormSubmitHandler}
              subscription={selectedSubscription}
              subscriptions={sortedSubscription}
              error={editFormError}
              submitting={editFormSubmitting}
            />
          </div>
        )}

        {selectedSubscription && isPreferencesModalOpen && (
          <SubscriptionPreferences
            isModalOpen={isPreferencesModalOpen}
            onCloseHandler={this.onPreferencesFormCloseHandler}
            onSubmitHandler={this.onPreferencesFormSubmitHandler}
            subscription={selectedSubscription}
            error={preferencesFormError}
            submitting={preferencesFormSubmitting}
          />
        )}

        {isNotesModalOpen && (
          <SubscriptionNotesForm
            isModalOpen={isNotesModalOpen}
            onCloseHandler={this.onNotesFormCloseHandler}
            onSubmitHandler={this.onNotesFormSubmitHandler}
            onStateChange={this.handleNotesFormStateChange}
            error={notesFormError}
            submitting={notesFormSubmitting}
            customerNotes={customerNotes}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  resetSearchFields: field => {
    dispatch(resetSearch(field));
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  },
  clearPage: fields => {
    dispatch(clearPage());
  },
  setDealerActive: value => {
    dispatch(setDealerActive(value));
  },
  resetDealerActive: () => {
    dispatch(resetDealerActive());
  },
  setCustomerNotes: field => {
    dispatch(setCustomerNotes(field));
  }
});

const mapStateToProps = state => {
  const {
    data: {
      searchFields,
      refreshSearch,
      subscriptionDealer,
      customerNotes,
      user
    }
  } = state;
  return {
    searchFields,
    refreshSearch,
    subscriptionDealer,
    customerNotes,
    user
  };
};

const pagingStyle = {
  background: 'white',
  position: 'absolute',
  right: '2.56%',
  paddingTop: '14px',
  fontSize: '15px',
  zIndex: '1'
};

export default compose(
  withRouter,
  withPagination(subscriptionQuery, 'subscription'),
  graphql(createSubscriptionsMutation, { name: 'createMutation' }),
  graphql(updateSubscriptionsMutation, { name: 'updateMutation' }),
  graphql(updatePreferencesMutation, { name: 'updatePreferencesMutation' }),
  graphql(notesQuery, { name: 'notesQuery' }),
  graphql(createCustomerNotesMutation, { name: 'createCustomerNotesMutation' }),
  graphql(updateCustomerNotesMutation, { name: 'updateCustomerNotesMutation' }),
  graphql(deleteCustomerNotesMutation, { name: 'deleteCustomerNotesMutation' }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(Subscriptions);
