import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header, Button } from 'semantic-ui-react';
import { COLOR_CODE_MAPPINGS } from '../../constants';

import ListRow from './ListRow';

class MarketFactorsList extends Component {
  render() {
    const {
      onSelectMarketFactor,
      marketFactors,
      openMarketFactorForm
    } = this.props;

    const listRow = ListRow(onSelectMarketFactor, COLOR_CODE_MAPPINGS);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Title</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Color</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Market Factors</Header>
        <Button color="green" onClick={openMarketFactorForm} inverted>
          New Market Factor
        </Button>
        <Table
          celled
          selectable
          padded
          renderBodyRow={listRow}
          tableData={marketFactors}
          headerRow={headerRow}
          size="small"
          striped
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(MarketFactorsList);
