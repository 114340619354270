import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import App from './App';
import store from './store';
import client from './apollo';
import registerServiceWorker from './registerServiceWorker';
import validatorAttributeFormatter from './validatorAttributeFormatter';
import './assets/react-datepicker-fix.css';
import './assets/semantic-ui-fix.css';

ReactDOM.render(
  <IntlProvider locale="en">
    <Provider store={store}>
      <ApolloProvider client={client}>
        <HashRouter>
          <App />
        </HashRouter>
      </ApolloProvider>
    </Provider>
  </IntlProvider>,
  document.getElementById('ds-admin-root')
);
registerServiceWorker();
validatorAttributeFormatter();
