import { gql } from 'react-apollo';

const networkPlusOfferQuery = gql`
  query networkPlusOffer($id: ID) {
    networkPlusOffer(id: $id) {
      id
      dealerNo
      dealerName
      units
      price
      miles
      eligibleForAcceptance
      approved
      rejected
      maxModelYear
      maxOdometerLimit
      maxVehicleValue
    }
  }
`;

export default networkPlusOfferQuery;
