import { gql } from 'react-apollo';

const networkPlusOfferRejectMutation = gql`
  mutation rejectNetworkPlusOffer($id: ID!) {
    rejectNetworkPlusOffer(input: { id: $id }) {
      success
      errors
    }
  }
`;

export default networkPlusOfferRejectMutation;
