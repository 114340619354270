import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import AuctionExceptionList from './List';
import { Button, Confirm, Message } from 'semantic-ui-react';
import AuctionExceptionForm from './AuctionException/Form';
import client from '../../apollo';
import apmsLocationListQuery from '../../graphql/apmsLocationListQuery';
import { get, toNumber } from 'lodash';
import { setPage } from '../../data/page/actions';
import { connect } from 'react-redux';
import withPagination from '../../containers/WithPagination';
import autoCancellationExceptionListQuery from '../../graphql/autoCancellationExceptionListQuery';
import { graphql } from 'react-apollo';
import createAutoCancellationExceptionMutation from '../../graphql/createAutoCancellationExceptionMutation';
import updateAutoCancellationExceptionMutation from '../../graphql/updateAutoCancellationExceptionMutation';
import deleteAutoCancellationExceptionMutation from '../../graphql/deleteAutoCancellationExceptionMutation';

const defaultAuctionException = {
  id: 0,
  auctionLocation: '',
  gracePeriod: '',
  exceptionReason: '',
  exceptionEndDate: '',
  createdBy: '',
  createdAt: '',
  lastUpdatedBy: '',
  lastUpdatedAt: ''
};
const fieldsToCompare = ['gracePeriod', 'exceptionEndTime', 'exceptionReason'];

class AuctionExceptions extends Component {
  state = {
    selectedAuctionException: null,
    isDeleteConfirmationOpen: false,
    isModalOpen: false,
    formError: null,
    formSubmitting: null,
    flashMessage: null,
    isNegativeFlashMessage: false,
    locations: []
  };

  isChange = (values, selectedAuctionException, fieldsToCompare) => {
    for (const field of fieldsToCompare) {
      if (values[field] !== selectedAuctionException[field]) {
        return true;
      }
    }
    return false;
  };
  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  onSelectAuctionException = (action, auctionException) => {
    this.setState({
      selectedAuctionException: auctionException,
      flashMessage: null,
      isNegativeFlashMessage: false
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isModalOpen: true
        });
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      default:
        this.setState({
          selectedAuctionException: null
        });
    }
  };

  onFormCloseHandler = () => {
    this.setState({
      isModalOpen: false,
      selectedAuctionException: null,
      formError: null,
      formSubmitting: null
    });
  };

  onClickNewAuction = () => {
    this.setState({
      selectedAuctionException: defaultAuctionException,
      isModalOpen: true
    });
  };
  onFormSubmitHandler = async values => {
    const {
      createAutoCancellationExceptionMutation,
      updateAutoCancellationExceptionMutation
    } = this.props;
    const { selectedAuctionException } = this.state;
    this.setState({
      formSubmitting: true
    });
    const action = values['action'];
    try {
      switch (action) {
        case 'Create':
          await this.onExcuteMutationAuctionException(
            createAutoCancellationExceptionMutation,
            'createAutoCancellationException',
            values,
            action
          );
          break;
        case 'Update':
          if (
            this.isChange(values, selectedAuctionException, fieldsToCompare)
          ) {
            await this.onExcuteMutationAuctionException(
              updateAutoCancellationExceptionMutation,
              'updateAutoCancellationException',
              values,
              action
            );
          } else {
            // unchanged
            this.onFormCloseHandler();
          }
          break;
      }
    } catch (error) {
      this.onFailedSubmitAuctionException(
        action,
        Array.isArray(error) ? error : error.message
      );
    }
  };

  handleDeleteConfirm = async () => {
    const {
      selectedAuctionException: { id }
    } = this.state;
    const { deleteAutoCancellationExceptionMutation } = this.props;
    const action = 'Delete';
    try {
      await this.onExcuteMutationAuctionException(
        deleteAutoCancellationExceptionMutation,
        'deleteAutoCancellationException',
        { id: id },
        action
      );
    } catch (error) {
      this.onFailedSubmitAuctionException(
        action,
        Array.isArray(error) ? error : error.message
      );
    }
  };

  locationGetAllQuery = () => {
    client
      .query({
        query: apmsLocationListQuery,
        variables: {
          page: 1,
          size: 200
        }
      })
      .then(({ data }) => {
        this.setState({
          locations: get(data, 'apmsLocationList')
        });
      })
      .catch(error => {
        this.setState({ locations: [] });
      });
  };

  successSubmitAuctionException = action => {
    this.setState({
      formSubmitting: false,
      formError: null,
      flashMessage: `Successfully ${action}d Auction Exception.`
    });
  };

  onFailedSubmitAuctionException = (action, errorMessage) => {
    if (action === 'Delete') {
      this.setState({
        isDeleteConfirmationOpen: false,
        flashMessage: `Unsuccessfully Deleted Auction Exception with error: ${errorMessage}`,
        isNegativeFlashMessage: true
      });
    } else {
      this.setState({
        formSubmitting: false,
        formError: errorMessage
      });
    }
  };

  getCreateExpiredVraGracePeriodMutationInput = (action, values) => {
    return {
      exceptionType: 'AUCTION',
      exceptionSourceId: toNumber(values['auctionId']),
      gracePeriod: toNumber(values['gracePeriod']),
      exceptionEndTime: values['exceptionEndTime'],
      exceptionReason: values['exceptionReason'],
      id: values['id'] ? toNumber(values['id']) : 0
    };
  };
  onExcuteMutationAuctionException = async (
    mutation,
    mutationResultName,
    values,
    action
  ) => {
    let input = this.getCreateExpiredVraGracePeriodMutationInput(
      action,
      values
    );
    await mutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { success, msg } = data[mutationResultName];
        if (success) {
          this.successSubmitAuctionException(action);
          this.onFormCloseHandler();
          if (action === 'Delete') {
            this.setState({
              isDeleteConfirmationOpen: false
            });
          }
          this.props.setPage({});
          this.props.data.refetch();
        } else {
          this.onFailedSubmitAuctionException(action, msg);
        }
      })
      .catch(error => {
        this.onFailedSubmitAuctionException(
          action,
          Array.isArray(error) ? error : error.message
        );
      });
  };

  handleMessageDismiss = () => {
    this.setState({ flashMessage: null });
  };

  componentWillMount() {
    this.locationGetAllQuery();
  }

  render() {
    const {
      data: { autoCancellationExceptionList }
    } = this.props;
    const {
      selectedAuctionException,
      isDeleteConfirmationOpen,
      formSubmitting,
      isModalOpen,
      formError,
      flashMessage,
      isNegativeFlashMessage,
      locations
    } = this.state;
    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
            negative={isNegativeFlashMessage}
          />
        )}
        <Button inverted color={'green'} onClick={this.onClickNewAuction}>
          New Location Exception
        </Button>
        <br />
        <br />
        <AuctionExceptionList
          onSelectException={this.onSelectAuctionException}
          exceptions={autoCancellationExceptionList}
          exceptionType={'AUCTION'}
        />

        {isModalOpen && (
          <AuctionExceptionForm
            isModalOpen={isModalOpen}
            onCloseHandler={this.onFormCloseHandler}
            onSubmitHandler={this.onFormSubmitHandler}
            auctionException={selectedAuctionException}
            locations={locations}
            error={formError}
            submitting={formSubmitting}
          />
        )}

        <Confirm
          open={isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { user }
  } = state;
  return {
    user: user
  };
};
export default compose(
  withRouter,
  withPagination(
    autoCancellationExceptionListQuery,
    'autoCancellationExceptionList',
    { exceptionTypes: 'AUCTION' }
  ),
  graphql(createAutoCancellationExceptionMutation, {
    name: 'createAutoCancellationExceptionMutation'
  }),
  graphql(updateAutoCancellationExceptionMutation, {
    name: 'updateAutoCancellationExceptionMutation'
  }),
  graphql(deleteAutoCancellationExceptionMutation, {
    name: 'deleteAutoCancellationExceptionMutation'
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(AuctionExceptions);
