import React, { Component } from 'react';
import { Button, Icon, Loader } from 'semantic-ui-react';

class Pagination extends Component {
  render() {
    const {
      start,
      end,
      nextHandler,
      previousHandler,
      loading,
      lastPage,
      pageSize
    } = this.props;

    return (
      <div style={{ float: 'right', margin: '1em 0' }}>
        {loading && (
          <Loader active inline size="mini">
            Loading...
          </Loader>
        )}
        {!loading && (
          <div>
            <span>
              Showing{' '}
              <b>
                {start} - {end}
              </b>&nbsp;&nbsp;
            </span>
            <Button
              icon
              color="green"
              inverted
              disabled={start <= 1}
              onClick={previousHandler}
            >
              <Icon name="caret left" />
            </Button>
            <Button
              icon
              color="green"
              inverted
              disabled={lastPage || end < pageSize}
              onClick={nextHandler}
            >
              <Icon name="caret right" />
            </Button>
            <br />
            {lastPage && <span>No more rows</span>}
          </div>
        )}
      </div>
    );
  }
}

export default Pagination;
