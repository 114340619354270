import { gql } from 'react-apollo';

const deleteDealershipBlacklistMutation = gql`
  mutation deleteDealershipBlacklist($input: DestroyDealershipBlacklistInput!) {
    deleteDealershipBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default deleteDealershipBlacklistMutation;
