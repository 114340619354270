import * as actionType from './actionTypes';

const initialState = [];

const mmrCalculatedValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_MMR_CALCULATED_VALUE:
      const newStateArray = state.filter(
        item => !(item.vin === action.payload.vin)
      );
      newStateArray.splice(state.length, 0, action.payload);
      return newStateArray;

    case actionType.CLEAR_MMR_CALCULATED_VALUE:
      return [];
    default:
      return state;
  }
};

export default mmrCalculatedValueReducer;
