import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import SupportRequestForm from './../views/SupportRequest/SearchForm';

class SupportRequest extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Segment>
          <SupportRequestForm props={this.props} />
        </Segment>
      </div>
    );
  }
}

export default compose(withRouter)(SupportRequest);
