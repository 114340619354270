import React, { Component } from 'react';
import {
  Form,
  Checkbox,
  Button,
  Modal,
  Icon,
  Select,
  Message
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';
import { CANCELATION_REASONS } from '../../constants';

class GuaranteePurchaseCancel extends Component {
  state = {
    guaranteePurchaseId: null,
    cancelationReason: null,
    reason: null,
    ineligibleForRepurchase: false,
    skipApplyingCredit: false,
    errors: {}
  };

  componentDidMount() {
    const { guaranteePurchase } = this.props;
    const { id } = guaranteePurchase;

    this.setState({ guaranteePurchaseId: id });
  }

  render() {
    const {
      guaranteePurchase,
      isCancelModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors, reason } = this.state;

    const onSelectHandler = field => (event, data) => {
      if (field === 'cancelationReason') {
        this.setState({ [field]: data.value, reason: data.value });
      } else {
        this.setState({ [field]: event.target.value });
      }
    };

    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const validationRules = {
      reason: 'required'
    };

    const submitForm = e => {
      const {
        guaranteePurchaseId,
        reason,
        ineligibleForRepurchase,
        skipApplyingCredit
      } = this.state;

      const validation = new Validator(this.state, validationRules);

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          guaranteePurchaseId,
          cancellation: {
            cancelationReason: reason,
            ineligibleForRepurchase,
            skipApplyingCredit
          }
        });
        this.setState({ ...validation.errors });
      }
    };

    const {
      isReturned,
      guaranteeOffer: {
        vehicleInformation: { vin }
      }
    } = guaranteePurchase;

    const maxLengthReason = 255;
    const countRestOfReason = reason
      ? maxLengthReason - reason.length
      : maxLengthReason;

    return (
      <Modal
        open={isCancelModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Cancel Guarantee Purchase for VIN {vin}</Modal.Header>

        {isReturned && (
          <Modal.Content image>
            <Modal.Description>
              <Message negative>
                This vehicle is returned, hence cancel is not allowed.
              </Message>
            </Modal.Description>
          </Modal.Content>
        )}
        {!isReturned && (
          <Modal.Content image>
            <Modal.Description>
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              <Form widths="equal">
                <Form.Field>
                  <Select
                    placeholder="Select cancellation reason"
                    options={CANCELATION_REASONS}
                    closeOnBlur
                    value={this.state.cancelationReason}
                    onChange={onSelectHandler('cancelationReason').bind(this)}
                  />
                </Form.Field>
                <Form.Field
                  style={{ width: '100%' }}
                  required
                  error={!isEmpty(errors.reason)}
                >
                  <label>Reason</label>
                  <input
                    style={{ width: '100% !important' }}
                    name="reason"
                    value={this.state.reason}
                    onChange={onSelectHandler('reason').bind(this)}
                    maxLength={maxLengthReason}
                  />
                  <span style={{ color: 'red', paddingLeft: '86%' }}>
                    {countRestOfReason} characters left
                  </span>

                  {errors.reason && (
                    <div style={{ color: 'red' }}>{errors.reason}</div>
                  )}
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Checkbox
                      label="Ineligible for repurchase"
                      checked={this.state.ineligibleForRepurchase}
                      onChange={onCheckboxHandler(
                        'ineligibleForRepurchase'
                      ).bind(this)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="Skip Applying Credit"
                      checked={this.state.skipApplyingCredit}
                      onChange={onCheckboxHandler('skipApplyingCredit').bind(
                        this
                      )}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Modal.Description>
          </Modal.Content>
        )}
        {!isReturned && (
          <Modal.Actions>
            {submitting && (
              <Button loading positive>
                Submitting...
              </Button>
            )}
            {!submitting && (
              <Button color="green" onClick={submitForm} inverted>
                <Icon name="checkmark" /> Submit
              </Button>
            )}
            <Button onClick={onCloseHandler}>Cancel</Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default GuaranteePurchaseCancel;
