import { gql } from 'react-apollo';

const marketIndexQuery = gql`
  query marketIndex {
    marketIndex {
      id
      notes
      value
      surchargeStatus
      surchargeAmount
      displaySurcharge
      displaySurchargeAmount
      marketFactors {
        id
        title
        description
        color
        status
      }
    }
  }
`;

export default marketIndexQuery;
