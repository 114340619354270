import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { isNumber } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { DEFAULT_PAGE_SIZE } from '../constants';
import Pagination from '../views/Shared/Pagination';
import { clearPage } from '../data/page/actions';

const withPagination = (
  gqlQuery,
  responseField,
  options = {}
) => WrappedComponent => {
  class PaginatedWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 1,
        lastPage: false,
        loading: false
      };
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        prevProps.pageNo !== this.props.pageNo &&
        isNumber(this.props.pageNo)
      ) {
        this.setState({ page: 1, lastPage: false });
      }
    }

    render() {
      const { page, lastPage } = this.state;
      const { data } = this.props;
      const dataSize = (data[responseField] || []).length;
      const start = dataSize > 0 ? (page - 1) * DEFAULT_PAGE_SIZE + 1 : 0;
      const end = start > 0 ? start + dataSize - 1 : 0;

      const nextHandler = () => {
        if (!lastPage) {
          const offset = end;
          this.setState({ loading: true });
          this.props.data.fetchMore({
            variables: {
              size: DEFAULT_PAGE_SIZE,
              offset: offset,
              page: page + 1
            },
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              this.props.clearPage({});
              if (fetchMoreResult[responseField].length > 0) {
                if (fetchMoreResult[responseField].length < DEFAULT_PAGE_SIZE) {
                  this.setState({
                    page: page + 1,
                    loading: false,
                    lastPage: true
                  });
                } else {
                  this.setState({ page: page + 1, loading: false });
                }
                return fetchMoreResult;
              } else {
                this.setState({ lastPage: true, loading: false });
                return previousResult;
              }
            }
          });
        }
      };

      const previousHandler = () => {
        if (page > 1) {
          this.setState({ loading: true, lastPage: false });
          const offset = (page - 2) * DEFAULT_PAGE_SIZE;
          this.props.data.fetchMore({
            variables: {
              size: DEFAULT_PAGE_SIZE,
              offset: offset,
              page: page - 1
            },
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              this.props.clearPage({});
              this.setState({
                page: page - 1,
                loading: false,
                lastPage: false
              });
              return fetchMoreResult;
            }
          });
        }
      };

      return (
        <div>
          <WrappedComponent {...this.props} />
          <Pagination
            nextHandler={nextHandler}
            previousHandler={previousHandler}
            start={start}
            end={end}
            loading={this.state.loading}
            lastPage={lastPage}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    clearPage: fields => {
      dispatch(clearPage());
    }
  });

  const mapStateToProps = state => {
    const { data: { page } } = state;
    return {
      pageNo: page
    };
  };

  const queryVars = Object.assign(
    {
      size: DEFAULT_PAGE_SIZE,
      vin: null,
      dealerNumbers: null,
      dealerNumber: null,
      locationInitial: null,
      auctionAccessNumber: null,
      batchId: null
    },
    options
  );

  return compose(
    graphql(gqlQuery, {
      options: {
        fetchPolicy: 'network-only',
        variables: queryVars
      }
    }),
    connect(mapStateToProps, mapDispatchToProps)
  )(PaginatedWrapper);
};

export default withPagination;
