import { gql } from 'react-apollo';

const externalAuctionLocationsQuery = gql`
  query externalAuctionLocationsQuery {
    externalAuctionLocations {
      id
      initials
      name
      auctionedge_code
      partnerNetwork
      acceptsReturns
    }
  }
`;

export default externalAuctionLocationsQuery;
