import { createAction } from 'redux-actions';
import * as actionType from './actionTypes';

const addDupeEntryVehicleIndex = createAction(
  actionType.ADD_DUPE_ENTRY_VEHICLE_INDEX
);
const removeDupeEntryVehicleIndex = createAction(
  actionType.REMOVE_DUPE_ENTRY_VEHICLE_INDEX
);
const cleanDupeEntryVehicleIndex = createAction(
  actionType.CLEAN_DUPE_ENTRY_VEHICLE_INDEX
);

export {
  addDupeEntryVehicleIndex,
  removeDupeEntryVehicleIndex,
  cleanDupeEntryVehicleIndex
};
