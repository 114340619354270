import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Message } from 'semantic-ui-react';

import LoadingDataEnhancer from './LoadingData';
import withPagination from './WithPagination';
import DateRangeSearch from '../views/Shared/DateRangeSearch';
import GuaranteeHistoryList from '../views/GuaranteeHistory/List';
import Export from '../views/GuaranteeHistory/Export';
import guaranteeHistoryQuery from '../graphql/guaranteeHistoryQuery';
import client from '../apollo';

import {
  setSearch,
  resetSearch,
  clearSearch
} from '../data/searchField/actions';
import { setPage } from '../data/page/actions';
import { setRefreshSearch } from '../data/refreshSearch/actions';

class GuaranteeHistory extends Component {
  state = {
    startDate: null,
    endDate: null,
    completeGuaranteeHistoryReport: [],
    exportClicked: false,
    filename: '',
    fetchedCompleteRecords: false,
    maxEndDate: null
  };

  componentDidMount() {
    document.getElementById('searchButton').style.background = '#13ae38';
    document.getElementById('searchButton').style.color = '#fff';
    document.getElementById('searchButton').style.marginRight = '10px';
    if (this.props.refreshSearch) {
      this.props.dispatchRefreshSearch(false);
      this.props.data.refetch({
        startDate: null,
        endDate: null
      });
      this.props.clearSearchFields({});
    }
  }

  fetchGuaranteeHistory = event => {
    const { startDate, endDate } = event.target;
    this.props.data.refetch({
      startDate: startDate.value,
      endDate: endDate.value
    });
    this.props.setSearchFields({
      startDate: isEmpty(startDate.value)
        ? null
        : moment(new Date(startDate.value), 'YYYY-MM-DD'),
      endDate: isEmpty(endDate.value)
        ? null
        : moment(new Date(endDate.value), 'YYYY-MM-DD')
    });
    this.props.setPage({});
  };

  handleDateChangeRaw = event => {
    event.preventDefault();
  };

  fetchCompleteRecords = event => {
    this.setState({ exportClicked: true });
  };

  render() {
    const {
      data: { guaranteeHistoryReport },
      searchFields
    } = this.props;
    if (
      !isEmpty(searchFields.startDate) &&
      !isEmpty(searchFields.endDate) &&
      !this.state.fetchedCompleteRecords
    ) {
      var startDate = moment(new Date(searchFields.startDate));
      var startDateMomentString = startDate.format('YY-MM-DD');
      var endDate = moment(new Date(searchFields.endDate));
      var endDateMomentString = endDate.format('YY-MM-DD');
      var datesString = `GuaranteeReport-${startDateMomentString}-${endDateMomentString}.csv`;
      client
        .query({
          query: guaranteeHistoryQuery,
          variables: {
            startDate: this.props.searchFields.startDate,
            endDate: this.props.searchFields.endDate,
            size: 0
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          this.setState({
            completeGuaranteeHistoryReport: data.guaranteeHistoryReport,
            exportClicked: false,
            fetchedCompleteRecords: true,
            filename: `${datesString}`
          });
        });
    }

    const onDateChangeHandler = (field, date) => {
      this.props.resetSearchFields(field);
      if (field === 'startDate') {
        this.props.setSearchFields({
          startDate: moment(new Date(date), 'YYYY-MM-DD'),
          endDate: null
        });
        this.props.data.refetch({
          startDate: null,
          endDate: null
        });
      }
      this.setState({
        [field]: moment(new Date(date), 'YYYY-MM-DD'),
        changed: { [field]: true }
      });
    };

    const clearDates = () => {
      this.props.clearSearchFields({});
      this.setState({ startDate: null, endDate: null });
      this.props.data.refetch({
        startDate: null,
        endDate: null
      });
    };

    return (
      <div>
        <DateRangeSearch
          onDateChangeHandler={onDateChangeHandler}
          clearDates={clearDates}
          searchFields={searchFields}
          value={this.state}
          onSearchHandler={this.fetchGuaranteeHistory}
          handleDateChangeRaw={this.handleDateChangeRaw}
        />
        <Export
          searchFields={searchFields}
          completeRecords={this.fetchCompleteRecords}
          completeGuaranteeHistoryReport={
            this.state.completeGuaranteeHistoryReport
          }
          filename={this.state.filename}
        />
        <br />
        {(!isEmpty(searchFields.startDate) || !isEmpty(this.state.startDate)) &&
          (!isEmpty(searchFields.endDate) || !isEmpty(this.state.endDate)) &&
          !isEmpty(guaranteeHistoryReport) && (
            <GuaranteeHistoryList guaranteeHistory={guaranteeHistoryReport} />
          )}
        {!isEmpty(searchFields) && isEmpty(guaranteeHistoryReport) && (
          <Message>No Records found</Message>
        )}
        {isEmpty(searchFields) && isEmpty(guaranteeHistoryReport) && (
          <Message negative>
            Please enter start date and end date and press the SEARCH button to
            proceed
          </Message>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  resetSearchFields: field => {
    dispatch(resetSearch(field));
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields, refreshSearch }
  } = state;
  return {
    searchFields,
    refreshSearch
  };
};

export default compose(
  withRouter,
  withPagination(guaranteeHistoryQuery, 'guaranteeHistoryReport'),
  LoadingDataEnhancer,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GuaranteeHistory);
