import { gql } from 'react-apollo';

// the value of exceptionTypes must be <exceptionType1,exceptionType2> like `DEALER,AUCTION,GROUP`
const autoCancellationExceptionListQuery = gql`
  query autoCancellationExceptionList(
    $exceptionTypes: String!
    $size: Int
    $page: Int
  ) {
    autoCancellationExceptionList(
      exceptionTypes: $exceptionTypes
      size: $size
      page: $page
    ) {
      createdAt
      createdBy
      exceptionSourceName
      exceptionSourceId
      exceptionEndTime
      exceptionReason
      exceptionType
      gracePeriod
      id
      updatedAt
      updatedBy
    }
  }
`;

export default autoCancellationExceptionListQuery;
