import { gql } from 'react-apollo';

const auctionAccessBlackListsQuery = gql`
  query auctionAccessBlackListsQuery($auctionAccessNumber: String, $size: Int, $offset: Int) {
    auctionAccessBlackLists(auctionAccessNumber: $auctionAccessNumber, offset: $offset, size: $size) {
      id
      auctionAccessNo
      expirationDate
      notes
      createdAt
      status
    }
  }
`;

export default auctionAccessBlackListsQuery;
