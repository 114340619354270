import { gql } from 'react-apollo';

const dupeExternalVehicleQuery = gql`
  query dupeExternalVehicleQuery($vin: String!, $dealerNumber: String!) {
    dupeExternalVehicle(vin: $vin, dealerNumber: $dealerNumber) {
      id
      vin
      dealerNo
      dealerName
      status
      locationInitials
    }
  }
`;

export default dupeExternalVehicleQuery;
