import React, { Component } from 'react';
import { Accordion } from 'semantic-ui-react';

import OriginalPurchaseTransaction from './OriginalPurchaseTransaction';
import PaymentAndFees from './PaymentAndFees';
import TitleStatus from './TitleStatus';
import ReturnInformation from './ReturnInformation';
import DisbursementInformation from './DisbursementInformation';
import SystemInformation from './SystemInformation';
import ResaleInformation from './ResaleInformation';

class VehicleDetails extends Component {
  render() {
    const {
      vehicleRecord,
      isAuctionEdgeLocation,
      activeIndex,
      handleClick,
      paymentStatus,
      formatField,
      formatDate,
      formatDateTime,
      formatCurrency,
      vehicleNetColourComparison
    } = this.props;
    return (
      <Accordion fluid styled exclusive={true}>
        <OriginalPurchaseTransaction
          vehicleRecord={vehicleRecord}
          formatField={formatField}
          formatDate={formatDate}
          isAuctionEdgeLocation={isAuctionEdgeLocation}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
        <PaymentAndFees
          vehicleRecord={vehicleRecord}
          formatField={formatField}
          formatDate={formatDate}
          formatDateTime={formatDateTime}
          formatCurrency={formatCurrency}
          activeIndex={activeIndex}
          handleClick={handleClick}
          paymentStatus={paymentStatus}
          vehicleNetColourComparison={vehicleNetColourComparison}
        />
        <TitleStatus
          vehicleRecord={vehicleRecord}
          formatField={formatField}
          formatDate={formatDate}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
        <div style={{ backgroundColor: 'black' }}>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <ReturnInformation
          vehicleRecord={vehicleRecord}
          formatField={formatField}
          formatDate={formatDate}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
        <ResaleInformation
          vehicleRecord={vehicleRecord}
          formatField={formatField}
          formatDate={formatDate}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
        <DisbursementInformation
          vehicleRecord={vehicleRecord}
          formatField={formatField}
          formatDate={formatDate}
          formatCurrency={formatCurrency}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
        <SystemInformation
          formatField={formatField}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
      </Accordion>
    );
  }
}

export default VehicleDetails;
