import React from 'react';
import { Grid, Header, Label, Accordion, Icon } from 'semantic-ui-react';

const TitleStatus = ({
  vehicleRecord,
  formatField,
  formatDate,
  activeIndex,
  handleClick
}) => {
  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(2)}
        index={2}
        onClick={handleClick}
        className={'accordion-title'}
      >
        Original Purchase Title Status
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(2)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Header size="small">
            <a href={process.env.REACT_APP_VAULT_URL} target="_blank">
              Link to Vault
            </a>
          </Header>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vault Status</strong>
              <Label basic>{formatField('origVehVaultStatus')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Received Date</strong>
              <Label basic>{formatDate('origVehTitleRecdDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Validated Date</strong>
              <Label basic>{formatDate('origVehTitleValdDate')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Comments</strong>
              <Label basic>{formatField('origVehTitleStatus')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Shipped Date</strong>
              <Label basic>{formatDate('origVehTitleShippedOnDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Tracking Number/Notes</strong>
              <Label basic>{formatField('origVehTitleTrackingNum')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Sent to Floorplan</strong>
              <Label basic></Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Problem Title</strong>
              <Label basic></Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Returned to Seller</strong>
              <Label basic></Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default TitleStatus;
