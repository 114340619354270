import gql from 'graphql-tag';

const createCustomerNotesMutation = gql`
  mutation createCustomerNotes(
    $CreateCustomerNotesInput: CreateCustomerNotesInput!
  ) {
    createCustomerNotes(input: $CreateCustomerNotesInput) {
      success
      msg
    }
  }
`;

export default createCustomerNotesMutation;
