import { gql } from 'react-apollo';

const pingUrlQuery = gql`
  query pingUrlQuery(
    $scope: String
    $redirect_uri: String
    $user_type: String
    $app_name: String
  ) {
    pingUrl(
      scope: $scope
      redirect_uri: $redirect_uri
      user_type: $user_type
      app_name: $app_name
    ) {
      url
    }
  }
`;

export default pingUrlQuery;
