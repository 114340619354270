import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Table, Modal, Message, Button } from 'semantic-ui-react';
import { map, isEmpty } from 'lodash';
import { addDupeEntryVehicleIndex } from '../../data/duplicateEntryVehicleIndex/actions';

class DuplicateEntryVehicleModal extends Component {
  state = {
    message: ''
  };

  removeDuplicateEntryVehicle = (vin, dealerNumber) => event => {
    const { values, dispatch } = this.props;
    let vehicles = values.vehicles;
    vehicles.filter(
      (item, index) => !(item.vin === vin && item.dealerNo === dealerNumber)
    );

    vehicles.map((item, index) => {
      if (item.vin === vin && item.dealerNo === dealerNumber) {
        dispatch(
          addDupeEntryVehicleIndex({
            vin: item.vin,
            dealerNumber: item.dealerNo,
            index: index
          })
        );
        this.setState({
          message: 'Deleted the record successfully with VIN ' + item.vin
        });
      }
      return {};
    });
  };

  render() {
    const {
      duplicateEntryVehicles,
      onDupeEntryVehicleModalCloseHandler,
      isDupeEntryVehicleModalOpen,
      keepDuplicateEntryVehicle,
      keepDuplicateEntryVehicleMessage
    } = this.props;

    const { message } = this.state;

    const TableHeader = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>VIN</Table.HeaderCell>
          <Table.HeaderCell>Dealer No</Table.HeaderCell>
          <Table.HeaderCell>Dealer Name</Table.HeaderCell>
          <Table.HeaderCell>Auction Name</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );

    return (
      <Modal
        open={isDupeEntryVehicleModalOpen}
        closeIcon
        onClose={onDupeEntryVehicleModalCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
        size={'large'}
      >
        <Modal.Header>
          A duplicate entry exists in the form for the below VINs:
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {!isEmpty(keepDuplicateEntryVehicleMessage) && (
              <Message positive>
                <p>{keepDuplicateEntryVehicleMessage}</p>
              </Message>
            )}
            {!isEmpty(message) && (
              <Message positive>
                <p>{message}</p>
              </Message>
            )}
            <Table celled singleLine verticalAlign="top">
              {TableHeader}
              <Table.Body>
                {map(duplicateEntryVehicles, vehicle => (
                  <Table.Row key={vehicle.vin}>
                    <Table.Cell>{vehicle.vin}</Table.Cell>
                    <Table.Cell>{vehicle.dealerNumber}</Table.Cell>
                    <Table.Cell>{vehicle.dealerName}</Table.Cell>
                    <Table.Cell>{vehicle.auctionName}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={keepDuplicateEntryVehicle(
                          vehicle.vin,
                          vehicle.dealerNumber
                        )}
                      >
                        Continue
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={this.removeDuplicateEntryVehicle(
                          vehicle.vin,
                          vehicle.dealerNumber
                        )}
                      >
                        Delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    data: { duplicateEntryVehicles },
    form: {
      npForm: { values }
    }
  } = state;
  return {
    duplicateEntryVehicles,
    values
  };
};

export default compose(connect(mapStateToProps))(DuplicateEntryVehicleModal);
