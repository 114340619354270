import React, { Component } from 'react';
import SubscriptionForm from './SubscriptionForm';

class Edit extends Component {
  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting,
      subscription
    } = this.props;

    return (
      <SubscriptionForm
        isModalOpen={isModalOpen}
        onCloseHandler={onCloseHandler}
        onSubmitHandler={onSubmitHandler}
        error={error}
        submitting={submitting}
        subscription={subscription}
      />
    );
  }
}

export default Edit;
