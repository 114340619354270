import React from 'react';
import { Form, Table, Checkbox, Input } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

const ListRow = (
  actionHandler,
  fieldUpdateHandler,
  chosenIndex,
  editedRate,
  baseRate,
  isRevise,
  errors
) => (offer, index) => {
  const {
    productName,
    rate,
    incompleteEnrollment,
    basePriceDs,
    daysPsi
  } = offer;
  const previousQuoteRateShow =
    isRevise &&
    incompleteEnrollment &&
    productName === incompleteEnrollment.productName;
  const offerRate = previousQuoteRateShow ? incompleteEnrollment.price : rate;
  const editedBaseRate = previousQuoteRateShow
    ? incompleteEnrollment.basePriceDs
    : basePriceDs;
  const ZeroRate = rate > 0 ? rate : 'N/A';
  const handleRadioButtonChange = field => (event, data) => {
    event.preventDefault();
    actionHandler(data.value);
    fieldUpdateHandler('editedRate', null);
    fieldUpdateHandler('baseRate', editedBaseRate);
  };

  const onChangeHandler = field => event => {
    event.preventDefault();
    fieldUpdateHandler(field, event.target.value);
  };

  return (
    <Table.Row key={index}>
      <Table.Cell>
        <Form.Field>
          <Checkbox
            radio
            value={index}
            checked={index === chosenIndex}
            onChange={handleRadioButtonChange('selectedOffer').bind(this)}
          />
          {` ${productName} `}
        </Form.Field>
      </Table.Cell>
      <Table.Cell>
        {ZeroRate === 'N/A' && <Form.Field>{`${ZeroRate}`}</Form.Field>}
        {ZeroRate !== 'N/A' && <Form.Field>{`$${ZeroRate}`}</Form.Field>}
      </Table.Cell>
      <Table.Cell className="enrollment-link">
        <Form.Field error={!isEmpty(errors.editedRate)}>
          {chosenIndex === index && (
            <Input
              name="editedRate"
              label={{ basic: true, content: '$' }}
              labelPosition="left"
              placeholder={offerRate}
              onChange={onChangeHandler('editedRate').bind(this)}
            />
          )}
          {chosenIndex === index && errors.editedRate && (
            <span>{errors.editedRate}</span>
          )}
        </Form.Field>
      </Table.Cell>
      <Table.Cell className="enrollment-link">
        <Form.Field error={!isEmpty(errors.baseRate)}>
          {chosenIndex === index &&
            parseInt(basePriceDs, 10) === 0 &&
            daysPsi !== null && (
              <Input
                name="baseRate"
                label={{ basic: true, content: '$' }}
                labelPosition="left"
                placeholder={editedBaseRate}
                onChange={onChangeHandler('baseRate').bind(this)}
              />
            )}
          {chosenIndex === index && errors.baseRate && (
            <span>{errors.baseRate}</span>
          )}
        </Form.Field>
      </Table.Cell>
      <Table.Cell className="enrollment-link" />
    </Table.Row>
  );
};

export default ListRow;
