import { gql } from 'react-apollo';

const surchargePriceUploadsQuery = gql`
  query surchargePriceUploadsQuery($size: Int, $offset: Int) {
    surchargePriceUploads(offset: $offset, size: $size) {
      id
      inputFile
      status
      inputFileName
    }
  }
`;

export default surchargePriceUploadsQuery;
