import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { isEmpty, get, map, find, trim } from 'lodash';
import dealerNumbersQuery from '../../graphql/dealerNumbersQuery';
import filteredFlatRatesQuery from '../../graphql/filteredFlatRatesQuery';
import FlatRatesList from './FlatRatesList';

import {
  Form,
  Checkbox,
  Button,
  Modal,
  Select,
  Message,
  Table,
  Label
} from 'semantic-ui-react';
import productMasterQuery from '../../graphql/productMasterQuery';

class NewFlatRateOffer extends Component {
  state = {
    auctionAccessNumber: '',
    govtLast4: '',
    externalUser: false,
    dealers: null,
    noDealersForAuction: false,
    selectedDealerNumbers: [],
    allDealersSelected: false,
    milesSelected: 360,
    daysSelected: 21,
    psiEligible: false,
    daysPsi: null,
    flatRates: null,
    selectedflatRates: [],
    buyerInformation: null,
    formError: null,
    submitting: false,
    productSelected: null,
    productId: null,
    protductType: null,
    productName: null,
    palv: false
  };

  onSearchByDealerHandler(e) {
    e.preventDefault();
    const {
      auctionAccessNumber,
      govtLast4,
      selectedDealerNumbers,
      milesSelected,
      psiEligible,
      daysSelected,
      daysPsi,
      palv,
      productSelected
    } = this.state;
    if (!isEmpty(selectedDealerNumbers) && productSelected !== null) {
      const { client } = this.props;
      this.setState({
        formError: null,
        flatRates: null,
        buyerInformation: null,
        submitting: true
      });
      client
        .query({
          query: filteredFlatRatesQuery,
          variables: {
            auctionAccessNumber: parseInt(auctionAccessNumber, 10),
            dealerNumbers: selectedDealerNumbers,
            miles: milesSelected,
            psi: +psiEligible,
            daysSelected: daysSelected,
            govtLast4: trim(govtLast4),
            daysPsi: daysPsi,
            lv: +palv
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          const flatRates = get(data, 'apmsFlatRates');
          const buyerInformation = get(data, 'buyerInformation');
          this.setState({ flatRates, buyerInformation, submitting: false });
        })
        .catch(error => {
          this.setState({
            formError: 'No 360 rate available',
            submitting: false
          });
        });
    } else {
      this.setState({
        formError: 'Please select atleast one dealer and product',
        submitting: false
      });
    }
  }

  onSearchByAuctionAccessHandler(e) {
    e.preventDefault();
    const { auctionAccessNumber, govtLast4 } = e.target;
    const { client } = this.props;

    if (!isEmpty(auctionAccessNumber.value)) {
      this.setState({
        dealers: null,
        flatRates: null,
        buyerInformation: null,
        selectedDealerNumbers: [],
        allDealersSelected: null,
        submitting: true
      });
      const govtLast4Value = govtLast4 ? govtLast4.value : null;
      client
        .query({
          query: dealerNumbersQuery,
          variables: {
            auctionAccessNumber: auctionAccessNumber.value,
            govtLast4: govtLast4Value
          }
        })
        .then(({ data }) => {
          const dealerAccounts = get(data, 'dealerAccounts');
          if (!isEmpty(dealerAccounts)) {
            this.setState({
              dealers: dealerAccounts,
              noDealersForAuction: false,
              submitting: false
            });
          } else {
            this.setState({
              dealers: null,
              noDealersForAuction: true,
              submitting: false
            });
          }
        })
        .catch(error => {});
    }
  }

  checkDealer(dealers, dealer) {
    let index = dealers.indexOf(find(dealers, ['id', dealer.id]));
    let newDealerObject = Object.assign({}, dealer);
    newDealerObject = Object.assign(newDealerObject, { selected: true });
    dealers[index] = newDealerObject;
    return dealers;
  }

  unCheckDealer(dealers, dealer) {
    let index = dealers.indexOf(find(dealers, ['id', dealer.id]));
    let newDealerObject = Object.assign({}, dealer);
    newDealerObject = Object.assign(newDealerObject, { selected: false });
    dealers[index] = newDealerObject;
    return dealers;
  }

  selectProduct(id) {
    const { data } = this.props;
    const products = get(data, 'products');
    if (products) {
      return find(products, ['productId', id]);
    }
  }

  getProduct() {
    const { data: products } = this.props;
    let selectedProducts = [];

    if (!isEmpty(products)) {
      selectedProducts = products.products.map(product => ({
        key: product.productId,
        value: product.productId,
        text: product.productType
      }));
    }
    return selectedProducts;
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const {
      dealers,
      noDealersForAuction,
      flatRates,
      auctionAccessNumber,
      palv,
      formError
    } = this.state;

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const onSelectProductHandler = field => (event, data) => {
      const product = this.selectProduct(data.value);
      if (product) {
        this.setState({
          milesSelected: product.mileDs,
          daysSelected: product.daysDs,
          daysPsi: product.daysPsi,
          psiEligible: product.daysPsi ? true : false,
          productId: product.productId,
          productSelected: data.value,
          productType: product.productType,
          productName: product.productDescription
        });

        if (product.daysPsi) {
          this.setState({ palv: false });
        }
      }
    };

    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const psiEligibleCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked, palv: false });
    };

    const palvCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked, psiEligible: false });
    };

    const onDealerCheckboxHandler = field => (event, data) => {
      let selectedDealerNumbers = this.state.selectedDealerNumbers;
      let allDealersSelected = false;

      let dealers = Object.assign([], this.state.dealers);
      let dealer = Object.assign({}, find(dealers, ['id', data.value]));

      if (data.checked) {
        selectedDealerNumbers.push(data.value);
        dealers = this.checkDealer(dealers, dealer);
      } else {
        dealers = this.unCheckDealer(dealers, dealer);
        selectedDealerNumbers.pop(data.value);
      }
      this.setState({ selectedDealerNumbers, allDealersSelected, dealers });
    };

    const onallDealersCheckboxHandler = field => (event, data) => {
      let selectedDealerNumbers = [];
      let allDealersSelected = false;
      let dealers = Object.assign([], this.state.dealers);

      if (data.checked) {
        map(dealers, dealer => {
          selectedDealerNumbers.push(dealer.id);
          dealers = this.checkDealer(dealers, dealer);
        });

        allDealersSelected = true;
      } else {
        map(dealers, dealer => {
          selectedDealerNumbers.pop(dealer.id);
          dealers = this.unCheckDealer(dealers, dealer);
        });

        allDealersSelected = false;
      }
      this.setState({ selectedDealerNumbers, allDealersSelected, dealers });
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
        size="large"
      >
        <Modal.Header>Create 360 Offer</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <Form onSubmit={this.onSearchByAuctionAccessHandler.bind(this)}>
              <Form.Group widths="4">
                <Form.Field>
                  <label>Auction Access # [100M]</label>
                  <Form.Input
                    name="auctionAccessNumber"
                    placeholder="Please Enter Auction Access # [100M]"
                    onChange={onSelectHandler('auctionAccessNumber')}
                    value={this.state.auctionAccessNumber}
                  />
                </Form.Field>
                <Form.Field>
                  <label>External User</label>
                  <Checkbox
                    name="externalUser"
                    checked={this.state.externalUser}
                    onChange={onCheckboxHandler('externalUser')}
                  />
                </Form.Field>
                {this.state.externalUser && (
                  <Form.Field>
                    <label>Govt. ID Last4</label>
                    <Form.Input
                      name="govtLast4"
                      placeholder="Please Enter Govt Last4"
                      onChange={onSelectHandler('govtLast4')}
                      value={this.state.govtLast4}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <br />
                  <Form.Button color="green" inverted>
                    Search
                  </Form.Button>
                </Form.Field>
              </Form.Group>
            </Form>
            {formError && (
              <Message negative>
                <p>{formError}</p>
              </Message>
            )}
            {!isEmpty(dealers) && (
              <Form onSubmit={this.onSearchByDealerHandler.bind(this)}>
                <br />
                <br />
                <Label>Select Dealer(s)</Label>
                <Table celled padded size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Checkbox
                          checked={this.state.allDealersSelected}
                          onChange={onallDealersCheckboxHandler(
                            'allDealersSelected'
                          ).bind(this)}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Dealer Number</Table.HeaderCell>
                      <Table.HeaderCell>Dealer Name</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {map(dealers, dealer => (
                      <Table.Row key={dealer.id}>
                        <Table.Cell>
                          <Checkbox
                            value={dealer.id}
                            checked={dealer.selected}
                            onChange={onDealerCheckboxHandler(
                              'selectedDealerNumbers'
                            ).bind(this)}
                          />
                        </Table.Cell>
                        <Table.Cell>{dealer.id}</Table.Cell>
                        <Table.Cell>{dealer.name}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <br />
                <br />
                <Form.Group>
                  <Form.Field width={8}>
                    <Label>Select Product</Label>
                    <br />
                    <br />
                    <Select
                      fluid
                      placeholder="Select Product"
                      options={this.getProduct()}
                      closeOnBlur
                      value={this.state.productSelected}
                      onChange={onSelectProductHandler('product').bind(this)}
                    />
                  </Form.Field>
                </Form.Group>
                <br />
                <br />
                <Form.Group>
                  <Form.Field>
                    <Form.Button color="green" inverted>
                      Generate Offer
                    </Form.Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
            {noDealersForAuction && (
              <Message>
                No dealers found for Auction Access # {auctionAccessNumber}
              </Message>
            )}

            {!isEmpty(flatRates) && (
              <FlatRatesList
                onSubmitHandler={onSubmitHandler}
                {...this.state}
                error={error}
              />
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {(submitting || this.state.submitting) && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const productGraphqlData = graphql(productMasterQuery);
export default compose(
  withApollo,
  productGraphqlData,
  withRouter
)(NewFlatRateOffer);
