import { gql } from 'react-apollo';

const cancelledPurchasesQuery = gql`
  query cancelledPurchasesQuery($vin: String, $size: Int, $offset: Int) {
    cancelledPurchases(vin: $vin, offset: $offset, size: $size) {
      id
      buyerEmail
      cancellingUserName
      auctionAccessNo
      dealerNo
      vin
      universalNo
      guaranteeDaysSelected
      guaranteeMilesSelected
      guaranteeIfBid
      guaranteeSellerPaid
      automaticPurchase
      vehiclePurchasePrice
      guaranteePrice
      inspectionPrice
      cancellationDate
      cancellationReason
      guaranteeOffer {
        guaranteeStatus
      }
    }
  }
`;

export default cancelledPurchasesQuery;
