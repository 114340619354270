import * as actionType from './actionTypes';

const initialState = {};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_PAGE:
      return 1;
    case actionType.CLEAR_PAGE:
      return null;
    default:
      return state;
  }
};

export default pageReducer;
