import React from 'react';
import { Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

const ListRow = (
  showProductSelection,
  isUpgradeAvailable,
  displayDealerConfirm,
  handleOpen,
  isAllOffersZero
) => (enrollment, index) => {
  const {
    currentlyEnrolled,
    dealerNo,
    groupName,
    productName,
    productType,
    incompleteEnrollment,
    name,
    rate,
    networkplus,
    networkplusRate,
    days,
    localLinkedFlag,
    closedFactoryExclusion
  } = enrollment;

  const removeLocallyDealerHandler = e => {
    e.preventDefault();
    displayDealerConfirm(dealerNo);
  };

  const resendMailHandler = e => {
    e.preventDefault();
    handleOpen(incompleteEnrollment);
  };

  const showProductSelectionHandler = e => {
    e.preventDefault();
    const isRevise = incompleteEnrollment !== null;
    const isUpgrade = currentlyEnrolled === 'Y';
    showProductSelection(dealerNo, name, isRevise, isUpgrade);
  };

  const groupContent =
    isEmpty(groupName) || groupName === 'null'
      ? ''
      : `(part of group ${groupName})`;
  return (
    <Table.Row key={index}>
      <Table.Cell>
        <span className="dealer-details">
          {' '}
          {`${dealerNo} - ${name} ${groupContent}`} <br />{' '}
          {currentlyEnrolled === 'Y' && (
            <div>
              {' '}
              {productName && productName}{' '}
              {!productName && `DealShield ${days}-Day`} {' at $'}{' '}
              {rate && parseInt(rate, 10)}{' '}
              {networkplus === 'TRUE' &&
                ` ($${parseInt(networkplusRate, 10)} – Network Plus)`}{' '}
              <br />
            </div>
          )}{' '}
          {localLinkedFlag && incompleteEnrollment === null && (
            <div>
              Linked Locally{' | '}
              <a onClick={removeLocallyDealerHandler} href="">
                Remove Dealer
              </a>
            </div>
          )}
          {localLinkedFlag && incompleteEnrollment !== null && (
            <div>
              Linked Locally{' | '}
              <a> Remove Dealer feature unavailable due to pending offer </a>
            </div>
          )}
          {closedFactoryExclusion && <div>Closed Factory Exclusion</div>}
        </span>{' '}
      </Table.Cell>{' '}
      <Table.Cell className="enrollment-link">
        <span>
          {' '}
          {currentlyEnrolled === 'Y' &&
            isUpgradeAvailable(enrollment) &&
            incompleteEnrollment === null && (
              <a onClick={showProductSelectionHandler} href="">
                Change Enrollment
              </a>
            )}
          {!(currentlyEnrolled === 'Y') && incompleteEnrollment === null && (
            <a onClick={showProductSelectionHandler} href="">
              {' '}
              {` Start Enrollment `}{' '}
            </a>
          )}
          {incompleteEnrollment !== null && (
            <span>
              <a onClick={showProductSelectionHandler} href="">
                Single - Dealer Quote <br />
              </a>{' '}
              {`Sent ${incompleteEnrollment.sendAt} `} <br />
              <a onClick={resendMailHandler} href="">
                Resend the Offer <br />
              </a>{' '}
            </span>
          )}
          {currentlyEnrolled === 'N' && productType === null && (
            <a> {` Not eligible for DealShield products `} </a>
          )}{' '}
          {isAllOffersZero(enrollment) && incompleteEnrollment === null && (
            <a class="margin_spacing">{` No eligible products available `}</a>
          )}{' '}
        </span>{' '}
      </Table.Cell>{' '}
    </Table.Row>
  );
};

export default ListRow;
