import React, { Component } from 'react';
import {
  Form,
  Checkbox,
  Button,
  Modal,
  Icon,
  Select,
  Message
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';
import { REINSTATEMENT_REASONS } from '../../constants';

class GuaranteeReinstate extends Component {
  state = {
    cancelledPurchaseId: null,
    reinstatementReason: null,
    bypassCharge: false,
    silent: false,
    errors: {}
  };

  componentDidMount() {
    const { cancelledPurchase } = this.props;
    const { id } = cancelledPurchase;

    this.setState({ cancelledPurchaseId: id });
  }

  render() {
    const {
      cancelledPurchase,
      isReinstateModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors } = this.state;

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const validationRules = {
      reinstatementReason: 'required'
    };

    const submitForm = e => {
      const {
        cancelledPurchaseId,
        reinstatementReason,
        bypassCharge,
        silent
      } = this.state;

      const validation = new Validator(this.state, validationRules);

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          cancelledPurchaseId,
          reinstatement: {
            reinstatementReason,
            bypassCharge,
            silent
          }
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isReinstateModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          Reinstate Guarantee Purchase for VIN {cancelledPurchase.vin}
        </Modal.Header>

        {
          <Modal.Content image>
            <Modal.Description>
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              <Form widths="equal">
                <Form.Field
                  required
                  error={!isEmpty(errors.reinstatementReason)}
                >
                  <label>Reason</label>
                  <Select
                    placeholder="Select reinstatement reason"
                    options={REINSTATEMENT_REASONS}
                    closeOnBlur
                    value={this.state.reinstatementReason}
                    onChange={onSelectHandler('reinstatementReason').bind(this)}
                  />
                  {errors.reinstatementReason && (
                    <span>{errors.reinstatementReason}</span>
                  )}
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Checkbox
                      label="Skip sending purchase confirmation"
                      checked={this.state.silent}
                      onChange={onCheckboxHandler('silent').bind(this)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="Bypass Charge"
                      checked={this.state.bypassCharge}
                      onChange={onCheckboxHandler('bypassCharge').bind(this)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Modal.Description>
          </Modal.Content>
        }
        {
          <Modal.Actions>
            {submitting && (
              <Button loading positive>
                Submitting...
              </Button>
            )}
            {!submitting && (
              <Button color="green" onClick={submitForm} inverted>
                <Icon name="checkmark" /> Submit
              </Button>
            )}
            <Button onClick={onCloseHandler}>Cancel</Button>
          </Modal.Actions>
        }
      </Modal>
    );
  }
}

export default GuaranteeReinstate;
