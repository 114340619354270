import { gql } from 'react-apollo';

const updateUserMutation = gql`
  mutation adminUpdateUser($input: AdminModifyUserInput!) {
    adminUpdateUser(input: $input) {
      success
      errors
    }
  }
`;

export default updateUserMutation;
