import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Form,
  Segment,
  Table,
  Header,
  Select,
  Input,
  Icon,
  Button,
  Message,
  Checkbox
} from 'semantic-ui-react';
import { get } from 'lodash';
import auctionLocationsQuery from '../../graphql/auctionLocationsQuery';
import LoadingDataEnhancer from '../../containers/LoadingData';
import { graphql } from 'react-apollo';
import { isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { utcMoment } from '../../utilities/dateUtils';
import Validator from 'validatorjs';
import NumberFormat from 'react-number-format';
import checkCurrentEnrollmentOfDealerQuery from '../../graphql/checkCurrentEnrollmentOfDealerQuery.js';
import dealerInformationQuery from '../../graphql/dealerInformationQuery';
import client from '../../apollo';

class AutoPsiForm extends Component {
  state = {
    dealerNumber: '',
    dealerNo: '',
    requestType: null,
    dealername: '',
    contactname: '',
    contactemail: '',
    contactphone: '',
    product1: '',
    product2: null,
    cascade: false,
    flatrate1: null,
    flatrate2: null,
    purchaseChannel1: ['In-Lane', 'Simulcast'],
    purchaseChannel2: [],
    location1: [],
    location2: [],
    lpiprice1: 0,
    psimileage1: '',
    startDate1: null,
    startDate2: null,
    openReviewModal: false,
    showAdditionalPriceMessage: false,
    showflatrate1PriceField: false,
    errors: {},
    modify: false,
    cancel: false,
    description: null,
    searched: false,
    customMileage1: null,
    customMileage2: null,
    lpiPrice: null,
    endDate1: null,
    endDate2: null,
    psiprice1: 0,
    lpimileage: 0,
    excludeCloseSale: false,
    createNewEnrollment: false
  };

  handleCheckBoxChange = (field1, field2) => (event, data) => {
    this.setState({ [field1]: data.checked, [field2]: false });
  };

  handleRadioButtonChange = field => (event, data) => {
    this.setState({ [field]: data.value });
  };

  onSearchSubmitHandler = event => {
    this.setState({
      description: null,
      searched: true,
      createNewEnrollment: true,
      errors: {}
    });
    this.setState({
      dealerNumber: '',
      dealername: '',
      contactname: '',
      contactemail: '',
      contactphone: '',
      cascade: false,
      product1: null,
      purchaseChannel1: ['In-Lane', 'Simulcast'],
      location1: [],
      location2: [],
      psimileage1: '',
      lpiprice1: 0,
      psiprice1: 0,
      lpimileage: 0,
      startDate1: '',
      endDate1: '',
      showLocation2Warning: false
    });
    const { dealerNo } = this.state;
    client
      .query({
        query: checkCurrentEnrollmentOfDealerQuery,
        variables: { dealerNumber: dealerNo },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const {
          checkCurrentEnrollmentOfDealer: { description }
        } = data;
        if (description !== null) {
          this.setState({ createNewEnrollment: false });
          const removedCharData = description.replace(/=>/g, ':');
          const finalData = JSON.parse(removedCharData);
          this.setState({ description: description });
          if (this.state.description !== null) {
            let dealernumber = finalData['dealerNumber'];
            let dealername = finalData['dealerName'];
            let contactname = finalData['contactName'];
            let contactemail = finalData['contactEmail'];
            let contactphone = finalData['contactPhoneNumber'];
            let excludeCloseSale = finalData['excludeCloseSale'];
            let products = Object.keys(finalData['products']);
            let cascade = finalData['cascade'];
            let product1 = products[0];
            let product2 = products[1];
            let product = finalData['products'];
            let location1 = product[product1]['auctionIds'];
            let psimileage1 = product[product1]['mileage'];
            let lpimileage1 = product[product1]['LPImileage'];
            let lpiprice1 = product[product1]['LPIprice'];
            let startDate1 = product[product1]['startDate'];
            let endDate1 = product[product1]['endDate'];
            let purchaseChannel1 = product[product1]['channel'];
            let psiprice1 = product[product1]['PSIprice'];
            if (product2 !== undefined) {
              let location2 = product[product2]['auctionIds'];
              let startDate2 = product[product2]['startDate'];
              let endDate2 = product[product2]['endDate'];
              let purchaseChannel2 = product[product2]['channel'];
              this.setState({
                purchaseChannel2: purchaseChannel2,
                location2: location2,
                startDate2: startDate2,
                endDate2: endDate2
              });
            }
            this.setState({
              dealerNumber: dealernumber,
              dealername: dealername,
              contactname: contactname,
              contactemail: contactemail,
              contactphone: contactphone,
              product1: product1,
              product2: product2,
              cascade: cascade,
              purchaseChannel1: purchaseChannel1,
              location1: location1,
              psimileage1:
                psimileage1 === 0 || psimileage1 === '0' ? null : psimileage1,
              lpimileage: lpimileage1,
              lpiprice1: lpiprice1,
              startDate1: startDate1,
              endDate1: endDate1,
              psiprice1: psiprice1,
              flatrate1: psiprice1,
              excludeCloseSale: excludeCloseSale
            });
          }
        }
        if (this.state.description === null) {
          this.setState({ createNewEnrollment: true });
          this.onNewFormDataHandler();
        }
      });
  };

  onNewFormDataHandler = event => {
    const dealerNumber = this.state.dealerNo;
    if (dealerNumber) {
      client
        .query({
          query: dealerInformationQuery,
          variables: { dealerNumber: dealerNumber }
        })
        .then(({ data }) => {
          const dealerName = get(data, 'dealerInformation.dealerName');
          this.setState({
            dealername: dealerName,
            dealerNumber: dealerNumber
          });
        });
    }
  };

  onFlatRateHandler = product => event => {
    const flatRateValue = event.target.value;
    this.setState({ flatrate1: flatRateValue, lpiprice1: 0 });
    if (product === 'sevenDayFlatPSI' && parseInt(flatRateValue, 10) > 0) {
      this.setState({ lpiprice1: parseInt(flatRateValue, 10) + 20 });
    }
    if (product === 'fourteenDayFlatPSI' && parseInt(flatRateValue, 10) > 0) {
      let finalLpiPrice = parseInt(flatRateValue, 10) - 10;
      this.setState({ lpiprice1: finalLpiPrice > 0 ? finalLpiPrice : 0 });
    }
  };

  onChangeHandler = field => event => {
    this.setState({ [field]: event.target.value });
  };

  render() {
    const { onSubmitHandler } = this.props;
    const {
      data: { locations }
    } = this.props;

    const manheimlocations = locations.filter(
      (item, index) => item.partnerNetwork === 'US-Manheim'
    );

    const { errors } = this.state;
    var allLocationsOption = [];

    if (
      this.state.location1.includes('*') ||
      this.state.location2.includes('*')
    ) {
      allLocationsOption = [
        {
          text: 'All locations',
          key: 0,
          value: '*',
          disabled: true
        }
      ];
    } else {
      allLocationsOption = [
        {
          text: 'All locations',
          key: 0,
          value: '*',
          disabled: false
        }
      ];
    }

    var locationOptions = [];
    if (
      this.state.product1 !== 'sevenDayFlatPSI' &&
      this.state.product1 !== 'fourteenDayFlatPSI'
    ) {
      locationOptions =
        !isEmpty(manheimlocations) &&
        manheimlocations.map(function(location) {
          return {
            text: location.name,
            key: location.id,
            value: location.initials
          };
        });
    } else {
      locationOptions = [];
    }

    var allLocations = [...allLocationsOption, ...locationOptions];
    const totalLocations = [...new Set(allLocations)];

    const requestTypeOptions = [
      { key: 1, text: 'New', value: 'New' },
      { key: 2, text: 'Modify', value: 'Modify' },
      { key: 3, text: 'Cancel', value: 'Cancel' }
    ];

    const productOptions = [
      { key: 1, text: 'Auto PSI 7 Day', value: 'sevenDayVarPSI' },
      { key: 2, text: 'Auto PSI 14 Day', value: 'fourteenDayVarPSI' },
      {
        key: 3,
        text: 'Flat Rate Auto PSI 7 Day',
        value: 'sevenDayFlatPSI'
      },
      {
        key: 4,
        text: 'Flat Rate Auto PSI 14 Day',
        value: 'fourteenDayFlatPSI'
      }
    ];

    const product2Options = [
      { key: 1, text: 'Auto PSI 7 Day', value: 'sevenDayVarPSI' },
      { key: 2, text: 'Auto PSI 14 Day', value: 'fourteenDayVarPSI' }
    ];

    const product2OptionForAuto7 = [
      { key: 1, text: 'Select Product', value: null },
      { key: 2, text: 'Auto PSI 14 Day', value: 'fourteenDayVarPSI' }
    ];

    const product2OptionForAuto14 = [
      { key: 1, text: 'Select Product', value: null },
      { key: 2, text: 'Auto PSI 7 Day', value: 'sevenDayVarPSI' }
    ];

    const cascadeOptions = [
      { key: 1, text: 'Yes', value: true },
      { key: 2, text: 'No', value: false }
    ];

    const excludeFactoryOptions = [
      { key: 1, text: 'True', value: true },
      { key: 2, text: 'False', value: false }
    ];

    const psiVehicleMilesOptions = [
      { key: 1, text: '0-36000', value: '36000' },
      { key: 2, text: 'Custom Mileage', value: 'custom' }
    ];

    const purchaseChannelOptions = [
      { key: 1, text: 'In-lane', value: 'In-Lane' },
      { key: 2, text: 'Simulcast', value: 'Simulcast' }
    ];

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
      if (
        (field === 'product1' || field === 'product2') &&
        (data.value !== 'sevenDayFlatPSI' ||
          data.value !== 'fourteenDayFlatPSI')
      ) {
        this.setState({ lpiprice1: 0, flatrate1: 0 });
      }
      if (
        (field === 'location1' && data.value.includes('*')) ||
        (field === 'location2' && data.value.includes('*'))
      ) {
        this.setState({ [field]: '*' });
      }
      if (
        field === 'product1' &&
        (data.value === 'sevenDayFlatPSI' ||
          data.value === 'fourteenDayFlatPSI')
      ) {
        this.setState({ purchaseChannel1: ['In-Lane', 'Simulcast'] });
      }
    };

    const product1Value = this.state.product1;
    const cascadeValue = this.state.cascade;

    const product2Value = this.state.product2Value;

    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD') });
    };

    const date = isEmpty(this.state.startDate1)
      ? null
      : utcMoment(this.state.startDate1);

    const validationRules = {
      dealerNo: 'required',
      contactemail: 'required|email',
      contactphone: 'required|telephone',
      psimileage1: 'customMileage',
      dealerNumber: 'required',
      dealername: 'required',
      contactname: 'required',
      purchaseChannel1: 'required',
      location1: 'required'
    };

    Validator.register(
      'telephone',
      function(value, requirement, attribute) {
        // requirement parameter defaults to null
        return value.match(/[^\d{3}]\d{3}-\d{4}$/);
      },
      'The :attribute phone number is not in the format (XXX)XXX-XXXX.'
    );

    Validator.register(
      'customMileage',
      function(value, requirement, attribute) {
        return value > 0 && value < 124999;
      },
      'Mileage should be between 0 – 124,999.'
    );

    const submitForm = e => {
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          ...this.state,
          totalLocations,
          requestTypeOptions,
          productOptions,
          product2Options,
          cascadeOptions,
          excludeFactoryOptions,
          psiVehicleMilesOptions,
          purchaseChannelOptions,
          product2OptionForAuto7,
          product2OptionForAuto14,
          openReviewModal: this.state.modify ? false : true,
          lpiprice1: this.state.cascade ? this.state.lpiprice1 : 0,
          psiprice1: isEmpty(this.state.flatrate1) ? 0 : this.state.flatrate1,
          startDate2:
            isEmpty(this.state.startDate2) && this.state.createNewEnrollment
              ? this.state.startDate1
              : this.state.startDate2,
          product1:
            this.state.product2 === 'sevenDayFlatPSI' ||
            this.state.product2 === 'fourteenDayFlatPSI'
              ? null
              : this.state.product1,
          product2:
            this.state.product1 === 'sevenDayFlatPSI' ||
            this.state.product1 === 'fourteenDayFlatPSI'
              ? null
              : this.state.product2,
          cancelForm
        });
        this.setState({ ...validation.errors });
      }
    };

    const cancelForm = e => {
      this.setState({
        dealerNumber: '',
        requestType: null,
        dealername: '',
        contactname: '',
        contactemail: '',
        contactphone: '',
        product1: null,
        product2: null,
        flatrate1: null,
        purchaseChannel1: null,
        location1: [],
        location2: [],
        psimileage1: '',
        lpiprice1: null,
        startDate1: null,
        startDate2: null,
        excludeCloseSale: false,
        openReviewModal: false,
        showAdditionalPriceMessage: false,
        showflatrate1PriceField: false,
        errors: {},
        modify: false,
        cancel: false,
        dealerNo: '',
        searched: false,
        description: null,
        cascade: false
      });
    };

    return (
      <div>
        <Segment placeholder="true">
          <Form>
            <Form.Group widths="two">
              <Form.Input
                name="dealerNo"
                placeholder="5M"
                value={this.state.dealerNo}
                onChange={this.onChangeHandler('dealerNo')}
              />
              <Form.Button
                color="green"
                onClick={event => {
                  this.onSearchSubmitHandler();
                }}
                inverted
              >
                Search
              </Form.Button>
            </Form.Group>

            {this.state.searched === false && (
              <Message negative>
                <p>
                  Please enter a DEALER NUMBER and press the SEARCH button to
                  proceed
                </p>
              </Message>
            )}

            {this.state.searched === true && this.state.description === null && (
              <Message negative>
                <p>
                  Dealer {this.state.dealerNo} does not have a PSI product
                  subscription
                </p>
              </Message>
            )}

            {this.state.description !== null && (
              <Message positive>
                <p>
                  Dealer {this.state.dealerNo} has a PSI product Subscription
                </p>
              </Message>
            )}
            <Header>
              On the AutoPSI form below, please fill out the required
              information(*).
            </Header>
            <div>
              <Table
                celled
                selectable
                padded
                size="large"
                compact="very"
                style={{ fontSize: '90%' }}
                striped
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.searched === true &&
                    this.state.description === null && (
                      <Table.Row>
                        <Table.Cell>Request Type:</Table.Cell>
                        <Table.Cell>
                          <Select
                            options={requestTypeOptions}
                            name="requestType"
                            onChange={onSelectHandler('requestType')}
                            value={'New'}
                            disabled={true}
                          />
                        </Table.Cell>
                        <Table.Cell />
                      </Table.Row>
                    )}
                  <Table.Row>
                    <Table.Cell>Dealer #</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Dealer No:"
                        name="dealerNumber"
                        value={this.state.dealerNo}
                        onChange={this.onChangeHandler('dealerNumber')}
                        disabled={true}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Dealership Name</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Dealer Name:"
                        name="dealername"
                        value={this.state.dealername}
                        onChange={this.onChangeHandler('dealername')}
                        disabled={true}
                      />
                      {errors.dealername && (
                        <span style={{ color: 'red' }}>
                          {'Dealer name not found in tappy database'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Contact Name
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Contact Name:"
                        name="contactname"
                        value={this.state.contactname}
                        onChange={this.onChangeHandler('contactname')}
                        disabled={!this.state.createNewEnrollment}
                      />
                      {errors.contactname && (
                        <span style={{ color: 'red' }}>
                          {'Contact name is mandatory'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Contact Email
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Contact Email:"
                        name="contactemail"
                        value={this.state.contactemail}
                        onChange={this.onChangeHandler('contactemail')}
                        disabled={!this.state.createNewEnrollment}
                      />
                      {errors.contactemail && (
                        <span style={{ color: 'red' }}>
                          {errors.contactemail}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Contact Phone #
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <NumberFormat
                        format="(###) ###-####"
                        mask="_"
                        value={this.state.contactphone}
                        onChange={this.onChangeHandler('contactphone')}
                        disabled={!this.state.createNewEnrollment}
                      />
                      {errors.contactphone && (
                        <span style={{ color: 'red' }}>
                          {errors.contactphone}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Product1</Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Product"
                        options={productOptions}
                        name="product1"
                        onChange={onSelectHandler('product1')}
                        value={this.state.product1}
                        className="product-type-selector"
                        disabled={!this.state.createNewEnrollment}
                      />
                    </Table.Cell>
                    {(product1Value === 'sevenDayFlatPSI' ||
                      product1Value === 'fourteenDayFlatPSI') && (
                      <Table.Cell>
                        Enter Flat Rate Price:
                        <Input
                          placeholder="Enter Flat Rate Price:"
                          name="flatrate1"
                          value={this.state.flatrate1}
                          onChange={this.onFlatRateHandler(this.state.product1)}
                          disabled={!this.state.createNewEnrollment}
                        />
                      </Table.Cell>
                    )}
                    {!(
                      product1Value === 'sevenDayFlatPSI' ||
                      product1Value === 'fourteenDayFlatPSI'
                    ) && <Table.Cell />}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Auction Location 1:
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        options={totalLocations}
                        name="location1"
                        onChange={onSelectHandler('location1')}
                        value={this.state.location1}
                        multiple
                        selection
                        disabled={!this.state.createNewEnrollment}
                      />
                      {errors.location1 && (
                        <span style={{ color: 'red' }}>
                          {'Location is mandatory'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  {product1Value !== 'sevenDayFlatPSI' &&
                    product1Value !== 'fourteenDayFlatPSI' && (
                      <Table.Row>
                        <Table.Cell>Product2</Table.Cell>
                        {product1Value === 'fourteenDayVarPSI' && (
                          <Table.Cell>
                            <Select
                              placeholder="Select Product"
                              options={product2OptionForAuto14}
                              name="product2"
                              onChange={onSelectHandler('product2')}
                              value={this.state.product2}
                              className="product-type-selector"
                              disabled={!this.state.createNewEnrollment}
                            />
                          </Table.Cell>
                        )}
                        {product1Value === 'sevenDayVarPSI' && (
                          <Table.Cell>
                            <Select
                              placeholder="Select Product"
                              options={product2OptionForAuto7}
                              name="product2"
                              onChange={onSelectHandler('product2')}
                              value={this.state.product2}
                              className="product-type-selector"
                              disabled={!this.state.createNewEnrollment}
                            />
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {(product1Value === 'sevenDayFlatPSI' ||
                            product1Value === 'fourteenDayFlatPSI') && (
                            <Select
                              placeholder="Select Product"
                              options={product2Options}
                              name="product2"
                              onChange={onSelectHandler('product2')}
                              value={this.state.product2}
                              className="product-type-selector"
                              disabled={!this.state.createNewEnrollment}
                            />
                          )}
                        </Table.Cell>
                        {!(
                          product2Value === 'sevenDayFlatPSI' ||
                          product2Value === 'fourteenDayFlatPSI'
                        ) && <Table.Cell />}
                      </Table.Row>
                    )}
                  {product1Value !== 'sevenDayFlatPSI' &&
                    product1Value !== 'fourteenDayFlatPSI' && (
                      <Table.Row>
                        <Table.Cell>Auction Location 2:</Table.Cell>
                        <Table.Cell>
                          <Select
                            options={totalLocations}
                            name="location2"
                            onChange={onSelectHandler('location2')}
                            value={this.state.location2}
                            multiple
                            selection
                            disabled={!this.state.createNewEnrollment}
                          />
                        </Table.Cell>
                        <Table.Cell />
                      </Table.Row>
                    )}
                  <Table.Row>
                    <Table.Cell>
                      <Header>
                        {' '}
                        The following selections will be applied to all
                        products:
                      </Header>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Purchase Channel:
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Purchase Channel"
                        options={purchaseChannelOptions}
                        name="purchaseChannel1"
                        onChange={onSelectHandler('purchaseChannel1')}
                        value={this.state.purchaseChannel1}
                        multiple
                        selection
                        disabled={
                          !this.state.createNewEnrollment ||
                          (product1Value === 'sevenDayFlatPSI' ||
                            product1Value === 'fourteenDayFlatPSI')
                        }
                      />
                      {errors.purchaseChannel1 && (
                        <span style={{ color: 'red' }}>
                          {'Purchase Channel is mandatory'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Exclude Factory</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={excludeFactoryOptions}
                        name="excludeCloseSale"
                        onChange={onSelectHandler('excludeCloseSale')}
                        value={this.state.excludeCloseSale}
                        disabled={!this.state.createNewEnrollment}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Cascade To LPI</Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Option"
                        options={cascadeOptions}
                        name="cascade"
                        onChange={onSelectHandler('cascade')}
                        value={this.state.cascade}
                        disabled={!this.state.createNewEnrollment}
                      />
                    </Table.Cell>
                    {cascadeValue === true &&
                      product1Value === 'sevenDayFlatPSI' && (
                        <Table.Cell>
                          <div>
                            Enter LPI Price:
                            <Input
                              placeholder="Enter LPI Price:"
                              name="lpiPrice1"
                              value={this.state.lpiprice1}
                              onChange={this.onChangeHandler('lpiprice1')}
                              disabled={!this.state.createNewEnrollment}
                            />
                            <Message>
                              (LPI will be ordered at the 7-day PSI price plus
                              $20)
                            </Message>
                          </div>
                        </Table.Cell>
                      )}
                    {cascadeValue === true &&
                      product1Value === 'fourteenDayFlatPSI' && (
                        <Table.Cell>
                          <div>
                            <Input
                              placeholder="Enter LPI Price:"
                              name="lpiPrice1"
                              value={this.state.lpiprice1}
                              onChange={this.onChangeHandler('lpiprice1')}
                              disabled={!this.state.createNewEnrollment}
                            />
                            <Message>
                              (LPI will be ordered at the 14-day PSI price minus
                              $10 *NOTE: LPI is only a 7 day Guarantee)
                            </Message>
                          </div>
                        </Table.Cell>
                      )}
                    {!(
                      cascadeValue === true &&
                      (product1Value === 'sevenDayFlatPSI' ||
                        product1Value === 'fourteenDayFlatPSI')
                    ) && <Table.Cell />}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PSI Vehicle miles:</Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Enter vehicle miles:"
                        name="psimileage"
                        options={psiVehicleMilesOptions}
                        value={'custom'}
                        onChange={this.onChangeHandler('psimileage')}
                        disabled={true}
                      />
                      {errors.psimileage1 && (
                        <span style={{ color: 'red' }}>
                          {errors.psimileage1}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Custom Mileage:"
                        name="psimileage1"
                        value={this.state.psimileage1}
                        onChange={this.onChangeHandler('psimileage1')}
                        disabled={!this.state.createNewEnrollment}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Select AutoPSI Start Date1: </Table.Cell>
                    <Table.Cell>
                      <DatePicker
                        style={{ padding: '0', width: '100%' }}
                        selected={date}
                        minDate={new Date()}
                        onChange={onDateChangeHandler('startDate1').bind(this)}
                        dateFormat="YYYY-MM-DD"
                        disabled={!this.state.createNewEnrollment}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <br />
            {this.state.searched === true && this.state.description !== null && (
              <p>
                What would you like to do?
                <br />
                <Checkbox
                  radio
                  label="Modify"
                  name="checkboxRadioGroup"
                  value="modify"
                  checked={this.state.modify}
                  onChange={this.handleCheckBoxChange('modify', 'cancel').bind(
                    this
                  )}
                />
                <br />
                <Checkbox
                  radio
                  label="Cancel"
                  name="checkboxRadioGroup"
                  value="cancel"
                  checked={this.state.cancel}
                  onChange={this.handleCheckBoxChange('cancel', 'modify').bind(
                    this
                  )}
                />
                <br />
              </p>
            )}
            {(this.state.modify === true ||
              this.state.cancel === true ||
              this.state.createNewEnrollment === true) && (
              <p>
                <Button color="green" inverted onClick={submitForm}>
                  <Icon name="checkmark" /> Continue
                </Button>
                <Button color="green" inverted onClick={cancelForm}>
                  <Icon name="checkmark" /> Cancel
                </Button>
              </p>
            )}
          </Form>
        </Segment>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(auctionLocationsQuery, 'auctionLocations'),
  LoadingDataEnhancer
)(AutoPsiForm);
