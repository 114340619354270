import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import ListRow from './ListRow';

class FeatureFlagList extends Component {
  render() {
    const { featureFlagList, checkboxHandler, featureFlag, setID } = this.props;

    const listRow = ListRow(checkboxHandler, featureFlag, setID);
    const headerRow = (
      <Table.Row>
        <Table.HeaderCell width={1}></Table.HeaderCell>
        <Table.HeaderCell width={2}>Flag Name</Table.HeaderCell>
        <Table.HeaderCell width={5}>Flag Description</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Table
          celled
          selectable
          padded
          renderBodyRow={listRow}
          tableData={featureFlagList}
          headerRow={headerRow}
          size="small"
          striped
          compact="very"
          style={{ fontSize: '90%' }}
        />
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(FeatureFlagList);
