import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import DealerList from '../views/DealerQuote/List';
import ResendEmail from '../views/DealerQuote/ResendEmail';

import { isEmpty } from 'lodash';
import { Form, Segment, Message } from 'semantic-ui-react';

class DealerQuote extends Component {
  state = {
    modalOpen: false,
    incompleteEnrollment: '',
    flashMessage: null,
    error: null
  };

  render() {
    const {
      enrollmentStatus,
      localDealerNo,
      onSearchSubmitHandler,
      auctionAccessNumber,
      onChangeHandler,
      showProductSelection,
      addDealerHandler,
      displayDealerConfirm
    } = this.props;

    const { modalOpen, incompleteEnrollment, flashMessage, error } = this.state;
    const isUpgradeAvailable = enrollment => {
      const offers = enrollmentStatus.eligibleOffers.filter(
        offer =>
          offer.dealerNo === enrollment.dealerNo &&
          offer.productId !== enrollment.productId
      );
      if (offers.length > 0) return true;
      return false;
    };

    const isAllOffersZero = enrollment => {
      const offers = enrollmentStatus.eligibleOffers.filter(
        offer =>
          offer.dealerNo === enrollment.dealerNo &&
          offer.incompleteEnrollment === null &&
          offer.rate === 0
      );
      if (offers.length > 0) return true;
      else if (enrollment.rate === 0) return true;
      return false;
    };

    const handleOpen = incompleteEnrollment => {
      this.setState({
        modalOpen: true,
        incompleteEnrollment: incompleteEnrollment,
        flashMessage: null,
        error: null
      });
    };

    const flashMessageSuccess = () => {
      this.setState({ flashMessage: 'mail sent successfully' });
    };

    const flashMessageFailure = error => {
      this.setState({ error: error });
    };

    const flashMessageClear = () => {
      this.setState({ flashMessage: null });
    };

    const handleClose = () => {
      this.setState({ modalOpen: false });
    };

    return (
      <div className="dealer-enrollment">
        {error && (
          <Message negative>
            <p>{error}</p>
          </Message>
        )}

        {flashMessage && (
          <Message
            onDismiss={flashMessageClear}
            content={flashMessage}
            positive
          />
        )}

        <Segment placeholder="true">
          <Form>
            <Form.Group widths="two">
              <Form.Input
                name="auctionAccessNumber"
                placeholder=" 100M"
                value={auctionAccessNumber}
                onChange={onChangeHandler('auctionAccessNumber').bind(this)}
              />
              <Form.Button
                color="green"
                onClick={event => {
                  onSearchSubmitHandler();
                  flashMessageClear();
                }}
                inverted
                disabled={isEmpty(auctionAccessNumber)}
              >
                Search
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
        {enrollmentStatus && (
          <div>
            <DealerList
              dealerDetails={enrollmentStatus}
              showProductSelection={showProductSelection}
              isUpgradeAvailable={isUpgradeAvailable}
              onChangeHandler={onChangeHandler}
              addDealerHandler={addDealerHandler}
              localDealerNo={localDealerNo}
              displayDealerConfirm={displayDealerConfirm}
              handleOpen={handleOpen}
              isAllOffersZero={isAllOffersZero}
            />
            {modalOpen && (
              <div>
                <ResendEmail
                  modalOpen={modalOpen}
                  handleClose={handleClose}
                  enrollmentStatus={enrollmentStatus}
                  incompleteEnrollment={incompleteEnrollment}
                  flashMessageSuccess={flashMessageSuccess}
                  flashMessageFailure={flashMessageFailure}
                />
              </div>
            )}
          </div>
        )}

        {enrollmentStatus &&
          isEmpty(enrollmentStatus.enrollments) && (
            <Message content="Invalid HundredMil" negative />
          )}
      </div>
    );
  }
}

export default compose(withRouter)(DealerQuote);
