import { gql } from 'react-apollo';

const guaranteePurchaseAuditsQuery = gql`
  query guaranteePurchaseAudits(
    $guaranteePurchaseId: Int!
    $startDate: String
    $endDate: String
  ) {
    guaranteePurchaseAudits(
      guaranteePurchaseId: $guaranteePurchaseId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      whodunnit
      event
      versionAuthor
      itemType
      changeset
      timeStamp
    }
  }
`;

export default guaranteePurchaseAuditsQuery;
