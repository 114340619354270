import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';

class MarketIndexDetails extends Component {
  render() {
    const formatNumber = value => {
      if (value === null || value === '') {
        return (value = '0.00');
      } else {
        return value.toFixed(2);
      }
    };

    const { marketIndex } = this.props;
    return (
      <div>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Header size="large">Market Index</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header size="tiny">Value:</Header>
            </Grid.Column>
            {marketIndex && <Grid.Column>{marketIndex.value}</Grid.Column>}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header size="tiny">Notes:</Header>
            </Grid.Column>
            {marketIndex && <Grid.Column>{marketIndex.notes}</Grid.Column>}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header size="tiny">Surcharge Status:</Header>
            </Grid.Column>
            {marketIndex && (
              <Grid.Column>{marketIndex.surchargeStatus}</Grid.Column>
            )}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header size="tiny">Surcharge Amount:</Header>
            </Grid.Column>
            <Grid.Column>
              {marketIndex && (
                <span>{formatNumber(marketIndex.surchargeAmount)}</span>
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header size="tiny">
                Display on DS.com ACO Acknowlegdement Page:
              </Header>
            </Grid.Column>
            <Grid.Column>
              {marketIndex && (
                <span>
                  {marketIndex.displaySurcharge === true ? 'Yes' : 'No'}
                </span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header size="tiny">
                Display Surcharge Amount on Market Condition
              </Header>
            </Grid.Column>
            <Grid.Column>
              {marketIndex && (
                <span>
                  {marketIndex.displaySurchargeAmount === true ? 'Yes' : 'No'}
                </span>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default MarketIndexDetails;
