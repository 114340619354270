import React, { Component } from 'react';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class List extends Component {
  render() {
    const { dealerGroupList } = this.props;
    const listRow = ListRow();

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Group Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.HeaderCell>Updated At</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Header>Dealer Groups</Header>
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={dealerGroupList}
            headerRow={headerRow}
            size="small"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default List;
