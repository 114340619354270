import { gql } from 'react-apollo';

const addOfferInFlatRateOfferBatchMutation = gql`
  mutation addOfferInFlatRateOfferBatch(
    $input: AddOfferInFlatRateOfferBatchInput!
  ) {
    addOfferInFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default addOfferInFlatRateOfferBatchMutation;
