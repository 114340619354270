import React from 'react';
import {
  Form,
  Header,
  Select,
  Button,
  Icon,
  Checkbox
} from 'semantic-ui-react';
import { OFFERS_INITIATED_SINCE } from '../../constants';

const Filter = ({
  offersInitiatedSince,
  onSelectHandler,
  submitFilter,
  singleDealerQuotes,
  groupQuotes,
  onCheckboxHandler,
  myQuotes,
  allStaffs
}) => {
  const onChangeHandler = field => (event, data) => {
    event.preventDefault();
    onSelectHandler(field, data.value);
  };

  const onCheckboxChangeHandler = field => (event, data) => {
    event.preventDefault();
    onCheckboxHandler(field, data.checked);
  };

  return (
    <div>
      <Form>
        <Header size="small" color="blue" />
        <Form.Group widths="1">
          <Form.Field>
            <Checkbox
              label="Single Dealer Quotes"
              checked={singleDealerQuotes}
              onChange={onCheckboxChangeHandler('singleDealerQuotes').bind(
                this
              )}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="My Quotes"
              checked={myQuotes}
              onChange={onCheckboxChangeHandler('myQuotes').bind(this)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Group Quotes"
              checked={groupQuotes}
              onChange={onCheckboxChangeHandler('groupQuotes').bind(this)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="All DS Staff & Sales Reps"
              checked={allStaffs}
              onChange={onCheckboxChangeHandler('allStaffs').bind(this)}
            />
          </Form.Field>
          <Form.Field>
            <label className="offer_since_label">Offers initiated since</label>
          </Form.Field>
          <Form.Field>
            <Select
              placeholder="Offers initiated since"
              options={OFFERS_INITIATED_SINCE}
              onChange={onChangeHandler('offersInitiatedSince').bind(this)}
              value={offersInitiatedSince}
              style={{ marginTop: '-10px', marginLeft: '-10px' }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              color="green"
              onClick={submitFilter}
              inverted
              style={{ marginTop: '-10px' }}
            >
              <Icon name="checkmark" /> Apply
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Filter;
