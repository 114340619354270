import { gql } from 'react-apollo';

const updateVinBlacklistMutation = gql`
  mutation updateVinBlacklist($input: UpdateVinBlacklistInput!) {
    updateVinBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default updateVinBlacklistMutation;
