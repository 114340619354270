import { gql } from 'react-apollo';

const createNewEnrollmentMutation = gql`
  mutation newSubscription($newEnrollmentInput: NewSubscriptionMutationInput!) {
    newSubscription(input: $newEnrollmentInput) {
      success
      errors
    }
  }
`;

export default createNewEnrollmentMutation;
