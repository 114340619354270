import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import {find, result} from "lodash";
import {CANCELLED_PURCHASE_STATUS} from "../../constants";

const ListRow = actionHandler => cancelledPurchase => {
  const {
    id,
    buyerEmail,
    cancellingUserName,
    auctionAccessNo,
    dealerNo,
    vin,
    universalNo,
    guaranteeDaysSelected,
    guaranteeMilesSelected,
    guaranteeIfBid,
    guaranteeSellerPaid,
    automaticPurchase,
    vehiclePurchasePrice,
    guaranteePrice,
    inspectionPrice,
    cancellationDate,
    cancellationReason,
    guaranteeOffer: {
      guaranteeStatus
    }
  } = cancelledPurchase;


  const rowMenuOptions = [
    { key: 1, text: 'Reinstate Guarantee', value: 'Reinstate Guarantee', disabled: guaranteeStatus === 'reinstating' }
  ];

  const currencyProps = price => {
    return {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 0,
      value: price
    };
  };

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, cancelledPurchase);
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{buyerEmail}</Table.Cell>
      <Table.Cell>{auctionAccessNo}</Table.Cell>
      <Table.Cell>{dealerNo}</Table.Cell>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell>{universalNo}</Table.Cell>
      <Table.Cell>{guaranteeDaysSelected}</Table.Cell>
      <Table.Cell>{guaranteeMilesSelected}</Table.Cell>
      <Table.Cell textAlign="center">
        {guaranteeIfBid && <Icon color="green" name="checkmark" size="large" />}
        {!guaranteeIfBid && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {guaranteeSellerPaid && (
          <Icon color="green" name="checkmark" size="large" />
        )}
        {!guaranteeSellerPaid && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {automaticPurchase && (
          <Icon color="green" name="checkmark" size="large" />
        )}
        {!automaticPurchase && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(vehiclePurchasePrice)} />
      </Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(guaranteePrice)} />
      </Table.Cell>
      <Table.Cell>{result(find(CANCELLED_PURCHASE_STATUS, ['value', guaranteeStatus]), 'text') || guaranteeStatus}</Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(inspectionPrice)} />
      </Table.Cell>
      <Table.Cell>{cancellingUserName}</Table.Cell>
      <Table.Cell>{cancellationDate}</Table.Cell>
      <Table.Cell>{cancellationReason}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
