import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';

import {
  Form,
  Button,
  Modal,
  Message,
  Icon,
  Input,
  TextArea,
  Select
} from 'semantic-ui-react';

class MarketIndexForm extends Component {
  state = {
    id: null,
    notes: '',
    value: '',
    surchargeStatus: '',
    surchargeAmount: '',
    displaySurcharge: '',
    displaySurchargeAmount: false,
    updated: true,
    errors: {}
  };

  componentDidMount() {
    const { marketIndex } = this.props;
    if (!isEmpty(marketIndex)) {
      const {
        id,
        notes,
        value,
        surchargeStatus,
        surchargeAmount,
        displaySurcharge,
        displaySurchargeAmount
      } = marketIndex;
      this.setState({
        id: parseInt(id, 10),
        notes,
        value,
        surchargeStatus,
        surchargeAmount,
        displaySurcharge,
        displaySurchargeAmount,
        updated: true
      });
    }
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors } = this.state;

    const onChangeHandler = field => event => {
      errors.surchargeAmount = '';
      let displaySurchargeAmount = this.state.displaySurchargeAmount;
      if (field === 'surchargeAmount') {
        let invalidOn =
          displaySurchargeAmount &&
          (event.target.value <= 0 || isEmpty(event.target.value));
        let invalidOff =
          !displaySurchargeAmount &&
          (event.target.value !== 0 || isEmpty(event.target.value));
        if (invalidOn) {
          errors.surchargeAmount =
            'Surcharge amount must be greater than 0 to turn this on';
          this.setState({ [field]: event.target.value, updated: false });
        }
        if (invalidOff) {
          errors.surchargeAmount =
            'Surcharge amount must be 0 to turn this off';
          this.setState({ [field]: event.target.value, updated: false });
        }
        if (!invalidOn && !invalidOff) {
          if (displaySurchargeAmount) {
            if (event.target.value > 0 && event.target.value <= 500) {
              this.setState({ [field]: event.target.value, updated: true });
            } else {
              errors.surchargeAmount =
                'Please enter a positive amount between 1 - 500';
              this.setState({ [field]: event.target.value, updated: false });
            }
          } else if (!displaySurchargeAmount) {
            if (event.target.value > 0) {
              errors.surchargeAmount =
                'Surcharge amount must be 0 to turn this off';
              this.setState({ [field]: event.target.value, updated: false });
            } else {
              this.setState({ [field]: event.target.value, updated: true });
            }
          }
        }
      } else {
        this.setState({ [field]: event.target.value, updated: true });
      }
    };

    const onSelectHandler = field => (event, data) => {
      let surchargeAmount = this.state.surchargeAmount;
      errors.surchargeAmount = '';
      let invalidOn =
        data.value && (surchargeAmount <= 0 || isEmpty(surchargeAmount));
      let invalidOff =
        !data.value && (surchargeAmount !== 0 || isEmpty(surchargeAmount));
      if (invalidOn) {
        errors.surchargeAmount =
          'Surcharge amount must be  greater than 0 to turn this on';
        this.setState({ [field]: data.value, updated: false });
      }
      if (invalidOff) {
        errors.surchargeAmount = 'Surcharge amount must be 0 to turn this off';
        this.setState({ [field]: data.value, updated: false });
      }
      if (!invalidOn && !invalidOff) {
        this.setState({ [field]: data.value, updated: true });
      }
    };

    const validationRules = {
      notes: 'required',
      value: 'required|numeric|customValidateMarketIndexPrice',
      surchargeAmount: 'numeric|customValidateSurcharge'
    };

    Validator.register(
      'customValidateMarketIndexPrice',
      function(value, requirement, attribute) {
        if (value >= 0 && value <= 200) {
          return value >= 0;
        }
      },
      'Please enter a positive number between 0 - 200'
    );

    Validator.register(
      'customValidateSurcharge',
      function(value, requirement, attribute) {
        const displaySurchargeAmount = this.validator.input
          .displaySurchargeAmount;
        if (displaySurchargeAmount) {
          if (value >= 0 && value <= 500) {
            return true;
          }
        } else {
          return true;
        }
      },
      'Please enter a positive amount between 1 - 500'
    );

    const displaySurchargeOptions = [
      { key: 1, text: 'Yes', value: true },
      { key: 2, text: 'No', value: false }
    ];
    const displaySurchargeAmountOptions = [
      { key: 1, text: 'Yes', value: true },
      { key: 2, text: 'No', value: false }
    ];

    const submitForm = () => {
      const {
        id,
        notes,
        value,
        surchargeStatus,
        surchargeAmount,
        displaySurcharge,
        displaySurchargeAmount
      } = this.state;
      if (this.state.updated === false) {
        return false;
      }
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          id: parseInt(id, 10),
          notes,
          value,
          surchargeStatus,
          surchargeAmount: parseFloat(surchargeAmount),
          displaySurcharge,
          displaySurchargeAmount
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>{this.state.id && 'Edit Market Index'}</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.value)}>
                  <label>Value</label>
                  <Input
                    name="value"
                    value={this.state.value}
                    onChange={onChangeHandler('value').bind(this)}
                  />
                  {errors.value && <span>{errors.value}</span>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.notes)}>
                  <label>Notes</label>
                  <TextArea
                    name="notes"
                    value={this.state.notes}
                    onChange={onChangeHandler('notes').bind(this)}
                  />
                  {errors.notes && <span>{errors.notes}</span>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.surchargeStatus)}>
                  <label>Surcharge Status</label>
                  <Input
                    name="surchargeStatus"
                    value={this.state.surchargeStatus}
                    onChange={onChangeHandler('surchargeStatus').bind(this)}
                  />
                  {errors.surchargeStatus && (
                    <span>{errors.surchargeStatus}</span>
                  )}
                </Form.Field>
                <Form.Field required error={!isEmpty(errors.surchargeAmount)}>
                  <label>Surcharge Amount</label>
                  <Input
                    name="surchargeAmount"
                    value={this.state.surchargeAmount}
                    onChange={onChangeHandler('surchargeAmount').bind(this)}
                  />
                  {errors.surchargeAmount && (
                    <span>{errors.surchargeAmount}</span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Display on DS.com ACO Acknowlegdement Page</label>
                  <Select
                    options={displaySurchargeOptions}
                    name="displaySurcharge"
                    onChange={onSelectHandler('displaySurcharge').bind(this)}
                    value={this.state.displaySurcharge}
                  />
                </Form.Field>
                <Form.Field
                  required
                  error={!isEmpty(errors.displaySurchargeAmount)}
                >
                  <label>Display Surcharge Amount on Market Condition</label>
                  <Select
                    options={displaySurchargeAmountOptions}
                    name="displaySurchargeAmount"
                    onChange={onSelectHandler('displaySurchargeAmount').bind(
                      this
                    )}
                    value={this.state.displaySurchargeAmount}
                  />
                  {errors.displaySurchargeAmount && (
                    <span>{errors.displaySurchargeAmount}</span>
                  )}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}

          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default MarketIndexForm;
