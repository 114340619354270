import { gql } from 'react-apollo';

const updateMarketFactorMutation = gql`
  mutation updateMarketFactor($input: UpdateMarketFactorInput!) {
    updateMarketFactor(input: $input) {
      success
      errors
    }
  }
`;

export default updateMarketFactorMutation;
