import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import NetworkPlusOfferContent from '../views/NetworkPlusOffer/NetworkPlusOfferContent';
import networkPlusOfferQuery from '../graphql/networkPlusOfferQuery';

class NetworkPlusOfferApproval extends Component {
  render() {
    const {
      data: { networkPlusOffer }
    } = this.props;

    return (
      <div>
        {networkPlusOffer && (
          <NetworkPlusOfferContent networkPlusOffer={networkPlusOffer} />
        )}
      </div>
    );
  }
}

const data = graphql(networkPlusOfferQuery, {
  options: props => {
    const id = props.match.params.id;

    return {
      variables: { id },
      fetchPolicy: 'network-only'
    };
  }
});

export default compose(withRouter, data)(NetworkPlusOfferApproval);
