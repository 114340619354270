import React, { Component } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Message } from 'semantic-ui-react';

import { Form, Button, Modal, Icon, Input } from 'semantic-ui-react';

import './preferencesStyle.css';
import subscriptionPreferenceQuery from '../../../graphql/subscriptionPreferenceQuery';
import preferencesDiscoverQuery from '../../../graphql/preferencesDiscoverQuery';

import client from '../../../apollo';

import { isEmpty } from 'lodash';
import Preference from './Preference';

import localStore from 'store';
import { utcMoment } from '../../../utilities/dateUtils';
import { isValidFacts } from '../../../utilities/preferenceUtil';

class SubscriptionPreferences extends Component {
  state = {
    preference: {},
    preferenceLoaded: false,
    preferenceDiscoverLoaded: true,
    isSubmitting: false
  };

  onCheckboxHandler = field => (event, data) => {
    this.setState({ [field]: data.checked });
  };

  onDateChangeHandler = field => date => {
    this.setState({ [field]: moment(date, 'YYYY-MM-DD') });
  };

  onSelectHandler = field => (event, data) => {
    const { key } = data.options.find(o => o.value === data.value);
    this.setState({ [field]: data.value, productId: key });
  };

  componentDidMount() {
    let { subscription } = this.props;
    this.getSubscriptionPreferenceQuery(subscription);
    this.getPreferencesDiscoverQuery();
  }

  getSubscriptionPreferenceQuery = subscription => {
    client
      .query({
        query: subscriptionPreferenceQuery,
        variables: {
          subscriptionId: parseInt(subscription.subscription_id, 10)
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        let { subscriptionPreference } = data;

        if (isEmpty(subscriptionPreference)) {
          localStore.set('preference', {});
        } else {
          localStore.set('preference', subscriptionPreference);
          this.setState({ preferenceLoaded: true });
        }
      })
      .catch(error => {
        localStore.set('preference', {});
      });
  };

  getPreferencesDiscoverQuery = () => {
    client
      .query({
        query: preferencesDiscoverQuery
      })
      .then(({ data }) => {
        const { preferencesDiscover } = data;
        if (isEmpty(preferencesDiscover)) {
          localStore.set('preferenceDiscover', preferencesDiscover);
        } else {
          localStore.set('preferenceDiscover', preferencesDiscover);
          this.setState({ preferenceDiscoverLoaded: true });
        }
      })
      .catch(error => {
        localStore.set('preferenceDiscover', {});
      });
  };

  onSaveHandler = () => {
    const { onSubmitHandler } = this.props;

    const data = localStore.get('preference') || {};
    const isEmptyRuleOrOperatorTypeOrFact = data.ruleList.filter(
      rule =>
        rule.ruleType === '' ||
        rule.operatorType === '' ||
        rule.facts.length === 0 ||
        rule.facts === '' ||
        !isValidFacts(rule.facts, rule.ruleType)
    );

    const isEmptyDefaultRule = data.ruleSetList.filter(
      defaultRule => defaultRule.defaultPreferenceRuleType === ''
    );

    if (
      !isEmpty(isEmptyRuleOrOperatorTypeOrFact) ||
      !isEmpty(isEmptyDefaultRule)
    ) {
      this.setState({ isSubmitting: true });
      return false;
    }

    onSubmitHandler();
  };

  render() {
    const { isModalOpen, onCloseHandler, error, submitting } = this.props;

    const { preferenceLoaded, preferenceDiscoverLoaded } = this.state;

    let preference = localStore.get('preference') || {};
    let { startDate } = preference;

    return (
      preferenceLoaded &&
      preferenceDiscoverLoaded && (
        <Modal
          open={isModalOpen}
          closeIcon
          onClose={onCloseHandler}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
          className="preferences"
        >
          <Modal.Content image>
            <Modal.Description>
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              <Form>
                <Form.Group widths="equal">
                  <Form.Field style={{ width: '50%' }}>
                    <label>Start Date</label>

                    <Input
                      placeholder="YYYY-MM-DD"
                      value={
                        isEmpty(startDate)
                          ? null
                          : utcMoment(startDate).format('YYYY-MM-DD')
                      }
                      disabled
                    />
                  </Form.Field>

                  <Form.Field style={{ width: '50%' }}>
                    <label>Preference Type</label>
                    <Input
                      placeholder="Preference Type"
                      value="BUYER"
                      disabled
                    />
                  </Form.Field>
                </Form.Group>
              </Form>

              <Form>
                <Preference isSubmitting={this.state.isSubmitting} />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {submitting && (
              <Button loading positive>
                Submitting...
              </Button>
            )}
            {!submitting && (
              <Button
                color="green"
                onClick={onCloseHandler}
                inverted
                onClick={this.onSaveHandler}
              >
                <Icon name="checkmark" /> Save
              </Button>
            )}
            <Button onClick={onCloseHandler}>Close</Button>
          </Modal.Actions>
        </Modal>
      )
    );
  }
}

export default SubscriptionPreferences;
