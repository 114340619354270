import React from 'react';
import numeral from 'numeral';
import { format as dateFormat } from 'date-fns';

const vehicleDetails = props => {
  const {
    transaction: {
      purchasedAt,
      expiresAt,
      receiveDate,
      vin,
      dealerNumber,
      dealershipName,
      odometer,
      locationName,
      milesSelected,
      returnInitiatedAt,
      universalNo,
      year,
      make,
      model,
      returnLocation: { name }
    }
  } = props.returnInvoice;

  return (
    <div>
      <section>
        <table>
          <tbody>
            <tr>
              <th>RETURN INITIATED:</th>
              <td>{dateFormat(returnInitiatedAt, 'MMM D, YYYY')}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>FULL VIN:</th>
              <td>{vin}</td>
            </tr>
            <tr>
              <th>YEAR, MAKE, MODEL:</th>
              <td>
                {year} {make} {model}
              </td>
            </tr>
            <tr>
              <th>ODOMETER</th>
              <td>{numeral(odometer).format('0,0')} miles</td>
            </tr>
          </tbody>
        </table>
        <table className="bold">
          <tbody>
            <tr>
              <th>VEHICLE AND TITLE MUST BE RECEIVED BY:</th>
              <td>{dateFormat(receiveDate, 'MMM D, YYYY')}</td>
            </tr>
            <tr>
              <th>ODOMETER LIMIT:</th>
              <td>{numeral(odometer + milesSelected).format('0,0')} miles</td>
            </tr>
            <tr>
              <th>RETURN LOCATION:</th>
              <td>{name}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <table>
          <tbody>
            <tr>
              <th>DEALERSHIP 5M#:</th>
              <td>{dealerNumber}</td>
            </tr>
            <tr>
              <th>DEALERSHIP NAME:</th>
              <td>{dealershipName}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <table>
          <tbody>
            <tr>
              <th>PURCHASE LOCATION:</th>
              <td>{locationName}</td>
            </tr>
            <tr>
              <th>UNIVERSAL (DS) CODE:</th>
              <td>{universalNo}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>Guarantee Purchased On:</th>
              <td>{dateFormat(purchasedAt, 'MMM D, YYYY')}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default vehicleDetails;
