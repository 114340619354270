import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Form,
  Segment,
  Table,
  Header,
  Select,
  Input,
  Icon,
  Button,
  Message
} from 'semantic-ui-react';
import auctionLocationsQuery from '../../graphql/auctionLocationsQuery';
import LoadingDataEnhancer from '../../containers/LoadingData';
import { graphql } from 'react-apollo';
import { isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { utcMoment } from '../../utilities/dateUtils';
import Validator from 'validatorjs';
import NumberFormat from 'react-number-format';

class Enrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerNumber: props.dealerNumber,
      requestType: props.requestType,
      dealerName: props.dealername,
      contactname: props.contactname,
      contactemail: props.contactemail,
      contactphone: props.contactphone,
      customMileage1: props.customMileage1,
      startDate1: props.startDate1,
      startDate2: props.startDate2,
      endDate1: props.endDate1,
      endDate2: props.endDate2,
      flatrate1: props.flatrate1,
      flatrate2: props.flatrate2,
      psiprice1: props.flatrate1,
      location1: props.location1,
      location2: props.location2,
      product1: props.product1,
      product2: props.product2 || null,
      cascade: props.cascade,
      purchaseChannel1: props.purchaseChannel1,
      modify: props.modify,
      miles: props.miles,
      openReviewModal: props.openReviewModal,
      showAdditionalPriceMessage: false,
      showFlatRatePriceField: false,
      errors: {},
      excludeCloseSale: props.excludeCloseSale,
      lpiprice1: props.lpiprice1,
      psimileage1: props.psimileage1,
      excludeFactoryOptions: props.excludeFactoryOptions,
      requestTypeOptions: props.requestTypeOptions,
      productOptions: props.productOptions,
      product2Options: props.product2Options,
      cascadeOptions: props.cascadeOptions,
      psiVehicleMilesOptions: props.psiVehicleMilesOptions,
      purchaseChannelOptions: props.purchaseChannelOptions,
      product2OptionForAuto7: props.product2OptionForAuto7,
      product2OptionForAuto14: props.product2OptionForAuto14,
      modifyCloseHandler: props.modifyCloseHandler
    };
  }

  handleCheckBoxChange = field => (event, data) => {
    this.setState({ [field]: data.value });
  };

  handleRadioButtonChange = field => (event, data) => {
    this.setState({ [field]: data.value });
  };

  onChangeHandler = field => event => {
    this.setState({ [field]: event.target.value });
  };

  onFlatRateHandler = product => event => {
    const flatRateValue = event.target.value;
    this.setState({ flatrate1: flatRateValue, lpiprice1: 0 });
    if (product === 'sevenDayFlatPSI' && parseInt(flatRateValue, 10) > 0) {
      this.setState({ lpiprice1: parseInt(flatRateValue, 10) + 20 });
    }
    if (product === 'fourteenDayFlatPSI' && parseInt(flatRateValue, 10) > 0) {
      let finalLpiPrice = parseInt(flatRateValue, 10) - 10;
      this.setState({ lpiprice1: finalLpiPrice > 0 ? finalLpiPrice : 0 });
    }
  };

  render() {
    const { onSubmitHandler, modifyCloseHandler } = this.props;
    const { errors } = this.state;

    const {
      data: { locations }
    } = this.props;
    const manheimlocations = locations.filter(
      (item, index) => item.partnerNetwork === 'US-Manheim'
    );
    var allLocationsOption = [];
    if (
      this.state.location1.includes('*') ||
      this.state.location2.includes('*')
    ) {
      allLocationsOption = [
        {
          text: 'All locations',
          key: 0,
          value: '*',
          disabled: true
        }
      ];
    } else {
      allLocationsOption = [
        {
          text: 'All locations',
          key: 0,
          value: '*',
          disabled: false
        }
      ];
    }

    var locationOptions = [];
    if (
      this.state.product1 !== 'sevenDayFlatPSI' &&
      this.state.product1 !== 'fourteenDayFlatPSI'
    ) {
      locationOptions =
        !isEmpty(manheimlocations) &&
        manheimlocations.map(function(location) {
          return {
            text: location.name,
            key: location.id,
            value: location.initials
          };
        });
    } else {
      locationOptions = [];
    }

    var allLocations = [...allLocationsOption, ...locationOptions];
    const totalLocations = [...new Set(allLocations)];

    const onSelectHandler = field => (event, data) => {
      const finalValue = data.value;
      this.setState({ [field]: finalValue });
      if (
        (field === 'product1' || field === 'product2') &&
        (finalValue !== 'sevenDayFlatPSI' ||
          finalValue !== 'fourteenDayFlatPSI')
      ) {
        this.setState({ lpiprice1: 0, flatrate1: 0 });
      }
      if (
        (field === 'location1' && data.value.includes('*')) ||
        (field === 'location2' && data.value.includes('*'))
      ) {
        this.setState({ [field]: '*' });
      }
      if (field === 'purchaseChannelNew') {
        this.setState({ purchaseChannel1: finalValue });
      }
      if (
        field === 'product1' &&
        (finalValue === 'sevenDayFlatPSI' ||
          finalValue === 'fourteenDayFlatPSI')
      ) {
        this.setState({ purchaseChannel1: ['In-Lane', 'Simulcast'] });
      }
    };

    const product1Value = this.state.product1;
    const cascadeValue = this.state.cascade;
    const product2Value = this.state.product2;

    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD') });
    };

    const date1 = isEmpty(this.state.startDate1)
      ? null
      : utcMoment(this.state.startDate1);

    const date2 = isEmpty(this.state.startDate2)
      ? null
      : utcMoment(this.state.startDate2);

    const date3 = isEmpty(this.state.endDate1)
      ? null
      : utcMoment(this.state.endDate1);

    const date4 = isEmpty(this.state.endDate2)
      ? null
      : utcMoment(this.state.endDate2);

    const validationRules = {
      contactemail: 'required|email',
      contactphone: 'required|telephone',
      psimileage1: 'customMileage',
      contactname: 'required',
      location1: 'required',
      purchaseChannel1: 'required'
    };

    Validator.register(
      'telephone',
      function(value, requirement, attribute) {
        return value.match(/[^\d{3}]\d{3}-\d{4}$/);
      },
      'The :attribute phone number is not in the format (XXX)XXX-XXXX.'
    );

    Validator.register(
      'customMileage',
      function(value, requirement, attribute) {
        return value > 0 && value < 124999;
      },
      'Mileage should be between 0 – 124,999.'
    );

    const submitForm = e => {
      const validation = new Validator(this.state, validationRules);

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        var finalHash = {
          ...this.state,
          totalLocations,
          openReviewModal: true,
          lpiprice1: this.state.cascade ? this.state.lpiprice1 : 0,
          psiprice1: this.state.flatrate1,
          product1:
            this.state.product2 === 'sevenDayFlatPSI' ||
            this.state.product2 === 'fourteenDayFlatPSI'
              ? null
              : this.state.product1,
          product2:
            this.state.product1 === 'sevenDayFlatPSI' ||
            this.state.product1 === 'fourteenDayFlatPSI'
              ? null
              : this.state.product2
        };
        const flatRateHash = { product2: null, location2: null };
        const zeroProductHash = { product2: null };
        const setStartDateHash = { startDate2: Date() };

        if (this.state.product2 === undefined) {
          finalHash = { ...finalHash, ...zeroProductHash };
        }

        if (this.state.product2 !== '' && this.state.startDate2 === '') {
          finalHash = { ...finalHash, setStartDateHash };
        }

        if (
          this.state.product1 === 'sevenDayFlatPSI' ||
          this.state.product1 === 'fourteenDayFlatPSI'
        ) {
          finalHash = { ...finalHash, ...flatRateHash };
        }
        onSubmitHandler({ ...finalHash });
        this.setState({ ...validation.errors });
      }
    };

    const cancelForm = e => {
      e.preventDefault();
      modifyCloseHandler();
    };

    return (
      <div>
        <Segment placeholder="true">
          <Form>
            <Header size="large">Enrollment</Header>

            <Header size="medium">
              On the AutoPSI form below, please fill out the required
              information(*). Current Enrollment details shown as (current
              selection)
            </Header>
            <div>
              <Table
                celled
                selectable
                padded
                size="large"
                compact="very"
                style={{ fontSize: '90%' }}
                striped
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Request Type</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={this.state.requestTypeOptions}
                        name="requestType"
                        value="Modify"
                        disabled={true}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Dealer #</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Dealer No:"
                        name="dealerNumber"
                        value={this.state.dealerNumber}
                        onChange={this.onChangeHandler('dealerNumber')}
                        disabled={true}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Dealership Name</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Dealer Name:"
                        name="dealerName"
                        value={this.state.dealerName}
                        onChange={this.onChangeHandler('dealerName')}
                        disabled={true}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Contact Name
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Contact Name:"
                        name="contactname"
                        value={this.state.contactname}
                        onChange={this.onChangeHandler('contactname')}
                      />
                      {errors.contactname && (
                        <span style={{ color: 'red' }}>
                          {'Contact name is mandatory'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Contact Email
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Contact Email:"
                        name="contactemail"
                        value={this.state.contactemail}
                        onChange={this.onChangeHandler('contactemail')}
                      />
                      {errors.contactemail && (
                        <span style={{ color: 'red' }}>
                          {errors.contactemail}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Contact Phone #
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <NumberFormat
                        format="(###) ###-####"
                        mask="_"
                        value={this.state.contactphone}
                        onChange={this.onChangeHandler('contactphone')}
                      />
                      {errors.contactphone && (
                        <span style={{ color: 'red' }}>
                          {errors.contactphone}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Product1</Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Product"
                        options={this.state.productOptions}
                        name="product1"
                        onChange={onSelectHandler('product1')}
                        value={this.state.product1}
                        className="product-type-selector"
                      />
                    </Table.Cell>
                    {(product1Value === 'sevenDayFlatPSI' ||
                      product1Value === 'fourteenDayFlatPSI') && (
                      <Table.Cell>
                        Enter Flat Rate Price:
                        <Input
                          placeholder="Enter Flat Rate Price:"
                          name="flatrate1"
                          value={this.state.flatrate1}
                          onChange={this.onFlatRateHandler(product1Value)}
                        />
                      </Table.Cell>
                    )}
                    {!(
                      product1Value === 'sevenDayFlatPSI' ||
                      product1Value === 'fourteenDayFlatPSI'
                    ) && <Table.Cell />}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Auction Location 1:
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        options={totalLocations}
                        name="location1"
                        onChange={onSelectHandler('location1')}
                        value={this.state.location1}
                        multiple
                        selection
                      />
                      {errors.location1 && (
                        <span style={{ color: 'red' }}>
                          {'Location is mandatory'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>AutoPSI Start Date: </Table.Cell>
                    <Table.Cell>
                      <DatePicker
                        style={{ padding: '0', width: '100%' }}
                        selected={date1}
                        minDate={new Date()}
                        onChange={onDateChangeHandler('startDate1').bind(this)}
                        dateFormat="YYYY-MM-DD"
                        disabled={true}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>AutoPSI End Date: </Table.Cell>
                    <Table.Cell>
                      <DatePicker
                        style={{ padding: '0', width: '100%' }}
                        selected={date3}
                        minDate={new Date()}
                        onChange={onDateChangeHandler('endDate1').bind(this)}
                        dateFormat="YYYY-MM-DD"
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  {product1Value !== 'sevenDayFlatPSI' &&
                    product1Value !== 'fourteenDayFlatPSI' && (
                      <Table.Row>
                        <Table.Cell>Product2</Table.Cell>
                        {product1Value === 'fourteenDayVarPSI' && (
                          <Table.Cell>
                            <Select
                              placeholder="Select Product"
                              options={this.state.product2OptionForAuto14}
                              name="product2"
                              onChange={onSelectHandler('product2')}
                              value={this.state.product2}
                              className="product-type-selector"
                            />
                          </Table.Cell>
                        )}
                        {product1Value === 'sevenDayVarPSI' && (
                          <Table.Cell>
                            <Select
                              placeholder="Select Product"
                              options={this.state.product2OptionForAuto7}
                              name="product2"
                              onChange={onSelectHandler('product2')}
                              value={this.state.product2}
                              className="product-type-selector"
                            />
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {(product1Value === 'sevenDayFlatPSI' ||
                            product1Value === 'fourteenDayFlatPSI') && (
                            <Select
                              placeholder="Select Product"
                              options={this.state.product2Options}
                              name="product2"
                              onChange={onSelectHandler('product2')}
                              value={this.state.product2}
                              className="product-type-selector"
                            />
                          )}
                        </Table.Cell>
                        {!(
                          product2Value === 'sevenDayFlatPSI' ||
                          product2Value === 'fourteenDayFlatPSI'
                        ) && <Table.Cell />}
                      </Table.Row>
                    )}
                  {product1Value !== 'sevenDayFlatPSI' &&
                    product1Value !== 'fourteenDayFlatPSI' && (
                      <Table.Row>
                        <Table.Cell>Auction Location 2:</Table.Cell>
                        <Table.Cell>
                          <Select
                            options={totalLocations}
                            name="location2"
                            onChange={onSelectHandler('location2')}
                            value={this.state.location2}
                            multiple
                            selection
                          />
                        </Table.Cell>
                        <Table.Cell />
                      </Table.Row>
                    )}

                  {product1Value !== 'sevenDayFlatPSI' &&
                    product1Value !== 'fourteenDayFlatPSI' && (
                      <Table.Row>
                        <Table.Cell>AutoPSI Start Date: </Table.Cell>
                        <Table.Cell>
                          <DatePicker
                            style={{ padding: '0', width: '100%' }}
                            selected={date2}
                            minDate={new Date()}
                            onChange={onDateChangeHandler('startDate2').bind(
                              this
                            )}
                            dateFormat="YYYY-MM-DD"
                            disabled={this.props.product2}
                          />
                        </Table.Cell>
                        <Table.Cell />
                      </Table.Row>
                    )}

                  {product1Value !== 'sevenDayFlatPSI' &&
                    product1Value !== 'fourteenDayFlatPSI' && (
                      <Table.Row>
                        <Table.Cell>AutoPSI End Date: </Table.Cell>
                        <Table.Cell>
                          <DatePicker
                            style={{ padding: '0', width: '100%' }}
                            selected={date4}
                            minDate={new Date()}
                            onChange={onDateChangeHandler('endDate2').bind(
                              this
                            )}
                            dateFormat="YYYY-MM-DD"
                          />
                        </Table.Cell>
                        <Table.Cell />
                      </Table.Row>
                    )}

                  <Table.Row>
                    <Table.Cell>
                      <Header>
                        {' '}
                        The following selections will be applied to all
                        products:
                      </Header>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Purchase Channel:
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Purchase Channel"
                        options={this.state.purchaseChannelOptions}
                        name="purchaseChannel1"
                        onChange={onSelectHandler('purchaseChannel1')}
                        value={this.state.purchaseChannel1}
                        multiple
                        selection
                        disabled={
                          product1Value === 'sevenDayFlatPSI' ||
                          product1Value === 'fourteenDayFlatPSI'
                        }
                      />
                      {errors.purchaseChannel1 && (
                        <span style={{ color: 'red' }}>
                          {'Purchase Channel is mandatory'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Exclude Factory</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={this.state.excludeFactoryOptions}
                        name="excludeCloseSale"
                        onChange={onSelectHandler('excludeCloseSale')}
                        value={this.state.excludeCloseSale}
                        selection
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Cascade To LPI</Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Option"
                        options={this.state.cascadeOptions}
                        name="cascade"
                        onChange={onSelectHandler('cascade')}
                        value={this.state.cascade}
                      />
                    </Table.Cell>
                    {cascadeValue === true &&
                      (product1Value === 'sevenDayFlatPSI' ||
                        product1Value === 'fourteenDayFlatPSI') && (
                        <Table.Cell>
                          <div>
                            <Input
                              placeholder="Enter LPI Price:"
                              name="lpiprice1"
                              value={this.state.lpiprice1}
                              onChange={this.onChangeHandler('lpiprice1')}
                            />
                            {product1Value === 'sevenDayFlatPSI' && (
                              <Message>
                                (LPI will be ordered at the 7-day PSI price plus
                                $20)
                              </Message>
                            )}
                            {product1Value === 'fourteenDayFlatPSI' && (
                              <Message>
                                (LPI will be ordered at the 14-day PSI price
                                minus $10 *NOTE: LPI is only a 7 day Guarantee)
                              </Message>
                            )}
                          </div>
                        </Table.Cell>
                      )}
                    {!(
                      cascadeValue === true &&
                      (product1Value === 'sevenDayFlatPSI' ||
                        product1Value === 'fourteenDayFlatPSI')
                    ) && <Table.Cell />}
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>PSI Vehicle miles:</Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Enter vehicle miles:"
                        name="psimileage"
                        options={this.state.psiVehicleMilesOptions}
                        value={'custom'}
                        onChange={this.onChangeHandler('psimileage')}
                        disabled={true}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Enter Custom Mileage:"
                        name="psimileage1"
                        value={this.state.psimileage1}
                        onChange={this.onChangeHandler('psimileage1')}
                      />
                      {errors.psimileage1 && (
                        <span style={{ color: 'red' }}>
                          {errors.psimileage1}
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <br />
            <Button color="green" inverted onClick={submitForm}>
              <Icon name="checkmark" /> Continue
            </Button>
            <Button color="green" inverted onClick={cancelForm}>
              <Icon name="checkmark" /> Cancel
            </Button>
          </Form>
        </Segment>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(auctionLocationsQuery, 'auctionLocations'),
  LoadingDataEnhancer
)(Enrollment);
