// Header CSV
const headersEatTransaction = [
  'Vin',
  'Vehicle',
  'Auction Location',
  'Sale Date',
  'Buyer Name',
  'Seller Name'
];

const headersEatEvent = [
  'Auction',
  'Auction (name/run)',
  'Buyer #',
  'Seller',
  'Guarantee Date',
  'VRA',
  'VRA Initiated Date',
  'Return Location',
  'Body',
  'Miles',
  'Channel',
  'ARB',
  'ARB Status',
  'ARB Date',
  'Salvage',
  'Closed Sale',
  'Return WoNumber',
  'Meridian SoldDate',
  'Unique ID',
  'How Paid Description1',
  'Paid Date',
  'Payment Amount1',
  'Ds Deposit Flag',
  'Split Pay',
  'Floor Plan',
  'Floor Plan Description',
  'BuyNet',
  'Buyer Adjustments',
  'Vehicle Sale Price',
  'Buy Fee',
  'DS Price',
  'Announcement',
  'Event',
  'Created At',
  'Updated At',
  'Hold Type',
  'Sold Location Title Status',
  'Title Received Date',
  'Title Issue Date',
  'Vehicle Secured',
  'IMS Vehicle Status',
  'IMS Secured Date',
  'IMS Record Date',
  'Trans Req Date',
  'Cleared For Sale',
  'Meridian Title Status',
  'Meridian/IMS Title Status',
  'IMS Title Received Status',
  'IMS Title Status Loc',
  'IMS Title Number',
  'How Paid2',
  'How Paid Description2',
  'Payment Amount2',
  'How Paid3',
  'How Paid Description3',
  'Payment Amount3'
];

const keysEatTransaction = [
  'vin',
  'vehicleMap',
  'purchaseLocationMap',
  'saleDate',
  'buyerNameMap',
  'sellerNameMap',
  'auction',
  'auctionMap',
  'buyer',
  'seller',
  'guaranteeDate',
  'vra',
  'vraInitiatedDate',
  'returnLocation',
  'body',
  'miles',
  'channel',
  'arb',
  'arbStatus',
  'arbDate',
  'salvage',
  'closedSale',
  'returnWoNumber',
  'meridianSoldDate',
  'uniqueId'
];

const keysEatEvent = [
  'howPaidDescription1',
  'paidDate',
  'paymentAmount1',
  'dsDepositFlag',
  'splitPay',
  'floorPlan',
  'floorPlanDescription',
  'buynet',
  'buyerAdjustments',
  'vehicleSalePrice',
  'buyFee',
  'dealshieldPrice',
  'announcement',
  'event',
  'createdAt',
  'updatedAt',
  'holdType',
  'partnerAuctionTitleStatus',
  'imsTitleReceived',
  'titleIssueDate',
  'vehicleSecured',
  'imsVehicleStatus',
  'imsSecuredDate',
  'imsRecordDate',
  'transReqDate',
  'clearedForSale',
  'imsTitleStatusFromAuction',
  'imsTitleStatus',
  'imsTitleReceivedStatus',
  'imsTitleStatusLoc',
  'imsTitleNumber',
  'howPaid2',
  'howPaidDescription2',
  'paymentAmount2',
  'howPaid3',
  'howPaidDescription3',
  'paymentAmount3'
];

export {
  headersEatTransaction,
  headersEatEvent,
  keysEatTransaction,
  keysEatEvent
};
