import React, { Component } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

const CREATE = 'Create';
const UPDATE = 'Update';
const CLEAR = 'Clear';

class SubscriptionNotesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notesContent: props.customerNotes.message,
      action: CREATE,
      confirmUpdating: false,
      message: null,
      errors: {}
    };
  }

  componentDidMount() {
    if (this.state.notesContent === undefined) {
      this.setState({ notesContent: '' });
    }
    if (this.isCreate()) {
      this.setState({ action: CREATE });
    } else {
      this.setState({ action: UPDATE });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.action !== this.state.action) {
      this.handleFormStateChange();
    }
  }

  onNotesChangeHandler = field => event => {
    if (field === 'notesContent') {
      if (!isEmpty(event.target.value)) {
        let actionTmp = '';
        if (this.isCreate()) {
          actionTmp = CREATE;
        } else {
          actionTmp = UPDATE;
        }
        this.setState({
          [field]: event.target.value,
          errors: { [field]: '' },
          action: actionTmp
        });
        return true;
      } else {
        if (this.state.action === CREATE) {
          this.setState({
            [field]: event.target.value,
            errors: { [field]: 'Notes can not be empty' },
            action: CREATE
          });
        } else if (this.state.action === UPDATE) {
          this.setState({ [field]: event.target.value, action: CLEAR });
        }
        return false;
      }
    }
    return false;
  };
  isCreate = () => {
    return (
      this.props.customerNotes === undefined ||
      this.props.customerNotes.noteId === null
    );
  };
  showTitleModal = () => {
    if (this.state.action === UPDATE) {
      return 'Edit notes';
    }
    return 'Create notes';
  };
  handleFormStateChange = () => {
    // Notify the parent component about the action
    const { onStateChange } = this.props;
    if (typeof onStateChange === 'function') {
      onStateChange(this.state.action);
    }
  };
  render() {
    const { errors, confirmUpdating, notesContent, action } = this.state;
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const maxLengthNotes = 50;
    const countRestOfNotes = notesContent
      ? maxLengthNotes - notesContent.length
      : maxLengthNotes;

    const submitForm = () => {
      let { notesContent } = this.state;

      this.setState({ confirmUpdating: true });
      if (action === CREATE && isEmpty(notesContent)) {
        this.setState({
          errors: {
            notesContent: 'This field is required'
          }
        });
        return false;
      }

      onSubmitHandler({
        message: notesContent
      });
    };

    const confirmSubmitNotes = () => {
      this.setState({ confirmUpdating: true });
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header style={headerStyle}>
          <span>{this.showTitleModal()}</span>
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}

            <Form>
              <Form.Group>
                <Form.Field
                  style={{ width: '100%' }}
                  required
                  error={!isEmpty(errors.notesContent)}
                >
                  <label>Notes</label>
                  <input
                    style={{ width: '100% !important' }}
                    name="notesContent"
                    value={notesContent}
                    onChange={this.onNotesChangeHandler('notesContent').bind(
                      this
                    )}
                    maxLength={maxLengthNotes}
                  />
                  <span style={{ color: '#9f3a38', paddingLeft: '500px;' }}>
                    Remaining characters: {countRestOfNotes}
                  </span>

                  {errors.notesContent && (
                    <div style={{ color: 'red' }}>{errors.notesContent}</div>
                  )}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && !confirmUpdating && (
            <Button color="green" onClick={confirmSubmitNotes} inverted>
              <Icon name="checkmark" /> {action}
            </Button>
          )}
          {confirmUpdating && !submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Are You Sure?
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const headerStyle = {
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto auto'
};
export default SubscriptionNotesForm;
