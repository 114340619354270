import React, { Component } from 'react';
import { Form, Message } from 'semantic-ui-react';

class OfferAcknowledgement extends Component {
  render() {
    return (
      <Form>
        <Message header="PAGE UNDER CONSTRUCTION" />
      </Form>
    );
  }
}

export default OfferAcknowledgement;
