import React, { Component } from 'react';
import { Table, Header } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import ListRow from './ListRow';

class FileUploaderList extends Component {
  render() {
    const { allFiles } = this.props;
    const listRow = ListRow();
    const hasRows = !isEmpty(allFiles);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell singleLine textAlign="center" width={4}>
          File Name
        </Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign="center" width={2}>
          Type
        </Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign="center">
          Source
        </Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign="center">
          Status
        </Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign="center">
          Job Processed
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Response Code</Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={3}>
          Response
        </Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        {hasRows && (
          <div>
            <Header>File Uploaded</Header>
            <Table
              celled
              selectable
              padded
              fixed
              renderBodyRow={listRow}
              tableData={allFiles}
              headerRow={headerRow}
              size="large"
              compact="very"
              style={{ fontSize: '90%' }}
              striped
            />
          </div>
        )}
      </div>
    );
  }
}

export default FileUploaderList;
