import { parseString2Fields } from './StringUtil';
import _ from 'lodash';
import {
  headersEatTransaction,
  headersEatEvent,
  keysEatTransaction,
  keysEatEvent
} from '../../constants/externalAuctionTransaction';

// Replace null value to empty string
const replaceNullData = eatData => {
  return _.map(eatData, item =>
    JSON.parse(JSON.stringify(item).replace(/:null/gi, ':""'))
  );
};

// add key vehicleMap, buyerNameMap, sellerNameMap, purchaseLocationMap to eat Data
const addTransactionMapToEatData = eatData => {
  return _.map(replaceNullData(eatData), item => {
    return {
      ...item,
      vehicleMap: `${item.modelYear} ${item.make} ${item.model}`,
      buyerNameMap: parseString2Fields(item.buyer, item.buyerName),
      sellerNameMap: parseString2Fields(item.seller, item.sellerName),
      purchaseLocationMap: parseString2Fields(
        item.purchaseLocation,
        item.auction
      ),
      auctionMap: `${item.purchaseLocation} / ${item.runNumber}`
    };
  });
};

// combine array of eat transaction and eat events data
const processedData = eatData => {
  return _.flatten(
    _.map(addTransactionMapToEatData(eatData), item => {
      // return array object eat events data map by keys eat event
      const pickEatEventsData = _.map(item.eatEventsData, eatEvent =>
        _.pick(eatEvent, keysEatEvent, (v, k) => v)
      );

      const eatEventsDataMap = _.map(pickEatEventsData, evData =>
        _.values(evData)
      );

      // return array of transaction and eat events data
      return _.map(eatEventsDataMap, eventsData => {
        return _.concat(
          _.values(_.pick(item, keysEatTransaction, (v, k) => v)),
          eventsData
        );
      });
    })
  );
};

export const handleEatExportData = eatData => {
  // return headers and eat data to export CSV
  return [
    _.union(headersEatTransaction, headersEatEvent),
    ...processedData(eatData)
  ];
};
