import { gql } from 'react-apollo';

const fileUploaderQuery = gql`
  query fileUploaderQuery($size: Int, $offset: Int) {
    fileUploader(offset: $offset, size: $size) {
      id
      inputFile
      status
      fileName
      fileType
      source
      jobProcessed
      response
      responseCode
      fileUrl
    }
  }
`;

export default fileUploaderQuery;
