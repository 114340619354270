import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Input,
  Checkbox
} from 'semantic-ui-react';

import {
  utcMoment,
  dateTimeFormattedWithOutTimeZone
} from '../../../utilities/dateUtils';
import { get, isEmpty, toNumber } from 'lodash';
import Validator from 'validatorjs';
import client from '../../../apollo';
import activeDealerTypeQuery from '../../../graphql/activeDealerTypeQuery';
import ExceptionForm from '../Form';

class DealerExceptionForm extends ExceptionForm {
  state = {
    id: '',
    gracePeriod: '',
    exceptionEndTime: '',
    exceptionEndTimeTemp: '',
    dealerNumber: '',
    dealerName: '',
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    isNoEndDate: false,
    action: 'Create',
    errors: {},
    exceptionEndTimeString: '',
    debounceTimeout: null
  };

  componentDidMount() {
    const { dealerException } = this.props;
    const {
      id,
      exceptionSourceId,
      exceptionSourceName,
      gracePeriod,
      exceptionEndTime,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt
    } = dealerException;
    this.setState({
      id: toNumber(id),
      dealerNumber: exceptionSourceId,
      dealerName: exceptionSourceName,
      gracePeriod: gracePeriod,
      exceptionEndTime: exceptionEndTime,
      exceptionEndTimeTemp: exceptionEndTime,
      exceptionEndTimeString: this.initExceptionEndTimeString(exceptionEndTime),
      createdBy: createdBy,
      createdAt: createdAt,
      updatedBy: updatedBy,
      updatedAt: updatedAt,
      isNoEndDate: this.initIsNoEndDate(id, exceptionEndTime),
      action: id === 0 ? 'Create' : 'Update'
    });
  }

  queryDealerNameByNumber = dealerNumber => {
    client
      .query({
        query: activeDealerTypeQuery,
        variables: {
          dealerNo: dealerNumber.toString()
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const result = get(data, 'activeDealer');
        const dealerNameFromResult = !isEmpty(result) ? result.dealerName : '';
        const active = !isEmpty(result) ? result.active : undefined;
        if (active !== null) {
          if (!isEmpty(dealerNameFromResult)) {
            this.setState({
              dealerName: dealerNameFromResult,
              loadingDealerName: false
            });
          }
        } else {
          // active undefined means dealer does not exist from APMS but exist from OVC
          this.setErrorByField(
            'dealerNumber',
            `Dealer Number does not exist. Please enter a valid Dealer Number.`
          );
        }
        this.setState({ loadingDealerName: false });
      })
      .catch(error => {
        console.log('Fail to get dealerName', dealerNumber, error);
      });
  };
  onChangeHandler = field => event => {
    this.setErrorByField(field, '');
    this.setState({ [field]: event.target.value });
    if (field === 'dealerNumber') {
      let dNumber = event.target.value;

      if (this.state.debounceTimeout) {
        clearTimeout(this.state.debounceTimeout);
      }

      // Set a new timer to delay the API call by 1 second
      const debounceTimeout = setTimeout(() => {
        this.queryDealerNameByNumber(dNumber); // call graphql and set value to dealer name
      }, 1000);

      this.setState({
        loadingDealerName: true,
        debounceTimeout: debounceTimeout
      });
    }
  };

  submitForm = () => {
    this.setState({
      error: null,
      errors: {}
    });
    const { onSubmitHandler } = this.props;
    const {
      id,
      dealerNumber,
      gracePeriod,
      exceptionEndTime,
      exceptionEndTimeString,
      action,
      errors
    } = this.state;
    const validationRules = {
      dealerNumber: 'required|integer|min: 5000000|max: 5999999',
      gracePeriod: 'required|integer|min: 0|max: 60'
    };

    const customerErrorMessage = {
      'required.dealerNumber': 'The Dealer Number field is required.',
      'required.gracePeriod': 'The Grace Period field is required.',
      'min.gracePeriod': 'Grace Period must be greater than or equal to zero.',
      'max.gracePeriod': 'Grace Period must be less than or equal to 60.',
      'min.dealerNumber':
        'Dealer Number does not exist. Please enter a valid Dealer Number.',
      'max.dealerNumber':
        'Dealer Number does not exist. Please enter a valid Dealer Number.'
    };
    const validation = new Validator(
      this.state,
      validationRules,
      customerErrorMessage
    );

    let messErrorDate = this.validateEndDateDate(exceptionEndTimeString);
    if (!isEmpty(messErrorDate) | validation.fails()) {
      console.log('update', this.state, validation, messErrorDate);
      let newErrors = {
        ...validation.errors.errors,
        exceptionEndTime: messErrorDate
      };
      this.setState({ errors: newErrors });
      return false;
    } else if (!isEmpty(errors.dealerNumber)) {
      return false;
    } else {
      onSubmitHandler({
        id: parseInt(id, 10),
        dealerNumber: dealerNumber || '',
        gracePeriod: gracePeriod || '',
        exceptionEndTime: isEmpty(exceptionEndTime)
          ? ''
          : dateTimeFormattedWithOutTimeZone(utcMoment(exceptionEndTime)),
        action: action
      });
    }
  };

  render() {
    const { isModalOpen, onCloseHandler, error, submitting } = this.props;

    const {
      loadingDealerName,
      exceptionEndTime,
      exceptionEndTimeString,
      dealerNumber,
      dealerName,
      gracePeriod,
      isNoEndDate,
      action,
      errors
    } = this.state;

    const exceptionEndTimeConverted = isEmpty(exceptionEndTime)
      ? null
      : utcMoment(exceptionEndTime);
    const datePickerClassName = isNoEndDate === true ? 'input-disabled' : '';
    const disabledClassName = action === 'Update' ? 'input-disabled' : '';
    const isDisableDealerNumber = action === 'Update';

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          {action === 'Create' ? action : 'Edit'} Dealer Exception
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  required
                  error={!isEmpty(errors.dealerNumber)}
                  width={6}
                >
                  <label>Dealer Number</label>
                  <Input
                    className={disabledClassName}
                    disabled={isDisableDealerNumber}
                    name="dealerNumber"
                    value={dealerNumber}
                    onChange={this.onChangeHandler('dealerNumber').bind(this)}
                    onKeyDown={this.onFormatInput}
                    type="number"
                    pattern="\d*"
                  />
                  {errors.dealerNumber && (
                    <span style={{ color: 'red' }}>
                      {errors.dealerNumber &&
                      Array.isArray(errors.dealerNumber) &&
                      errors.dealerNumber.length > 1
                        ? errors.dealerNumber[errors.dealerNumber.length - 1]
                        : errors.dealerNumber}
                    </span>
                  )}
                </Form.Field>
                <Form.Field width={6}>
                  {(loadingDealerName || dealerName) && (
                    <div className="field">
                      <label>Dealer Name</label>
                      <Input
                        disabled
                        loading={loadingDealerName}
                        value={loadingDealerName ? '...' : dealerName}
                        className="input-disabled"
                      />
                    </div>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.gracePeriod)}>
                  <label>Grace Period</label>
                  <Input
                    name="gracePeriod"
                    value={gracePeriod}
                    onChange={this.onChangeHandler('gracePeriod').bind(this)}
                    onKeyDown={this.onFormatInput}
                    type="number"
                    min={0}
                    max={60}
                  />
                  {errors.gracePeriod && (
                    <span style={{ color: 'red' }}>
                      {errors.gracePeriod &&
                      Array.isArray(errors.gracePeriod) &&
                      errors.gracePeriod.length > 1
                        ? errors.gracePeriod[errors.gracePeriod.length - 1]
                        : errors.gracePeriod}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.exceptionEndTime)}>
                  <label>Exception End Date</label>
                  <Checkbox
                    onClick={this.toggleEndDate}
                    label="No end date"
                    checked={isNoEndDate}
                  />

                  <DatePicker
                    className={datePickerClassName}
                    style={{ padding: '0', background: 'red' }}
                    selected={exceptionEndTimeConverted}
                    onChange={this.onDateChangeHandler('exceptionEndTime').bind(
                      this
                    )}
                    onBlur={this.onDateFocusHandler(
                      'exceptionEndTimeString'
                    ).bind(this)}
                    dateFormat="YYYY-MM-DD"
                    disabled={isNoEndDate}
                    value={isNoEndDate ? '' : null}
                    minDate={this.today}
                  />
                  {errors.exceptionEndTime && (
                    <span style={{ color: 'red' }}>
                      {errors.exceptionEndTime}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && (
            <Button color="green" onClick={this.submitForm} inverted>
              <Icon name="checkmark" /> {action}
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DealerExceptionForm;
