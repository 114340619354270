import * as actionType from './actionTypes';

const initialState = {};

const customerNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_CUSTOMER_NOTES:
      return action.payload;
    default:
      return state;
  }
};

export default customerNotesReducer;
