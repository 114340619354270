import React, { Component } from 'react';
import UserForm from './UserForm';

class Create extends Component {
  render() {
    const {
      isModalOpen,
      onCloseHandler,
      searchValue,
      searchQuery
    } = this.props;
    return (
      <UserForm
        isModalOpen={isModalOpen}
        onCloseHandler={onCloseHandler}
        searchValue={searchValue}
        searchQuery={searchQuery}
      />
    );
  }
}

export default Create;
