import * as actionType from './actionTypes';
import _ from 'lodash';

const initialState = {
  isDealerOnIsl: false,
  dealerName: '',
  dealerNumber: ''
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_DEALER_ACTIVE:
      return {
        ...state,
        isDealerOnIsl: action.payload.isDealerOnIsl,
        dealerName: action.payload.dealerName,
        dealerNumber: action.payload.dealerNumber
      };
    case actionType.RESET_DEALER_ACTIVE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
