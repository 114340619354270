import { gql } from 'react-apollo';

const checkCurrentEnrollmentOfDealerQuery = gql`
  query checkCurrentEnrollmentOfDealerQuery($dealerNumber: String!) {
    checkCurrentEnrollmentOfDealer(dealerNumber: $dealerNumber) {
      description
    }
  }
`;

export default checkCurrentEnrollmentOfDealerQuery;
