import { gql } from 'react-apollo';

const pendingGuaranteesQuery = gql`
  query pendingGuarantees(
    $dealerNumbers: [String]
    $size: Int
    $offset: Int
    $vin: String
    $locationInitial: String
  ) {
    pendingGuarantees(
      dealerNumbers: $dealerNumbers
      offset: $offset
      size: $size
      vin: $vin
      locationInitial: $locationInitial
    ) {
      id
      vehicleFullDescription
      purchasePrice
      daysSelected
      milesSelected
      guaranteeStatus
      guaranteePrice
      ifBid
      updatedAt
      emails
      eligibleForPurchase
      pendingReasonNotice
      pendingComments
      guaranteePurchaseId
      auctionInformation {
        id
        auctionAccessNumber
        dealerNumber
        badgeNumber
        universalNumber
        locationInitials
        laneNumber
        runNumber
      }
      vehicleInformation {
        id
        odometerReading
        vin
      }
    }
  }
`;

export default pendingGuaranteesQuery;
