import React, { Component } from 'react';
import {
  Form,
  Checkbox,
  Button,
  Modal,
  Icon,
  Select,
  Message
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';
import {
  RETURN_CANCELATION_REASONS,
  CANCELLATION_NOTE_LAST_PART
} from '../../constants';
import REGEX from '../../constants/regexPatterns';

class ReturnInvoiceCancel extends Component {
  state = {
    show_return: false,
    show_warning: false,
    returnInvoiceId: null,
    cancelationReason: null,
    reason: null,
    sendEmail: false,
    userEmail: '',
    cancellationNote: null,
    errors: {}
  };

  componentDidMount() {
    const { returnInvoice } = this.props;
    const { id, transaction: { vin, vehicleReceived } } = returnInvoice;

    this.setState({ returnInvoiceId: id });
    this.setState({
      show_warning: vehicleReceived,
      show_return: !vehicleReceived
    });
  }

  render() {
    const {
      returnInvoice,
      isCancelModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors, reason, show_warning, show_return } = this.state;

    const continueToReturn = arg => {
      this.setState({ show_warning: arg, show_return: !arg });
    };

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
      this.setState({ reason: data.value });
    };

    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const validationRules = {
      cancelationReason: 'required',
      reason: 'required',
      userEmail: [
        { required_if: ['sendEmail', true] },
        'regex:' + REGEX.MULTIPLE_EMAILS
      ]
    };

    const submitForm = e => {
      const { returnInvoiceId, reason, sendEmail, userEmail } = this.state;

      const validation = new Validator(this.state, validationRules);

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          returnInvoiceId,
          cancellation: {
            cancelationReason: reason,
            sendEmail,
            userEmail
          }
        });
        this.setState({ ...validation.errors });
      }
    };

    const { transaction: { vin, vehicleReceived }, status } = returnInvoice;
    const cancellationNote = isEmpty(reason)
      ? null
      : reason + ', ' + CANCELLATION_NOTE_LAST_PART;
    const isCancelling = status === 'cancelling';
    const isNotAllowedToCancel = isCancelling;
    const openCancelModal = isCancelModalOpen && show_return;
    return (
      <div>
        <Modal open={true} closeOnEscape={false} closeOnRootNodeClick={false}>
          <Modal
            open={show_warning}
            closeOnEscape={false}
            closeOnRootNodeClick={false}
          >
            <Modal.Header>Cancel Return Invoice for VIN {vin}</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Message negative>
                  <p>
                    <strong>
                      This Vehicle Has Already Been Received At The Auction.
                    </strong>
                  </p>
                </Message>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              {show_warning && (
                  <Button onClick={onCloseHandler}>Cancel</Button>
              )}
            </Modal.Actions>
          </Modal>

          <Modal
            open={openCancelModal}
            closeIcon
            onClose={onCloseHandler}
            closeOnEscape={false}
            closeOnRootNodeClick={false}
          >
            <Modal.Header>Cancel Return Invoice for VIN {vin}</Modal.Header>
            {isNotAllowedToCancel && (
              <Modal.Content image>
                <Modal.Description>
                  <Message negative>
                    <p>
                      {isCancelling
                        ? 'This return is cancelling.'
                        : 'This vehicle has been received at auction, hence this return cannot be cancelled.'}
                    </p>
                  </Message>
                </Modal.Description>
              </Modal.Content>
            )}
            {!isNotAllowedToCancel && (
              <Modal.Content image scrolling>
                <Modal.Description>
                  {error && (
                    <Message negative>
                      <p>{error}</p>
                    </Message>
                  )}
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field
                        required
                        error={!isEmpty(errors.cancelationReasons)}
                      >
                        <Select
                          placeholder="Select cancellation reason"
                          options={RETURN_CANCELATION_REASONS}
                          closeOnBlur
                          value={this.state.cancelationReason}
                          onChange={onSelectHandler('cancelationReason').bind(
                            this
                          )}
                        />
                        {errors.cancelationReason && (
                          <span className="error-text">{errors.cancelationReason}</span>
                        )}
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required error={!isEmpty(errors.reason)}>
                        <label>Reason</label>
                        <input
                          label="Reason"
                          name="reason"
                          value={this.state.reason}
                          onChange={onChangeHandler('reason').bind(this)}
                        />
                        {!errors.cancelationReason &&
                          errors.reason && <span className="error-text">{errors.reason}</span>}
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      {cancellationNote && (
                        <Message Floating>
                          <Message.Header>
                            The user will see the following:
                          </Message.Header>
                          <br />
                          <p>{cancellationNote}</p>
                        </Message>
                      )}
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field error={!isEmpty(errors.userEmail)}>
                        <Checkbox
                          label="Send cancellation email?"
                          checked={this.state.sendEmail}
                          onChange={onCheckboxHandler('sendEmail').bind(this)}
                        />
                        <input
                          label="Email"
                          name="email"
                          value={this.state.userEmail}
                          onChange={onChangeHandler('userEmail').bind(this)}
                        />
                        {errors.userEmail && <span className="error-text">{errors.userEmail}</span>}
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Modal.Description>
              </Modal.Content>
            )}
            {!isNotAllowedToCancel && (
              <Modal.Actions>
                {submitting && (
                  <Button loading positive>
                    Submitting...
                  </Button>
                )}
                {!submitting && (
                  <Button color="green" onClick={submitForm} inverted>
                    <Icon name="checkmark" /> Submit
                  </Button>
                )}
                <Button onClick={onCloseHandler}>Cancel</Button>
              </Modal.Actions>
            )}
          </Modal>
        </Modal>
      </div>
    );
  }
}

export default ReturnInvoiceCancel;
