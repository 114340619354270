import { gql } from 'react-apollo';

const subscriptionsQuery = gql`
  query subscriptions($dealerNumber: String, $size: Int, $offset: Int, $auctionAccessNumber: String) {
    subscriptions(dealerNumber: $dealerNumber, offset: $offset, size: $size, auctionAccessNumber: $auctionAccessNumber) {
      id
      email
      firstName
      lastName
      cellPhone
      userName
      notificationSettings {
        id
        dealerNumber
        emailFrequency
      }
      auctionAccessNumber
    }
  }
`;

export default subscriptionsQuery;
