import moment from 'moment';

export const utcMoment = function(date) {
  return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const dateTimeFormatted = function(momentObj) {
  return momentObj.format('YYYY-MM-DD HH:mm:ss ZZ');
};

export const dateTimeFormatted12Hour = function(momentObj) {
  return momentObj.format('MM-DD-YYYY hh:mm:ss A');
};

export const formatDateOnly = function(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
};

export const dateTimeFormattedWithOutTimeZone = function(momentObj) {
  return momentObj.format('YYYY-MM-DD HH:mm:ss');
};
