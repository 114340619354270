import React, { Component } from 'react';
import { isEmpty, map, find } from 'lodash';
import Validator from 'validatorjs';
import NumberFormat from 'react-number-format';

import { Form, Button, Modal, Icon, Message, Divider } from 'semantic-ui-react';
import NotificationSettingsRow from './NotificationSettingsRow';
import NotificationSettingsForm from './NotificationSettingsForm';

class SubscriptionForm extends Component {
  state = {
    id: null,
    email: null,
    firstName: '',
    lastName: '',
    cellPhone: '',
    userName: '',
    notificationSettings: [],
    errors: {},
    error: null
  };

  componentDidMount() {
    const { subscription } = this.props;
    if (!isEmpty(subscription)) {
      const {
        id,
        email,
        firstName,
        lastName,
        cellPhone,
        userName,
        notificationSettings
      } = subscription;

      this.setState({
        id: parseInt(id, 10),
        email,
        firstName,
        lastName,
        cellPhone,
        userName,
        notificationSettings
      });
    }
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors, notificationSettings } = this.state;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const removeNotificationSettingHandler = field => (event, data) => {
      let notificationSettings = Object.assign(
        [],
        this.state.notificationSettings
      );

      let index = notificationSettings.indexOf(
        find(notificationSettings, ['dealerNumber', data.content])
      );

      notificationSettings.splice(index, 1);
      this.setState({ notificationSettings });
    };

    const onChangeEmailFrequencyHandler = field => (event, data) => {
      let notificationSettings = Object.assign(
        [],
        this.state.notificationSettings
      );
      let notificationSetting = Object.assign(
        {},
        find(notificationSettings, ['dealerNumber', field])
      );
      let index = notificationSettings.indexOf(
        find(notificationSettings, ['dealerNumber', field])
      );
      notificationSetting.emailFrequency = data.value;
      notificationSettings[index] = notificationSetting;
      this.setState({ notificationSettings });
    };

    const onAddNewSettingsHandler = values => {
      let notificationSettings = Object.assign(
        [],
        this.state.notificationSettings
      );

      notificationSettings.push(values);
      this.setState({ notificationSettings });
    };

    const validationRules = {
      email: 'required|email',
      cellPhone: 'required|telephone',
      firstName: 'required',
      lastName: 'required',
      userName: 'required'
    };

    Validator.register(
      'telephone',
      function(value, requirement, attribute) {
        // requirement parameter defaults to null
        return value.match(/[^\d{3}]\d{3}-\d{4}$/);
      },
      'The :attribute phone number is not in the format (XXX)XXX-XXXX.'
    );

    const submitForm = () => {
      const {
        id,
        email,
        firstName,
        lastName,
        cellPhone,
        userName,
        notificationSettings
      } = this.state;

      let notificationSettingsArray = [];
      map(notificationSettings, notificationSetting =>
        notificationSettingsArray.push({
          dealerNumber: notificationSetting.dealerNumber,
          emailFrequency: notificationSetting.emailFrequency
        })
      );

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else if (notificationSettingsArray.length === 0) {
        this.setState({
          ...validation.errors,
          error:
            "Please click the green + button to add notifications for this dealer and then click 'Create'"
        });
        return false;
      } else {
        onSubmitHandler({
          id: parseInt(id, 10),
          email: email,
          firstName: firstName,
          lastName: lastName,
          cellPhone: cellPhone,
          userName: userName,
          notificationSettings: notificationSettingsArray
        });
        this.setState({ ...validation.errors, error: null });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Notification Settings</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            You can select how frequently you get notifications for each
            dealership on your DealShield account.
            <br />
            <br />
            <br />
            {(error || this.state.error) && (
              <Message negative>
                <p>{error || this.state.error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.firstName)}>
                  <label>First Name</label>
                  <input
                    name="firstName"
                    value={this.state.firstName}
                    onChange={onChangeHandler('firstName').bind(this)}
                  />
                  {errors.firstName && <span>{errors.firstName}</span>}
                </Form.Field>
                <Form.Field required error={!isEmpty(errors.lastName)}>
                  <label>Last Name</label>
                  <input
                    name="lastName"
                    value={this.state.lastName}
                    onChange={onChangeHandler('lastName').bind(this)}
                  />
                  {errors.lastName && <span>{errors.lastName}</span>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.cellPhone)}>
                  <label>Mobile Phone</label>
                  <NumberFormat
                    format="(###) ###-####"
                    mask="_"
                    value={this.state.cellPhone}
                    onChange={onChangeHandler('cellPhone').bind(this)}
                  />
                  {errors.cellPhone && <span>{errors.cellPhone}</span>}
                </Form.Field>
                <Form.Field required error={!isEmpty(errors.email)}>
                  <label>Email</label>
                  <input
                    name="email"
                    value={this.state.email}
                    onChange={onChangeHandler('email').bind(this)}
                  />
                  {errors.email && <span>{errors.email}</span>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.userName)}>
                  <label>User Name</label>
                  <input
                    name="userName"
                    value={this.state.userName}
                    onChange={onChangeHandler('userName').bind(this)}
                  />
                  {errors.userName && <span>{errors.userName}</span>}
                </Form.Field>
                <Form.Field />
              </Form.Group>
              <br />
              <Divider />
              <br />
              {map(notificationSettings, notificationSetting => (
                <NotificationSettingsRow
                  notificationSetting={notificationSetting}
                  onChangeEmailFrequencyHandler={onChangeEmailFrequencyHandler}
                  removeNotificationSettingHandler={
                    removeNotificationSettingHandler
                  }
                />
              ))}

              <br />
              <Divider />
              <br />
              <NotificationSettingsForm
                onSubmitHandler={onAddNewSettingsHandler}
                notificationSettings={notificationSettings}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && !this.state.id && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Create
            </Button>
          )}

          {!submitting && this.state.id && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}

          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default SubscriptionForm;
