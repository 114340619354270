import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';

const ListRow = externalTransaction => {
  const {
    id,
    vin,
    purchaseLocation,
    guaranteeDate,
    arb,
    arbStatus,
    arbDate,
    buyer,
    buyerName,
    seller,
    sellerName,
    saleDate,
    paidDate,
    modelYear,
    make,
    model,
    body,
    auction,
    vehicleSalePrice,
    buyFee,
    dealshieldPrice,
    vraId,
    returnLocation,
    vraReturnDate,
    dsDepositFlag,
    floorPlan,
    floorPlanDescription,
    buynet,
    imsVehicleStatus,
    vehicleSecured,
    imsSecuredDate,
    partnerAuctionTitleStatus,
    imsTitleStatusFromAuction,
    imsTitleReceivedStatus,
    imsTitleReceived,
    holdType,
    titleIssueDate,
    imsTitleStatus,
    meridianSoldDate,
    runNumber,
    uniqueId,
    announcement,
    miles,
    channel,
    salvage,
    closedSale,
    returnWoNumber,
    imsTitleNumber,
    clearedForSale,
    imsRecordDate,
    transReqDate,
    imsTitleStatusLoc,
    createdAt,
    updatedAt,
    event,
    howPaidDescription1,
    paymentAmount1,
    howPaid2,
    howPaidDescription2,
    paymentAmount2,
    howPaid3,
    howPaidDescription3,
    paymentAmount3,
    buyerAdjustments,
    splitPay
  } = externalTransaction;

  const vehicle = `${_.toString(modelYear)} ${_.toString(make)} ${_.toString(
    model
  )}`;

  const auctionInfo = [purchaseLocation, runNumber].join(' / ');

  return (
    <Table.Row key={id}>
      <Table.Cell>{vraId}</Table.Cell>
      <Table.Cell>{vraReturnDate}</Table.Cell>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell>{buyer}</Table.Cell>
      <Table.Cell>{buyerName}</Table.Cell>
      <Table.Cell>{seller}</Table.Cell>
      <Table.Cell>{sellerName}</Table.Cell>
      <Table.Cell>{saleDate}</Table.Cell>
      <Table.Cell>{guaranteeDate}</Table.Cell>
      <Table.Cell>{howPaidDescription1}</Table.Cell>
      <Table.Cell>{_.toString(dsDepositFlag)}</Table.Cell>
      <Table.Cell>{splitPay}</Table.Cell>
      <Table.Cell>{paidDate}</Table.Cell>
      <Table.Cell>{floorPlan}</Table.Cell>
      <Table.Cell>{floorPlanDescription}</Table.Cell>
      <Table.Cell>{paymentAmount1}</Table.Cell>
      <Table.Cell>{vehicleSalePrice}</Table.Cell>
      <Table.Cell>{buyFee}</Table.Cell>
      <Table.Cell>{dealshieldPrice}</Table.Cell>
      <Table.Cell>{buynet}</Table.Cell>
      <Table.Cell>{buyerAdjustments}</Table.Cell>
      <Table.Cell>{_.toString(arb)}</Table.Cell>
      <Table.Cell>{arbStatus}</Table.Cell>
      <Table.Cell>{arbDate}</Table.Cell>
      <Table.Cell>{auctionInfo}</Table.Cell>
      <Table.Cell>{auction}</Table.Cell>
      <Table.Cell>{returnLocation}</Table.Cell>
      <Table.Cell>{_.toString(vehicleSecured)}</Table.Cell>
      <Table.Cell>{imsSecuredDate}</Table.Cell>
      <Table.Cell>{imsVehicleStatus}</Table.Cell>
      <Table.Cell>{partnerAuctionTitleStatus}</Table.Cell>
      <Table.Cell>{imsTitleStatusFromAuction}</Table.Cell>
      <Table.Cell>{imsTitleReceived}</Table.Cell>
      <Table.Cell>{imsTitleStatus}</Table.Cell>
      <Table.Cell>{titleIssueDate}</Table.Cell>
      <Table.Cell>{meridianSoldDate}</Table.Cell>
      <Table.Cell>{clearedForSale}</Table.Cell>
      <Table.Cell>{transReqDate}</Table.Cell>
      <Table.Cell>{announcement}</Table.Cell>
      <Table.Cell>{returnWoNumber}</Table.Cell>
      <Table.Cell>{createdAt}</Table.Cell>
      <Table.Cell>{updatedAt}</Table.Cell>
      <Table.Cell>{event}</Table.Cell>
      <Table.Cell>{imsTitleReceivedStatus}</Table.Cell>
      <Table.Cell>{imsTitleNumber}</Table.Cell>
      <Table.Cell>{imsRecordDate}</Table.Cell>
      <Table.Cell>{imsTitleStatusLoc}</Table.Cell>
      <Table.Cell>{uniqueId}</Table.Cell>
      <Table.Cell>{body}</Table.Cell>
      <Table.Cell>{vehicle}</Table.Cell>
      <Table.Cell>{miles}</Table.Cell>
      <Table.Cell>{channel}</Table.Cell>
      <Table.Cell>{_.toString(salvage)}</Table.Cell>
      <Table.Cell>{_.toString(closedSale)}</Table.Cell>
      <Table.Cell>{howPaid2}</Table.Cell>
      <Table.Cell>{howPaidDescription2}</Table.Cell>
      <Table.Cell>{paymentAmount2}</Table.Cell>
      <Table.Cell>{howPaid3}</Table.Cell>
      <Table.Cell>{howPaidDescription3}</Table.Cell>
      <Table.Cell>{paymentAmount3}</Table.Cell>
      <Table.Cell>{holdType}</Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
