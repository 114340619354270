import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import LoadingDataEnhancer from './LoadingData';
import withPagination from './WithPagination';
import GuaranteePurchasesList from '../views/GuaranteePurchases/List';
import Search from '../views/Shared/Search';
import GuaranteePurchaseEdit from '../views/GuaranteePurchases/EditForm';
import GuaranteePurchaseExtend from '../views/GuaranteePurchases/ExtendForm';
import GuaranteePurchaseCancel from '../views/GuaranteePurchases/CancelForm';
import GuaranteePurchaseAudit from '../views/GuaranteePurchases/Audit';

import guaranteePurchasesQuery from '../graphql/guaranteePurchasesQuery';
import updateGuaranteePurchaseMutation from '../graphql/updateGuaranteePurchaseMutation';
import cancelGuaranteePurchaseMutation from '../graphql/cancelGuaranteePurchaseMutation';
import { clearSearch, setSearch } from '../data/searchField/actions';
import { setRefreshSearch } from '../data/refreshSearch/actions';
import APP_PATH from '../constants/paths';
import { setPage } from '../data/page/actions';

class GuaranteePurchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGuaranteePurchase: null,
      isEditModalOpen: false,
      isAuditModalOpen: false,
      editFormError: null,
      editFormSubmitting: null,
      isCancelModalOpen: false,
      cancelFormError: null,
      cancelFormSubmitting: null,
      flashMessage: null,
      isExtendModalOpen: false,
      extendFormError: null,
      extendFormSubmitting: null
    };
    const { clearSearchFields, dispatchRefreshSearch, refreshSearch } = props;

    if (refreshSearch) {
      dispatchRefreshSearch(false);
      clearSearchFields({});
      const variableArguments = {
        vin: null,
        dealerNumbers: []
      };
      this.props.data.refetch({
        ...variableArguments
      });
    }
  }

  handleMessageDismiss = () => {
    this.setState({ flashMessage: null });
  };

  onSelectGuarantee = (action, guarantee) => {
    this.setState({
      selectedGuaranteePurchase: guarantee
    });

    const { history } = this.props;
    switch (action) {
      case 'Edit':
        this.setState({
          isEditModalOpen: true,
          isCancelModalOpen: false
        });
        break;
      case 'Extend Guarantee':
        this.setState({
          isExtendModalOpen: true,
          extendFormError: null,
          extendFormSubmitting: null,
          isCancelModalOpen: false,
          isEditModalOpen: false
        });
        break;
      case 'Cancel Guarantee':
        this.setState({
          isCancelModalOpen: true,
          isEditModalOpen: false,
          cancelFormError: null,
          cancelFormSubmitting: null
        });
        break;
      case 'Audit':
        this.setState({
          isAuditModalOpen: true,
          isEditModalOpen: false,
          cancelFormError: null,
          cancelFormSubmitting: null
        });
        break;
      case 'Vehicle Status Tracker':
        history.push(
          APP_PATH.VEHICLE_STATUS_TRACKER.replace(':purchaseId', guarantee.id)
        );
        break;
      default:
        this.setState({
          selectedGuaranteePurchase: null
        });
    }
  };

  onEditFormCloseHandler = () => {
    this.setState({
      isEditModalOpen: false,
      selectedGuaranteePurchase: null,
      editFormError: null,
      editFormSubmitting: null
    });
  };

  onAuditCloseHandler = () => {
    this.setState({
      isAuditModalOpen: false,
      selectedGuaranteePurchase: null
    });
  };

  onEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const purchaseInput = { guaranteePurchase: { ...values } };
    this.setState({
      editFormSubmitting: true
    });
    updateMutation({
      variables: { purchaseInput }
    })
      .then(({ data }) => {
        const {
          updateGuaranteePurchase: { success, errors }
        } = data;
        if (success) {
          this.setState({
            editFormSubmitting: false,
            flashMessage: 'Successfully Updated Guarantee Purchase'
          });
          this.props.data.refetch();
          this.props.setPage({});
          this.onEditFormCloseHandler();
        } else {
          this.setState({
            editFormSubmitting: false,
            editFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: 'Error while updating Guarantee Purchase'
        });
      });
  };

  onExtendFormCloseHandler = () => {
    this.setState({
      isExtendModalOpen: false,
      selectedGuaranteePurchase: null,
      extendFormError: null,
      extendFormSubmitting: null
    });
  };

  onExtendFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const purchaseInput = { guaranteePurchase: { ...values } };
    this.setState({
      extendFormSubmitting: true
    });
    updateMutation({
      variables: { purchaseInput }
    })
      .then(({ data }) => {
        const {
          updateGuaranteePurchase: { success, errors }
        } = data;
        if (success) {
          this.setState({
            extendFormSubmitting: false,
            flashMessage: 'Successfully extended Guarantee Purchase'
          });
          this.onExtendFormCloseHandler();
        } else {
          this.setState({
            extendFormSubmitting: false,
            extendFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          extendFormSubmitting: false,
          extendFormError: 'Error while extending Guarantee Purchase'
        });
      });
  };

  onCancelFormCloseHandler = () => {
    this.setState({
      isCancelModalOpen: false,
      selectedGuaranteePurchase: null
    });
  };

  onCancelFormSubmitHandler = values => {
    this.setState({
      cancelFormSubmitting: true
    });
    const { cancelMutation } = this.props;
    const cancelInput = { ...values };
    cancelMutation({
      variables: { cancelInput }
    })
      .then(({ data }) => {
        const {
          cancelGuaranteePurchase: { success, errors }
        } = data;
        if (success) {
          this.props.data.refetch();
          this.setState({
            isCancelModalOpen: false,
            selectedGuaranteePurchase: null,
            cancelFormSubmitting: null,
            flashMessage: 'Successfully Cancelled Guarantee Purchase'
          });
        } else {
          this.setState({
            cancelFormSubmitting: false,
            cancelFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          cancelFormSubmitting: false,
          cancelFormError: 'Error while cancelling Guarantee Purchase'
        });
      });
  };

  onSearchSubmitHandler = event => {
    const { vin, dealerNumbers } = event.target;
    const dealerNos = isEmpty(dealerNumbers.value)
      ? null
      : [dealerNumbers.value.trim()];
    const variableArguments = {
      vin: vin.value.trim() || null,
      dealerNumbers: dealerNos
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({
      cancelFormSubmitting: true
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  render() {
    const {
      selectedGuaranteePurchase,
      isEditModalOpen,
      isAuditModalOpen,
      editFormError,
      editFormSubmitting,
      isCancelModalOpen,
      cancelFormError,
      cancelFormSubmitting,
      isExtendModalOpen,
      extendFormError,
      extendFormSubmitting,
      flashMessage
    } = this.state;

    const {
      data: { guaranteePurchases },
      searchFields
    } = this.props;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
          />
        )}
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['vin', 'dealerNumbers']}
        />
        <GuaranteePurchasesList
          onSelectGuarantee={this.onSelectGuarantee}
          guaranteePurchases={guaranteePurchases}
        />

        {selectedGuaranteePurchase && isEditModalOpen && (
          <div>
            <GuaranteePurchaseEdit
              isModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              onSubmitHandler={this.onEditFormSubmitHandler}
              guaranteePurchase={selectedGuaranteePurchase}
              error={editFormError}
              submitting={editFormSubmitting}
            />
          </div>
        )}

        {selectedGuaranteePurchase && isCancelModalOpen && (
          <div>
            <GuaranteePurchaseCancel
              isCancelModalOpen={isCancelModalOpen}
              onCloseHandler={this.onCancelFormCloseHandler}
              onSubmitHandler={this.onCancelFormSubmitHandler}
              guaranteePurchase={selectedGuaranteePurchase}
              error={cancelFormError}
              submitting={cancelFormSubmitting}
            />
          </div>
        )}

        {selectedGuaranteePurchase && isAuditModalOpen && (
          <div>
            <GuaranteePurchaseAudit
              isAuditModalOpen={isAuditModalOpen}
              onCloseHandler={this.onAuditCloseHandler}
              guaranteePurchase={selectedGuaranteePurchase}
            />
          </div>
        )}

        {selectedGuaranteePurchase && isExtendModalOpen && (
          <div>
            <GuaranteePurchaseExtend
              isModalOpen={isExtendModalOpen}
              onCloseHandler={this.onExtendFormCloseHandler}
              onSubmitHandler={this.onExtendFormSubmitHandler}
              guaranteePurchase={selectedGuaranteePurchase}
              error={extendFormError}
              submitting={extendFormSubmitting}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields, refreshSearch }
  } = state;
  return {
    searchFields: searchFields,
    refreshSearch
  };
};

export default compose(
  withRouter,
  withPagination(guaranteePurchasesQuery, 'guaranteePurchases'),
  graphql(updateGuaranteePurchaseMutation, { name: 'updateMutation' }),
  graphql(cancelGuaranteePurchaseMutation, { name: 'cancelMutation' }),
  LoadingDataEnhancer,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GuaranteePurchases);
