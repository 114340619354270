import React from 'react';
import Barcode from 'react-barcode';
import Header from '../../views/ReturnCertificate/header';
import VehicleDetails from '../../views/ReturnCertificate/vehicleDetails';
import PrintButton from '../../views/ReturnCertificate/printButton';
import ReactTooltip from 'react-tooltip';

const certificate = ({ returnInvoice, dealerData }) => {
  const {
    transaction: { vin }
  } = returnInvoice;
  const dealer = dealerData[0];

  return (
    <div>
      <PrintButton />
      <Header
        notification={
          dealer ? dealer.accountNotifications.notifyStateReassignment : {}
        }
      />
      <p className="sm" style={{ marginLeft: 10 + 'em' }}>
        PLEASE PRINT AND PLACE COPY ON VEHICLE DASHBOARD.
      </p>
      <div data-tip={vin}>
        <Barcode value={vin} displayValue={false} />
      </div>
      <ReactTooltip />
      <VehicleDetails returnInvoice={returnInvoice} />
      <br />
      <br />
      All DealShield returns are subject to audit for compliance with
      DealShield's terms and conditions (for more information, see{' '}
      <a href="http://dealshield.com/terms">dealshield.com/terms).</a>
      <br />
      <br />
      <footer>
        <h2>NEED HELP? QUESTIONS?</h2>
        <p>Please contact DealShield Customer Service:</p>
        1-855-246-5556 | Support@dealshield.com
        <br />
        <em>
          Customer Service available (Mon – Fri, 8:30am - 8:30pm Eastern).
        </em>
      </footer>
    </div>
  );
};

export default certificate;
