import { gql } from 'react-apollo';

const dealerInformationQuery = gql`
  query dealerInformationQuery($dealerNumber: String!) {
    dealerInformation(dealerNumber: $dealerNumber) {
      dealerNo
      dealerName
    }
  }
`;

export default dealerInformationQuery;
