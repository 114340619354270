export default {
  RULE_TYPE_FACTS_NUMBER: [
    'LOCATION_KEY',
    'SALE_PRICE',
    'MILEAGE',
    'AUCTION_LANE_NUM',
    'SELLER_NUMBER',
    'HUNDRED_MIL',
    'REP_NUMBER',
    'YEAR',
    'ODOMETER'
  ],
  RULE_TYPE_FACTS_CHARACTER: [
    'BUYER_CERTIFIED',
    'SALE_TYPE',
    'MAKE',
    'PARTNER',
    'GROUP_NAME',
    'PARTNER_CHANNEL',
    'CHANNEL',
    'LOCATION_CODE',
    'MODEL'
  ],
  RULE_TYPE_FACTS_CHARACTER_WITH_SPACE: ['MODEL'],
  MSG_FACT_NUMBER: 'Fact value of this rule must be a number',
  MSG_FACT_CHARACTER: 'Special characters are not allowed',
  MSG_FACT_NULL:
    'Fact value is required for a rule. Please make sure none of the Facts fields is empty.'
};
