import React from 'react';
import { Grid, Form, Button, Image } from 'semantic-ui-react';
import logo from '../../assets/dealshield-logo.png';

const PingLoginForm = ({ error, onSubmit }) => (
  <Grid centered rows={2}>
    <Grid.Row>
      <Image src={logo} />
    </Grid.Row>
    <Grid.Row>
      <Form as="form" onSubmit={onSubmit}>
        <Button fluid type="submit">
          Employee Login
        </Button>
      </Form>
    </Grid.Row>
  </Grid>
);

PingLoginForm.defaultProps = {
  errors: ''
};

export default PingLoginForm;
