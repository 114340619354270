import { createAction } from 'redux-actions';
import * as actionType from './actionTypes';

const setSecondarySearch = createAction(actionType.SET_SECONDARY_SEARCH);
const clearSecondarySearch = createAction(actionType.CLEAR_SECONDARY_SEARCH);
const clearAllSecondarySearch = createAction(
  actionType.CLEAR_ALL_SECONDARY_SEARCH
);

export { setSecondarySearch, clearSecondarySearch, clearAllSecondarySearch };
