import { gql } from 'react-apollo';

const deleteMarketFactorMutation = gql`
  mutation deleteMarketFactor($input: DestroyMarketFactorInput!) {
    deleteMarketFactor(input: $input) {
      success
      errors
    }
  }
`;

export default deleteMarketFactorMutation;
