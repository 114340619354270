import React, { Component } from 'react';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class List extends Component {
  render() {
    const { userList, openEditUserForm, resetApiTokenHandler } = this.props;
    const listRow = ListRow(openEditUserForm, resetApiTokenHandler);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Username</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Active</Table.HeaderCell>
        <Table.HeaderCell>Location Initials</Table.HeaderCell>
        <Table.HeaderCell>Authentication Token</Table.HeaderCell>
        <Table.HeaderCell width="4">Roles</Table.HeaderCell>
        <Table.HeaderCell>Edit</Table.HeaderCell>
        <Table.HeaderCell>Reset API Token</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Header>Users</Header>
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={userList}
            headerRow={headerRow}
            size="small"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default List;
