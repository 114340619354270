import { gql } from 'react-apollo';

const createVinBlacklistMutation = gql`
  mutation createVinBlacklist($input: CreateVinBlacklistInput!) {
    createVinBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default createVinBlacklistMutation;
