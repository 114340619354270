import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class ReturnInvoicesList extends Component {
  render() {
    const { onSelectReturnInvoice, returnInvoices } = this.props;
    const listRow = ListRow(onSelectReturnInvoice);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>VRA</Table.HeaderCell>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>Dealer Number</Table.HeaderCell>
        <Table.HeaderCell>Return Location</Table.HeaderCell>
        <Table.HeaderCell>Odometer on Return</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Price</Table.HeaderCell>
        <Table.HeaderCell>Return Initiated At</Table.HeaderCell>
        <Table.HeaderCell>Return Expiration Date</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Return Initiated By</Table.HeaderCell>
        <Table.HeaderCell>Return Canceled By</Table.HeaderCell>
        <Table.HeaderCell>Canceled At</Table.HeaderCell>
        <Table.HeaderCell>Cancellation Reason</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Canceled Return Invoices</Header>
        <div>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={returnInvoices}
            headerRow={headerRow}
            size="small"
            striped
            compact="very"
            style={{ fontSize: '90%' }}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(ReturnInvoicesList);
