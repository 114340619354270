import { gql } from 'react-apollo';

const userSearchQuery = gql`
  query($searchParam: String) {
    searchUser(searchParam: $searchParam) {
      id
      username
      email
      active
      roles
      locationInitials
      authenticationToken
      legacySiteDisabled
    }
  }
`;

export default userSearchQuery;
