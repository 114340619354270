import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Message, List, Button } from 'semantic-ui-react';
import { format as dateFormat } from 'date-fns';
import { isEmpty } from 'lodash';
import { SubmissionError } from 'redux-form';

import APP_PATH from '../../constants/paths';
import networkPlusOfferAcceptMutation from '../../graphql/networkPlusOfferAcceptMutation';
import networkPlusOfferRejectMutation from '../../graphql/networkPlusOfferRejectMutation';

class NetworkPlusOfferContent extends Component {
  constructor(props) {
    super(props);
    this.setState({
      error: ''
    });
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleRejectClick = this.handleRejectClick.bind(this);
  }

  handleAcceptClick() {
    this.setState({ action: 'accept' });
  }

  handleRejectClick() {
    this.setState({ action: 'reject' });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      history,
      acceptNetworkPlusOffer,
      rejectNetworkPlusOffer,
      networkPlusOffer: { id }
    } = this.props;

    if (this.state.action === 'accept') {
      return acceptNetworkPlusOffer({ variables: { id } }).then(
        ({ data: { acceptNetworkPlusOffer } }) => {
          const { success, errors } = acceptNetworkPlusOffer;
          if (!success || !isEmpty(errors)) {
            this.setState({ error: 'Error! Something went wrong.' });
            throw new SubmissionError({ _error: errors });
          }
          this.setState({ error: '' });

          history.push(APP_PATH.NETWORK_PLUS_OFFER_APPROVAL.replace(':id', id));
        }
      );
    }
    return rejectNetworkPlusOffer({ variables: { id } }).then(
      ({ data: { rejectNetworkPlusOffer } }) => {
        const { success, errors } = rejectNetworkPlusOffer;
        if (!success || !isEmpty(errors)) {
          this.setState({ error: 'Error! Something went wrong.' });
          throw new SubmissionError({ _error: errors });
        }
        this.setState({ error: '' });
        history.push(APP_PATH.NETWORK_PLUS_OFFER_APPROVAL.replace(':id', id));
      }
    );
  }

  render() {
    const {
      networkPlusOffer: {
        dealerNo,
        dealerName,
        units,
        price,
        miles,
        eligibleForAcceptance,
        approved,
        rejected,
        maxModelYear,
        maxOdometerLimit,
        maxVehicleValue
      }
    } = this.props;

    const today = dateFormat(new Date(), 'MMM D, YYYY');

    return (
      <div style={{ fontSize: '18px' }}>
        <Message>
          <Message.Header style={{ textAlign: 'center' }}>
            Offer Terms
          </Message.Header>
        </Message>

        <div>
          {approved && (
            <Message success>
              <p>
                This offer has been accepted. If you have any questions please
                contact Customer Care at
                <a href="mailto: automatic@dealshield.com">
                  {' automatic@dealshield.com'}
                </a>
              </p>
            </Message>
          )}
          {rejected && (
            <Message negative>
              <p>
                This offer has been rejected. If you have any questions please
                contact Customer Care at
                <a href="mailto: automatic@dealshield.com">
                  {' automatic@dealshield.com'}
                </a>
              </p>
            </Message>
          )}
          <div>{today}</div>
          <div>Dear {dealerName}</div>
          <br />
          <br />
          <p>
            We are pleased to offer you DealShield (DS) on every vehicle you
            purchase! Under this program, the fee for the protection will be
            applied to every vehicle automatically. This is an exclusive offer
            for select dealerships. As a member of the program, you will enjoy
            peace of mind by protecting{' '}
            <u>
              <b>all</b>
            </u>{' '}
            of your vehicle purchases, as well as benefiting from a consistent
            fee structure.
          </p>
          <p>
            For all your purchases, your program rate will be according to the
            schedule below. This rate will begin to be applied to all your
            purchases made outside of Manheim and BSC auctions, once you
            electronically reply to this email with the words “I accept the
            terms” outlined in this letter.
          </p>
          <div>
            <div style={{ textAlign: 'center' }}>
              <b style={{ borderBottom: '1px solid black' }}>
                Protection Terms and Limits Applied to Each vehicle
              </b>
            </div>
            <br />
            <table>
              <thead>
                <tr style={{ borderBottom: '1px solid black' }}>
                  <th style={{ width: '30%', textAlign: 'center' }}>
                    <b>Dealer Information</b>
                  </th>
                  <th style={{ width: '10%', textAlign: 'center' }}>
                    <b>Rate</b>
                  </th>
                  <th style={{ width: '10%', textAlign: 'center' }}>
                    <b>Days From Purchase</b>
                  </th>
                  <th style={{ width: '10%', textAlign: 'center' }}>
                    <b>Addtl {units} Covered</b>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    {dealerNo} - {dealerName}
                  </td>

                  <td style={{ textAlign: 'center' }}>${price}</td>

                  <td style={{ textAlign: 'center' }}>21</td>

                  <td style={{ textAlign: 'center' }}>{miles}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <p>There are just a few simple guidelines to the program:</p>
          <List bulleted>
            <List.Item>
              DealShield must be applied to all of your eligible vehicle
              purchases at each Manheim location and all your outside Manheim
              purchases.
            </List.Item>

            <List.Item>
              For purchases outside of Manheim, we need a faxed or emailed copy
              (to{' '}
              <a href="mailto:Network+@DealShield.com">
                Network+@DealShield.com
              </a>
              ) of your vehicle purchase summary/workbook, so that we can track
              the purchases. [Such purchase summaries/workbooks must be provided
              within 2 days of vehicle purchase.]
            </List.Item>

            <List.Item>
              Once we receive your vehicle purchase summary/workbook for a
              specific sale, we will email you a Network+ Confirmation &
              Invoice.
            </List.Item>

            <List.Item>
              Such Network+ invoices should be paid within 7 days. Checks, made
              payable to “DealShield”, can be mailed to: DealShield, 3003 Summit
              Blvd, Ste. 200, Atlanta, GA 30319. In no event may any vehicle be
              returned prior to paying the Network+ invoice.
            </List.Item>

            <List.Item>
              Vehicles eligible in the DealShield Network Plus Program must meet
              the following qualifications: model year {maxModelYear} or newer,
              under {maxOdometerLimit} miles on the odometer, and up to{' '}
              {maxVehicleValue} in vehicle value. As is vehicles are covered,
              however TRA (Total Resource Auction), Salvage, TMU (True Miles
              Unknown), Specialty, Hand-built, Exotic, and MSO (Manufacturer’s
              Statement of Origin) vehicles are not covered.
            </List.Item>

            <List.Item>
              For all vehicles purchased at a price in excess of $35,000, a
              surcharge will be applied to your program rate on those vehicles
              only. The surcharge is 1.5% of the amount in excess of $35,000.
              For example, if you purchase a vehicle at a sale price of $38,000
              and your program rate is $185, the rate on this vehicle would be
              would be $185 + $45 (1.5% of 3,000) or a total of $230.
            </List.Item>

            <List.Item>
              If you need to return a vehicle during the guarantee period,
              please log on to guarantee.dealshield.com to initiate a return and
              obtain your Vehicle Return Authorization (VRA) for each vehicle
              returned. You will not be able to return a vehicle if you have not
              first paid for its DealShield protection. For more detailed
              instructions on the return process, please view our guide at{' '}
              <a href="http://www.dealshield.com/how-to-return/">
                'http://www.dealshield.com/how-to-return/'
              </a>
              .
            </List.Item>

            <List.Item>
              "Once you have received the VRA, return the vehicle to the
              selected Manheim Auction location with a copy of the respective
              VRA placed on the vehicle's dashboard."
            </List.Item>

            <List.Item>
              The guarantee period, denoted as 'Days from Purchase' in the table
              above, starts the day after the vehicle purchase (Day #1); returns
              must be initiated no later than 5pm on the last day of your
              guarantee period. You have until one day after your guarantee
              period ends to return the vehicle to that Manheim Auction
              location, regardless of the day that you initiated the return.
            </List.Item>

            <List.Item>
              Returns may not be initiated on the day that the vehicle was
              purchased.
            </List.Item>

            <List.Item>
              Both the vehicle and title must be received and verified by a
              Manheim Auction before refunds will be issued; refunds will be
              processed within 5 business days from the date of such
              verification.
            </List.Item>

            <List.Item>
              Your program rate will be re-assessed on the first business day of
              each month, but will not be adjusted for at least 60 days from the
              date you start on this program. You will be notified before any
              rate adjustment (it could go up or down) is made. If your rate is
              adjusted, you will have the right to immediately opt-out of the
              DealShield Network Plus Program. You can do so by calling
              DealShield Customer Service at 855-246-5556.
            </List.Item>

            <List.Item>
              Your DealShield rate is unique to your dealership and this
              information is CONFIDENTIAL. You understand and agree that failure
              to maintain the confidentiality of your DealShield Network Plus
              Program rate is a material breach of this agreement. Such breach
              gives DealShield the right to terminate your continued
              participation in this program at its sole discretion. Any
              guarantees purchased through the DealShield Network Plus Program
              prior to the date of termination will remain in effect pursuant to
              the terms of this agreement.
            </List.Item>

            <List.Item>
              All other terms and conditions of DealShield (available on
              guarantee.dealshield.com) apply. In the event that any terms and
              conditions referenced herewith conflict with the terms, the terms
              listed on guarantee.dealshield.com will apply.
            </List.Item>

            <List.Item>
              We reserve the right to make changes to or cancel the program at
              any time, with 48 hours advance notice of such changes. Any
              guarantees purchased pursuant to this DealShield Network Plus
              Program prior to the date of such change or cancelation will be
              governed by the terms in effect at the time of purchase.
            </List.Item>
          </List>
          <p>
            The last thing that we ask – given that you are in a very select
            group of dealers – is that you keep this program confidential
            outside of your dealership/dealer group. Membership is a privilege
            and we hope you enjoy the assurance it provides.
          </p>
          <p>
            Before your enrollment in this DealShield Network Plus Program is
            effective, we must receive your electronic acceptance that you agree
            to these terms. The link to accept entrance into the program can be
            found in the welcome email that contained this letter. Once
            received, we can start you on this DealShield Network Plus Program
            immediately.
          </p>
          <p>
            If you have any questions or concerns, please contact our customer
            service team.
          </p>
          <p>Welcome to the program!</p>
          <br />
          <br />
          Regards,
          <br />
          <br />
          <strong> The DealShield Team </strong>
          <br />
          <br />
          DealShield Customer Service:
          <br />
          Phone: 855-246-5556 | Fax: 855-299-6250 | Email:
          <a href="mailto:Members@dealshield.com">
            {' Members@dealshield.com'}
          </a>
          <br />
          Hours: (Mon – Fri, 8:30am - 8:30pm Eastern).
        </div>

        {eligibleForAcceptance && (
          <Form onSubmit={this.handleSubmit}>
            <div>
              <Button
                color="green"
                type="submit"
                value="accept"
                onClick={this.handleAcceptClick}
              >
                Accept
              </Button>
              <Button
                color="red"
                type="submit"
                value="reject"
                onClick={this.handleRejectClick}
              >
                Reject
              </Button>
              <br />
              <b>
                When you click {'Accept'}, your Automatic Guarantee coverage
                will begin the following business day.
              </b>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

NetworkPlusOfferContent.propTypes = {
  acceptNetworkPlusOffer: PropTypes.func,
  rejectNetworkPlusOffer: PropTypes.func,
  history: PropTypes.object,
  networkPlusOffer: PropTypes.object
};

const acceptNetworkPlusOfferQL = graphql(networkPlusOfferAcceptMutation, {
  name: 'acceptNetworkPlusOffer'
});

const rejectNetworkPlusOfferQL = graphql(networkPlusOfferRejectMutation, {
  name: 'rejectNetworkPlusOffer'
});

export default compose(
  withRouter,
  acceptNetworkPlusOfferQL,
  rejectNetworkPlusOfferQL
)(NetworkPlusOfferContent);
