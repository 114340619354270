import React, { Component } from 'react';

import { Form, Button, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import localStore from 'store';
import RuleSet from './RuleSet';
import { v1 as uuidv1 } from 'uuid';

import './preferencesStyle.css';

class Preference extends Component {
  onAddRuleSet = e => {
    e.preventDefault();

    let preference = localStore.get('preference');
    let { ruleSetList } = preference;

    const newRuleSet = {
      uid: uuidv1(),
      defaultPreferenceRuleType: '',
      items: []
    };

    ruleSetList.push(newRuleSet);
    localStore.set('preference', preference);
    this.setState({});
  };

  render() {
    const preference = localStore.get('preference') || {};

    return (
      <main>
        <Form.Group className="preferencesHeader mtb-2em">
          <h3>Subscriptions Preferences</h3>
          <span>
            <Button color="green" inverted onClick={this.onAddRuleSet}>
              <Icon name="add" /> Add RuleSet
            </Button>
          </span>
        </Form.Group>

        <section>
          <div className="ruleSetForm">
            {!isEmpty(preference.ruleSetList) &&
              preference.ruleSetList.map((ruleSet, idx) => {
                return (
                  <Form.Group>
                    <RuleSet
                      key={idx}
                      refId={ruleSet.uid}
                      id={idx}
                      parentRender={() => {
                        this.setState({});
                      }}
                      isSubmitting={this.props.isSubmitting}
                    />
                  </Form.Group>
                );
              })}
          </div>
        </section>
      </main>
    );
  }
}

export default Preference;
