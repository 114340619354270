import React from 'react';
import { renderComponent, branch } from 'recompose';
import { Dimmer, Loader, Container } from 'semantic-ui-react';

const CenteredCircularProgress = () => {
  return (
    <Container>
      <Dimmer inverted active style={{ height: 300 }}>
        <Loader indeterminate>Loading...</Loader>
      </Dimmer>
    </Container>
  );
};

const spinnerWhileLoading = isLoading =>
  branch(props => isLoading(props), renderComponent(CenteredCircularProgress));

const LoadingData = spinnerWhileLoading(props => props.data.loading);

export default LoadingData;
