import React, { Component } from 'react';

import './preferencesStyle.css';
import { Radio, Tab, Dropdown } from 'semantic-ui-react';
import localStore from 'store';

import Rule from './Rule';
import { isEmpty } from 'lodash';

import { v1 as uuidv1 } from 'uuid';
import preferenceZIndex from '../../../utilities/preferenceUtil';

let inner_rules_set = [];
let rs_inner_rules = [];
class InnerRuleSet extends Component {
  swapHandle = (arr, idx1, idx2) => {
    let temp = arr[idx1];
    arr[idx1] = arr[idx2];
    arr[idx2] = temp;
  };

  selectActionHandler = (e, action) => {
    const { refId, parentId, parentRender } = this.props;
    let preference = localStore.get('preference') || {};
    let { ruleSetList, ruleList, innerRuleSetList } = preference;

    let parent = ruleSetList.find(r => r.uid === parentId);
    if (isEmpty(parent)) {
      parent = innerRuleSetList.find(r => r.uid === parentId);
    }
    let parentItems = parent.items;
    let itemIndex = parentItems.indexOf(refId);
    let innerRuleSet = innerRuleSetList.find(
      innerRuleSet => innerRuleSet.uid === refId
    );

    e.preventDefault();

    switch (action.value) {
      case 'Add Rule':
        const newRule = {
          uid: uuidv1(),
          ruleType: '',
          facts: [],
          operatorType: '',
          include: false
        };

        ruleList.push(newRule);
        innerRuleSet.items.push(newRule.uid);

        localStore.set('preference', preference);
        parentRender();
        break;
      case 'Add Inner Rule':
        const newInnerRule = {
          uid: uuidv1(),
          ruleType: 'OR',
          items: []
        };

        innerRuleSetList.push(newInnerRule);
        innerRuleSet.items.push(newInnerRule.uid);

        localStore.set('preference', preference);
        parentRender();
        break;
      case 'Move Up':
        if (itemIndex > 0) {
          this.swapHandle(parentItems, itemIndex, itemIndex - 1);
          localStore.set('preference', preference);
          parentRender();
        }
        break;
      case 'Move Down':
        if (itemIndex < parentItems.length - 1) {
          this.swapHandle(parentItems, itemIndex, itemIndex + 1);
          localStore.set('preference', preference);
          parentRender();
        }
        break;
      case 'Delete InnerRule':
        rs_inner_rules = [];
        inner_rules_set = [];
        parent.items = parentItems.filter(item => item !== refId);
        const allRules = this.findAllRulesOfCurrent(
          refId,
          ruleList,
          innerRuleSetList
        );
        const allInnerRuleSet = this.findAllInnerRulesetOfCurrent(
          refId,
          innerRuleSetList
        );
        preference.innerRuleSetList = innerRuleSetList.filter(
          i => !allInnerRuleSet.includes(i.uid)
        );
        preference.ruleList = ruleList.filter(
          item => !allRules.includes(item.uid)
        );
        localStore.set('preference', preference);
        parentRender();
        break;
      default:
        break;
    }
  };

  findAllInnerRulesetOfCurrent(refId, innerRuleSetList) {
    innerRuleSetList.map(irs => {
      if (irs.uid === refId) {
        inner_rules_set = [...inner_rules_set, ...irs.uid.split(' ')];
        if (irs.items.length === 1) {
          return (inner_rules_set = [
            ...inner_rules_set,
            ...irs.uid.split(' ')
          ]);
        } else {
          irs.items.forEach(element => {
            this.findAllInnerRulesetOfCurrent(element, innerRuleSetList);
          });
        }
      }
    });
    return inner_rules_set;
  }

  findAllRulesOfCurrent(refId, rulesList, innerRuleSetList) {
    const outerRules = this.findOuterRules(refId, rulesList);
    const innerRules = this.findInnerRules(refId, rulesList, innerRuleSetList);
    return [...outerRules, ...innerRules];
  }

  findInnerRules(refId, ruleList, innerRuleSet) {
    innerRuleSet.map(irs => {
      let rl = this.findOuterRules(refId, ruleList);
      if (irs.uid === refId && rl.length > 0) {
        rs_inner_rules = [...rs_inner_rules, ...refId];
        return rs_inner_rules;
      }
      if (irs.uid === refId && irs.items.length > 0 && rl.length === 0) {
        irs.items.forEach(element => {
          const orl = (rl = this.findOuterRules(element, ruleList));
          if (orl.length > 0) {
            return (rs_inner_rules = [...rs_inner_rules, ...orl]);
          } else {
            this.findInnerRules(element, ruleList, innerRuleSet);
          }
        });
      }
    });
    return rs_inner_rules;
  }

  findOuterRules(ruleId, ruleList) {
    return ruleList.filter(item => item.uid === ruleId).map(item => item.uid);
  }

  onCheckRadioOption = (e, data) => {
    const { refId, parentRender } = this.props;
    let preference = localStore.get('preference') || {};
    let { innerRuleSetList } = preference;

    const innerRuleSet = innerRuleSetList.find(
      innerRuleSet => innerRuleSet.uid === refId
    );

    innerRuleSet.ruleType = data.value;
    localStore.set('preference', preference);
    parentRender();
  };

  render() {
    let {
      refId,
      deep,
      first,
      last,
      ruleSetGroupIndex,
      innerRuleSetGroupIndex,
      isSubmitting
    } = this.props;

    let preference = localStore.get('preference') || {};
    let { ruleList, innerRuleSetList } = preference;

    let innerRuleSet = innerRuleSetList.find(
      innerRuleSet => innerRuleSet.uid === refId
    );

    let innerRuleSetItems = isEmpty(innerRuleSet) ? [] : innerRuleSet.items;

    let irIdx = 1;

    let items = isEmpty(innerRuleSetItems)
      ? []
      : innerRuleSetItems.map((item, idx) => {
          const ruleItems = ruleList.filter(rule => rule.uid === item);

          if (!isEmpty(ruleItems)) {
            return (
              <Rule
                refId={item}
                parentId={refId}
                parentRender={() => {
                  this.setState({});
                }}
                first={idx === 0}
                last={idx === innerRuleSetItems.length - 1}
                isSubmitting={isSubmitting}
              />
            );
          }

          const irs = innerRuleSetList.filter(
            innerRule => innerRule.uid === item
          );
          if (!isEmpty(irs)) {
            return (
              <InnerRuleSet
                refId={item}
                deep={deep + 1}
                parentId={refId}
                parentRender={() => {
                  this.setState({});
                }}
                ruleSetGroupIndex={`${ruleSetGroupIndex}${innerRuleSetGroupIndex}.`}
                innerRuleSetGroupIndex={irIdx++}
                first={idx === 0}
                last={idx === innerRuleSetItems.length - 1}
                isSubmitting={isSubmitting}
              />
            );
          }
        });

    let RULE_ACTIONS = [
      { key: 1, text: 'Add Rule', value: 'Add Rule' },
      {
        key: 2,
        text: 'Add Inner Rule',
        value: 'Add Inner Rule',
        disabled: deep === 5
      },
      { key: 3, text: 'Move Up', value: 'Move Up', disabled: first },
      { key: 4, text: 'Move Down', value: 'Move Down', disabled: last },
      { key: 'Delete InnerRule', text: 'Delete', value: 'Delete InnerRule' }
    ];

    let panes = [
      {
        menuItem: `Inner Rule ${ruleSetGroupIndex}${innerRuleSetGroupIndex}`,
        render: () => (
          <Tab.Pane style={{ background: '#F3F3F3' }} key={refId}>
            {' '}
            <section>
              <div
                className="ruleSetHeaderAction"
                style={{ zIndex: `${100000 - preferenceZIndex(refId)}` }}
              >
                <span />
                <span>
                  <Dropdown
                    style={{ width: '200px' }}
                    fluid
                    selection
                    text="Actions"
                    options={RULE_ACTIONS}
                    selectOnBlur={false}
                    value={null}
                    onChange={this.selectActionHandler}
                  />
                </span>
              </div>
              <div style={{ textAlign: 'right', marginBottom: '30px' }}>
                <Radio
                  label="OR"
                  name={`ruleType${refId}`}
                  value="OR"
                  checked={innerRuleSet.ruleType === 'OR'}
                  style={{ paddingRight: '15px' }}
                  onChange={this.onCheckRadioOption}
                />
                <Radio
                  label="AND"
                  name={`ruleType${refId}`}
                  value="AND"
                  checked={innerRuleSet.ruleType === 'AND'}
                  onChange={this.onCheckRadioOption}
                />
              </div>

              {isEmpty(items) ? '' : items}
            </section>
          </Tab.Pane>
        )
      }
    ];

    return (
      <div className="mb30px">
        <Tab
          panes={panes}
          style={{ width: '100%' }}
          className="tabInnerRuleSet"
        />
      </div>
    );
  }
}
export default InnerRuleSet;
