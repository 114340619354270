import gql from 'graphql-tag';

const deleteCustomerNotesMutation = gql`
  mutation deleteCustomerNotes(
    $DeleteCustomerNotesInput: DeleteCustomerNotesInput!
  ) {
    deleteCustomerNotes(input: $DeleteCustomerNotesInput) {
      success
      msg
    }
  }
`;

export default deleteCustomerNotesMutation;
