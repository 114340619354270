import { gql } from 'react-apollo';

const dupeVehicleQuery = gql`
  query dupeVehicleQuery($vin: String!, $dealerNumber: String!) {
    dupeVehicle(vin: $vin, dealerNumber: $dealerNumber) {
      id
      vin
      guaranteeOffer {
        dealerName
        guaranteedAt
        auctionInformation {
          dealerNumber
          auctionLocation {
            name
          }
        }
      }
    }
  }
`;

export default dupeVehicleQuery;
