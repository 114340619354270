import React from 'react';
import '../../src/assets/errorpage.css';

const MaintenancePage = () => {
  const redirectToTarget = () => {
    const homeUrl = window.location.origin;
    window.location = homeUrl;
  };

  return (
    <div className="outerContainer">
      <div className="section">
        <div className="container maintenance-outer">
          <div className="page-titlebar">
            <div className="loader react-app" />
            <div className="message">
              <h2 className="header">
                <strong>System Down For Maintenance</strong>
              </h2>
              <p style={{ color: '#000', textAlign: 'center' }}>
                {' '}
                The requested application is down for maintenance, please try
                again later.{' '}
              </p>
              <div className="return_to">
                <button
                  className="btn btn-primary maintenance-btn"
                  onClick={redirectToTarget}
                >
                  Return To Mission Control
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
