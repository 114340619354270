import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';

const ListRow = actionHandler => auctionAccessBlacklists => {
  const rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 2, text: 'Delete', value: 'Delete' }
  ];
  const { id, auctionAccessNo, expirationDate, notes, createdAt, status } = auctionAccessBlacklists;

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, auctionAccessBlacklists);
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{auctionAccessNo}</Table.Cell>
      <Table.Cell>{expirationDate}</Table.Cell>
      <Table.Cell>{notes}</Table.Cell>
      <Table.Cell>{status}</Table.Cell>
      <Table.Cell>{createdAt}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
