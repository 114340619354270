import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

class Export extends Component {
  state = {
    guaranteeHistoryReport: {}
  };

  render() {
    const { completeGuaranteeHistoryReport, filename } = this.props;
    const headers = [
      { label: 'Transaction Date And Time', key: 'transactionDateAndTime' },
      { label: 'Auction Date', key: 'auctionDate' },
      { label: 'Action', key: 'action' },
      { label: 'Cancelled Date', key: 'cancelledDate' },
      { label: 'Cancellation Reason', key: 'cancellationReason' },
      { label: 'Cancelled By', key: 'cancelledBy' },
      { label: 'Guarantee Status', key: 'guaranteeStatus' },
      { label: 'Guarantee Kind', key: 'guaranteeKind' },
      { label: 'Payment Kind', key: 'paymentKind' },
      { label: 'Guarantee Channel', key: 'guaranteeChannel' },
      { label: 'Vin', key: 'vin' },
      { label: 'DSPrice', key: 'dsPrice' },
      { label: 'DSDays', key: 'dsDays' },
      { label: 'DSMiles', key: 'dsMiles' },
      { label: 'GuaranteeId', key: 'guaranteeId' },
      { label: 'Expiry Date', key: 'expiryDate' },
      { label: 'Selling Location', key: 'sellingLocation' },
      { label: 'Buyer Name', key: 'buyerName' },
      { label: 'Buyer Number', key: 'buyerNum' },
      { label: 'Seller Name', key: 'sellerName' },
      { label: 'Seller Number', key: 'sellerNum' },
      { label: 'Sale Year', key: 'saleYear' },
      { label: 'Sale Week', key: 'saleWeek' },
      { label: 'Sale/Lane/Run', key: 'saleLaneRun' },
      { label: 'Vehicle', key: 'yearMakeModel' },
      { label: 'Odometer', key: 'carOdometer' },
      { label: 'Group Name', key: 'groupName' }
    ];

    return (
      <div>
        <Button
          color="red"
          inverted
          onClick={this.props.completeRecords}
          disabled={isEmpty(completeGuaranteeHistoryReport)}
        >
          <CSVLink
            data={completeGuaranteeHistoryReport}
            headers={headers}
            filename={filename}
          >
            Export
          </CSVLink>
        </Button>
      </div>
    );
  }
}

export default compose(withRouter)(Export);
