import React, { Component } from 'react';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import Validator from 'validatorjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { utcMoment, dateTimeFormatted } from '../../utilities/dateUtils';
import REGEX from '../../constants/regexPatterns';

import {
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Input,
  Table,
  Header
} from 'semantic-ui-react';

class EditOfferBatch extends Component {
  state = {
    id: null,
    auctionAccessNo: '',
    salesRepEmail: '',
    emails: '',
    expirationDate: '',
    name: '',
    cellPhone: '',
    userEmail: '',
    acceptedOnBehalfOf: '',
    errors: {},
    flatRateOffers: [],
    approved: false,
    rejected: false
  };

  componentDidMount() {
    const { flatRateOfferBatch } = this.props;
    if (!isEmpty(flatRateOfferBatch)) {
      const {
        id,
        auctionAccessNo,
        buyerInformation: { emails, name, cellPhone },
        userEmail,
        salesRepEmail,
        expirationDate,
        flatRateOffers,
        approved,
        rejected
      } = flatRateOfferBatch;

      this.setState({
        id: parseInt(id, 10),
        auctionAccessNo,
        emails,
        name,
        cellPhone,
        salesRepEmail,
        expirationDate,
        userEmail,
        flatRateOffers,
        approved,
        rejected
      });
    }
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      onAcceptHandler,
      onRejectHandler,
      error,
      submitting,
      resetForm,
      flatRateOfferBatch: { state }
    } = this.props;

    const {
      errors,
      auctionAccessNo,
      emails,
      name,
      cellPhone,
      salesRepEmail,
      expirationDate,
      userEmail,
      flatRateOffers,
      acceptedOnBehalfOf,
      approved,
      rejected
    } = this.state;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD HH:mm:ss') });
    };

    const validationRules = {
      auctionAccessNo: 'required|numeric',
      emails: ['required', 'regex:' + REGEX.MULTIPLE_EMAILS],
      salesRepEmail: ['required', 'regex:' + REGEX.MULTIPLE_EMAILS]
    };

    const acceptHandler = () => {
      const { id, acceptedOnBehalfOf } = this.state;

      if (isEmpty(acceptedOnBehalfOf)) {
        this.setState({
          errors: {
            acceptedOnBehalfOf: 'The Accepting on behalf of field is required.'
          }
        });
        return false;
      } else {
        onAcceptHandler({
          id: parseInt(id, 10),
          acceptedOnBehalfOf
        });
        this.setState({ errors: {} });
      }
    };

    const rejectHandler = () => {
      const { id } = this.state;

      onRejectHandler({
        id: parseInt(id, 10)
      });
    };

    const submitForm = () => {
      const {
        id,
        emails,
        salesRepEmail,
        auctionAccessNo,
        expirationDate,
        name,
        cellPhone,
        userEmail
      } = this.state;
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          id: parseInt(id, 10),
          emails,
          name,
          cellPhone,
          FlatRateOfferBatch: {
            auctionAccessNo: auctionAccessNo,
            salesRepEmail,
            userEmail,
            expirationDate: dateTimeFormatted(utcMoment(expirationDate))
          }
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Edit Dealshield 360 offer</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              {!resetForm && (
                <Form.Group widths="equal">
                  <Form.Field required error={!isEmpty(errors.auctionAccessNo)}>
                    <label>Auction access number</label>
                    <Input
                      placeholder="Auction Access Number"
                      name="auctionAccessNo"
                      value={auctionAccessNo}
                      onChange={onChangeHandler('auctionAccessNo').bind(this)}
                    />
                    {errors.auctionAccessNo && (
                      <span>{errors.auctionAccessNo}</span>
                    )}
                  </Form.Field>
                  <Form.Field required error={!isEmpty(errors.expirationDate)}>
                    <label>Expiration Date</label>
                    <DatePicker
                      style={{ padding: '0', width: '100%' }}
                      selected={utcMoment(expirationDate)}
                      onChange={onDateChangeHandler('expirationDate').bind(
                        this
                      )}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="YYYY-MM-DD HH:mm:ss ZZ"
                    />
                    {errors.expirationDate && (
                      <span>{errors.expirationDate}</span>
                    )}
                  </Form.Field>
                </Form.Group>
              )}

              {resetForm && (
                <Form.Group widths="equal">
                  <Form.Field required error={!isEmpty(errors.name)}>
                    <label>Dealer Name</label>
                    <Input
                      placeholder="Dealer Name"
                      name="name"
                      value={name}
                      onChange={onChangeHandler('name').bind(this)}
                    />
                    {errors.name && <span>{errors.name}</span>}
                  </Form.Field>
                  <Form.Field required error={!isEmpty(errors.cellPhone)}>
                    <label>Dealer Phone #:</label>
                    <Input
                      placeholder="Dealer Phone #:"
                      name="cellPhone"
                      value={cellPhone}
                      onChange={onChangeHandler('cellPhone').bind(this)}
                    />
                    {errors.cellPhone && <span>{errors.cellPhone}</span>}
                  </Form.Field>
                </Form.Group>
              )}

              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.emails)}>
                  <label>Emails</label>
                  <Input
                    placeholder="Emails"
                    name="emails"
                    value={emails}
                    onChange={onChangeHandler('emails').bind(this)}
                  />
                  {errors.emails && <span>{errors.emails}</span>}
                </Form.Field>
                <Form.Field required error={!isEmpty(errors.salesRepEmail)}>
                  <label>Sales Rep Email</label>
                  <Input
                    placeholder="Sales Rep Email"
                    name="salesRepEmail"
                    value={salesRepEmail}
                    onChange={onChangeHandler('salesRepEmail').bind(this)}
                  />
                  {errors.salesRepEmail && <span>{errors.salesRepEmail}</span>}
                </Form.Field>
              </Form.Group>
              {resetForm && (
                <Form.Group widths="equal">
                  <Form.Field required error={!isEmpty(errors.userEmail)}>
                    <label>
                      Please provide an email address to receive updates on this
                      offer
                    </label>
                    <Input
                      placeholder="Dealer Name"
                      name="userEmail"
                      value={userEmail}
                      onChange={onChangeHandler('userEmail').bind(this)}
                    />
                    {errors.userEmail && <span>{errors.userEmail}</span>}
                  </Form.Field>
                </Form.Group>
              )}

              {!resetForm &&
                !approved &&
                !rejected && (
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      error={!isEmpty(errors.acceptedOnBehalfOf)}
                    >
                      <label>Accepting on behalf of</label>
                      <Input
                        placeholder="Accepting on behalf of"
                        name="acceptedOnBehalfOf"
                        value={acceptedOnBehalfOf}
                        onChange={onChangeHandler('acceptedOnBehalfOf').bind(
                          this
                        )}
                      />
                      {errors.acceptedOnBehalfOf && (
                        <span>{errors.acceptedOnBehalfOf}</span>
                      )}
                    </Form.Field>
                    <Form.Field required error={!isEmpty(errors.userEmail)}>
                      {!submitting &&
                        this.state.id && (
                          <div className="accept-reject-button">
                            <Button
                              color="green"
                              onClick={acceptHandler}
                              inverted
                            >
                              <Icon name="checkmark" /> Accept
                            </Button>
                            <Button
                              color="red"
                              onClick={rejectHandler}
                              inverted
                            >
                              Reject
                            </Button>
                          </div>
                        )}
                    </Form.Field>
                  </Form.Group>
                )}
            </Form>
            <br />
            <br />
            <Header>Offers in this batch</Header>
            <Table celled padded size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine textAlign="center">
                    5m#
                  </Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Miles</Table.HeaderCell>
                  <Table.HeaderCell>PSI</Table.HeaderCell>
                  <Table.HeaderCell>Accepted User</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {map(flatRateOffers, flatRateOffer => (
                  <Table.Row key={flatRateOffer.id}>
                    <Table.Cell>{flatRateOffer.dealerNo}</Table.Cell>
                    <Table.Cell>{flatRateOffer.price}</Table.Cell>
                    <Table.Cell>{flatRateOffer.milesSelected}</Table.Cell>
                    <Table.Cell>
                      {flatRateOffer.psiEligible && (
                        <Icon color="green" name="checkmark" size="large" />
                      )}
                      {!flatRateOffer.psiEligible && (
                        <Icon color="red" name="close" size="large" />
                      )}
                    </Table.Cell>
                    <Table.Cell>{flatRateOffer.acceptingUserName}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}

          {!submitting &&
            state !== 'void' &&
            this.state.id && (
              <Button color="green" onClick={submitForm} inverted>
                <Icon name="checkmark" /> Update
              </Button>
            )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditOfferBatch;
