import { isEmpty } from 'lodash';

// show field 1 - field 2 ---- Ex: 5014249 - Buyer 01
export const parseString2Fields = (field1, field2) => {
  if (!isEmpty(field1) && !isEmpty(field2)) {
    return `${field1} - ${field2}`;
  }

  if (!isEmpty(field1) && isEmpty(field2)) {
    return field1;
  }

  if (isEmpty(field1) && !isEmpty(field2)) {
    return field2;
  }

  return '';
};
