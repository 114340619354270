import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const Icon = ({ name, className, style, first, last }) => (
  <i
    className={cn('mdi', `mdi-${name}`, className, {
      'is-first': first,
      'is-last': last
    })}
    style={style}
  />
);

Icon.propTypes = {
  className: PropTypes.string,
  first: PropTypes.bool,
  last: PropTypes.bool,
  name: PropTypes.string.isRequired,
  style: PropTypes.object
};

Icon.defaultProps = {
  className: '',
  first: false,
  last: false,
  style: {}
};

export default Icon;
