import { gql } from 'react-apollo';

const createUserMutation = gql`
  mutation adminCreateUser($input: AdminCreateUserInput!) {
    adminCreateUser(input: $input) {
      success
      errors
    }
  }
`;

export default createUserMutation;
