import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';
import 'react-datepicker/dist/react-datepicker.css';

import { Form, Button, Modal, Header, Icon, Message } from 'semantic-ui-react';

class GlobalPeriodForm extends Component {
  EXCEPTION_REASON_MAX_LENGTH = 30;
  state = {
    id: null,
    modalType: null,
    expirationDate: null,
    gracePeriod: null,
    exceptionReason: '',
    errors: {}
  };

  UPDATE_GLOBAL_PERIOD_FORM_ID = 'UPDATE_GLOBAL_PERIOD';
  UPDATE_GLOBAL_REINSTATE_PERIOD_FORM_ID = 'UPDATE_GLOBAL_REINSTATE_PERIOD';

  isUpdateGlobalPeriodForm() {
    return this.state.modalType === this.UPDATE_GLOBAL_PERIOD_FORM_ID;
  }
  isUpdateGlobalReinstatePeriodForm() {
    return this.state.modalType === this.UPDATE_GLOBAL_REINSTATE_PERIOD_FORM_ID;
  }

  componentDidMount() {
    const { modalType, globalExpiredVraSettings } = this.props;
    if (!isEmpty(globalExpiredVraSettings)) {
      this.setState({
        modalType: modalType,
        id: globalExpiredVraSettings.id,
        gracePeriod: globalExpiredVraSettings.gracePeriod,
        exceptionReason: globalExpiredVraSettings.exceptionReason
      });
    }
  }
  onChangeHandler = field => event => {
    this.setState({ [field]: event.target.value });
  };

  submitForm = () => {
    const { onSubmitHandler } = this.props;
    const { id, gracePeriod, exceptionReason } = this.state;
    const validationRules = {
      gracePeriod: 'required|integer|min: 0|max: 60'
    };
    const validationMessages = {
      'required.gracePeriod': 'The Grace Period field is required.',
      'max.gracePeriod': 'Grace Period must be less than or equal to 60.',
      'min.gracePeriod': 'Grace Period must be greater than or equal to zero.',
      'integer.gracePeriod': 'Grace Period must be integer'
    };
    const validation = new Validator(
      this.state,
      validationRules,
      validationMessages
    );
    if (validation.fails()) {
      this.setState({ ...validation.errors });
      return false;
    } else {
      onSubmitHandler({
        id,
        gracePeriod: parseInt(gracePeriod),
        exceptionReason
      });
      this.setState({ ...validation.errors });
    }
  };

  render() {
    const { onCloseHandler, error, submitting } = this.props;

    const { errors, exceptionReason } = this.state;

    let exceptionReasonLength = 0;
    if (!isEmpty(exceptionReason)) {
      exceptionReasonLength = exceptionReason.length;
    }
    return (
      <Modal
        open={true}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          {this.isUpdateGlobalPeriodForm() && 'Edit Current Grace Period'}
          {this.isUpdateGlobalReinstatePeriodForm() &&
            'Edit Reinstated VRA Grace Period'}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {error && <Message content={error} negative />}
            <Form>
              <Header size="small" color="blue" />
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.gracePeriod)}>
                  <label>
                    {this.isUpdateGlobalPeriodForm() && 'Current Grace Period'}
                    {this.isUpdateGlobalReinstatePeriodForm() &&
                      'Reinstated VRA Grace Period'}
                  </label>
                  <input
                    name="gracePeriod"
                    value={this.state.gracePeriod}
                    onChange={this.onChangeHandler('gracePeriod').bind(this)}
                    type="number"
                    min={0}
                    max={60}
                  />
                  {errors.gracePeriod && (
                    <span style={{ color: 'red' }}>{errors.gracePeriod}</span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Exception Reason</label>
                  <textarea
                    name="exceptionReason"
                    rows={1}
                    value={this.state.exceptionReason}
                    onChange={this.onChangeHandler('exceptionReason').bind(
                      this
                    )}
                    maxLength={this.EXCEPTION_REASON_MAX_LENGTH}
                  />
                  <span style={{ float: 'right' }}>
                    {exceptionReasonLength} / {this.EXCEPTION_REASON_MAX_LENGTH}
                  </span>
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && (
            <Button color="green" onClick={this.submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GlobalPeriodForm;
