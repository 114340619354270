import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isString } from 'lodash';

const DateInput = ({ input, label, placeholder, type, meta, ...custom }) => {
  const { touched, error, warning } = meta;
  const formatedDate = stringInput => {
    if (input.value && input.value !== '') {
      const isoRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/;
      if (isString(input.value) && input.value.match(isoRegex)) {
        return moment(input.value, 'YYYY-MM-DDTHH:mm:ssZ');
      } else {
        return moment(input.value, 'MM/DD/YY hh:mm');
      }
    } else {
      return moment();
    }
  };
  const selectedValue = formatedDate(input);

  return (
    <span>
      <DatePicker
        style={{ padding: '0' }}
        name={input.name}
        onBlur={input.onBlur}
        onChange={input.onChange}
        onFocus={input.onFocus}
        selected={selectedValue}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="MM/DD/YY hh:mm"
      />

      {touched &&
        ((error && <span className="error-text">{error}</span>) ||
          (warning && <span className="warn-text">{warning}</span>))}
    </span>
  );
};

export default DateInput;
