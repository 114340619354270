import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { toString, isNumber, toNumber } from 'lodash';
import client from '../apollo';

import LoadingDataEnhancer from './LoadingData';
import EditExternalVehicleBatch from '../views/ExternalVehicleBatches/EditForm';
import ExternalVehicleList from '../views/ExternalVehicleBatches/VehicleList';
import ExternalVehicleRejectForm from '../views/ExternalVehicleBatches/RejectForm';
import externalVehiclesQuery from '../graphql/externalVehiclesQuery';
import rejectExternalVehicleMutation from '../graphql/rejectExternalVehiclesLoadMutation';
import aproveExternalVehicleMutation from '../graphql/approveExternalVehiclesLoadMutation';
import updateExternalVehiclesLoadMutation from '../graphql/updateExternalVehiclesLoadMutation';
import { hasRole } from '../utilities/user';
import externalVehicleDocumentsQuery from '../graphql/externalVehicleDocumentsQuery';

class ExternalVehicles extends Component {
  state = {
    selectedVehicle: null,
    approveSubmitting: false,
    approveError: null,
    rejectSubmitting: false,
    rejectError: null,
    updateSubmitting: false,
    updateError: null,
    isRejectFormOpen: false,
    flashMessage: null,
    externalVehicleDocuments: []
  };

  onRejectFormCloseHandler = () => {
    this.setState({
      rejectError: null,
      rejectSubmitting: null,
      isRejectFormOpen: false
    });
  };

  onApproveSubmitHandler = externalVehicle => {
    const {
      id,
      approvingUserId,
      rejectingUserId,
      secondaryApprovingUserId
    } = externalVehicle;
    const { approveMutation, user } = this.props;
    this.setState({
      approveSubmitting: true
    });
    const primaryApproval =
      isNumber(approvingUserId) && approvingUserId !== toNumber(user.id);
    const primaryRejection =
      isNumber(rejectingUserId) && rejectingUserId !== toNumber(user.id);
    const secondaryApproval =
      !isNumber(secondaryApprovingUserId) &&
      (primaryApproval || primaryRejection);

    approveMutation({
      variables: { id, secondaryApproval }
    })
      .then(({ data }) => {
        const { approveExternalVehiclesLoad: { success, errors } } = data;
        if (success) {
          this.setState({
            approveSubmitting: false,
            flashMessage: 'Successfully approved external vehicle.'
          });
          this.props.data.refetch();
        } else {
          this.setState({
            approveSubmitting: false,
            approveError: errors
          });
          this.props.data.refetch();
        }
      })
      .catch(error => {
        this.setState({
          approveSubmitting: false,
          approveError: 'Error while approving external vehicle'
        });
      });
  };

  onRejectSubmitHandler = values => {
    const { rejectMutation } = this.props;
    this.setState({
      rejectSubmitting: true
    });
    rejectMutation({
      variables: { ...values }
    })
      .then(({ data }) => {
        const { rejectExternalVehiclesLoad: { success, errors } } = data;
        if (success) {
          this.setState({
            rejectSubmitting: false,
            flashMessage: 'Successfully rejected external vehicle.'
          });
          this.props.data.refetch();
          this.onRejectFormCloseHandler();
        } else {
          this.setState({
            rejectSubmitting: false,
            rejectError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          rejectSubmitting: false,
          rejectError: 'Error while rejecting external vehicle'
        });
      });
  };

  showRejectForm = externalVehicle => {
    this.setState({
      isRejectFormOpen: true,
      rejectError: null,
      selectedVehicle: externalVehicle
    });
  };

  onUpdateHandler = externalVehicle => {
    const { updateMutation } = this.props;
    this.setState({
      updateSubmitting: true
    });
    const {
      id,
      vin,
      year,
      make,
      model,
      odometerReading,
      dealerNo,
      sellerName,
      vehiclePurchasePrice,
      buyFee,
      vehicleTotal,
      locationInitials,
      automaticPurchaseEmail,
      purchasedAt,
      rejectionComment
    } = externalVehicle;

    const externalVehiclesLoadsParams = {
      vin,
      year,
      make,
      model,
      odometerReading: toString(odometerReading),
      dealerNo,
      sellerName,
      vehiclePurchasePrice: toString(vehiclePurchasePrice),
      buyFee: toString(buyFee),
      vehicleTotal: toString(vehicleTotal),
      locationInitials,
      automaticPurchaseEmail,
      purchasedAt,
      rejectionComment
    };

    const externalVehicleInput = {
      id,
      externalVehiclesLoadsParams
    };

    updateMutation({
      variables: {
        externalVehicleInput
      }
    })
      .then(({ data }) => {
        const { updateExternalVehiclesLoad: { success, errors } } = data;
        if (success) {
          this.setState({
            updateSubmitting: false,
            flashMessage: 'Successfully updated external vehicle.'
          });
          this.props.data.refetch();
        } else {
          this.setState({
            updateSubmitting: false,
            updateError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          updateSubmitting: false,
          updateError: 'Error while updating external vehicle'
        });
      });
  };

  componentWillMount() {
    const batchId = this.props.match.params.id;
    if (!isEmpty(batchId)) {
      client
        .query({
          query: externalVehicleDocumentsQuery,
          variables: {
            id: parseInt(batchId, 10)
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          this.setState({
            externalVehicleDocuments: data.externalVehicleDocuments
          });
        });
    }
  }

  render() {
    const { externalVehicleDocuments } = this.state;
    const { data: { externalVehicles }, user } = this.props;
    const {
      flashMessage,
      isRejectFormOpen,
      rejectError,
      rejectSubmitting,
      selectedVehicle,
      approveError
    } = this.state;
    const error = rejectError || approveError;

    const isApprover = hasRole('external_vehicle_approver', 'roles')(user);
    const isEnterer = hasRole('external_vehicle_enterer', 'roles')(user);
    const showEditForm = isApprover && isEnterer;
    const showViewForm = isApprover || isEnterer;

    return (
      <div>
        {error && (
          <Message negative>
            <p>{error}</p>
          </Message>
        )}

        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}

        {showEditForm && (
          <EditExternalVehicleBatch
            externalVehicles={externalVehicles}
            onApproveHandler={this.onApproveSubmitHandler}
            onRejectHandler={this.showRejectForm}
            onUpdateHandler={this.onUpdateHandler}
            user={user}
            externalVehicleDocuments={externalVehicleDocuments}
          />
        )}

        {!showEditForm &&
          showViewForm && (
            <ExternalVehicleList
              externalVehicles={externalVehicles}
              onApproveHandler={this.onApproveSubmitHandler}
              onRejectHandler={this.showRejectForm}
              user={user}
              externalVehicleDocuments={externalVehicleDocuments}
            />
          )}

        {isRejectFormOpen && (
          <ExternalVehicleRejectForm
            isModalOpen={isRejectFormOpen}
            onCloseHandler={this.onRejectFormCloseHandler}
            onSubmitHandler={this.onRejectSubmitHandler}
            error={rejectError}
            submitting={rejectSubmitting}
            externalVehicle={selectedVehicle}
            user={user}
            externalVehicleDocuments={externalVehicleDocuments}
          />
        )}
      </div>
    );
  }
}

const withData = graphql(externalVehiclesQuery, {
  options: props => {
    const batchId = parseInt(props.match.params.id, 10);
    return {
      variables: { batchId },
      fetchPolicy: 'network-only'
    };
  }
});

const mapStateToProps = state => {
  return { user: state.data.user };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withData,
  graphql(aproveExternalVehicleMutation, { name: 'approveMutation' }),
  graphql(rejectExternalVehicleMutation, { name: 'rejectMutation' }),
  graphql(updateExternalVehiclesLoadMutation, { name: 'updateMutation' }),
  LoadingDataEnhancer
)(ExternalVehicles);
