import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class GuaranteeHistoryList extends Component {
  render() {
    const { guaranteeHistory } = this.props;
    const listRow = ListRow;

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Transaction date/time</Table.HeaderCell>
        <Table.HeaderCell>Auction Date</Table.HeaderCell>
        <Table.HeaderCell>Action</Table.HeaderCell>
        <Table.HeaderCell>Cancelled Date</Table.HeaderCell>
        <Table.HeaderCell>Cancellation Reason</Table.HeaderCell>
        <Table.HeaderCell>Cancelled By</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Status</Table.HeaderCell>
        <Table.HeaderCell>GuaranteeKind</Table.HeaderCell>
        <Table.HeaderCell>Payment Kind</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Channel</Table.HeaderCell>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>DS Price</Table.HeaderCell>
        <Table.HeaderCell>DS Days</Table.HeaderCell>
        <Table.HeaderCell>DS Miles</Table.HeaderCell>
        <Table.HeaderCell>Guarantee ID</Table.HeaderCell>
        <Table.HeaderCell>ExpiryDate</Table.HeaderCell>
        <Table.HeaderCell>Selling Location</Table.HeaderCell>
        <Table.HeaderCell>Buyer Name</Table.HeaderCell>
        <Table.HeaderCell>Buyer Number</Table.HeaderCell>
        <Table.HeaderCell>Seller Name</Table.HeaderCell>
        <Table.HeaderCell>Seller Number</Table.HeaderCell>
        <Table.HeaderCell>Sale Year</Table.HeaderCell>
        <Table.HeaderCell>Sale Week</Table.HeaderCell>
        <Table.HeaderCell>Sale/Lane/Run</Table.HeaderCell>
        <Table.HeaderCell>Vehicle</Table.HeaderCell>
        <Table.HeaderCell>Odometer</Table.HeaderCell>
        <Table.HeaderCell>Groupname</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Header>Guarantee History Report</Header>
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={guaranteeHistory}
            headerRow={headerRow}
            size="small"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(GuaranteeHistoryList);
