import { Component } from 'react';
import { get } from 'lodash';
import { parse as parseQuery } from 'query-string';

import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import localStore from 'store';

import APP_PATH from '../constants/paths';
import pingAuthenticationQuery from '../graphql/pingAuthenticationQuery';
import { setUser } from '../data/user/actions';
import { hasRole } from '../utilities/user';
import USER from '../constants/appUser';

class LoginCallback extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  componentWillMount() {
    const { client, history, signInUser } = this.props;
    const { code } = parseQuery(history.location.search);
    localStore.set('authorizationCode', code);
    const user = USER.APP;

    client
      .query({
        query: pingAuthenticationQuery,
        variables: {
          code,
          redirectUri: window.location.origin + APP_PATH.REDIRECT_URL,
          appName: user
        }
      })
      .then(({ data }) => {
        const error = get(data, 'pingAuthentication.error');
        history.push(APP_PATH.ERROR_PAGE);
        if (error) {
          throw Error(error);
        }
        const accessToken = get(data, 'pingAuthentication.accessToken');
        if (!accessToken) {
          throw Error('Unexpected error');
        }
        const user = get(data, 'pingAuthentication.viewer.user');

        if (hasRole('admin', 'roles')(user)) {
          signInUser(user);
          localStore.set('userId', user.id);
          localStore.set('jwt', accessToken);
          history.push(APP_PATH.BASE);
        } else {
          throw Error('You are not authorized to view this page.');
        }
      })
      .catch(error => {
        this.setState({
          error: error.message
        });
      });
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  signInUser: user => {
    dispatch(setUser(user));
  }
});

export default compose(
  withApollo,
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(LoginCallback);
