import { gql } from 'react-apollo';

const bulkDealerActionsQuery = gql`
  query bulkDealerActionsQuery($size: Int, $offset: Int) {
    bulkDealerActions(offset: $offset, size: $size) {
      id
      inputFile
      status
      outputFile
      inputFileName
      outputFileName
    }
  }
`;

export default bulkDealerActionsQuery;
