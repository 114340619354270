export default {
  CANCELLATION_REASONS: [
    {
      key: '1',
      value: 'Client Cancel - Customer Experience',
      text: 'Client Cancel - Customer Experience'
    },
    {
      key: '2',
      value: 'Client Cancel - Not Utilizing',
      text: 'Client Cancel - Not Utilizing'
    },
    {
      key: '3',
      value: 'Client Cancel - Misunderstanding of the product',
      text: 'Client Cancel - Misunderstanding of the product'
    },
    {
      key: '4',
      value: 'Client Cancel - Cost Cutting',
      text: 'Client Cancel - Cost Cutting'
    },
    {
      key: '5',
      value: 'Client Cancel - 5mil Number change/OOB',
      text: 'Client Cancel - 5mil Number change/OOB'
    },
    {
      key: '6',
      value: 'Client Cancel - Reorg',
      text: 'Client Cancel - Reorg'
    },
    {
      key: '7',
      value: 'DS Unsubscribed - Priced Out',
      text: 'DS Unsubscribed - Priced Out'
    },
    {
      key: '8',
      value: 'DS Unsubscribed - Risk',
      text: 'DS Unsubscribed - Risk'
    },
    {
      key: '9',
      value: 'DS Unsubscribed - Inactive',
      text: 'DS Unsubscribed - Inactive'
    },
    {
      key: '8',
      value: 'DS Unsubscribed - ISL',
      text: 'DS Unsubscribed - ISL'
    }
  ]
};
