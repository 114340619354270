import { gql } from 'react-apollo';

const createAuctionAccessBlacklistMutation = gql`
  mutation createAuctionAccessBlacklist($input: CreateAuctionAccessBlacklistInput!) {
    createAuctionAccessBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default createAuctionAccessBlacklistMutation;

