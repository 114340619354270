import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';

const ListRow = (openEditUserForm, resetApiTokenHandler) => (user, index) => {
  const {
    username,
    email,
    active,
    locationInitials,
    roles,
    authenticationToken
  } = user;

  const editHandler = () => {
    openEditUserForm(user);
  };

  const resetHandler = () => {
    resetApiTokenHandler(user);
  };

  return (
    <Table.Row key={index}>
      <Table.Cell>{username}</Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{active ? 'true' : 'false'}</Table.Cell>
      <Table.Cell>{locationInitials}</Table.Cell>
      <Table.Cell>{authenticationToken}</Table.Cell>
      <Table.Cell>{roles.join(' , ')}</Table.Cell>
      <Table.Cell>
        <Button color="green" onClick={editHandler} inverted>
          <Icon name="checkmark" /> Edit
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Button color="green" onClick={resetHandler} inverted>
          <Icon name="checkmark" /> Reset
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
