import { gql } from 'react-apollo';

const rejectFlatRateOfferBatchMutation = gql`
  mutation rejectFlatRateOfferBatch($input: RejectFlatRateOfferBatchInput!) {
    rejectFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default rejectFlatRateOfferBatchMutation;
