import { gql } from 'react-apollo';

const externalVehicleDocumentsQuery = gql`
  query externalVehicleDocumentsQuery($id: Int) {
    externalVehicleDocuments(id: $id) {
      id
      document
      imageName
    }
  }
`;

export default externalVehicleDocumentsQuery;
