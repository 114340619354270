import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import {
  utcMoment,
  formatDateOnly,
  dateTimeFormattedWithOutTimeZone
} from '../../utilities/dateUtils';
import { isEmpty } from 'lodash';

const ListRow = (actionHandler, exceptionType) => exception => {
  const rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 2, text: 'Delete', value: 'Delete' }
  ];

  const {
    id,
    exceptionSourceName,
    exceptionSourceId,
    exceptionReason,
    gracePeriod,
    exceptionEndTime,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  } = exception;

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, exception);
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{exceptionSourceName}</Table.Cell>
      {(exceptionType === 'DEALER' || exceptionType === 'GROUP') && (
        <Table.Cell>{exceptionSourceId}</Table.Cell>
      )}
      <Table.Cell>{gracePeriod}</Table.Cell>
      {exceptionType === 'AUCTION' && (
        <Table.Cell>{exceptionReason}</Table.Cell>
      )}
      <Table.Cell>
        {!isEmpty(exceptionEndTime)
          ? formatDateOnly(utcMoment(exceptionEndTime))
          : 'No End Date'}
      </Table.Cell>
      <Table.Cell>{createdBy}</Table.Cell>
      <Table.Cell>
        {dateTimeFormattedWithOutTimeZone(utcMoment(createdAt))}
      </Table.Cell>
      <Table.Cell>{updatedBy}</Table.Cell>
      <Table.Cell>
        {dateTimeFormattedWithOutTimeZone(utcMoment(updatedAt))}
      </Table.Cell>

      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
