import React from 'react';
import { Input } from 'semantic-ui-react';

const TextInput = ({ input, label, placeholder, type, meta, ...custom }) => {
  const { touched, error, warning } = meta;
  const hasError = touched && error !== undefined;

  return (
    <span>
      <Input
        {...input}
        error={hasError}
        fluid
        placeholder={placeholder}
        {...custom}
      />

      {touched &&
        ((error && <span className="error-text">{error}</span>) ||
          (warning && <span className="warn-text">{warning}</span>))}
    </span>
  );
};

export default TextInput;
