import { gql } from 'react-apollo';

const externalAuctionTransactionsQuery = gql`
  query externalAuctionTransactionsQuery(
    $vin: String
    $dealerNumber: String
    $size: Int
    $offset: Int
  ) {
    externalAuctionTransactions(
      vin: $vin
      dealerNumber: $dealerNumber
      offset: $offset
      size: $size
    ) {
      id
      vin
      purchaseLocation
      guaranteeDate
      arb
      arbStatus
      arbDate
      buyer
      buyerName
      seller
      sellerName
      saleDate
      paidDate
      modelYear
      make
      model
      body
      auction
      vehicleSalePrice
      buyFee
      dealshieldPrice
      vraId
      returnLocation
      vraReturnDate
      dsDepositFlag
      howPaid
      howPaidDescription
      floorPlan
      floorPlanDescription
      buynet
      imsVehicleStatus
      vehicleSecured
      imsSecuredDate
      partnerAuctionTitleStatus
      imsTitleStatusFromAuction
      imsTitleReceivedStatus
      imsTitleReceived
      holdType
      titleIssueDate
      imsTitleStatus
      meridianSoldDate
      runNumber
      uniqueId
      announcement
      miles
      channel
      salvage
      closedSale
      returnWoNumber
      imsTitleNumber
      clearedForSale
      imsRecordDate
      transReqDate
      imsTitleStatusLoc
      createdAt
      updatedAt
      event
      howPaid1
      howPaidDescription1
      paymentAmount1
      howPaid2
      howPaidDescription2
      paymentAmount2
      howPaid3
      howPaidDescription3
      paymentAmount3
      buyerAdjustments
      splitPay
    }
  }
`;

export default externalAuctionTransactionsQuery;
