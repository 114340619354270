import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../../containers/Icon';

const dispatchCustomPrintEvent = hideFooter => {
  window.document.dispatchEvent(
    new window.CustomEvent('DEAL_SHIELD/PRINT', {
      detail: { hideFooter }
    })
  );
  return (() => {
    window.print();
  })();
};

const PrintButton = ({ onClick }) => (
  <button className="btn btn-primary" onClick={onClick}>
    <Icon name="printer" className="is-first" /> Print
  </button>
);

PrintButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

class PrintButtonContainer extends Component {
  /* eslint-disable class-methods-use-this */
  onClick() {
    dispatchCustomPrintEvent(true);
  }
  render() {
    return <PrintButton onClick={this.onClick} />;
  }
}

export default PrintButtonContainer;
