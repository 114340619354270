import { combineReducers } from 'redux';

import userReducer from './user/reducer';
import searchReducer from './searchField/reducer';
import pageReducer from './page/reducer';
import nextPathReducer from './nextPath/reducer';
import duplicateVehicleReducer from './duplicateVehicles/reducer';
import duplicateVehicleIndexReducer from './duplicateVehicleIndex/reducer';
import duplicateExternalVehicleReducer from './duplicateExternalVehicles/reducer';
import duplicateExternalVehicleIndexReducer from './duplicateExternalVehicleIndex/reducer';
import duplicateEntryVehicleReducer from './duplicateEntryVehicles/reducer';
import duplicateEntryVehicleIndexReducer from './duplicateEntryVehicleIndex/reducer';
import secondarySearchReducer from './secondarySearch/reducer';
import searchUserReducer from './searchUser/reducer';
import refreshSearchReducer from './refreshSearch/reducer';
import authTokenReducer from './authToken/reducer';
import mmrValueReducer from './mmrValues/reducer';
import mmrCalculatedValueReducer from './mmrCalculatedValues/reducer';
import subscriptionReducer from './subscription/reducer';
import customerNotesReducer from './customerNotes/reducer';

const reducer = combineReducers({
  user: userReducer,
  searchFields: searchReducer,
  page: pageReducer,
  nextPath: nextPathReducer,
  duplicateVehicles: duplicateVehicleReducer,
  duplicateVehicleIndex: duplicateVehicleIndexReducer,
  duplicateExternalVehicles: duplicateExternalVehicleReducer,
  duplicateExternalVehicleIndex: duplicateExternalVehicleIndexReducer,
  duplicateEntryVehicles: duplicateEntryVehicleReducer,
  duplicateEntryVehicleIndex: duplicateEntryVehicleIndexReducer,
  secondarySearch: secondarySearchReducer,
  searchUser: searchUserReducer,
  refreshSearch: refreshSearchReducer,
  authToken: authTokenReducer,
  mmrValue: mmrValueReducer,
  mmrCalculatedValue: mmrCalculatedValueReducer,
  subscriptionDealer: subscriptionReducer,
  customerNotes: customerNotesReducer
});

export default reducer;
