import { gql } from 'react-apollo';

const rejectPendingOfferMutation = gql`
  mutation rejectPendingOffer($rejectInput: RejectPendingOfferInput!) {
    rejectPendingOffer(input: $rejectInput) {
      success
      errors
    }
  }
`;

export default rejectPendingOfferMutation;
