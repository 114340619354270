import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Form } from 'semantic-ui-react';
import { resetSearch, setSearch } from '../../data/searchField/actions';
import SearchField from './SearchField';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const variableArguments = {
      groupName: value
    };
    this.setState({ [name]: value, changed: { [name]: true } });
    this.props.setSearchFields(variableArguments);
  }

  render() {
    const { onSubmit, searchOptions } = this.props;

    return (
      <div>
        <Form onSubmit={onSubmit}>
          <Form.Group widths="two">
            {map(searchOptions, searchOption => (
              <SearchField
                handleInputChange={this.handleInputChange}
                searchOption={searchOption}
                value={this.state[searchOption]}
                key={searchOption}
              />
            ))}
            <Form.Field>
              <Form.Button color="green" inverted>
                Search
              </Form.Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  searchFields: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  resetSearchFields: field => {
    dispatch(resetSearch(field));
  },
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  }
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(Search);
