import { gql } from 'react-apollo';

const updateFlatRateOfferBatchMutation = gql`
  mutation updateFlatRateOfferBatch($input: UpdateFlatRateOfferBatchInput!) {
    updateFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default updateFlatRateOfferBatchMutation;
