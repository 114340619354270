import React, { Component } from 'react';
import { Form, Button, Icon, Input, Loader, Dimmer } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

class ContactForm extends Component {
  componentWillMount() {
    const { auctionAccessName, email, phone } = this.props;
    this.setState({
      email: email,
      phone: phone,
      auctionAccessName: auctionAccessName,
      userEmail: '',
      salesRepEmail: ''
    });
  }

  render() {
    const {
      auctionAccessName,
      editedRate,
      email,
      phone,
      fieldUpdateHandler,
      errors,
      submitForm,
      handleCancel,
      loading,
      isRevise,
      chosenOffer,
      submitWithoutApproval,
      isUpgrade,
      salesRepEmail,
      userEmail
    } = this.props;
    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
      fieldUpdateHandler(field, event.target.value);
    };

    return (
      <div>
        <Form>
          <Form.Group widths="two">
            <Form.Field error={!isEmpty(errors.auctionAccessName)}>
              <label>SEND RATE TO</label>
              <Input
                icon="pencil alternate"
                enabled
                id="name"
                name="auctionAccessName"
                placeholder={auctionAccessName}
                value={this.state.auctionAccessName}
                onChange={onChangeHandler('auctionAccessName').bind(this)}
              />
              {errors.auctionAccessName && (
                <span>{errors.auctionAccessName}</span>
              )}
            </Form.Field>
            <Form.Field error={!isEmpty(errors.email)}>
              <label>EMAIL ADDRESS</label>
              <Input
                icon="pencil alternate"
                enabled
                name="email"
                placeholder={email}
                value={this.state.email}
                onChange={onChangeHandler('email').bind(this)}
              />
              {errors.email && <span>{errors.email}</span>}
            </Form.Field>
            <Form.Field>
              <label>PHONE</label>
              <Input
                icon="pencil alternate"
                name="phone"
                placeholder={phone}
                value={this.state.phone}
                onChange={onChangeHandler('phone').bind(this)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="two">
            <Form.Field error={!isEmpty(errors.userEmail)}>
              <label>
                Please provide an email address to receive updates on this offer
              </label>
              <Input
                icon="pencil alternate"
                enabled
                name="userEmail"
                placeholder={userEmail}
                value={this.state.userEmail}
                onChange={onChangeHandler('userEmail').bind(this)}
              />
              {errors.userEmail && <span>{errors.userEmail}</span>}
            </Form.Field>
            <Form.Field error={!isEmpty(errors.salesRepEmail)}>
              <label>Sales Rep Email</label>
              <Input
                icon="pencil alternate"
                enabled
                name="salesRepEmail"
                placeholder={salesRepEmail}
                value={this.state.salesRepEmail}
                onChange={onChangeHandler('salesRepEmail').bind(this)}
              />
              {errors.salesRepEmail && <span>{errors.salesRepEmail}</span>}
            </Form.Field>
          </Form.Group>

          {/* when the start enrolment */}
          {!isUpgrade && (
            <Button color="green" inverted onClick={submitForm}>
              <Icon name="checkmark" />
              {isRevise && `Submit Revised Offer`}
              {!isRevise && `Submit Offer`}
            </Button>
          )}
          {/* upgrade = true and no override rate */}
          {isUpgrade && !editedRate && (
            <Button color="green" inverted onClick={submitForm}>
              <Icon name="checkmark" />
              {isRevise && `Submit Revised Offer`}
              {!isRevise && `Submit Offer`}
            </Button>
          )}
          {/* upgrade = true and when override rate is greater than the offer(upgrade) */}
          {isUpgrade &&
            parseInt(chosenOffer.rate, 10) <= parseInt(editedRate, 10) && (
              <Button color="green" inverted onClick={submitForm}>
                <Icon name="checkmark" />
                {isRevise && `Submit Revised Offer`}
                {!isRevise && `Submit Offer`}
              </Button>
            )}
          {/* upgrade = true and when override rate is less than the offer(downgrade) */}
          {isUpgrade &&
            parseInt(editedRate, 10) > 0 &&
            parseInt(chosenOffer.rate, 10) > parseInt(editedRate, 10) && (
              <Button color="green" inverted onClick={submitWithoutApproval}>
                {' '}
                Change Enrollment Without Approval
              </Button>
            )}
          <Button color="green" inverted onClick={handleCancel}>
            <Icon /> Cancel
          </Button>
        </Form>
        {loading && (
          <Dimmer active inverted>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
      </div>
    );
  }
}

export default ContactForm;
