import { gql } from 'react-apollo';

const modifyEnrollmentMutation = gql`
  mutation updateSubscription(
    $modifyEnrollmentInput: UpdateSubscriptionMutationInput!
  ) {
    updateSubscription(input: $modifyEnrollmentInput) {
      success
      errors
    }
  }
`;

export default modifyEnrollmentMutation;
