import { gql } from 'react-apollo';

const sessionQuery = gql`
  query($username: String!, $password: String!) {
    session(username: $username, password: $password) {
      accessToken
      error
      viewer {
        user {
          id
          username
          roles
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default sessionQuery;
