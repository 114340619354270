import { gql } from 'react-apollo';

const reinstateGuaranteePurchaseMutation = gql`
  mutation reinstateGuaranteePurchase(
    $reinstateInput: ReinstateGuaranteePurchaseInput!
  ) {
    reinstateGuaranteePurchase(input: $reinstateInput) {
      success
      errors
    }
  }
`;

export default reinstateGuaranteePurchaseMutation;
