import { gql } from 'react-apollo';

const productMasterQuery = gql`
  query products {
    products {
      productId
      productType
      productDescription
      daysDs
      mileDs
      daysPsi
    }
  }
`;

export default productMasterQuery;
