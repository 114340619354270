import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';

const ListRow = actionHandler => subscription => {
  const rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 2, text: 'Delete', value: 'Delete' }
  ];

  const { id, email, firstName, lastName, auctionAccessNumber, cellPhone  } = subscription;

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, subscription);
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{cellPhone}</Table.Cell>
      <Table.Cell>{firstName}</Table.Cell>
      <Table.Cell>{lastName}</Table.Cell>
      <Table.Cell>{auctionAccessNumber}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
