import { gql } from 'react-apollo';

const customerGroupQuery = gql`
  query customerGroupList($groupName: String, $size: Int, $page: Int) {
    customerGroupList(groupName: $groupName, size: $size, page: $page) {
      id
      groupName
      groupDescription
    }
  }
`;

export default customerGroupQuery;
