import { gql } from 'react-apollo';

const deleteLocallyLinkedDealerMutation = gql`
  mutation deleteLocallyLinkedDealer(
    $input: deleteLocallyLinkedDealerMutationInput!
  ) {
    deleteLocallyLinkedDealer(input: $input) {
      success
      errors
    }
  }
`;

export default deleteLocallyLinkedDealerMutation;
