import { gql } from 'react-apollo';

const createNetworkPlusVehiclesMutation = gql`
  mutation createExternalVehiclesLoad(
    $nplusInput: CreateExternalVehiclesLoadInput!
  ) {
    createExternalVehiclesLoad(input: $nplusInput) {
      success
      errors
      externalVehiclesBatchId
    }
  }
`;

export default createNetworkPlusVehiclesMutation;
