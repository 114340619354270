import { gql } from 'react-apollo';

const flatRatesFromBatchesQuery = gql`
  query flatRatesFromBatches($flatRateOfferBatchId: Int!) {
    flatRates(flatRateOfferBatchId: $flatRateOfferBatchId) {
      id
      dealerNo
    }
  }
`;

export default flatRatesFromBatchesQuery;
