import { gql } from 'react-apollo';

const sessionTokenQuery = gql`
  query($token: String) {
    sessionToken(token: $token) {
      accessToken
      error
      viewer {
        user {
          id
          cellPhone
          username
          roles
          firstName
          lastName
          email
          isOfferAgreementRequired
          flatRateOfferBatchId
          auctionAccessNumber
          externalUser
        }
      }
    }
  }
`;

export default sessionTokenQuery;
