import { gql } from 'react-apollo';

const updateExternalVehiclesLoadMutation = gql`
  mutation updateExternalVehiclesLoad(
    $externalVehicleInput: updateExternalVehiclesLoadInput!
  ) {
    updateExternalVehiclesLoad(input: $externalVehicleInput) {
      success
      errors
    }
  }
`;

export default updateExternalVehiclesLoadMutation;
