import { gql } from 'react-apollo';

const resendVraStatusMutation = gql`
  mutation resendVraStatus($input: ResendVraStatusMutationInput!) {
    resendVraStatus(input: $input) {
      success
      errors
    }
  }
`;

export default resendVraStatusMutation;
