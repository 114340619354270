import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, toNumber, isEmpty, isNumber } from 'lodash';
import VehicleListRow from './VehicleListRow';
import { Table, Message, Icon, Button } from 'semantic-ui-react';
import Clipboard from 'react-clipboard.js';
import ViewOrRemoveAttachments from './ViewOrRemoveAttachments';
import { Link } from 'react-router-dom';
import APP_PATH from '../../constants/paths';
class VehicleList extends Component {
  state = {
    gettingInfo: ''
  };

  onSuccess = () => {
    this.setState({ gettingInfo: 'VIN copied' });
  };

  handleDismiss = () => {
    this.setState({ gettingInfo: '' });
  };
  getText = () => {
    const { externalVehicles } = this.props;
    const vins_array = [];
    externalVehicles &&
      externalVehicles.map(vehicle => {
        return vins_array.push(vehicle.vin);
      });
    return vins_array.join('\n');
  };

  render() {
    const {
      externalVehicles,
      onApproveHandler,
      onRejectHandler,
      user,
      externalVehicleDocuments
    } = this.props;
    const batchId =
      externalVehicles && externalVehicles[0]
        ? externalVehicles[0].externalVehiclesBatchId
        : 0;

    const batch = {
      externalVehicleDocuments: externalVehicleDocuments
    };

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>
          VIN
          <Clipboard
            option-text={() => this.getText()}
            onSuccess={this.onSuccess}
          >
            <Icon name="clipboard" style={{ cursor: 'pointer' }} />
          </Clipboard>
        </Table.HeaderCell>
        <Table.HeaderCell>Year</Table.HeaderCell>
        <Table.HeaderCell>Make</Table.HeaderCell>
        <Table.HeaderCell>Model</Table.HeaderCell>
        <Table.HeaderCell>Odometer</Table.HeaderCell>
        <Table.HeaderCell>Dealer#</Table.HeaderCell>
        <Table.HeaderCell>Dealer Name</Table.HeaderCell>
        <Table.HeaderCell>Seller Name</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Purchase Price</Table.HeaderCell>
        <Table.HeaderCell>Buy Fee</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Total</Table.HeaderCell>
        <Table.HeaderCell>Location</Table.HeaderCell>
        <Table.HeaderCell>Email Address</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Purchase Date</Table.HeaderCell>
        <Table.HeaderCell>Rejection Comment</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    );

    const enteredVehicles = find(externalVehicles, [
      'userId',
      toNumber(user.id)
    ]);
    const review = isEmpty(enteredVehicles) ? true : false;

    const vehicleRow = VehicleListRow(
      onApproveHandler,
      onRejectHandler,
      user,
      review
    );

    const needsSecondaryApproval = externalVehicles.filter(
      item =>
        item.secondaryApprovalEligibility &&
        !isNumber(item.secondaryApprovingUserId) &&
        !isNumber(item.secondaryRejectingUserId)
    );

    return (
      <div>
        <h2> External Vehicle Batch - {batchId} </h2>
        <Link
          to={{
            pathname: APP_PATH.EXTERNAL_VEHICLE_BATCHES,
            state: { refreshSearch: false }
          }}
        >
          <Button color="yellow" style={{ float: 'right', marginTop: '-3%' }}>
            Back
          </Button>
        </Link>
        {externalVehicleDocuments && <ViewOrRemoveAttachments batch={batch} />}

        <br />
        {!isEmpty(needsSecondaryApproval) && (
          <Message info content={'Requires secondary approval'} />
        )}
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          {this.state.gettingInfo && (
            <Message
              info
              content={this.state.gettingInfo}
              onDismiss={() => this.handleDismiss}
            />
          )}
          <Table
            celled
            selectable
            padded
            renderBodyRow={vehicleRow}
            tableData={externalVehicles}
            headerRow={headerRow}
            size="small"
            striped
            singleLine
          />
        </div>
      </div>
    );
  }
}

VehicleList.propTypes = {
  externalVehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onApproveHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default VehicleList;
