import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import withPagination from './WithPagination';
import Search from '../views/DealerManager/Search';
import List from '../views/DealerManager/List';
import dealerListQuery from '../graphql/dealerListQuery';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';

class DealerManager extends Component {
  state = {
    dealerNumber: '',
    isDealer: true,
    dealerError: ''
  };

  dealerSearchQuery = event => {
    const { dealerNumber } = event.target;
    if (dealerNumber.value && !/^5[0-9]{6,6}$/i.test(dealerNumber.value)) {
      this.setState({
        isDealer: false,
        dealerError: 'Must be a valid Dealer Number'
      });
    } else {
      this.setState({ isDealer: true, dealerError: '' });
    }
    const variableArguments = {
      dealer_no: dealerNumber.value
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  render() {
    const {
      data: { customerDealerList },
      searchFields
    } = this.props;

    const { isDealer, dealerError } = this.state;

    return (
      <div>
        <Button color="green" inverted>
          Create Dealer Information
        </Button>
        <br />
        <br />
        <Search
          onSubmit={this.dealerSearchQuery}
          searchFields={searchFields}
          searchOptions={['dealerNumber']}
        />
        {!isDealer && <span style={{ color: 'red' }}>{dealerError}</span>}
        <br />
        <br />
        <List dealerList={customerDealerList} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields }
  } = state;
  return {
    searchFields
  };
};

export default compose(
  withRouter,
  withPagination(dealerListQuery, 'customerDealerList'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DealerManager);
