import React from 'react';
import { Grid, Label, Accordion, Icon } from 'semantic-ui-react';

const SystemInformation = ({ formatField, activeIndex, handleClick }) => {
  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(6)}
        index={6}
        onClick={handleClick}
        className={'accordion-title'}
      >
        System Information
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(6)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Purchase ID</strong>
              <Label basic>{formatField('tappyPurchaseId')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>SBLU</strong>
              <Label basic>{formatField('sblu')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Work Order Number</strong>
              <Label basic>{formatField('auctionSaleId')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Consignment ID</strong>
              <Label basic>{formatField('consignmentId')}</Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default SystemInformation;
