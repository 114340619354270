import React, { Component } from 'react';
import client from '../apollo';
import returnInvoiceQuery from '../graphql/returnInvoiceQuery';
import dealerAccountsQuery from '../graphql/dealerAccountsQuery';
import Certificate from '../views/ReturnCertificate/certificate';

class ReturnCertificate extends Component {
  state = {
    returnInvoice: '',
    dealerData: []
  };

  componentDidMount() {
    const returnInvoiceId = this.props.match.params.returnInvoiceId;

    client
      .query({
        query: returnInvoiceQuery,
        variables: {
          id: returnInvoiceId
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const { returnInvoice } = data;
        const {
          transaction: { dealerNumber }
        } = returnInvoice;
        this.setState({
          returnInvoice: returnInvoice
        });
        dealerQuery(dealerNumber);
      })
      .catch(error => {
        this.setState({
          returnInvoice: ''
        });
      });

    const dealerQuery = dealerNumber => {
      client
        .query({
          query: dealerAccountsQuery,
          variables: {
            dealerNumber: `${dealerNumber}`
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          const { dealerAccounts } = data;
          this.setState({
            dealerData: dealerAccounts
          });
        })
        .catch(error => {
          this.setState({
            dealerData: []
          });
        });
    };
  }

  render() {
    return (
      <div className="certificate">
        {this.state.returnInvoice && (
          <Certificate
            returnInvoice={this.state.returnInvoice}
            dealerData={this.state.dealerData}
          />
        )}
      </div>
    );
  }
}

export default ReturnCertificate;
