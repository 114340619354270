import { createAction } from 'redux-actions';
import * as actionType from './actionTypes';

const addDupeExternalVehicleIndex = createAction(
  actionType.ADD_DUPE_EXT_VEHICLE_INDEX
);
const removeDupeExternalVehicleIndex = createAction(
  actionType.REMOVE_DUPE_EXT_VEHICLE_INDEX
);
const cleanDupeExternalVehicleIndex = createAction(
  actionType.CLEAN_DUPE_EXT_VEHICLE_INDEX
);

export {
  addDupeExternalVehicleIndex,
  removeDupeExternalVehicleIndex,
  cleanDupeExternalVehicleIndex
};
