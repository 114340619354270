import React, { Component } from 'react';
import { Form, Message, Table, Confirm, Checkbox } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import ListRow from '../views/ProductSelection/ListRow';
import ContactForm from '../views/ProductSelection/ContactForm';
import Validator from 'validatorjs';
import createFlatRateOfferMutation from '../graphql/createFlatRateOfferMutation';
import createAndAcceptFlatRateOfferMutation from '../graphql/createAndAcceptFlatRateOfferMutation';

import { find, isEmpty } from 'lodash';

class ProductSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDealerNo: null,
      selectedDealerName: null,
      incompleteEnrollment: null,
      auctionAccessName: null,
      offers: [],
      email: null,
      phone: null,
      editedRate: null,
      baseRate: 0,
      errors: {},
      auctionAccessNumber: null,
      showConfirm: false,
      flashMessage: null,
      loading: false,
      isUpgrade: false,
      offerAccepted: false,
      userEmail: null,
      salesRepEmail: null,
      closedFactoryExclusion: false
    };
  }

  componentDidMount() {
    const {
      selectedDealerNo,
      selectedDealerName,
      auctionAccessName,
      offers,
      email,
      phone,
      auctionAccessNumber,
      onChangeHandler,
      handleRadioButtonChange,
      isUpgrade,
      userEmail,
      salesRepEmail,
      enrollment
    } = this.props;

    this.setState({
      selectedDealerNo,
      selectedDealerName,
      auctionAccessName,
      offers,
      email,
      phone,
      auctionAccessNumber,
      isUpgrade,
      userEmail,
      salesRepEmail
    });

    const incompleteOffer = offers.filter(
      offer => offer && offer.incompleteEnrollment !== null
    )[0];
    if (incompleteOffer) {
      const incompleteEnrollment = incompleteOffer.incompleteEnrollment;
      const productName =
        incompleteEnrollment && incompleteEnrollment.productName;

      const savedOfferIndex = offers.indexOf(
        find(offers, ['productName', productName])
      );
      incompleteEnrollment &&
        onChangeHandler('editedRate', incompleteEnrollment.price);
      incompleteEnrollment &&
        this.setState({
          baseRate: incompleteEnrollment.basePriceDs
        });
      incompleteEnrollment &&
        this.setState({
          incompleteEnrollment: incompleteEnrollment
        });
      handleRadioButtonChange(savedOfferIndex);
      incompleteEnrollment &&
        this.setState({
          closedFactoryExclusion: incompleteEnrollment.closedFactoryExclusion
        });
    } else {
      this.setState({
        closedFactoryExclusion: enrollment[0].closedFactoryExclusion
      });
    }
  }

  fieldUpdateHandler = (field, value) => {
    this.setState({ [field]: value, errors: {} });
  };

  validateEditedRate = (editedRate, chosenOffer, isUpgrade) => {
    if (
      parseInt(editedRate, 10) === 0 ||
      (parseInt(editedRate, 10) === parseInt(chosenOffer.rate, 10) && isUpgrade)
    ) {
      this.setState({
        flashMessage:
          'Override rate must be greater than zero and different from the SAS rate',
        loading: false
      });
      return false;
    }
  };

  validateBaseRate = (baseRate, chosenOffer) => {
    if (
      parseInt(baseRate || 0, 10) === 0 &&
      parseInt(chosenOffer.basePriceDs, 10) === 0 &&
      chosenOffer.productName.includes('PSI')
    ) {
      this.setState({
        flashMessage: 'Base Rate can not be zero',
        loading: false
      });
      return false;
    }
  };

  validateChosenIndex = chosenIndex => {
    if (chosenIndex === '') {
      this.setState({
        flashMessage: 'Please select one among the offers listed',
        loading: false
      });
      return false;
    }
  };

  validateChosenOffer = (chosenOffer, editedRate) => {
    if ((chosenOffer.rate === 0 && isEmpty(editedRate)) || editedRate === 0) {
      this.setState({
        flashMessage: 'Override amount must be greater than 0',
        loading: false
      });
      return false;
    }
  };

  validateEditedRateToOffer = (chosenOffer, editedRate, baseRate) => {
    if (
      !isEmpty(editedRate) &&
      editedRate <= baseRate &&
      chosenOffer.productName.includes('PSI')
    ) {
      this.setState({
        flashMessage:
          'The PSI Override amount must be greater than the Base Rate',
        loading: false
      });
      return false;
    }
  };

  validateRevisedOffer = (editedRate, chosenOffer) => {
    const { incompleteEnrollment } = this.state;
    if (
      incompleteEnrollment &&
      chosenOffer.rate === incompleteEnrollment.price &&
      chosenOffer.productName === incompleteEnrollment.productName &&
      (isEmpty(editedRate) || editedRate === incompleteEnrollment.price)
    ) {
      this.setState({
        flashMessage: 'Exact same offer cannot be re-sent.',
        loading: false
      });
      return false;
    }
  };

  render() {
    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const validationRules = {
      email: 'required|email',
      salesRepEmail: 'required|email',
      userEmail: 'required|email',
      editedRate: 'integer',
      baseRate: 'integer',
      auctionAccessName: 'required'
    };

    const {
      handleRadioButtonChange,
      chosenIndex,
      handleProductSelection,
      handleCancel,
      isRevise,
      onSearchSubmitHandler,
      enrollment,
      isUpgrade
    } = this.props;

    const {
      selectedDealerNo,
      offers,
      editedRate,
      baseRate,
      flashMessage,
      email,
      phone,
      incompleteEnrollment,
      auctionAccessName,
      selectedDealerName
    } = this.state;

    const handleConfirm = () => {
      this.setState({ showConfirm: false });
      handleProductSelection(email, phone);
      onSearchSubmitHandler();
    };

    const handleAccepted = () => {
      this.setState({ offerAccepted: false });
      handleProductSelection(email, phone);
      onSearchSubmitHandler();
    };

    const productname = isEmpty(enrollment[0].productName)
      ? `DealShield ${enrollment[0].days} Day`
      : enrollment[0].productName;

    const submitWithoutApproval = e => {
      e.preventDefault();
      this.setState({ loading: true });
      const {
        email,
        phone,
        editedRate,
        auctionAccessName,
        auctionAccessNumber,
        isUpgrade,
        baseRate,
        userEmail,
        salesRepEmail
      } = this.state;
      const { createAndAcceptMutation, chosenOffer } = this.props;

      const validation = new Validator(this.state, validationRules, {
        'required.email':
          'Please enter a valid email address for the offer recipient',
        email: 'Please enter a valid email address',
        userEmail: 'Please enter a valid user email address',
        salesRepEmail: 'Please enter a valid Sales Rep email address',
        integer: 'Please enter whole number',
        'required.auctionAccessName': 'Please enter a full name'
      });

      if (
        this.validateEditedRate(editedRate, chosenOffer) === false ||
        this.validateChosenIndex(chosenIndex) === false ||
        this.validateRevisedOffer(editedRate, chosenOffer) === false
      ) {
        return false;
      }

      if (validation.fails()) {
        this.setState({ ...validation.errors, loading: false });
        return false;
      } else {
        let flatRateOffers = [];
        const psiEligible = chosenOffer.productName.includes('PSI');
        let basePrice =
          chosenOffer.productName.includes('PSI') &&
          parseInt(chosenOffer.basePriceDs, 10) === 0
            ? baseRate
            : chosenOffer.basePriceDs;
        flatRateOffers.push({
          flatRateId: 0,
          price: parseFloat(editedRate || chosenOffer.rate),
          milesSelected: parseInt(chosenOffer.miles, 10),
          psiEligible: psiEligible,
          palv: false,
          aco: true,
          dealerNo: chosenOffer.dealerNo,
          targetPrice: parseFloat(chosenOffer.rate),
          targetPriceForMiles: parseFloat(chosenOffer.rate),
          productId: chosenOffer.productId,
          daysSelected:
            chosenOffer.exceptionsProductName === 'extendedprotect'
              ? 30
              : parseInt(chosenOffer.days, 10),
          productType: chosenOffer.productType,
          productName: chosenOffer.productName,
          exceptionsProductName: chosenOffer.exceptionsProductName,
          basePriceDs: parseInt(basePrice, 10),
          daysPsi: chosenOffer.daysPsi,
          isUpgrade: isUpgrade,
          locallyLinked: chosenOffer.localLinkedFlag
        });

        const flatRateOfferInput = {
          auctionAccessNumber,
          userEmail,
          salesRepEmail,
          aco: true,
          isDowngrade: true,
          buyerInformation: {
            name: auctionAccessName,
            emails: email,
            cellPhone: phone
          },
          flatRateOffers
        };
        createAndAcceptMutation({
          variables: { flatRateOfferInput }
        })
          .then(({ data }) => {
            const {
              createAndAcceptFlatRateOfferBatch: { success, errors }
            } = data;
            if (success) {
              this.setState({
                offerAccepted: true,
                loading: false
              });
            } else {
              this.setState({
                flashMessage: errors[0],
                loading: false
              });
            }
          })
          .catch(error => {
            this.setState({
              loading: false,
              flashMessage: error
            });
          });
        this.setState({ ...validation.errors });
      }
    };

    const submitForm = e => {
      e.preventDefault();
      this.setState({ loading: true });
      const {
        email,
        phone,
        editedRate,
        baseRate,
        auctionAccessName,
        auctionAccessNumber,
        isUpgrade,
        salesRepEmail,
        userEmail,
        closedFactoryExclusion
      } = this.state;

      const { createMutation, chosenOffer } = this.props;

      const validation = new Validator(this.state, validationRules, {
        'required.email':
          'Please enter a valid email address for the offer recipient',
        email: 'Please enter a valid email address',
        userEmail: 'Please enter a valid user email address',
        salesRepEmail: 'Please enter a valid Sales Rep email address',
        integer: 'Please enter whole number',
        'required.auctionAccessName': 'Please enter a full name'
      });

      if (
        this.validateEditedRate(editedRate, chosenOffer, isUpgrade) === false ||
        this.validateChosenIndex(chosenIndex) === false ||
        this.validateRevisedOffer(editedRate, chosenOffer) === false ||
        this.validateChosenOffer(chosenOffer, editedRate) === false ||
        this.validateEditedRateToOffer(chosenOffer, editedRate, baseRate) ===
          false ||
        this.validateBaseRate(baseRate, chosenOffer, isUpgrade) === false
      ) {
        return false;
      }

      if (validation.fails()) {
        this.setState({ ...validation.errors, loading: false });
        return false;
      } else {
        let flatRateOffers = [];
        const psiEligible = chosenOffer.productName.includes('PSI');
        let basePrice =
          chosenOffer.productName.includes('PSI') &&
          parseInt(chosenOffer.basePriceDs, 10) === 0
            ? baseRate
            : chosenOffer.basePriceDs;
        flatRateOffers.push({
          flatRateId: 0,
          price: parseFloat(editedRate || chosenOffer.rate),
          milesSelected: parseInt(chosenOffer.miles, 10),
          psiEligible: psiEligible,
          palv: false,
          aco: true,
          dealerNo: chosenOffer.dealerNo,
          targetPrice: parseFloat(chosenOffer.rate),
          targetPriceForMiles: parseFloat(chosenOffer.rate),
          productId: chosenOffer.productId,
          daysSelected:
            chosenOffer.exceptionsProductName === 'extendedprotect'
              ? 30
              : parseInt(chosenOffer.days, 10),
          productType: chosenOffer.productType,
          productName: chosenOffer.productName,
          exceptionsProductName: chosenOffer.exceptionsProductName,
          basePriceDs: parseInt(basePrice, 10),
          daysPsi: chosenOffer.daysPsi,
          isUpgrade: isUpgrade,
          locallyLinked: chosenOffer.localLinkedFlag,
          closedFactoryExclusion: Boolean(closedFactoryExclusion)
        });

        const flatRateOfferInput = {
          auctionAccessNumber,
          userEmail,
          salesRepEmail,
          aco: true,
          buyerInformation: {
            name: auctionAccessName,
            emails: email,
            cellPhone: phone
          },
          flatRateOffers
        };
        createMutation({
          variables: { flatRateOfferInput }
        })
          .then(({ data }) => {
            const {
              createFlatRateOfferBatch: { success, errors }
            } = data;
            if (success) {
              this.setState({
                showConfirm: true,
                flashMessage: null,
                loading: false
              });
            } else {
              this.setState({
                flashMessage: errors,
                loading: false
              });
            }
          })
          .catch(error => {
            this.setState({
              loading: false,
              flashMessage: error
            });
          });
        this.setState({ ...validation.errors });
      }
    };

    const checked = !(chosenIndex === '');

    const listRow = ListRow(
      handleRadioButtonChange,
      this.fieldUpdateHandler,
      chosenIndex,
      editedRate,
      baseRate,
      isRevise,
      this.state.errors
    );
    const { chosenOffer } = this.props;

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell width={5}>PRODUCT*</Table.HeaderCell>
        <Table.HeaderCell width={2}>RATE**</Table.HeaderCell>
        {!checked && <Table.HeaderCell className="enrollment-link" />}
        {checked && (
          <Table.HeaderCell width={2} className="enrollment-link">
            OVERRIDE
          </Table.HeaderCell>
        )}
        {checked &&
          chosenOffer.productName.includes('PSI') &&
          parseInt(chosenOffer.basePriceDs, 10) === 0 && (
            <Table.HeaderCell width={2} className="enrollment-link">
              BASE RATE
            </Table.HeaderCell>
          )}
        <Table.HeaderCell width={7} className="enrollment-link" />
      </Table.Row>
    );

    return (
      <div>
        <Form>
          <h1> Enrollment </h1>
          {!isRevise && isUpgrade && (
            <Message
              header={`Current product for ${selectedDealerNo} ${selectedDealerName} is ${productname} at $ ${parseInt(
                enrollment[0].rate,
                10
              )}.`}
              content={`Please select a new product.`}
              className={'header-content-aco'}
            />
          )}
          {!isRevise && !isUpgrade && (
            <Message
              header={`QUOTES FOR - ${selectedDealerNo} - ${selectedDealerName}`}
            />
          )}
          {isRevise && incompleteEnrollment && (
            <Message
              header={`Revising offer sent to ${auctionAccessName} for ${selectedDealerName} for`}
              content={`${incompleteEnrollment.productName} at $${incompleteEnrollment.price}, sent ${incompleteEnrollment.sendAt}`}
              className={'header-content-aco'}
            />
          )}
          {flashMessage && (
            <Message
              onDismiss={this.handleDismiss}
              content={flashMessage}
              negative
            />
          )}
          <div>
            <Table
              celled
              selectable
              padded
              renderBodyRow={listRow}
              tableData={offers}
              headerRow={headerRow}
              size="small"
              striped
              compact="very"
              style={{ fontSize: '90%' }}
            />
            <div>
              <span>
                <label>Closed Factory Exclusion</label>
                <Checkbox
                  className="item-checkbox-right"
                  checked={this.state.closedFactoryExclusion}
                  onChange={onCheckboxHandler('closedFactoryExclusion').bind(
                    this
                  )}
                />
              </span>
            </div>
            <br />
            <ContactForm
              {...this.state}
              fieldUpdateHandler={this.fieldUpdateHandler}
              submitForm={submitForm}
              handleProductSelection={handleProductSelection}
              handleCancel={handleCancel}
              isRevise={isRevise}
              {...this.props}
              submitWithoutApproval={submitWithoutApproval}
            />
            <br />
            {this.state.showConfirm && (
              <Confirm
                className="confirm-form"
                open={true}
                content="An email offer will be sent and the offer will be tracked in Tappy"
                onConfirm={handleConfirm}
              />
            )}
            {this.state.offerAccepted && (
              <Confirm
                className="confirm-form"
                open={true}
                content={`Dealer ${chosenOffer.dealerNo} ${selectedDealerName} \
                has been changed in SAS to ${chosenOffer.productName} at \
                $ ${parseFloat(editedRate || chosenOffer.rate)} \
                and a confirmation has been sent to Customer Service`}
                onConfirm={handleAccepted}
              />
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(createFlatRateOfferMutation, { name: 'createMutation' }),
  graphql(createAndAcceptFlatRateOfferMutation, {
    name: 'createAndAcceptMutation'
  })
)(ProductSelection);
