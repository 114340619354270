import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { Message, Button, Confirm } from 'semantic-ui-react';

import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import dealershipBlacklistsQuery from '../graphql/dealershipBlacklistsQuery';
import createDealershipBlacklistMutation from '../graphql/createDealershipBlacklistMutation';
import updateDealershipBlacklistMutation from '../graphql/updateDealershipBlacklistMutation';
import deleteDealershipBlacklistMutation from '../graphql/deleteDealershipBlacklistMutation';

import Search from '../views/Shared/Search';
import DealershipBlacklistsNew from '../views/DealershipBlacklists/New';
import DealershipBlacklistsEdit from '../views/DealershipBlacklists/Edit';
import DealershipBlacklistsList from '../views/DealershipBlacklists/List';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';

class DealershipBlacklists extends Component {
  state = {
    flashMessage: null,
    selectedDealershipBlacklist: null,
    isEditModalOpen: false,
    isDeleteConfirmationOpen: false,
    isNewModalOpen: false,
    newFormError: null,
    newFormSubmitting: null
  };

  onSearchSubmitHandler = event => {
    const { dealerNumber } = event.target;

    const variableArguments = {
      dealerNumber: dealerNumber.value || null
    };

    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({
      formSubmitting: true
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  onSelectDealershipBlacklist = (action, dealershipBlacklist) => {
    this.setState({
      selectedDealershipBlacklist: dealershipBlacklist
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isEditModalOpen: true
        });
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      default:
        this.setState({
          selectedDealershipBlacklist: null
        });
    }
  };

  openNewForm = () => {
    this.setState({ isNewModalOpen: true });
  };

  onNewFormCloseHandler = () => {
    this.setState({
      newFormError: null,
      newFormSubmitting: null,
      isNewModalOpen: false
    });
  };

  onNewFormSubmitHandler = values => {
    const { createMutation } = this.props;
    const input = { dealershipBlacklist: { ...values } };
    this.setState({
      newFormSubmitting: true
    });

    createMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { createDealershipBlacklist: { success, errors } } = data;
        if (success) {
          this.setState({
            newFormSubmitting: false,
            flashMessage: 'Successfully Created Dealership Blacklist'
          });
          this.props.data.refetch();
          this.onNewFormCloseHandler();
        } else {
          this.setState({
            newFormSubmitting: false,
            newFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          newFormSubmitting: false,
          newFormError: 'Error while creating dealership blacklist'
        });
      });
  };

  onEditFormCloseHandler = () => {
    this.setState({
      editFormError: null,
      editFormSubmitting: null,
      isEditModalOpen: false
    });
  };

  onEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const input = { dealershipBlacklist: { ...values } };
    this.setState({
      editFormSubmitting: true
    });
    updateMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { updateDealershipBlacklist: { success, errors } } = data;
        if (success) {
          this.setState({
            editFormSubmitting: false,
            flashMessage: 'Successfully Updated dealership blacklist'
          });
          this.props.data.refetch();
          this.props.setPage({});
          this.onEditFormCloseHandler();
        } else {
          this.setState({
            editFormSubmitting: false,
            editFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: error
        });
      });
  };

  handleDeleteConfirm = () => {
    const { deleteMutation } = this.props;
    const { selectedDealershipBlacklist: { id } } = this.state;
    const input = { dealershipBlacklistId: id };

    deleteMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { deleteDealershipBlacklist: { success } } = data;
        if (success) {
          this.setState({
            flashMessage: 'Successfully Deleted dealership blacklist'
          });

          this.props.data.fetchMore({
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              return fetchMoreResult;
            }
          });
          this.props.setPage({});
        } else {
          this.setState({
            flashMessage: 'Deleting dealership blacklist not Success'
          });
        }
        this.setState({ isDeleteConfirmationOpen: false });
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          flashMessage: 'Deleting dealership blacklist not Success'
        });
      });
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  render() {
    const { data: { dealershipBlacklists }, searchFields } = this.props;
    const {
      isNewModalOpen,
      newFormError,
      newFormSubmitting,
      isEditModalOpen,
      editFormError,
      editFormSubmitting,
      selectedDealershipBlacklist,
      flashMessage
    } = this.state;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}

        <Button color="green" onClick={this.openNewForm} inverted>
          {' '}
          Create New Dealership Blacklist
        </Button>
        <br />
        <br />

        {isNewModalOpen && (
          <div>
            <DealershipBlacklistsNew
              isModalOpen={isNewModalOpen}
              onCloseHandler={this.onNewFormCloseHandler}
              onSubmitHandler={this.onNewFormSubmitHandler}
              error={newFormError}
              submitting={newFormSubmitting}
            />
          </div>
        )}

        {isEditModalOpen && (
          <div>
            <DealershipBlacklistsEdit
              isModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              onSubmitHandler={this.onEditFormSubmitHandler}
              error={editFormError}
              submitting={editFormSubmitting}
              dealershipBlacklist={selectedDealershipBlacklist}
            />
          </div>
        )}

        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['dealerNumber']}
        />

        <DealershipBlacklistsList
          onSelectDealershipBlacklist={this.onSelectDealershipBlacklist}
          dealershipBlacklists={dealershipBlacklists}
        />
        <Confirm
          open={this.state.isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const { data: { searchFields } } = state;
  return {
    searchFields: searchFields
  };
};

export default compose(
  withRouter,
  withPagination(dealershipBlacklistsQuery, 'dealershipBlacklists'),
  graphql(createDealershipBlacklistMutation, { name: 'createMutation' }),
  graphql(updateDealershipBlacklistMutation, { name: 'updateMutation' }),
  graphql(deleteDealershipBlacklistMutation, { name: 'deleteMutation' }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(DealershipBlacklists);
