import React, { Component } from 'react';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class SurchargePriceUploaderList extends Component {
  render() {
    const { allFiles } = this.props;
    const listRow = ListRow();

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell singleLine textAlign="center">
          Input File
        </Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign="center">
          Status
        </Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <div>
          <Header>Surcharge Price Uploader</Header>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={allFiles}
            headerRow={headerRow}
            size="large"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default SurchargePriceUploaderList;
