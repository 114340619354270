import React from 'react';
import { Grid, Label, Accordion, Icon } from 'semantic-ui-react';

const DisbursementInformation = ({
  vehicleRecord,
  formatField,
  formatDate,
  formatCurrency,
  activeIndex,
  handleClick
}) => {
  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(5)}
        index={5}
        onClick={handleClick}
        className={'accordion-title'}
      >
        Claim Reimbursement Information
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(5)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Status</strong>
              <Label basic>
                {formatField('origReimbursementPaymentStatus')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Date</strong>
              <Label basic>{formatDate('origReimbursementPaymentDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Amount</strong>
              <Label basic>
                {formatCurrency('origReimbursementPaymentAmount')}
              </Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Method</strong>
              <Label basic>
                {formatField('origReimbursementPaymentMethod')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Check/ACH Number</strong>
              <Label basic>
                {formatField('origReimbursementPaymentReferenceNumber')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payee Name</strong>
              <Label basic>{formatField('origReimbursementPayeeName')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Credit Memo Number</strong>
              <Label basic>
                {formatField('origReimbursementInvoiceNumber')}
              </Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default DisbursementInformation;
