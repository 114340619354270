import { gql } from 'react-apollo';

const updateGuaranteePurchaseMutation = gql`
  mutation updateGuaranteePurchase(
    $purchaseInput: UpdateGuaranteePurchaseInput!
  ) {
    updateGuaranteePurchase(input: $purchaseInput) {
      success
      errors
      guaranteePurchase {
        id
        kind
        extensionDays
        additionalDays
        expireGuarantee
        extensionReason
        partner
        purchasingUserId
        guaranteedAt
        expiresAt
        channel
        orderNumber
        guaranteeOffer {
          id
          milesSelected
          daysSelected
          emails
          guaranteeStatus
          guaranteePrice
          transportReimbursement
          leftLot
          vehicleInformation {
            id
            vin
            vehicleCode
            make
            model
            odometerReading
            year
          }
          auctionInformation {
            id
            locationInitials
            dealerNumber
            laneNumber
            auctionAccessNumber
            saleNumber
            buyFee
            runNumber
            vehiclePurchasePrice
          }
        }
      }
    }
  }
`;

export default updateGuaranteePurchaseMutation;
