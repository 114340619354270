import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Table, Dropdown } from 'semantic-ui-react';
import { formatDateOnly } from '../../utilities/dateUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';

const ListRow = (actionHandler, isDisable) => subscription => {
  const activeEnableActions = endDate => {
    const end_date = moment(endDate, 'YYYY-MM-DD');
    if (
      !isEmpty(endDate) &&
      (end_date.isValid() && end_date < moment(new Date(), 'YYYY-MM-DD'))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const rowMenuOptions = endDate => {
    const enableDisableOption = !activeEnableActions(endDate);
    const enableDisableOptionTitle = enableDisableOption
      ? 'Enable Subscription'
      : 'Disable Subscription';
    const disableOption = enableDisableOption ? false : true;
    const classNameOption = enableDisableOption ? '' : 'textColorGray';

    let menuOptions = [
      { key: 1, text: 'Edit', value: 'Edit' },
      {
        key: 2,
        text: enableDisableOptionTitle.toString(),
        value: enableDisableOptionTitle.toString(),
        disabled: disableOption,
        className: classNameOption
      },
      { key: 3, text: 'Preferences', value: 'Preferences' },
      { key: 4, text: 'Edit Notes', value: 'Edit Notes' }
    ];
    return menuOptions;
  };

  const {
    id,
    productName,
    subscriptionType,
    priceDs,
    pricePsiLpi,
    notifyEmail,
    networkPlusRate,
    startDate,
    endDate,
    active,
    dsPaidPsi,
    priceStartDate
  } = subscription;

  const dsPaidPsiValue = dsPaidPsi ? 'active' : 'inactive';

  const currencyProps = price => {
    return {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 0,
      value: price
    };
  };

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, subscription);
  };

  return (
    <Table.Row key={id} active={!active}>
      <Table.Cell>{productName}</Table.Cell>
      <Table.Cell>{priceStartDate}</Table.Cell>
      <Table.Cell>
        {!isEmpty(priceDs) ? (
          ''
        ) : (
          <FormattedNumber {...currencyProps(priceDs)} />
        )}
      </Table.Cell>
      <Table.Cell>
        {!isEmpty(pricePsiLpi) ? (
          ''
        ) : (
          <FormattedNumber {...currencyProps(pricePsiLpi)} />
        )}
      </Table.Cell>
      <Table.Cell className="rowEmailStyle">
        {!isEmpty(notifyEmail) &&
          notifyEmail
            .split(/(?:,)|(?:;)/gi)
            .map((element, idx) => <p key={idx}>{element}</p>)}
      </Table.Cell>
      <Table.Cell>{networkPlusRate}</Table.Cell>
      <Table.Cell>
        {isEmpty(startDate) ? '' : formatDateOnly(startDate)}
      </Table.Cell>
      <Table.Cell>{isEmpty(endDate) ? '' : formatDateOnly(endDate)}</Table.Cell>
      <Table.Cell>{dsPaidPsiValue}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions(endDate)}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
          disabled={isDisable || !active}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
