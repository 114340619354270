import React from 'react';
import { Grid, Label, Accordion, Icon } from 'semantic-ui-react';
import { GREEN_AUTO_IMS_STATUS } from '../../constants';

const ResaleInformation = ({
  vehicleRecord,
  formatField,
  formatDate,
  activeIndex,
  handleClick
}) => {
  const auto_ims_status = GREEN_AUTO_IMS_STATUS.includes(
    vehicleRecord.returnVehAutoIMSStatus
  )
    ? 'green-font'
    : 'black-font';

  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(4)}
        index={4}
        onClick={handleClick}
        className={'accordion-title'}
      >
        Resale Information
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(4)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>AutoIMS Vehicle Status</strong>
              <Label basic className={auto_ims_status}>
                {formatField('returnVehAutoIMSStatus')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Resold Seller Number/Name</strong>
              <Label basic></Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Resold Date</strong>
              <Label basic></Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Left Lot Date</strong>
              <Label basic>{formatDate('consignmentsCheckedOutDate')}</Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default ResaleInformation;
