import { gql } from 'react-apollo';

const updateMarketIndexMutation = gql`
  mutation updateMarketIndex($input: UpdateMarketIndexInput!) {
    updateMarketIndex(input: $input) {
      success
      errors
    }
  }
`;

export default updateMarketIndexMutation;
