import React, { Component } from 'react';
import '../../src/assets/errorpage.css';

class ErrorPage extends Component {
  redirectToTarget = () => {
    const homeUrl = window.location.origin;
    window.location = homeUrl;
  };

  render() {
    return (
      <div className="error_page_outer">
        <div className="error_container_outer">
          <div className="error_container">
            <div className="box">
              <h2>Error</h2>
              <hr />
              <p>
                The page you are looking for might have been removed, had its
                name changed, or is temporarily unavailable.
              </p>
              <p>Please try the following:</p>
              <ul>
                <li>
                  If you typed the page address in the Address bar, make sure
                  that it is spelled correctly.
                </li>
                <li>
                  Click the Back button to return to your previously visited
                  page.
                </li>
                <li>
                  If you were linked to this page, contact the administrator and
                  make them aware of this issue.
                </li>
              </ul>
              <div className="return_to">
                <button
                  className="btn btn-primary"
                  onClick={this.redirectToTarget}
                >
                  Return To Mission Control
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
