import { gql } from 'react-apollo';

const returnInvoicesQuery = gql`
  query returnInvoices(
    $vin: String
    $size: Int
    $offset: Int
    $dealerNumbers: [String]
    $vra: String
  ) {
    returnInvoices(
      vin: $vin
      offset: $offset
      size: $size
      dealerNumbers: $dealerNumbers
      vra: $vra
    ) {
      id
      guaranteePurchaseId
      auctionLocationId
      titleStatus
      reinstate
      estimatedCost
      userEmail
      mechanicalIssues
      cosmeticIssues
      unableToSell
      changedMind
      other
      noAnswer
      additionalInformation
      titleNotificationSent
      reinstatedAt
      odometerOnReturn
      status
      reinstatedBy {
        username
      }
      returningUser {
        username
      }
      expirationDate
      transaction {
        id
        vin
        dealerNumber
        dealershipName
        odometer
        vehicleReceived
        titleReceived
        refundProcessed
        returnInitiatedAt
        purchasePrice
        returnLocation {
          initials
        }
      }
    }
  }
`;

export default returnInvoicesQuery;
