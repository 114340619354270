import { gql } from 'react-apollo';

const bulkAllowPendingOfferMutation = gql`
  mutation bulkAllowPendingOffer($input: BulkAllowPendingOfferInput!) {
    bulkAllowPendingOffer(input: $input) {
      success
      errors
    }
  }
`;

export default bulkAllowPendingOfferMutation;
