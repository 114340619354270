import { gql } from 'react-apollo';

const notesQuery = gql`
  query customerNotesQuery($customerNo: String, $type: String) {
    customerNotes(customerNo: $customerNo, type: $type) {
      noteId
      customerNo
      type
      tags
      message
      createdAt
      updatedAt
      updatedBy
    }
  }
`;

export default notesQuery;
