import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import ListRow from './ListRow';

class DealerDashboardList extends Component {
  render() {
    const {
      dealerDetails,
      localDealerNo,
      showProductSelection,
      isUpgradeAvailable,
      onChangeHandler,
      addDealerHandler,
      displayDealerConfirm,
      handleOpen,
      isAllOffersZero
    } = this.props;
    const { name, auctionAccessNumber, enrollments } = dealerDetails;
    const listRow = ListRow(
      showProductSelection,
      isUpgradeAvailable,
      displayDealerConfirm,
      handleOpen,
      isAllOffersZero
    );
    const headerRow = (
      <Table.Row>
        <Table.HeaderCell width={8}>
          <Form widths="equal">
            <Form.Group>
              <Form.Input
                name="add5m"
                placeholder="Add Unauthorized Dealer"
                value={localDealerNo}
                onChange={onChangeHandler('localDealerNo').bind(this)}
              />
              <Form.Button
                color="green"
                onClick={addDealerHandler.bind(this)}
                inverted
                disabled={isEmpty(localDealerNo)}
              >
                Add dealer
              </Form.Button>
            </Form.Group>
          </Form>
        </Table.HeaderCell>
        <Table.HeaderCell className="enrollment-link" />
      </Table.Row>
    );

    return (
      <div>
        <div className="dealer-details">
          <h4>
            {enrollments.length} Dealers associated with {name} [{
              auctionAccessNumber
            }{' '}
            ]
          </h4>
        </div>
        <Table
          celled
          selectable
          padded
          renderBodyRow={listRow}
          tableData={enrollments}
          headerRow={headerRow}
          size="small"
          striped
          compact="very"
          style={{ fontSize: '90%' }}
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(DealerDashboardList);
