import { gql } from 'react-apollo';

const offerUpgradesQuery = gql`
  query offerUpgradesQuery($size: Int, $offset: Int) {
    offerUpgrades(offset: $offset, size: $size) {
      id
      inputFile
      status
      offerUpgradeLinksFile
      inputFileName
      offerUpgradeFileName
    }
  }
`;

export default offerUpgradesQuery;
