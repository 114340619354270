import { gql } from 'react-apollo';

const externalAuctionTransactionsDataQuery = gql`
  query externalAuctionTransactionsDataQuery(
    $vin: String
    $dealerNumber: String
    $saleDate: String
    $purchaseLocation: String
    $auction: String
    $size: Int
    $offset: Int
  ) {
    externalAuctionTransactionsData(
      vin: $vin
      dealerNumber: $dealerNumber
      saleDate: $saleDate
      auction: $auction
      purchaseLocation: $purchaseLocation
      offset: $offset
      size: $size
    ) {
      id
      vin
      buyer
      buyerName
      seller
      sellerName
      saleDate
      modelYear
      make
      model
      miles
      auction
      purchaseLocation
      createdAt
      updatedAt
    }
  }
`;

export default externalAuctionTransactionsDataQuery;
