import React, { Component } from 'react';
import { isEmpty, isNumber, toNumber } from 'lodash';
import Validator from 'validatorjs';

import {
  Form,
  Button,
  Modal,
  Message,
  TextArea,
  Table
} from 'semantic-ui-react';

class ExternalVehicleRejectForm extends Component {
  state = {
    rejectionComment: '',
    secondaryRejectionComment: '',
    errors: {}
  };

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting,
      externalVehicle,
      user
    } = this.props;

    const {
      id,
      vin,
      year,
      make,
      model,
      dealerNo,
      locationInitials,
      approvingUserId,
      rejectingUserId,
      secondaryApprovingUserId
    } = externalVehicle;

    const { errors } = this.state;

    const primaryApproval =
      isNumber(approvingUserId) && approvingUserId !== toNumber(user.id);
    const primaryRejection =
      isNumber(rejectingUserId) && rejectingUserId !== toNumber(user.id);
    const secondaryApproval =
      !isNumber(secondaryApprovingUserId) &&
      (primaryApproval || primaryRejection);

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const validationRules = secondaryApproval
      ? { secondaryRejectionComment: 'required' }
      : { rejectionComment: 'required' };

    const vehicleDetails = (
      <Table celled padded size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>VIN</Table.HeaderCell>
            <Table.HeaderCell>Year</Table.HeaderCell>
            <Table.HeaderCell>Make</Table.HeaderCell>
            <Table.HeaderCell>Model</Table.HeaderCell>
            <Table.HeaderCell>Dealer#</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={id}>
            <Table.Cell>{vin}</Table.Cell>
            <Table.Cell>{year}</Table.Cell>
            <Table.Cell>{make}</Table.Cell>
            <Table.Cell>{model}</Table.Cell>
            <Table.Cell>{dealerNo}</Table.Cell>
            <Table.Cell>{locationInitials}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );

    const submitForm = () => {
      const { rejectionComment, secondaryRejectionComment } = this.state;

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          id: parseInt(id, 10),
          secondaryApproval,
          rejectionComment,
          secondaryRejectionComment
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          {externalVehicle.id && 'Reject External Vehicle'}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            {vehicleDetails}
            <Form>
              <Form.Group widths="equal">
                {!secondaryApproval && (
                  <Form.Field
                    required
                    error={!isEmpty(errors.rejectionComment)}
                  >
                    <label>Rejection Comments</label>
                    <TextArea
                      name="rejectionComment"
                      value={this.state.rejectionComment}
                      onChange={onChangeHandler('rejectionComment').bind(this)}
                    />
                    {errors.rejectionComment && (
                      <span>{errors.rejectionComment}</span>
                    )}
                  </Form.Field>
                )}

                {secondaryApproval && (
                  <Form.Field
                    required
                    error={!isEmpty(errors.secondaryRejectionComment)}
                  >
                    <label>Rejection Comments</label>
                    <TextArea
                      name="secondaryRejectionComment"
                      value={this.state.secondaryRejectionComment}
                      onChange={onChangeHandler(
                        'secondaryRejectionComment'
                      ).bind(this)}
                    />
                    {errors.secondaryRejectionComment && (
                      <span>{errors.secondaryRejectionComment}</span>
                    )}
                  </Form.Field>
                )}
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}

          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              Reject
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ExternalVehicleRejectForm;
