import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form, Select } from 'semantic-ui-react';

import { APPROVAL_STATUS } from '../../constants';
import { clearSecondarySearch } from '../../data/secondarySearch/actions';

class DropdownSearch extends Component {
  constructor(props) {
    super(props);
    const { searchFields } = props;

    const searchValueStatus =
      searchFields && !isEmpty(searchFields['status'])
        ? searchFields['status']
        : '';
    const searchValueUser =
      searchFields && searchFields['userId'] ? searchFields['userId'] : '';

    this.state = { approvalStatus: searchValueStatus, userId: searchValueUser };
  }

  render() {
    const { onSelectApprovalStatus, searchFields, searchUser } = this.props;

    const searchValueStatus =
      isEmpty(this.state.approvalStatus) &&
      searchFields &&
      !isEmpty(searchFields['status'])
        ? searchFields['status']
        : this.state.approvalStatus;
    const searchValueUser =
      isEmpty(this.state.userId) && searchFields && searchFields['userId']
        ? searchFields['userId']
        : this.state.userId;

    const onSelectHandler = field => (event, data) => {
      const key = data.name === 'approvalStatus' ? 'status' : data.name;
      this.props.clearSecondarySearchFields(key);
      this.setState({ [field]: data.value });
    };
    const submitForm = e => {
      e.preventDefault();
      const { approvalStatus, userId } = this.state;
      onSelectApprovalStatus(approvalStatus, userId);
    };

    return (
      <div>
        <Form>
          <Form.Group widths="two">
            <Form.Field required>
              <Select
                placeholder="ApprovalStatus"
                name="approvalStatus"
                options={APPROVAL_STATUS}
                value={searchValueStatus}
                onChange={onSelectHandler('approvalStatus')}
                key={APPROVAL_STATUS}
              />
            </Form.Field>
            <Form.Field required>
              <Select
                placeholder="Users"
                name="userId"
                options={searchUser}
                value={searchValueUser}
                onChange={onSelectHandler('userId').bind(this)}
              />
            </Form.Field>
            <Form.Button color="green" onClick={submitForm} inverted>
              Search
            </Form.Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    data: { searchUser }
  } = state;
  return {
    searchUser
  };
};

const mapDispatchToProps = dispatch => ({
  clearSecondarySearchFields: field => {
    dispatch(clearSecondarySearch(field));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DropdownSearch
);
