import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Message, Form, Label, Image, Icon, Confirm } from 'semantic-ui-react';
import doc from '../assets/doc.png';

import {
  reduxForm,
  SubmissionError,
  FieldArray,
  formValueSelector
} from 'redux-form';
import { join, isEmpty, map, isNumber, get } from 'lodash';
import { graphql } from 'react-apollo';
import { toNumber } from 'lodash';
import axios from 'axios';
import localStore from 'store'; // cross-browser local storage

import RenderRow from '../views/NetworkPlusVehicles/RenderRow';

import DuplicateVehicleModal from '../views/NetworkPlusVehicles/DuplicateVehicleModal';
import DuplicateExternalVehicleModal from '../views/NetworkPlusVehicles/DuplicateExternalVehicleModal';
import DuplicateEntryVehicleModal from '../views/NetworkPlusVehicles/DuplicateEntryVehicleModal';
import PurchasePriceExceedingMmrValueModal from '../views/NetworkPlusVehicles/PurchasePriceExceedingMmrValueModal';
import createNetworkPlusVehiclesMutation from '../graphql/createNetworkPlusVehiclesMutation';
import { removeDupeVehicle } from '../data/duplicateVehicles/actions';
import { removeDupeExternalVehicle } from '../data/duplicateExternalVehicles/actions';
import { removeDupeEntryVehicle } from '../data/duplicateEntryVehicles/actions';
import {
  addMmrCalculatedValue,
  clearMmrCalculatedValue
} from '../data/mmrCalculatedValues/actions';
import client from '../apollo';
import mmrCheckQuery from '../graphql/mmrCheckQuery';
import { addMmrValue } from '../data/mmrValues/actions';
import ReactDropzone from 'react-dropzone';

class NewNetworkPlusVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: '',
      submitSuccess: '',
      isDupeVehicleModalOpen: false,
      showDupeVehicles: false,
      keepDuplicateVehicleMessage: '',
      isDupeExternalVehicleModalOpen: false,
      showDupeExternalVehicles: false,
      keepDuplicateExternalVehicleMessage: '',
      isPurchasePriceExceedingMmrValueModalOpen: false,
      isPurchasePriceExceedingMmrValueOkClicked: false,
      percentOver: 125,
      selectedFiles: [],
      isSubmitConfirmationOpen: false
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
  }

  isVehiclePurchasePriceOverMmr = (vehicles, index, dispatch) => {
    const vehicle = vehicles[index];
    if (!isEmpty(vehicle.vehiclePurchasePrice)) {
      const vehiclePurchasePrice = toNumber(vehicle.vehiclePurchasePrice);
      const vin = vehicle.vin;
      client
        .query({
          query: mmrCheckQuery,
          variables: { vin: vin }
        })
        .then(({ data }) => {
          const mmrValue = get(data, 'mmrCheck.mmrValue');
          if (isNumber(mmrValue) && isNumber(vehiclePurchasePrice)) {
            const mmrVehicleObject = {
              vin: vin,
              vehiclePurchasePrice: vehiclePurchasePrice,
              mmrValue: mmrValue
            };
            dispatch(addMmrValue(mmrVehicleObject));
          }
        });
    }
    this.setState({
      isPurchasePriceExceedingMmrValueOkClicked: false
    });
  };

  keepDuplicateVehicle = (vin, dealerNumber) => event => {
    this.props.dispatch(removeDupeVehicle({ vin, dealerNumber }));
    this.setState({
      keepDuplicateVehicleMessage:
        'You have chosen to create a duplicated VIN ' + vin
    });
  };

  keepDuplicateExternalVehicle = (vin, dealerNumber) => event => {
    this.props.dispatch(removeDupeExternalVehicle({ vin, dealerNumber }));
    this.setState({
      keepDuplicateExternalVehicleMessage:
        'You have chosen to create a duplicated VIN ' + vin
    });
  };

  keepDuplicateEntryVehicle = (vin, dealerNumber) => event => {
    this.props.dispatch(removeDupeEntryVehicle({ vin, dealerNumber }));
    this.setState({
      keepDuplicateEntryVehicleMessage:
        'You have chosen to create a duplicated VIN ' + vin
    });
  };

  onDupeVehicleModalCloseHandler = () => {
    this.setState({
      showDupeVehicles: false,
      isDupeVehicleModalOpen: false,
      keepDuplicateVehicleMessage: ''
    });
  };

  onPurchasePriceExceedingMmrValueModalCloseHandler = event => {
    event.preventDefault();
    this.setState({
      isPurchasePriceExceedingMmrValueModalOpen: false
    });
  };

  onPurchasePriceExceedingMmrValueModalOkHandler = event => {
    event.preventDefault();
    this.setState({
      isPurchasePriceExceedingMmrValueOkClicked: true,
      isPurchasePriceExceedingMmrValueModalOpen: false
    });
  };

  onDupeExternalVehicleModalCloseHandler = () => {
    this.setState({
      showDupeExternalVehicles: false,
      isDupeExternalVehicleModalOpen: false,
      keepDuplicateExternalVehicleMessage: ''
    });
  };

  onDupeEntryVehicleModalCloseHandler = () => {
    this.setState({
      showDupeEntryVehicles: false,
      isDupeEntryVehicleModalOpen: false,
      keepDuplicateEntryVehicleMessage: ''
    });
  };

  isDuplicateVehiclePresent = () => {
    const { vehicles, duplicateVehicles } = this.props;
    const duplicateVehiclesToShow = [];
    if (!isEmpty(duplicateVehicles) && !isEmpty(vehicles)) {
      map(duplicateVehicles, vehicle => {
        let existingVehicle = vehicles.filter(
          item =>
            item.vin === vehicle.vin && item.dealerNo === vehicle.dealerNumber
        );
        if (!isEmpty(existingVehicle)) {
          duplicateVehiclesToShow.push(vehicle);
        }
      });
    }
    return duplicateVehiclesToShow;
  };

  isDuplicateExternalVehiclePresent = () => {
    const { vehicles, duplicateExternalVehicles } = this.props;
    const duplicateExternalVehiclesToShow = [];
    if (!isEmpty(duplicateExternalVehicles) && !isEmpty(vehicles)) {
      map(duplicateExternalVehicles, vehicle => {
        let existingVehicle = vehicles.filter(
          item =>
            item.vin === vehicle.vin && item.dealerNo === vehicle.dealerNumber
        );
        if (!isEmpty(existingVehicle)) {
          duplicateExternalVehiclesToShow.push(vehicle);
        }
      });
    }
    return duplicateExternalVehiclesToShow;
  };

  isDuplicateEntryVehiclePresent = () => {
    const { vehicles, duplicateEntryVehicles } = this.props;
    const duplicateEntryVehiclesToShow = [];
    if (!isEmpty(duplicateEntryVehicles) && !isEmpty(vehicles)) {
      map(duplicateEntryVehicles, vehicle => {
        let existingVehicles = vehicles.filter(
          item =>
            item.vin === vehicle.vin && item.dealerNo === vehicle.dealerNumber
        );
        if (!isEmpty(existingVehicles) && existingVehicles.length > 1) {
          duplicateEntryVehiclesToShow.push(vehicle);
        }
      });
    }
    return duplicateEntryVehiclesToShow;
  };

  isMmrValuesPresent = () => {
    const { vehicles, mmrValue, dispatch } = this.props;
    const {
      percentOver,
      isPurchasePriceExceedingMmrValueOkClicked
    } = this.state;
    let isMmrOverPurchasePrice;
    let percentageAboveMmr;
    let calculatedMmrAvailable = false;
    let percentOverValue =
      toNumber(percentOver) > 0 ? toNumber(percentOver) : 125;
    dispatch(clearMmrCalculatedValue());

    if (!isEmpty(mmrValue) && !isPurchasePriceExceedingMmrValueOkClicked) {
      map(mmrValue, item => {
        isMmrOverPurchasePrice =
          item.vehiclePurchasePrice > (percentOverValue * item.mmrValue) / 100;
        percentageAboveMmr = (item.vehiclePurchasePrice / item.mmrValue) * 100;

        let existingVehicles = vehicles.filter(
          vehicle => vehicle.vin === item.vin
        );

        if (isMmrOverPurchasePrice && !isEmpty(existingVehicles)) {
          const mmrOverVehiclePurchasePriceObject = {
            vin: item.vin,
            vehiclePurchasePrice: item.vehiclePurchasePrice,
            mmrValue: item.mmrValue,
            percentageAboveMmr: percentageAboveMmr
          };
          calculatedMmrAvailable = true;
          dispatch(addMmrCalculatedValue(mmrOverVehiclePurchasePriceObject));
        }
      });
      if (calculatedMmrAvailable) {
        this.setState({
          isPurchasePriceExceedingMmrValueModalOpen: true
        });
      }
    }
    return calculatedMmrAvailable;
  };

  handleDeleteCancel = () => {
    this.setState({ isSubmitConfirmationOpen: false });
  };

  submitHandler(values) {
    const { vehicles } = this.props;
    const duplicateVehiclesToShow = this.isDuplicateVehiclePresent();
    const duplicateExternalVehiclesToShow = this.isDuplicateExternalVehiclePresent();
    const duplicateEntryVehiclesToShow = this.isDuplicateEntryVehiclePresent();
    const calculatedMmrAvailable = this.isMmrValuesPresent();
    const { percentOver, selectedFiles } = this.state;

    if (!isEmpty(duplicateVehiclesToShow)) {
      this.setState({
        showDupeVehicles: true,
        isDupeVehicleModalOpen: true,
        keepDuplicateVehicleMessage: ''
      });
    }

    if (!isEmpty(duplicateExternalVehiclesToShow)) {
      this.setState({
        showDupeExternalVehicles: true,
        isDupeExternalVehicleModalOpen: true,
        keepDuplicateExternalVehicleMessage: ''
      });
    }

    if (!isEmpty(duplicateEntryVehiclesToShow)) {
      this.setState({
        showDupeEntryVehicles: true,
        isDupeEntryVehicleModalOpen: true,
        keepDuplicateEntryVehicleMessage: ''
      });
    }

    if (
      isEmpty(duplicateVehiclesToShow) &&
      isEmpty(duplicateExternalVehiclesToShow) &&
      isEmpty(duplicateEntryVehiclesToShow) &&
      !calculatedMmrAvailable &&
      !isEmpty(vehicles)
    ) {
      const isSubmitConfirmationOpen = selectedFiles.length > 0 ? false : true;

      this.setState({
        showDupeVehicles: false,
        isDupeVehicleModalOpen: false,
        keepDuplicateVehicleMessage: '',
        showDupeExternalVehicles: false,
        isDupeExternalVehicleModalOpen: false,
        keepDuplicateExternalVehicleMessage: '',
        showDupeEntryVehicles: false,
        isDupeEntryVehicleModalOpen: false,
        keepDuplicateEntryVehicleMessage: '',
        isPurchasePriceExceedingMmrValueOkClicked: false,
        percentOver: percentOver <= 0 ? 125 : percentOver,
        isSubmitConfirmationOpen: isSubmitConfirmationOpen
      });

      if (selectedFiles.length > 0) {
        this.excecuteMutation();
      }
    }
  }

  excecuteMutation = () => {
    const { createMutation, reset, vehicles } = this.props;

    const nplusInput = {
      externalVehiclesLoads: vehicles,
      externalVehiclesDocuments: [{ documentPath: '' }]
    };
    createMutation({
      variables: { nplusInput }
    })
      .then(({ data }) => {
        const {
          createExternalVehiclesLoad: {
            success,
            errors,
            externalVehiclesBatchId
          }
        } = data;
        if (success === true) {
          const { selectedFiles } = this.state;

          selectedFiles.map(selectedFile => {
            this.uploadImage(selectedFile.file, externalVehiclesBatchId);
            return URL.revokeObjectURL(selectedFile.preview);
          });

          reset();
          this.setState({
            selectedFiles: [],
            isSubmitConfirmationOpen: false,
            submitSuccess: 'Successfully submitted Network+ Vehicles.'
          });
        } else {
          this.setState({
            submitError: `Error while processing - ${join(errors, ',')}`
          });
          throw new SubmissionError({
            _error: 'Error on submit:'
          });
        }
      })
      .catch(error => {
        throw new SubmissionError({
          _error: `Error on submit: ${error}`
        });
      });
  };

  onKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = toNumber(target.value);
    const name = target.name;
    this.setState({
      [name]:
        (isNumber(value) && toNumber(value)) || isEmpty(value) ? value : 125
    });
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    const { selectedFiles } = this.state;
    selectedFiles.map(selectedFile => {
      return URL.revokeObjectURL(selectedFile.preview);
    });
  }

  handleDrop = files => {
    let { selectedFiles } = this.state;
    files.map(file => {
      return selectedFiles.push({
        name: file.name,
        file: file,
        preview: URL.createObjectURL(file)
      });
    });
    this.setState({ selectedFiles: selectedFiles });
  };

  uploadImage = (file, externalVehiclesBatchId) => {
    const formData = new FormData();
    const token = localStore.get('jwt');

    formData.append('file', file);
    formData.append('model', 'ExternalVehiclesBatch');
    formData.append('id', externalVehiclesBatchId);
    formData.append('AUTHORIZATION', token ? `${token}` : null);

    axios
      .post(process.env.REACT_APP_UPLOAD_API_URL, formData)
      .then(result => console.log(result))
      .catch(e => console.log(e));
  };

  removeDocument = index => {
    let { selectedFiles } = this.state;
    URL.revokeObjectURL(selectedFiles[index].preview);
    selectedFiles.splice(index, 1);
    this.setState({ selectedFiles });
  };

  render() {
    const { handleSubmit } = this.props;
    const {
      showDupeVehicles,
      isDupeVehicleModalOpen,
      keepDuplicateVehicleMessage,
      showDupeExternalVehicles,
      isDupeExternalVehicleModalOpen,
      keepDuplicateExternalVehicleMessage,
      showDupeEntryVehicles,
      isDupeEntryVehicleModalOpen,
      keepDuplicateEntryVehicleMessage,
      isPurchasePriceExceedingMmrValueModalOpen,
      percentOver,
      selectedFiles
    } = this.state;

    const thumb = {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 2,
      marginRight: 2,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box'
    };

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };

    const img = {
      display: 'block',
      width: 'auto',
      height: '100%'
    };

    const handleDismiss = () => {
      this.setState({ submitSuccess: '', submitError: '' });
    };

    const duplicateVehiclesToShow = this.isDuplicateVehiclePresent();
    const duplicateExternalVehiclesToShow = this.isDuplicateExternalVehiclePresent();
    const duplicateEntryVehiclesToShow = this.isDuplicateEntryVehiclePresent();
    return (
      <div>
        <h2>Enter New Network+ Vehicle</h2>
        <br />
        {/*<h4>Import Data: (XLSX,XLS,CSV)</h4>*/}
        <div>
          <ReactDropzone
            onDrop={this.handleDrop}
            multiple={true}
            className="react-drop-zone"
          >
            Upload your documents here!!
          </ReactDropzone>
          <div>
            {selectedFiles.map((selectedFile, index) => {
              return (
                <Label key={`${selectedFile.name} + ${index}`}>
                  <div style={thumb}>
                    <div style={thumbInner}>
                      {selectedFile.file['type'].includes('image') && (
                        <Image src={selectedFile.preview} style={img} />
                      )}

                      {!selectedFile.file['type'].includes('image') && (
                        <Image src={doc} style={img} />
                      )}
                    </div>
                  </div>
                  <Icon
                    color="red"
                    name="close"
                    className="close-icon"
                    onClick={() => this.removeDocument(index)}
                  />
                  <br />
                  {selectedFile.name}
                </Label>
              );
            })}
          </div>
        </div>
        <h4>
          % over vehicle price &nbsp;&nbsp;
          <Form.Field>
            <Form.Input
              name="percentOver"
              value={percentOver}
              onChange={this.handleInputChange.bind(this)}
            />
          </Form.Field>
        </h4>
        <br />
        {this.state.submitError && (
          <Message
            error
            content={this.state.submitError}
            onDismiss={handleDismiss}
          />
        )}
        {this.state.submitSuccess && (
          <Message
            positive
            content={this.state.submitSuccess}
            onDismiss={handleDismiss}
          />
        )}
        <Form
          onSubmit={handleSubmit(this.submitHandler.bind(this))}
          onKeyPress={this.onKeyPress}
        >
          <FieldArray
            name="vehicles"
            component={RenderRow}
            isVehiclePurchasePriceOverMmr={this.isVehiclePurchasePriceOverMmr}
          />
        </Form>

        {isPurchasePriceExceedingMmrValueModalOpen && (
          <PurchasePriceExceedingMmrValueModal
            mmrValue={this.props.mmrValue}
            isPurchasePriceExceedingMmrValueModalOpen={
              isPurchasePriceExceedingMmrValueModalOpen
            }
            mmrCalculatedValue={this.props.mmrCalculatedValue}
            onPurchasePriceExceedingMmrValueModalCloseHandler={
              this.onPurchasePriceExceedingMmrValueModalCloseHandler
            }
            onPurchasePriceExceedingMmrValueModalOkHandler={
              this.onPurchasePriceExceedingMmrValueModalOkHandler
            }
            percentOver={percentOver}
          />
        )}

        {showDupeVehicles && !isEmpty(duplicateVehiclesToShow) && (
          <DuplicateVehicleModal
            isDupeVehicleModalOpen={isDupeVehicleModalOpen}
            keepDuplicateVehicle={this.keepDuplicateVehicle}
            removeDuplicateVehicle={this.removeDuplicateVehicle}
            onDupeVehicleModalCloseHandler={this.onDupeVehicleModalCloseHandler}
            keepDuplicateVehicleMessage={keepDuplicateVehicleMessage}
          />
        )}

        {showDupeExternalVehicles &&
          !isEmpty(duplicateExternalVehiclesToShow) && (
            <DuplicateExternalVehicleModal
              isDupeExternalVehicleModalOpen={isDupeExternalVehicleModalOpen}
              keepDuplicateExternalVehicle={this.keepDuplicateExternalVehicle}
              removeDuplicateExternalVehicle={
                this.removeDuplicateExternalVehicle
              }
              onDupeExternalVehicleModalCloseHandler={
                this.onDupeExternalVehicleModalCloseHandler
              }
              keepDuplicateExternalVehicleMessage={
                keepDuplicateExternalVehicleMessage
              }
            />
          )}

        {showDupeEntryVehicles && !isEmpty(duplicateEntryVehiclesToShow) && (
          <DuplicateEntryVehicleModal
            isDupeEntryVehicleModalOpen={isDupeEntryVehicleModalOpen}
            keepDuplicateEntryVehicle={this.keepDuplicateEntryVehicle}
            removeDuplicateEntryVehicle={this.removeDuplicateEntryVehicle}
            onDupeEntryVehicleModalCloseHandler={
              this.onDupeEntryVehicleModalCloseHandler
            }
            keepDuplicateEntryVehicleMessage={keepDuplicateEntryVehicleMessage}
          />
        )}

        {showDupeEntryVehicles && !isEmpty(duplicateEntryVehiclesToShow) && (
          <DuplicateEntryVehicleModal
            isDupeEntryVehicleModalOpen={isDupeEntryVehicleModalOpen}
            keepDuplicateEntryVehicle={this.keepDuplicateEntryVehicle}
            removeDuplicateEntryVehicle={this.removeDuplicateEntryVehicle}
            onDupeEntryVehicleModalCloseHandler={
              this.onDupeEntryVehicleModalCloseHandler
            }
            keepDuplicateEntryVehicleMessage={keepDuplicateEntryVehicleMessage}
          />
        )}

        <Confirm
          open={this.state.isSubmitConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          content="No documentation attached to batch, would you like to proceed?"
          onConfirm={this.excecuteMutation}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const formSelector = formValueSelector('npForm');
  const {
    data: {
      duplicateVehicles,
      duplicateExternalVehicles,
      duplicateEntryVehicles,
      mmrValue,
      mmrCalculatedValue
    }
  } = state;
  const dupeVehicles = !isEmpty(duplicateVehicles) ? duplicateVehicles : {};
  const dupeExternalVehicles = !isEmpty(duplicateExternalVehicles)
    ? duplicateExternalVehicles
    : {};
  const dupeEntryVehicles = !isEmpty(duplicateEntryVehicles)
    ? duplicateEntryVehicles
    : {};

  return {
    duplicateVehicles: dupeVehicles,
    duplicateExternalVehicles: dupeExternalVehicles,
    duplicateEntryVehicles: dupeEntryVehicles,
    vehicles: formSelector(state, 'vehicles'),
    mmrValue: mmrValue,
    mmrCalculatedValue: mmrCalculatedValue
  };
};

export default compose(
  connect(mapStateToProps),
  graphql(createNetworkPlusVehiclesMutation, {
    name: 'createMutation'
  }),
  reduxForm({
    form: 'npForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
)(NewNetworkPlusVehicle);
