import { gql } from 'react-apollo';

const createAndAcceptFlatRateOfferMutation = gql`
  mutation createAndAcceptFlatRateOfferBatch(
    $flatRateOfferInput: CreateAndAcceptFlatRateOfferBatchInput!
  ) {
    createAndAcceptFlatRateOfferBatch(input: $flatRateOfferInput) {
      success
      errors
    }
  }
`;

export default createAndAcceptFlatRateOfferMutation;
