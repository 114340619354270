import { gql } from 'react-apollo';

const createLocallyLinkedDealerMutation = gql`
  mutation createLocallyLinkedDealer(
    $input: CreateLocallyLinkedDealerMutationInput!
  ) {
    createLocallyLinkedDealer(input: $input) {
      success
      errors
    }
  }
`;

export default createLocallyLinkedDealerMutation;
