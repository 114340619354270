import { gql } from 'react-apollo';

const marketForecastUploadsQuery = gql`
  query marketForecastUploadsQuery($size: Int, $offset: Int) {
    marketForecastUploads(offset: $offset, size: $size) {
      id
      inputFile
      status
      inputFileName
    }
  }
`;

export default marketForecastUploadsQuery;
