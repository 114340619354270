import React from 'react';
import { Grid, Header, Label, Accordion, Icon } from 'semantic-ui-react';

const PaymentAndFees = ({
  vehicleRecord,
  formatField,
  formatDate,
  formatDateTime,
  formatCurrency,
  activeIndex,
  handleClick,
  paymentStatus,
  vehicleNetColourComparison
}) => {
  const colour = vehicleNetColourComparison(vehicleRecord);

  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(1)}
        index={1}
        onClick={handleClick}
        className={'accordion-title'}
      >
        Original Purchase Payment, Fees and Credits
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(1)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Grid.Row>
            <Grid.Column>
              <Header size="small">
                <a
                  href={
                    process.env.REACT_APP_WORKBENCH_URL +
                    '?customerNumber=' +
                    vehicleRecord.buyer5mil +
                    '&consignmentID=' +
                    vehicleRecord.consignmentId
                  }
                  target="_blank"
                >
                  View in Workbench
                </a>
              </Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={9} textAlign="right">
              Sale Record Last updated: {formatDateTime('updatedOn')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Method</strong>
              <Label basic size="medium"></Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vehicle Buy Net</strong>
              <Label basic size="medium" className={colour}>
                {formatCurrency('origVehNet')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Amount</strong>
              <Label basic size="medium"></Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Deposited</strong>
              <Label basic></Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Payment Date</strong>
              <Label basic>{formatDate('origVehPaymentDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Buyer Adjustments</strong>
              <Label basic></Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Floorplan Name</strong>
              <Label basic>
                {formatField('origVehFloorplanCompName')}{' '}
                {formatField('origVehFloorplanCompCode') &&
                  ' - ' + formatField('origVehFloorplanCompCode')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Floorplan Funded Amount</strong>
              <Label basic>
                {formatCurrency('origVehFloorplanFundedAmount')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Floorplan Holds Title</strong>
              <Label basic>{formatField('origVehFloorplanHoldsTitle')}</Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vehicle Sale Price</strong>
              <Label basic>{formatCurrency('origVehSalePrice')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Buyer's Fee</strong>
              <Label basic>{formatCurrency('origVehBuyersFee')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>OVE Fee</strong>
              <Label basic>{formatCurrency('origVehOVETransactionFee')}</Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Outside Floor Fee</strong>
              <Label basic>{formatCurrency('origVehOutsideFloorFee')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Certification Fee</strong>
              <Label basic>{formatCurrency('origVehCertificationFee')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Simulcast Fee</strong>
              <Label basic>
                {formatCurrency('origVehTransactionSimulcastFee')}
              </Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Floorplan Fee</strong>
              <Label basic>
                {formatCurrency('origVehTransactionFloorPlanFee')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Late Fee</strong>
              <Label basic>{formatCurrency('origVehTransactionLateFee')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>PSI Fee</strong>
              <Label basic>{formatCurrency('origVehTransactionPSIFee')}</Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Toyota Admin Fee</strong>
              <Label basic>{formatCurrency('origVehToyotaAdminFee')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Miscellaneous Fees</strong>
              <Label basic>
                {formatCurrency('origVehTranMiscellaneousFee')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Taxes</strong>
              <Label basic>{formatCurrency('origVehTaxFee')}</Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Promotions & Discounts</strong>
              <Label basic>
                {formatCurrency('origVehTranPromoDiscountFee')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Transportation</strong>
              <Label basic>
                {formatCurrency('origVehTranTransportAssistancFee')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Goodwill</strong>
              <Label basic>{formatCurrency('origVehTranGoodwillFee')}</Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vehicle Payment Status</strong>
              <Label basic size="medium">
                {paymentStatus(vehicleRecord)}
              </Label>
            </Grid.Column>
          </Grid.Row>

          {/* <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Floorplan Code</strong>
              <Label basic>{formatField('origVehFloorplanCompCode')}</Label>
            </Grid.Column>
          </Grid.Row> */}
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default PaymentAndFees;
