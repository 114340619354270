import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import withPagination from './WithPagination';
import Search from '../views/GroupManager/Search';
import List from '../views/GroupManager/List';
import dealerGroupQuery from '../graphql/dealerGroupQuery';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';

class GroupManager extends Component {
  state = {
    groupName: '',
    error: ''
  };

  groupNameSearchQuery = event => {
    const { groupName } = event.target;
    if (groupName.value.length > 100) {
      this.setState({
        error: 'Group name is too long (maximum 100 characters)'
      });
      return false;
    }
    const variableArguments = {
      groupName: groupName.value
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({ error: '' });
    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  render() {
    const {
      data: { dealerGroupList },
      searchFields
    } = this.props;

    const { error } = this.state;

    return (
      <div>
        <b>Group Manager</b>
        <br />
        <br />
        <Button color="green" inverted>
          Create Dealer Group
        </Button>
        <br />
        <br />
        <Search
          onSubmit={this.groupNameSearchQuery}
          searchFields={searchFields}
          searchOptions={['groupName']}
        />
        {error && <span style={{ color: 'red' }}>{error}</span>}
        <br />
        <br />
        <List dealerGroupList={dealerGroupList} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields }
  } = state;
  return {
    searchFields
  };
};

export default compose(
  withRouter,
  withPagination(dealerGroupQuery, 'dealerGroupList'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GroupManager);
