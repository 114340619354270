import React, { Component } from 'react';
import { isEmpty, get } from 'lodash';

import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import localStore from 'store';

import pingRevokeTokenRequestQuery from '../graphql/pingRevokeTokenRequestQuery';
import { clearUser } from '../data/user/actions';
import onepixelimage from '../assets/1_1pixelimage.png';
import LogoutError from '../containers/LogoutError';
import USER from '../constants/appUser';
import APP_PATH from '../constants/paths';

class LogoutCallback extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  componentWillMount() {
    const { client, signOutUser } = this.props;
    const userId = localStore.get('userId');
    const authCode = localStore.get('authorizationCode');
    const user = USER.APP;

    if (!isEmpty(userId)) {
      client
        .query({
          query: pingRevokeTokenRequestQuery,
          variables: {
            app_name: user,
            token: authCode,
            user_id: userId,
            target_resource: window.location.origin + APP_PATH.BASE,
            in_error_resource: window.location.origin + APP_PATH.LOGOUT_ERROR
          }
        })
        .then(({ data }) => {
          const error = get(data, 'pingRevokeTokenRequest.error_message');
          const success = get(data, 'pingRevokeTokenRequest.success');
          if (success === false) {
            this.setState({ error: error });
          } else {
            signOutUser();
            client.resetStore();
            localStore.remove('jwt');
            localStore.remove('authorizationCode');
            localStore.remove('userId');
            window.location = data.ping_logout_url;
          }
        });
    }
  }

  render() {
    const img = {
      display: 'block'
    };
    return (
      <div>
        {isEmpty(this.state.error) && (
          <img src={onepixelimage} style={img} alt="" />
        )}
        {!isEmpty(this.state.error) && <LogoutError />}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  signOutUser: () => {
    dispatch(clearUser());
  }
});

export default compose(
  withApollo,
  withRouter,
  connect(null, mapDispatchToProps)
)(LogoutCallback);
