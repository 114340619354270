import { gql } from 'react-apollo';

const apmsLocationListQuery = gql`
  query apmsLocationList($name: String, $page: Int, $size: Int) {
    apmsLocationList(name: $name, page: $page, size: $size) {
      id
      name
    }
  }
`;

export default apmsLocationListQuery;
