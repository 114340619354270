import React, { Component } from 'react';
import { Modal, Message, Loader, Dimmer } from 'semantic-ui-react';
import SaleInformationList from './saleInformationList';

class SaleInformationForm extends Component {
  render() {
    const {
      isModalOpen,
      onCloseHandler,
      error,
      eatTransactionId,
      vinHeader,
      externalAuctionTransactionsEventData,
      loading
    } = this.props;
    const resultSaleInformation = () => {
      return (
        <div>
          {' '}
          {loading && (
            <Dimmer active inverted>
              <Loader size="large"> Loading </Loader>{' '}
            </Dimmer>
          )}{' '}
          <SaleInformationList
            eatTransactionId={eatTransactionId}
            externalAuctionTransactionsEventData={
              externalAuctionTransactionsEventData
            }
          />{' '}
        </div>
      );
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={true}
        size={'fullscreen'}
      >
        <Modal.Header>Sale Information - {vinHeader}</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            {resultSaleInformation()}
            <br />
            <br />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions />
      </Modal>
    );
  }
}

export default SaleInformationForm;
