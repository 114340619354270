import React, { Component } from 'react';
import {
  Form,
  Select,
  Message,
  Button,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import moment from 'moment';
import Validator from 'validatorjs';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import _, { isEmpty, get } from 'lodash';
import client from '../../apollo';
import { compose } from 'recompose';

import withPagination from '../../containers/WithSmallerPagination';
import externalAuctionLocationsQuery from '../../graphql/externalAuctionLocationsQuery';
import externalAuctionTransactionsDataQuery from '../../graphql/externalAuctionTransactionsDataQuery';
import ExternalAuctionTransactionsList from '../../views/ExternalAuctionTransactions/saleTransactionList';
import { clearSearch, setSearch } from '../../data/searchField/actions';
import { clearPage, setPage } from '../../data/page/actions';
import { setRefreshSearch } from '../../data/refreshSearch/actions';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      vin: '',
      dealerNumber: '',
      auctionLocation: '',
      auctionLocations: [],
      saleDate: '',
      auction: '',
      purchaseLocation: '',
      changed: {},
      errors: {},
      searched: false,
      focusErrors: {},
      column: '',
      direction: 'descending',
      externalAuctionTransactionsData: [],
      size: 0
    };
    const { clearSearchFields, dispatchRefreshSearch, refreshSearch } = props;

    if (refreshSearch) {
      dispatchRefreshSearch(false);
      clearSearchFields({});
      const variableArguments = {
        vin: '',
        dealerNumber: '',
        auctionLocation: '',
        saleDate: '',
        auction: '',
        purchaseLocation: '',
        size: 0
      };
      this.props.data.refetch({
        ...variableArguments
      });
    }
  }

  componentWillMount() {
    client
      .query({
        query: externalAuctionLocationsQuery
      })
      .then(({ data }) => {
        const result = get(data, 'externalAuctionLocations');
        if (!isEmpty(result)) {
          this.setState({
            auctionLocations: result
          });
        }
      });
  }

  handleSort = clickedColumn => () => {
    const { column, direction, externalAuctionTransactionsData } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        externalAuctionTransactionsData: _.sortBy(
          externalAuctionTransactionsData,
          [clickedColumn]
        ),
        direction: 'ascending'
      });
      return;
    }

    let en = Object.assign([], externalAuctionTransactionsData);
    const dir = direction === 'ascending' ? 'descending' : 'ascending';
    this.setState({
      column: clickedColumn,
      externalAuctionTransactionsData: en.slice().reverse(),
      direction: dir
    });
  };

  componentDidMount() {
    const {
      data: { externalAuctionTransactionsData }
    } = this.props;
    this.setState({ externalAuctionTransactionsData, loading: false });
  }

  // newProps got the latest props and this.props got only the old data
  componentWillReceiveProps(newProps) {
    const {
      data: { externalAuctionTransactionsData }
    } = newProps;
    this.setState({ externalAuctionTransactionsData, loading: false });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading) {
      if (prevProps.data !== this.props.data && this.state.searched) {
        this.setState({
          loading: false
        });
      }
    }
  }

  onDateChangeHandler = (field, date) => {
    this.setState({
      [field]: moment(new Date(date), 'YYYY-MM-DD'),
      changed: { [field]: true }
    });
  };

  onSelectHandler = field => (event, data) => {
    this.setState({
      [field]: data.value,
      changed: { [field]: true },
      loading: false
    });
  };

  resetFields = () => {
    this.setState({
      loading: false,
      vin: '',
      dealerNumber: '',
      auctionLocation: '',
      saleDate: '',
      auction: '',
      purchaseLocation: '',
      searched: false,
      errors: {},
      focusErrors: {},
      column: '',
      direction: 'descending',
      externalAuctionTransactionsData: []
    });
    const variableArguments = {
      vin: '',
      dealerNumber: '',
      auctionLocation: '',
      saleDate: '',
      size: 0
    };
    this.props.data.refetch({
      ...variableArguments
    });
    this.props.clearPage({});
  };

  render() {
    const {
      loading,
      column,
      direction,
      externalAuctionTransactionsData
    } = this.state;
    var defaultLocation = [
      {
        text: 'Select',
        key: 0,
        value: ''
      }
    ];
    const externalLocations =
      !isEmpty(this.state.auctionLocations) &&
      this.state.auctionLocations.map(function(location) {
        return {
          text: `${location.initials} - ${location.name}`,
          key: location.id,
          value: `${location.initials} - ${location.name}`
        };
      });

    var allLocations = [...defaultLocation, ...externalLocations];
    const externalAuctionLocations = [...new Set(allLocations)];

    const validationRules = {
      vin: 'checkValue|alphaNumeric',
      dealerNumber: 'shouldBe5mil',
      saleDate: [{ required_with: 'auctionLocation' }, 'shouldBeDate']
    };

    Validator.register(
      'alphaNumeric',
      function(value, requirement, attribute) {
        return value && value.match(/^[a-zA-Z0-9]*$/);
      },
      ' Only alphanumeric characters.'
    );

    Validator.register(
      'checkValue',
      function(value, requirement, attribute) {
        return value && value.length === 17;
      },
      'Must be 17 characters'
    );

    Validator.register(
      'shouldBe5mil',
      function(value, requirement, attribute) {
        return value && /^5[0-9]{6,6}$/i.test(value);
      },
      'Must be a Dealer Number'
    );
    Validator.register(
      'shouldBeDate',
      function(value, requirement, attribute) {
        return value && value.format('YYYY-MM-DD');
      },
      'Must be a valid Date'
    );
    const reloadSearching = () => {
      const {
        vin,
        dealerNumber,
        saleDate,
        auctionLocation,
        errors,
        size
      } = this.state;

      if (
        isEmpty(vin) &&
        isEmpty(dealerNumber) &&
        isEmpty(saleDate) &&
        isEmpty(auctionLocation)
      ) {
        this.setState({
          focusErrors: {
            vin: 'Required',
            dealerNumber: 'Required',
            saleDate: 'Required',
            auctionLocation: 'Required'
          },
          searched: false,
          errors: {},
          loading: false
        });
        this.resetFields();
        return false;
      }
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({
          ...validation.errors,
          searched: false,
          focusErrors: {},
          loading: false
        });
        return false;
      } else {
        this.setState({
          ...validation.errors,
          searched: true,
          focusErrors: {},
          loading: true
        });
        const splitActionLocation = this.state.auctionLocation.split('-');
        const purchaseLocationParam = !isEmpty(splitActionLocation[0])
          ? splitActionLocation[0].trim().toUpperCase()
          : '';
        let formattedSaleDate = !isEmpty(this.state.saleDate)
          ? this.state.saleDate.format('YYYY-MM-DD')
          : this.state.saleDate;
        const variableArguments = {
          vin: this.state.vin,
          dealerNumber: this.state.dealerNumber,
          saleDate: formattedSaleDate,
          purchaseLocation: purchaseLocationParam,
          size: 50
        };
        this.props.data
          .refetch({
            ...variableArguments
          })
          .then(({ data }) => {
            this.setState({
              loading: false
            });
          });
        this.setState({ loading: false });
        this.props.setPage({});
      }
      this.setState({ searched: false });
    };

    const submitForm = () => {
      const {
        vin,
        dealerNumber,
        saleDate,
        auctionLocation,
        errors,
        size
      } = this.state;

      if (
        isEmpty(vin) &&
        isEmpty(dealerNumber) &&
        isEmpty(saleDate) &&
        isEmpty(auctionLocation)
      ) {
        this.setState({
          focusErrors: {
            vin: 'Required',
            dealerNumber: 'Required',
            saleDate: 'Required',
            auctionLocation: 'Required'
          },
          searched: false,
          errors: {},
          loading: false
        });
        return false;
      }
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({
          ...validation.errors,
          searched: false,
          focusErrors: {},
          loading: false
        });
        this.props.clearPage({});
        return false;
      } else {
        this.setState({
          ...validation.errors,
          searched: true,
          focusErrors: {},
          loading: true
        });
      }
    };

    return (
      <div>
        {console.log('return loading:', loading)}{' '}
        {loading && (
          <Dimmer active inverted>
            <Loader size="large"> Loading </Loader>{' '}
          </Dimmer>
        )}{' '}
        <Form onSubmit={submitForm}>
          <Form.Group widths="two">
            <Form.Field error={this.state.focusErrors.vin}>
              <Form.Input
                name="vin"
                placeholder={'Search by Vin'}
                value={this.state.vin}
                onChange={this.onSelectHandler('vin')}
              />
              {this.state.errors.vin && (
                <span style={{ color: 'red' }}>{this.state.errors.vin}</span>
              )}
            </Form.Field>
            <Form.Field error={this.state.focusErrors.dealerNumber}>
              <Form.Input
                name="dealerNumber"
                placeholder={'Search by Dealer Number'}
                value={this.state.dealerNumber}
                onChange={this.onSelectHandler('dealerNumber')}
              />
              {this.state.errors.dealerNumber && (
                <span style={{ color: 'red' }}>
                  {this.state.errors.dealerNumber}
                </span>
              )}
            </Form.Field>
            <Form.Field error={this.state.focusErrors.auctionLocation}>
              <Select
                search
                placeholder="Search by Auction Location"
                name="auctionLocation"
                options={externalAuctionLocations}
                value={this.state.auctionLocation}
                onChange={this.onSelectHandler('auctionLocation')}
              />
              {this.state.errors.auctionLocation && (
                <span style={{ color: 'red' }}>
                  {this.state.errors.auctionLocation}
                </span>
              )}
            </Form.Field>
            <Form.Field error={this.state.focusErrors.saleDate}>
              <DatePicker
                placeholderText="Search by Sale Date"
                selected={this.state.saleDate}
                onChange={date => this.onDateChangeHandler('saleDate', date)}
                dateFormat="YYYY-MM-DD"
                name="saleDate"
                autoComplete="offline"
              />
              {this.state.errors.saleDate && (
                <span style={{ color: 'red' }}>
                  {this.state.errors.saleDate}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <Button
                color="green"
                id="searchButton"
                onClick={reloadSearching}
                inverted
              >
                Search
              </Button>
              <Button
                color="green"
                type="button"
                onClick={this.resetFields}
                inverted
              >
                Clear
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <br />
        {this.state.searched && (
          <ExternalAuctionTransactionsList
            externalAuctionTransactionsData={externalAuctionTransactionsData}
            column={column}
            direction={direction}
            handleSort={this.handleSort}
            searched={this.state.searched}
            vin={this.state.vin}
            dealerNumber={this.state.dealerNumber}
            auctionLocation={this.state.auctionLocation}
            auction={
              this.state.auctionLocation
                ? this.state.auctionLocation.split(' - ')[1]
                : ''
            }
            purchaseLocation={
              this.state.auctionLocation
                ? this.state.auctionLocation.split(' - ')[0]
                : ''
            }
            saleDate={
              this.state.saleDate
                ? this.state.saleDate.format('YYYY-MM-DD')
                : ''
            }
          />
        )}{' '}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  setPage: fields => {
    dispatch(setPage());
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  },
  clearPage: fields => {
    dispatch(clearPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields, refreshSearch }
  } = state;
  return {
    searchFields: searchFields,
    refreshSearch
  };
};

export default compose(
  withPagination(
    externalAuctionTransactionsDataQuery,
    'externalAuctionTransactionsData'
  ),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Search);
