import { gql } from 'react-apollo';

const auctionLocationsQuery = gql`
  query auctionLocationsQuery($sortBy: String) {
    locations(sortBy: $sortBy) {
      id
      initials
      name
      auctionedge_code
      partnerNetwork
      acceptsReturns
    }
  }
`;

export default auctionLocationsQuery;
