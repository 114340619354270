import * as actionType from './actionTypes';
import _ from 'lodash';

const initialState = {};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_SEARCH:
      return action.payload;
    case actionType.RESET_SEARCH:
      return _.omit(state, action.payload);
    case actionType.CLEAR_SEARCH:
      return {};
    default:
      return state;
  }
};

export default searchReducer;
