import { gql } from 'react-apollo';

const cancelEnrollmentMutation = gql`
  mutation cancelSubscription(
    $cancelEnrollmentInput: CancelSubscriptionMutationInput!
  ) {
    cancelSubscription(input: $cancelEnrollmentInput) {
      success
      errors
    }
  }
`;

export default cancelEnrollmentMutation;
