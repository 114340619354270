import { gql } from 'react-apollo';

const cancelGuaranteePurchaseMutation = gql`
  mutation cancelGuaranteePurchase(
    $cancelInput: CancelGuaranteePurchaseInput!
  ) {
    cancelGuaranteePurchase(input: $cancelInput) {
      success
      errors
    }
  }
`;

export default cancelGuaranteePurchaseMutation;
