import { gql } from 'react-apollo';

const pingAuthenticationQuery = gql`
  query pingAuthentication(
    $code: String!
    $redirectUri: String!
    $appName: String!
  ) {
    pingAuthentication(
      code: $code
      redirectUri: $redirectUri
      appName: $appName
    ) {
      accessToken
      error
      viewer {
        user {
          id
          username
          roles
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default pingAuthenticationQuery;
