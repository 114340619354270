import { gql } from 'react-apollo';

const getPingStatusQuery = gql`
  query featureFlagsQuery {
    pingFeatureFlagStatus {
      active
    }
  }
`;

export default getPingStatusQuery;
