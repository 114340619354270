import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _, { map, isEmpty } from 'lodash';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import externalVehiclesBatchesQuery from '../graphql/externalVehiclesBatchesQuery';
import ExternalVehicleBatchesList from '../views/ExternalVehicleBatches/List';
import Search from '../views/Shared/Search';
import DropdownSearch from '../views/ExternalVehicleBatches/DropdownSearch';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';
import { setSecondarySearch } from '../data/secondarySearch/actions';
import { setSearchUser } from '../data/searchUser/actions';

class ExternalVehicleBatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApprovalStatus: ''
    };
    const {
      data: { externalVehiclesBatchUsers },
      searchFields,
      secondarySearch,
      refreshSearch
    } = props;

    if (refreshSearch) {
      this.refetchData(searchFields, secondarySearch);
    }

    if (isEmpty(searchFields) && isEmpty(secondarySearch)) {
      const all = { text: 'All', key: 0, value: 'ALL' };
      const user = externalVehiclesBatchUsers.map(function(data) {
        return {
          text: data.username,
          key: parseInt(data.id, 10),
          value: parseInt(data.id, 10)
        };
      });

      let users_array = [all];
      let users = _.uniqWith(user, _.isEqual);
      map(users, user => {
        users_array.push(user);
      });
      props.dispatch(setSearchUser(users_array));
    }
  }

  refetchData(searchFields, secondarySearch) {
    const status =
      secondarySearch.status === 'ALL' ? null : secondarySearch.status;
    const variableArguments = {
      vin: searchFields.vin || null,
      batchId: searchFields.batchId || null,
      dealerNumber: searchFields.dealerNumber || null,
      userId: parseInt(secondarySearch.userId, 10) || null,
      status
    };
    this.props.data.refetch({
      ...variableArguments
    });
  }

  onSearchSubmitHandler = event => {
    const { dealerNumber, vin, batchId } = event.target;
    const { secondarySearch, setSearchFields, setPage } = this.props;
    const dealerNo = isEmpty(dealerNumber.value) ? null : dealerNumber.value;
    const batch = parseInt(batchId.value, 10);
    const status =
      secondarySearch.status === 'ALL' ? null : secondarySearch.status;
    const variableArguments = {
      vin: vin.value || null,
      batchId: batch || null,
      dealerNumber: dealerNo || null,
      userId: parseInt(secondarySearch.userId, 10) || null,
      status
    };
    this.props.data.refetch({
      ...variableArguments
    });
    this.setState({
      formSubmitting: true
    });
    setSearchFields(variableArguments);
    setPage({});
  };

  onSelectApprovalStatus = (approvalStatus, userId) => {
    const status = approvalStatus === 'ALL' ? null : approvalStatus;
    const variableArguments = {
      status,
      userId: parseInt(userId, 10) || null,
      vin: this.props.searchFields.vin || null,
      batchId: this.props.searchFields.batchId || null,
      dealerNumber: this.props.searchFields.dealerNumber || null
    };
    this.props.data.refetch({
      ...variableArguments
    });
    this.props.setSecondarySearchFields({ status: approvalStatus, userId });
    this.props.setPage({});
  };

  render() {
    const {
      data: { externalVehiclesBatches },
      searchFields,
      secondarySearch
    } = this.props;
    return (
      <div>
        <h2> External Vehicle Batches </h2>
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['dealerNumber', 'vin', 'batchId']}
          multipleSearches={true}
        />
        <DropdownSearch
          onSelectApprovalStatus={this.onSelectApprovalStatus}
          externalVehiclesBatches={externalVehiclesBatches}
          searchFields={secondarySearch}
          searchOptions={['status', 'userId']}
        />
        <ExternalVehicleBatchesList
          externalVehicleBatches={externalVehiclesBatches}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setSecondarySearchFields: fields => {
    dispatch(setSecondarySearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields, secondarySearch, refreshSearch, page }
  } = state;
  return {
    searchFields,
    secondarySearch,
    refreshSearch,
    page
  };
};

export default compose(
  withRouter,
  withPagination(externalVehiclesBatchesQuery, 'externalVehiclesBatches'),
  LoadingDataEnhancer,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ExternalVehicleBatches);
