import { gql } from 'react-apollo';

const externalVehiclesBatchesQuery = gql`
  query externalVehiclesBatchesQuery(
    $vin: String
    $dealerNumber: String
    $batchId: Int
    $size: Int
    $offset: Int
    $userId: Int
    $status: String
  ) {
    externalVehiclesBatches(
      vin: $vin
      dealerNumber: $dealerNumber
      batchId: $batchId
      offset: $offset
      size: $size
      userId: $userId
      status: $status
    ) {
      id
      status
      createdAt
      user {
        username
        id
      }
      externalVehiclesLoads {
        dealerNo
        model
        odometerReading
        year
        make
        vin
        dealerNo
        vehiclePurchasePrice
        locationInitials
        automaticPurchaseEmail
        purchasedAt
        userId
        rejectionComment
        secondaryRejectionComment
        vehicleTotal
      }
    }
    externalVehiclesBatchUsers {
      id
      username
    }
  }
`;

export default externalVehiclesBatchesQuery;
