import React from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, Select, Icon, Button } from 'semantic-ui-react';
import { EMAIL_FREQUENCY_OPTIONS } from '../../constants';

const NotificationSettingsRow = ({
  notificationSetting,
  onChangeEmailFrequencyHandler,
  removeNotificationSettingHandler
}) => {
  return (
    <Form.Group widths="equal" key={notificationSetting.id}>
      <Form.Field required>
        <label>Dealer Number</label>
        <Input value={notificationSetting.dealerNumber} />
      </Form.Field>
      <Form.Field required>
        <label>Email frequency</label>
        <Select
          placeholder="Select frequency"
          options={EMAIL_FREQUENCY_OPTIONS}
          onChange={onChangeEmailFrequencyHandler(
            notificationSetting.dealerNumber
          )}
          value={notificationSetting.emailFrequency}
          className="email-frequency-selector"
        />
        {isEmpty(notificationSetting.id) && (
          <Button
            icon
            size="mini"
            color="red"
            content={notificationSetting.dealerNumber}
            onClick={removeNotificationSettingHandler(
              notificationSetting.dealerNumber
            )}
          >
            <Icon name="remove" />
          </Button>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default NotificationSettingsRow;
