import { gql } from 'react-apollo';

const mmrCheckQuery = gql`
  query mmrCheckQuery($vin: String!) {
    mmrCheck(vin: $vin) {
    	mmrValue
    }
  }
`;

export default mmrCheckQuery;
