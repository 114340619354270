import React from 'react';
import { Table } from 'semantic-ui-react';

const ListRow = (guaranteeHistory, index) => {
  const {
    transactionDateAndTime,
    auctionDate,
    action,
    cancellationReason,
    cancelledBy,
    guaranteeStatus,
    guaranteeKind,
    paymentKind,
    guaranteeChannel,
    vin,
    dsPrice,
    dsDays,
    dsMiles,
    guaranteeId,
    expiryDate,
    sellingLocation,
    buyerName,
    buyerNum,
    sellerName,
    sellerNum,
    saleYear,
    saleWeek,
    saleLaneRun,
    yearMakeModel,
    carOdometer,
    groupName,
    cancelledDate
  } = guaranteeHistory;

  return (
    <Table.Row key={index}>
      <Table.Cell>{transactionDateAndTime}</Table.Cell>
      <Table.Cell>{auctionDate}</Table.Cell>
      <Table.Cell>{action}</Table.Cell>
      <Table.Cell>{cancelledDate}</Table.Cell>
      <Table.Cell>{cancellationReason}</Table.Cell>
      <Table.Cell>{cancelledBy}</Table.Cell>
      <Table.Cell>{guaranteeStatus}</Table.Cell>
      <Table.Cell>{guaranteeKind}</Table.Cell>
      <Table.Cell>{paymentKind}</Table.Cell>
      <Table.Cell>{guaranteeChannel}</Table.Cell>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell
        style={guaranteeStatus === 'Cancelled' ? { color: 'red' } : {}}
      >
        {dsPrice}
      </Table.Cell>
      <Table.Cell>{dsDays}</Table.Cell>
      <Table.Cell>{dsMiles}</Table.Cell>
      <Table.Cell>{guaranteeId}</Table.Cell>
      <Table.Cell>{expiryDate}</Table.Cell>
      <Table.Cell>{sellingLocation}</Table.Cell>
      <Table.Cell>{buyerName}</Table.Cell>
      <Table.Cell>{buyerNum}</Table.Cell>
      <Table.Cell>{sellerName}</Table.Cell>
      <Table.Cell>{sellerNum}</Table.Cell>
      <Table.Cell>{saleYear}</Table.Cell>
      <Table.Cell>{saleWeek}</Table.Cell>
      <Table.Cell>{saleLaneRun}</Table.Cell>
      <Table.Cell>{yearMakeModel}</Table.Cell>
      <Table.Cell>{carOdometer}</Table.Cell>
      <Table.Cell>{groupName}</Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
