import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import localStore from 'store';

import { Form, Tab, Select, Dropdown } from 'semantic-ui-react';
import './preferencesStyle.css';

import Rule from './Rule';
import InnerRuleSet from './InnerRuleSet';
import { v1 as uuidv1 } from 'uuid';

import preferenceZIndex from '../../../utilities/preferenceUtil';

let rs_inner_rules = [];

class RuleSet extends Component {
  swapHandle = (arr, idx1, idx2) => {
    let temp = arr[idx1];
    arr[idx1] = arr[idx2];
    arr[idx2] = temp;
  };

  onSelectDefaultRuleType = (e, data) => {
    const { refId, parentRender } = this.props;

    let preference = localStore.get('preference');
    let { ruleSetList } = preference;
    let ruleSet = ruleSetList.find(ruleSet => ruleSet.uid === refId);

    ruleSet.defaultPreferenceRuleType = data.value;
    localStore.set('preference', preference);
    parentRender();
  };

  selectActionHandler = (e, action) => {
    e.preventDefault();

    const { refId, id, parentRender } = this.props;

    let preference = localStore.get('preference');
    let { ruleSetList, ruleList, innerRuleSetList } = preference;
    let ruleSet = ruleSetList.find(ruleSet => ruleSet.uid === refId);

    switch (action.value) {
      case 'Add Rule':
        const newRule = {
          uid: uuidv1(),
          ruleType: '',
          facts: [],
          operatorType: '',
          include: false
        };

        ruleList.push(newRule);
        ruleSet.items.push(newRule.uid);

        localStore.set('preference', preference);
        parentRender();
        break;
      case 'Add Inner Rule':
        const newInnerRule = {
          uid: uuidv1(),
          ruleType: 'OR',
          items: []
        };

        innerRuleSetList.push(newInnerRule);
        ruleSet.items.push(newInnerRule.uid);

        localStore.set('preference', preference);
        parentRender();
        break;
      case 'Move Up':
        if (id > 0) {
          this.swapHandle(ruleSetList, id, id - 1);
          localStore.set('preference', preference);
          parentRender();
        }
        break;
      case 'Move Down':
        if (id < ruleSetList.length - 1) {
          this.swapHandle(ruleSetList, id, id + 1);
          localStore.set('preference', preference);
          parentRender();
        }
        break;
      case 'Delete RuleSet':
        const currRuleSet = ruleSetList.find(rs => rs.uid === refId);

        preference.ruleSetList = ruleSetList.filter(
          ruleSet => ruleSet.uid !== refId
        );
        const allCurrentRules = this.findAllCurrentRules(
          currRuleSet,
          ruleList,
          innerRuleSetList
        );
        if (currRuleSet.uid.includes('rs') || currRuleSet.items.length > 0) {
          preference.ruleList = ruleList.filter(
            r => !r.uid.startsWith([currRuleSet.uid])
          );
          preference.innerRuleSetList = innerRuleSetList.filter(
            ir => !ir.uid.startsWith([currRuleSet.uid])
          );
        }

        if (currRuleSet.items.length > 0) {
          preference.ruleList = ruleList.filter(
            item => !allCurrentRules.includes(item.uid)
          );
          preference.innerRuleSetList = innerRuleSetList.filter(
            item => !currRuleSet.items.some(curr => curr.includes(item.uid))
          );
        }

        localStore.set('preference', preference);
        parentRender();
        break;
      default:
        break;
    }
  };

  findAllCurrentRules(currRulesSet, ruleList, innerRuleSetList) {
    let rs;
    let outerRules = [];
    let innerRules = [];
    currRulesSet.items.forEach(element => {
      const rl = this.findOuterRules(element, ruleList);
      if (rl.length === 0) {
        const inr = this.findInnerRules(element, ruleList, innerRuleSetList);
        innerRules = [...inr];
      } else {
        outerRules = [...rl];
      }
    });

    rs = [...innerRules, ...outerRules];
    return rs;
  }

  findOuterRules(ruleId, ruleList) {
    return ruleList.filter(item => item.uid === ruleId).map(item => item.uid);
  }

  findInnerRules(ruleId, ruleList, innerRuleSet) {
    innerRuleSet.map(irs => {
      let rl = this.findOuterRules(ruleId, ruleList);
      if (irs.uid === ruleId && rl.length > 0) {
        rs_inner_rules = [...rs_inner_rules, ...ruleId];
        return rs_inner_rules;
      }
      if (irs.uid === ruleId && irs.items.length > 0 && rl.length === 0) {
        irs.items.forEach(element => {
          const orl = (rl = this.findOuterRules(element, ruleList));
          if (orl.length > 0) {
            return (rs_inner_rules = [...rs_inner_rules, ...orl]);
          } else {
            this.findInnerRules(element, ruleList, innerRuleSet);
          }
        });
      }
    });
    return rs_inner_rules;
  }

  render() {
    let { refId, id } = this.props;
    let { defaultPreferencesRules } =
      localStore.get('preferenceDiscover') || {};

    let preference = localStore.get('preference') || {};
    let { ruleSetList, ruleList, innerRuleSetList } = preference;
    let ruleSet = ruleSetList.find(ruleSet => ruleSet.uid === refId);

    let ruleSetItems = isEmpty(ruleSet) ? [] : ruleSet.items;

    let innerRuleSetGroupIndex = 1;

    let items = isEmpty(ruleSetItems)
      ? []
      : ruleSetItems.map((item, idx) => {
          const ruleItems = ruleList.filter(rule => rule.uid === item);
          if (!isEmpty(ruleItems)) {
            return (
              <Rule
                refId={item}
                parentId={refId}
                parentRender={() => {
                  this.setState({});
                }}
                first={idx === 0}
                last={idx === ruleSetItems.length - 1}
                isSubmitting={this.props.isSubmitting}
              />
            );
          }

          const innerRuleSetItems = innerRuleSetList.filter(
            innerRule => innerRule.uid === item
          );
          if (!isEmpty(innerRuleSetItems)) {
            return (
              <InnerRuleSet
                refId={item}
                ruleSetGroupIndex={`${id + 1}.`}
                innerRuleSetGroupIndex={innerRuleSetGroupIndex++}
                deep={1}
                parentId={refId}
                parentRender={() => {
                  this.setState({});
                }}
                first={idx === 0}
                last={idx === ruleSetItems.length - 1}
                isSubmitting={this.props.isSubmitting}
              />
            );
          }
        });

    let RULE_ACTIONS = [
      { key: 1, text: 'Add Rule', value: 'Add Rule' },
      { key: 2, text: 'Add Inner Rule', value: 'Add Inner Rule' },
      { key: 3, text: 'Move Up', value: 'Move Up', disabled: id === 0 },
      {
        key: 4,
        text: 'Move Down',
        value: 'Move Down',
        disabled: id === ruleSetList.length - 1
      },
      { key: 'Delete RuleSet', text: 'Delete', value: 'Delete RuleSet' }
    ];

    let panes = [
      {
        menuItem: `RuleSet ${id + 1}`,
        render: () => (
          <Tab.Pane>
            {' '}
            <section className="ruleSet">
              <div
                className="ruleSetHeaderAction"
                style={{ zIndex: `${100000 - preferenceZIndex(refId)}` }}
              >
                <span />
                <span>
                  <Dropdown
                    style={{ width: '200px' }}
                    fluid
                    selection
                    text="Actions"
                    options={RULE_ACTIONS}
                    selectOnBlur={false}
                    value={null}
                    onChange={this.selectActionHandler}
                  />
                </span>
              </div>

              <div>{isEmpty(items) ? '' : items}</div>

              <div className="defaultPreferencesRuleType">
                <span className="mr-5px">
                  Select an additional default preference
                </span>
                <Select
                  placeholder="Select default preference"
                  options={defaultPreferencesRules}
                  closeOnBlur
                  value={
                    isEmpty(ruleSet) ? '' : ruleSet.defaultPreferenceRuleType
                  }
                  onChange={this.onSelectDefaultRuleType}
                  style={{
                    border:
                      isEmpty(ruleSet.defaultPreferenceRuleType) &&
                      this.props.isSubmitting
                        ? '1px solid red'
                        : '',
                    zIndex: `${100000 - preferenceZIndex(refId) - 99}`
                  }}
                />
                {isEmpty(ruleSet.defaultPreferenceRuleType) &&
                  this.props.isSubmitting && (
                    <div style={{ color: 'red', paddingRight: '8%' }}>
                      This field is required
                    </div>
                  )}
              </div>
            </section>
          </Tab.Pane>
        )
      }
    ];

    return <Tab panes={panes} style={innerRuleSetStyle} />;
  }
}

const innerRuleSetStyle = {
  width: '100%',
  marginBottom: '55px'
};

export default RuleSet;
