import { gql } from 'react-apollo';

const vinBlacklistsQuery = gql`
  query vinBlacklistsQuery($vin: String, $size: Int, $offset: Int) {
    vinBlacklists(vin: $vin, offset: $offset, size: $size) {
      id
      vin
      expirationDate
      notes
      createdAt
      status
    }
  }
`;

export default vinBlacklistsQuery;
