import { gql } from 'react-apollo';

const resetApiTokenMutation = gql`
  mutation adminUserResetToken($input: AdminUserResetApiTokenInput!) {
    adminUserResetToken(input: $input) {
      success
      errors
    }
  }
`;

export default resetApiTokenMutation;
