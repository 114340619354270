import { gql } from 'react-apollo';

const enrollmentStatusQuery = gql`
  query enrollmentStatus($auctionAccessNumber: String!) {
    enrollmentStatus(auctionAccessNumber: $auctionAccessNumber) {
      name
      auctionAccessNumber
      email
      phone
      enrollments {
        dealerNo
        name
        miles
        productType
        groupName
        productName
        productId
        rate
        closedFactoryExclusion
        days
        currentlyEnrolled
        recommended
        networkplus
        networkplusRate
        exceptionsProductName
        daysPsi
        basePriceDs
        localLinkedFlag
        incompleteEnrollment {
          id
          dealerNo
          productName
          flatRateOfferBatchId
          dealershipName
          price
          sendAt
          basePriceDs
          closedFactoryExclusion
        }
      }
      eligibleOffers {
        dealerNo
        rate
        miles
        days
        productName
        productId
        exceptionsProductName
        daysPsi
        basePriceDs
        localLinkedFlag
        incompleteEnrollment {
          id
          dealerNo
          dealershipName
          productName
          flatRateOfferBatchId
          price
          sendAt
          basePriceDs
          closedFactoryExclusion
        }
        groupName
        productType
      }
    }
  }
`;

export default enrollmentStatusQuery;
