import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { decode as jwtDecode } from 'jwt-simple';
import localStore from 'store';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';

import sessionTokenQuery from '../graphql/sessionTokenQuery';
import client from '../apollo';
import APP_PATH from '../constants/paths';
import viewerQuery from '../graphql/viewerQuery';
import { setUser, clearUser } from '../data/user/actions';
import { setNextPath } from '../data/nextPath/actions';
import { setAuthToken, resetAuthToken } from '../data/authToken/actions';
import URLPATH from '../constants/urlPaths';

const requireAuth = ComposedComponent => {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    isLoggedOut() {
      const nowInSeconds = Math.round(new Date().getTime() / 1000);
      let token = localStore.get('jwt');
      const linkToken = this.props.match.params.token;
      if (!isEmpty(linkToken)) {
        token = linkToken;
      }
      if (isEmpty(token)) {
        return true;
      }
      const decoded = jwtDecode(token, null, true);
      const exp = decoded.exp;
      return nowInSeconds > exp;
    }

    refetchUser() {
      const { signInUser } = this.props;
      client
        .query({
          query: viewerQuery
        })
        .then(({ data }) => {
          const user = get(data, 'viewer.user');
          signInUser(user);
        });
    }

    setRedirect() {
      const appPath = this.props.location.pathname;
      if (this.validatePath(appPath)) {
        localStorage.setItem('external_path', appPath);
      }
    }

    validatePath = path => {
      if (!isEmpty(path)) {
        const regex = /([^/].+?(?=\/|$))/;
        const regexResult = regex.exec(path);
        if (
          !isEmpty(regexResult) &&
          URLPATH.PATH.includes(regexResult[0].toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    };

    componentDidUpdate(prevProps) {
      if (
        prevProps.data.sessionToken !== this.props.data.sessionToken &&
        !isEmpty(this.props.match) &&
        !isEmpty(this.props.match.params)
      ) {
        const {
          data: { sessionToken },
          signInUser
        } = this.props;
        if (!isEmpty(sessionToken) && !isEmpty(sessionToken.viewer)) {
          signInUser(sessionToken.viewer.user);
          localStore.set('jwt', sessionToken.accessToken);
          localStore.set('tokenUser', true);
          this.props.setAuthTokenAvailability({});
        }
      }
    }

    componentWillMount() {
      let token = localStore.get('jwt');
      const params = this.props.match.params;
      const noTokenCondition =
        isEmpty(params) || (!isEmpty(params) && isEmpty(params.token));

      const authToken =
        isEmpty(token) && !isEmpty(params) && !isEmpty(params.token);

      if (
        !this.props.authenticated &&
        !(token === undefined) &&
        noTokenCondition
      ) {
        this.refetchUser();
      }
      if (authToken) {
        token = params.token;
      }

      if (
        (!this.props.authenticated &&
          token === undefined &&
          noTokenCondition) ||
        this.isLoggedOut() ||
        (this.props.authToken && noTokenCondition)
      ) {
        if (!window.location.href.includes('loginCallback')) {
          this.props.setNextPath(this.props.history.location.pathname);
          this.setRedirect();
          this.props.history.push(APP_PATH.LOGIN);
        } else {
          this.props.setNextPath(APP_PATH.REDIRECT_URL);
        }
      }
    }

    componentWillUpdate(nextProps, nextState) {
      if (
        ((!isEmpty(nextState) && isEmpty(nextState.data.user)) ||
          this.isLoggedOut()) &&
        (isEmpty(nextProps.match.params) ||
          (!isEmpty(nextProps.match.params) &&
            isEmpty(nextProps.match.params.token)))
      ) {
        this.props.history.push(APP_PATH.LOGIN);
      }
    }

    componentDidMount() {
      const params = this.props.match.params;
      const tokenUser = localStore.get('tokenUser');
      const noTokenCondition =
        isEmpty(params) || (!isEmpty(params) && isEmpty(params.token));

      if ((this.props.authToken || tokenUser) && noTokenCondition) {
        this.props.signOutUser();
        client.resetStore();
        localStore.remove('jwt');
        localStore.remove('tokenUser');
        this.props.resetAuthTokenAvailability({});
        this.props.history.push(APP_PATH.LOGIN);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    const { user, authToken } = state.data;
    return {
      authenticated: !isEmpty(user),
      authToken: isEmpty(authToken) ? false : authToken
    };
  };

  const mapDispatchToProps = dispatch => ({
    signInUser: user => {
      dispatch(setUser(user));
    },
    signOutUser: () => {
      dispatch(clearUser());
    },
    setNextPath: pathName => {
      dispatch(setNextPath(pathName));
    },
    setAuthTokenAvailability: val => {
      dispatch(setAuthToken());
    },
    resetAuthTokenAvailability: val => {
      dispatch(resetAuthToken());
    }
  });

  const data = graphql(sessionTokenQuery, {
    options: props => {
      let token = null;
      const params = props.match.params;
      if (isEmpty(props.user) && !isEmpty(params.token)) {
        token = params.token;
      }

      return {
        variables: { token: token },
        fetchPolicy: 'network-only'
      };
    }
  });

  return compose(
    data,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(Authentication);
};

export default requireAuth;
