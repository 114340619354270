import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import doc from '../assets/doc.png';

import ReactDropzone from 'react-dropzone';
import axios from 'axios';
import localStore from 'store';
import { isEmpty } from 'lodash';
import { Message, Image, Button, Label } from 'semantic-ui-react';

import OfferUpgradesList from '../views/OfferUpgrades/List';
import offerUpgradesQuery from '../graphql/offerUpgradesQuery';
import { setPage } from '../data/page/actions';

class OfferUpgrades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      flashMessage: null,
      allFiles: [],
      files: []
    };
  }

  onDrop = files => {
    this.setState({ selectedFile: { name: files[0].name, file: files[0] } });
  };

  saveFile = () => {
    const { selectedFile } = this.state;

    if (!isEmpty(selectedFile)) {
      this.uploadImage(selectedFile.file);
    }
  };

  uploadImage = file => {
    const formData = new FormData();
    const token = localStore.get('jwt');

    formData.append('file', file);
    formData.append('model', 'OfferUpgrade');
    formData.append('AUTHORIZATION', token ? `${token}` : null);

    axios
      .post(process.env.REACT_APP_UPLOAD_API_URL, formData)
      .then(() => {
        this.setState({
          flashMessage: 'Successfully Uploaded file and queued for processing',
          selectedFile: null
        });
        this.props.data.refetch();
        this.props.setPage({});
      })
      .catch(e => {});
  };

  render() {
    const { data: { offerUpgrades } } = this.props;
    const allFiles = offerUpgrades;
    const { flashMessage, selectedFile } = this.state;

    const thumb = {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 2,
      marginRight: 2,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box'
    };

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };

    const img = {
      display: 'block',
      width: 'auto',
      height: '100%'
    };

    return (
      <div className="drop-zone">
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}
        <ReactDropzone
          accept=".csv"
          onDrop={this.onDrop}
          multiple={false}
          className="react-drop-zone react-drop-zone-small"
        >
          Drop Offer Upgrade Document (CSV File) here!!
        </ReactDropzone>

        <Button color="green" onClick={this.saveFile} inverted>
          {' '}
          Save
        </Button>
        <br />
        {selectedFile && (
          <Label>
            <div style={thumb}>
              <div style={thumbInner}>
                <Image src={doc} style={img} />
              </div>
            </div>
            <br />
            {selectedFile.name}
          </Label>
        )}
        <br />
        <br />
        <OfferUpgradesList allFiles={allFiles} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPage: fields => {
    dispatch(setPage());
  }
});

export default compose(
  withRouter,
  withPagination(offerUpgradesQuery, 'offerUpgrades'),
  LoadingDataEnhancer,
  connect(mapDispatchToProps)
)(OfferUpgrades);
