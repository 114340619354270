import { gql } from 'react-apollo';

const deleteFlatRateOfferBatchMutation = gql`
  mutation deleteFlatRateOfferBatch($input: DeleteFlatRateOfferBatchInput!) {
    deleteFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default deleteFlatRateOfferBatchMutation;
