import { gql } from 'react-apollo';

const dealerAccountsQuery = gql`
  query($dealerNumber: String) {
    dealerAccounts(dealerNumber: $dealerNumber) {
      id
      name
      state
      accountNotifications {
        notifyStateReassignment {
          active
          content
          name
        }
      }
    }
  }
`;

export default dealerAccountsQuery;
