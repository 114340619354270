import { gql } from 'react-apollo';

const reinstateReturnInvoiceMutation = gql`
  mutation reinstateReturnInvoice($input: ReinstateReturnInvoiceInput!) {
    reinstateReturnInvoice(input: $input) {
      success
      errors
    }
  }
`;

export default reinstateReturnInvoiceMutation;
