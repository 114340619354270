import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import { reducer as formReducer } from 'redux-form';

import client from './apollo';
import dataReducer from './data/reducer';

const devTools =
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

const initialState = {};

const appReducer = combineReducers({
  data: dataReducer,
  apollo: client.reducer(),
  form: formReducer
});

let enhancer = compose();

if (process.env.NODE_ENV !== 'production') {
  enhancer = compose(applyMiddleware(client.middleware(), logger), devTools);
}

const store = createStore(appReducer, initialState, enhancer);

export default store;
