import { gql } from 'react-apollo';

const vraReasonsQuery = gql`
  query vraReasonsQuery {
    vraReasons {
      active
      groupId
      id
      orderId
      returnReason
    }
  }
`;

export default vraReasonsQuery;
