import gql from 'graphql-tag';

const updateCustomerNotesMutation = gql`
  mutation updateCustomerNotes(
    $UpdateCustomerNotesInput: UpdateCustomerNotesInput!
  ) {
    updateCustomerNotes(input: $UpdateCustomerNotesInput) {
      success
      msg
    }
  }
`;

export default updateCustomerNotesMutation;
