import React from 'react';
import { Table } from 'semantic-ui-react';

const ListRow = () => allFiles => {
  const { id, inputFile, status, inputFileName } = allFiles;

  return (
    <Table.Row key={id}>
      <Table.Cell className="table-field">
        <a href={inputFile} target="_blank">
          {inputFileName}
        </a>{' '}
      </Table.Cell>
      <Table.Cell className="table-field">{status}</Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
