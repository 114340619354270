import React, { Component } from 'react';
import Form from './Form';

class New extends Component {
  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    return (
      <Form
        isModalOpen={isModalOpen}
        onCloseHandler={onCloseHandler}
        onSubmitHandler={onSubmitHandler}
        error={error}
        submitting={submitting}
        action="new"
      />
    );
  }
}

export default New;
