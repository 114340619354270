import { gql } from 'react-apollo';

const approveExternalVehiclesLoadMutation = gql`
  mutation approveExternalVehiclesLoad($id: Int, $secondaryApproval: Boolean) {
    approveExternalVehiclesLoad(
      input: { id: $id, secondaryApproval: $secondaryApproval }
    ) {
      success
      errors
    }
  }
`;

export default approveExternalVehiclesLoadMutation;
