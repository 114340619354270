import { gql } from 'react-apollo';

const createFlatRateOfferMutation = gql`
  mutation createFlatRateOfferBatch(
    $flatRateOfferInput: CreateFlatRateOfferBatchInput!
  ) {
    createFlatRateOfferBatch(input: $flatRateOfferInput) {
      success
      errors
    }
  }
`;

export default createFlatRateOfferMutation;
