import React from 'react';
import { Table, Dropdown, Icon, Checkbox } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

const ListRow = (
  actionHandler,
  bulkSelectHandler,
  isEligibleForBulkUpdate
) => pendingGuarantee => {
  let notPending = pendingGuarantee.guaranteeStatus !== 'pending';
  const rowMenuOptions = [
    { key: 1, text: 'Details', value: 'Details' },
    { key: 3, text: 'Allow', value: 'Allow', disabled: notPending },
    { key: 4, text: 'Update ODS Attributes', value: 'UpdateOdsAttributes' }
  ];

  const {
    id,
    vehicleFullDescription,
    purchasePrice,
    guaranteePrice,
    ifBid,
    updatedAt,
    emails,
    pendingReasonNotice,
    auctionInformation: { auctionAccessNumber, dealerNumber, universalNumber },
    vehicleInformation: { odometerReading, vin },
    guaranteePurchaseId,
    guaranteeStatus
  } = pendingGuarantee;

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, pendingGuarantee);
  };

  const handleCheckboxchange = e => {
    e.preventDefault();
    bulkSelectHandler(id, guaranteePurchaseId);
  };
  const formatedEmails = isEmpty(emails)
    ? null
    : `${emails.replace(/,/g, ', ')}`;

  return (
    <Table.Row key={id}>
      <Table.Cell>
        <Checkbox
          checked={isEligibleForBulkUpdate}
          onChange={handleCheckboxchange}
        />
      </Table.Cell>
      <Table.Cell>{dealerNumber}</Table.Cell>
      <Table.Cell>{auctionAccessNumber}</Table.Cell>
      <Table.Cell>{universalNumber}</Table.Cell>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell>{vehicleFullDescription}</Table.Cell>
      <Table.Cell>{guaranteeStatus}</Table.Cell>
      <Table.Cell>{purchasePrice}</Table.Cell>
      <Table.Cell>{odometerReading}</Table.Cell>
      <Table.Cell>{guaranteePrice}</Table.Cell>
      <Table.Cell>{updatedAt}</Table.Cell>
      <Table.Cell>{formatedEmails}</Table.Cell>
      <Table.Cell>{pendingReasonNotice}</Table.Cell>
      <Table.Cell>
        {ifBid && <Icon color="green" name="checkmark" size="large" />}
        {!ifBid && <Icon color="red" name="close" size="large" />}
      </Table.Cell>

      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
