import React from 'react';
import { Header } from 'semantic-ui-react';

const NotFound = () => (
  <div>
    <Header as="h2">Page Not Found</Header>
    <p>The page you are trying to reach is not available.</p>
  </div>
);

export default NotFound;
