import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import moment from 'moment';

const ListRow = () => (dealer, index) => {
  let rowMenuOptions = [
    { key: 1, text: 'Assign/Unassign group', value: 'Assign/Unassign group' }
  ];

  const {
    customerNumber,
    customerName,
    customerEmail,
    customerPhone,
    address1,
    customerCity,
    customerState,
    customerZip,
    groupId,
    groupName,
    createdAt,
    updatedAt
  } = dealer;

  return (
    <Table.Row key={index}>
      <Table.Cell>{customerNumber}</Table.Cell>
      <Table.Cell>{customerName}</Table.Cell>
      <Table.Cell>{customerEmail}</Table.Cell>
      <Table.Cell>{customerPhone}</Table.Cell>
      <Table.Cell>{`${address1}, ${customerCity}, ${customerState}, ${customerZip}`}</Table.Cell>
      <Table.Cell>{groupId}</Table.Cell>
      <Table.Cell>{groupName}</Table.Cell>
      <Table.Cell>{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
      <Table.Cell>{moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
