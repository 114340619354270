import React from 'react';
import { Grid, Label, Accordion, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import APP_PATH from '../../constants/paths';

const OriginalPurchaseTransaction = ({
  vehicleRecord,
  formatField,
  formatDate,
  isAuctionEdgeLocation,
  activeIndex,
  handleClick
}) => {
  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(0)}
        index={0}
        onClick={handleClick}
        className={'accordion-title'}
      >
        Original Purchase Transaction
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(0)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>VIN</strong>
              <Label basic size="medium">
                {formatField('vin')}
              </Label>
              <br />
              {isAuctionEdgeLocation && (
                <Link
                  to={{
                    pathname: APP_PATH.EXTERNAL_TRANSACTIONS,
                    state: { vin: formatField('vin') }
                  }}
                >
                  Real-Time External Transactions
                </Link>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Buyer Number</strong>
              <Label basic>{formatField('buyer5mil')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Buyer Name</strong>
              <Label basic>{formatField('origVehBuyerName')}</Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Purchase Location</strong>
              <Label basic>
                {formatField('locationCode')}{' '}
                {formatField('origLocationName') &&
                  ' - ' + formatField('origLocationName')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Seller Number</strong>
              <Label basic>{formatField('seller5mil')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Seller Name</strong>
              <Label basic>{formatField('origVehSellerName')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Guarantee Date</strong>
              <Label basic>{formatDate('dateDS')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Guarantee Expiration Date</strong>
              <Label basic>{formatDate('expireDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Guarantee Cancel Date</strong>
              <Label basic>{formatDate('guaranteeCancelledDate')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>VRA Initiated Date</strong>
              <Label basic>{formatDate('vraCreatedDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>VRA Reinstated Date</strong>
              <Label basic></Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Transaction Status</strong>
              <Label basic></Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>VRA Number</strong>
              <Label basic size="medium">
                {formatField('vra')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>VRA Cancel Date</strong>
              <Label basic size="medium">
                {formatDate('vraCancelledDate')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Arbitration Status</strong>
              <Label basic>{formatField('arbitrationStatus')}</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Sales Channel</strong>
              <Label basic>{formatField('origVehSalesChannel')}</Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default OriginalPurchaseTransaction;
