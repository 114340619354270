import * as actionType from './actionTypes';

const initialState = [];

const duplicateEntryVehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_DUPE_ENTRY_VEHICLE:
      return action.payload;

    case actionType.REMOVE_DUPE_ENTRY_VEHICLE:
      let newStateArray = state.filter(
        item =>
          !(
            item.vin === action.payload.vin &&
            item.dealerNumber === action.payload.dealerNumber
          )
      );
      return newStateArray;
    case actionType.CLEAR_DUPE_ENTRY_VEHICLE:
      return [];
    default:
      return state;
  }
};

export default duplicateEntryVehicleReducer;
