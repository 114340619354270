import { gql } from 'react-apollo';

const returnInvoiceQuery = gql`
  query returnInvoice($id: ID) {
    returnInvoice(id: $id) {
      id
      guaranteePurchaseId
      reinstate
      returningUser {
        username
      }
      expirationDate
      transaction {
        id
        purchasedAt
        universalNo
        expiresAt
        receiveDate
        locationName
        vin
        dealerNumber
        dealershipName
        odometer
        vehicleReceived
        titleReceived
        refundProcessed
        returnInitiatedAt
        purchasePrice
        milesSelected
        year
        make
        model
        returnLocation {
          initials
          name
        }
      }
      pendingArbitration
      selectedReasons
    }
  }
`;

export default returnInvoiceQuery;
