import { gql } from 'react-apollo';

const updateFeatureFlagMutation = gql`
  mutation updateFeatureFlag($input: UpdateFeatureFlagMutationInput!) {
    updateFeatureFlag(input: $input) {
      success
      errors
    }
  }
`;

export default updateFeatureFlagMutation;
