import { gql } from 'react-apollo';

const networkPlusOfferAcceptMutation = gql`
  mutation acceptNetworkPlusOffer($id: ID!) {
    acceptNetworkPlusOffer(input: { id: $id }) {
      success
      errors
    }
  }
`;

export default networkPlusOfferAcceptMutation;
