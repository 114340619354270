import * as actionType from './actionTypes';

const initialState = {};

const searchUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_SEARCH_USER:
      return action.payload;
    case actionType.CLEAR_SEARCH_USER:
      return {};
    default:
      return state;
  }
};

export default searchUserReducer;
