import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Message, Button, Icon, Form } from 'semantic-ui-react';
import { reduxForm, FieldArray } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import APP_PATH from '../../constants/paths';

import RenderRow from './EditVehicleFieldRow';
import ViewOrRemoveAttachments from './ViewOrRemoveAttachments';

class EditExternalVehicleBatch extends Component {
  state = {
    submitError: '',
    submitSuccess: ''
  };

  onKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  handleDismiss() {
    this.setState({
      submitSuccess: '',
      submitError: ''
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const {
      externalVehicles,
      onApproveHandler,
      onRejectHandler,
      onUpdateHandler,
      externalVehicleDocuments
    } = this.props;

    const rowProps = {
      onApproveHandler,
      onRejectHandler,
      onUpdateHandler
    };
    const batchId =
      externalVehicles && externalVehicles[0]
        ? externalVehicles[0].externalVehiclesBatchId
        : 0;
    const batch = {
      externalVehicleDocuments: externalVehicleDocuments
    };

    return (
      <div>
        <h2>Network+ Vehicles for Batch - {batchId}</h2>
        <Link
          to={{
            pathname: APP_PATH.EXTERNAL_VEHICLE_BATCHES,
            state: { refreshSearch: false }
          }}
        >
          <Button color="yellow" style={{ float: 'right', marginTop: '-3%' }}>
            Back
          </Button>
        </Link>

        <br />
        {/*<h4>Import Data: (XLSX,XLS,CSV)</h4>*/}
        <h4>
          Upload Documentation &nbsp;&nbsp;<Button
            icon
            size="mini"
            color="grey"
            labelPosition="left"
          >
            <Icon name="file" /> Add Document
          </Button>
        </h4>

        {externalVehicleDocuments && <ViewOrRemoveAttachments batch={batch} />}

        <br />
        {this.state.submitError && (
          <Message
            error
            content={this.state.submitError}
            onDismiss={this.handleDismiss}
          />
        )}
        {this.state.submitSuccess && (
          <Message
            positive
            content={this.state.submitSuccess}
            onDismiss={this.handleDismiss}
          />
        )}
        <Form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress}>
          <FieldArray name="vehicles" component={RenderRow} props={rowProps} />
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const existingVehicles = { vehicles: ownProps.externalVehicles };
  return {
    initialValues: existingVehicles
  };
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'npEditForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true
  })
)(EditExternalVehicleBatch);
