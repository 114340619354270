import { gql } from 'react-apollo';

const rejectExternalVehiclesLoadMutation = gql`
  mutation rejectExternalVehiclesLoad(
    $id: Int
    $rejectionComment: String
    $secondaryApproval: Boolean
    $secondaryRejectionComment: String
  ) {
    rejectExternalVehiclesLoad(
      input: {
        id: $id
        rejectionComment: $rejectionComment
        secondaryApproval: $secondaryApproval
        secondaryRejectionComment: $secondaryRejectionComment
      }
    ) {
      success
      errors
    }
  }
`;

export default rejectExternalVehiclesLoadMutation;
