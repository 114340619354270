import React from 'react';
import { Grid, Label, Accordion, Icon } from 'semantic-ui-react';

const ReturnInformation = ({
  vehicleRecord,
  formatField,
  formatDate,
  activeIndex,
  handleClick
}) => {
  const checked_in_status =
    vehicleRecord.locationCode === vehicleRecord.returnVehLocation
      ? 'black-font'
      : 'red-font';

  return (
    <div className={'accordion-div'}>
      <Accordion.Title
        active={activeIndex.includes(3)}
        index={3}
        onClick={handleClick}
        className={'accordion-title'}
      >
        Return Information
        <Icon name="dropdown" className={'right-icon'} />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex.includes(3)}
        className={'accordion-content'}
      >
        <Grid columns={3} divided className="vst-grid">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vehicle Check In Status</strong>
              <Label basic>{formatField('returnCheckInStatus')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vehicle Checked In Date</strong>
              <Label basic>{formatDate('returnCheckInDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Checked In Location</strong>
              <Label basic className={checked_in_status}>
                {formatField('returnVehLocation')}
              </Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Checked in Seller Number/Name</strong>
              <Label basic>
                {formatField('returnSeller5mil')}{' '}
                {formatField('returnSellerName') &&
                  ' - ' + formatField('returnSellerName')}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Meridian Title Status/Title Received</strong>
              <Label basic>{formatField('returnVehTitleStatus')}</Label>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Vault Status</strong>
              <Label basic>{formatField('returnVehVaultStatus')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Received Date</strong>
              <Label basic>{formatDate('returnVehTitleRecdDate')}</Label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <strong>Title Validated Date</strong>
              <Label basic>{formatDate('returnVehTitleValdDate')}</Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </div>
  );
};

export default ReturnInformation;
