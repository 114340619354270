import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Table, Modal, Message, Button } from 'semantic-ui-react';
import { map, isEmpty } from 'lodash';
import { addDupeExternalVehicleIndex } from '../../data/duplicateExternalVehicleIndex/actions';

class DuplicateExternalVehicleModal extends Component {
  state = {
    message: ''
  };

  removeDuplicateExternalVehicle = (vin, dealerNumber) => event => {
    const { values, dispatch } = this.props;
    let vehicles = values.vehicles;
    vehicles.filter(
      (item, index) => !(item.vin === vin && item.dealerNo === dealerNumber)
    );

    vehicles.map((item, index) => {
      if (item.vin === vin && item.dealerNo === dealerNumber) {
        dispatch(
          addDupeExternalVehicleIndex({
            vin: item.vin,
            dealerNumber: item.dealerNo,
            index: index
          })
        );
        this.setState({
          message: 'Deleted the record successfully with VIN ' + item.vin
        });
      }
      return {};
    });
  };

  render() {
    const {
      onDupeExternalVehicleModalCloseHandler,
      isDupeExternalVehicleModalOpen,
      duplicateExternalVehicles,
      keepDuplicateExternalVehicle,
      keepDuplicateExternalVehicleMessage,
      values: { vehicles }
    } = this.props;

    const { message } = this.state;
    let duplicateVehiclesToShow = [];
    if (!isEmpty(duplicateExternalVehicles) && !isEmpty(vehicles)) {
      map(duplicateExternalVehicles, vehicle => {
        let existingVehicle = vehicles.filter(
          item =>
            item.vin === vehicle.vin && item.dealerNo === vehicle.dealerNumber
        );
        if (!isEmpty(existingVehicle)) {
          duplicateVehiclesToShow.push(vehicle);
        }
      });
    }

    const TableHeader = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>VIN</Table.HeaderCell>
          <Table.HeaderCell>Dealer No</Table.HeaderCell>
          <Table.HeaderCell>Dealer Name</Table.HeaderCell>
          <Table.HeaderCell>Auction Name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );

    return (
      <Modal
        open={isDupeExternalVehicleModalOpen}
        closeIcon
        onClose={onDupeExternalVehicleModalCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
        size={'large'}
      >
        <Modal.Header>
          A Network+ vehicle already exists for the below VINs:
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {!isEmpty(keepDuplicateExternalVehicleMessage) && (
              <Message positive>
                <p>{keepDuplicateExternalVehicleMessage}</p>
              </Message>
            )}
            {!isEmpty(message) && (
              <Message positive>
                <p>{message}</p>
              </Message>
            )}
            <Table celled singleLine verticalAlign="top">
              {TableHeader}
              <Table.Body>
                {map(duplicateVehiclesToShow, vehicle => (
                  <Table.Row key={vehicle.vin}>
                    <Table.Cell>{vehicle.vin}</Table.Cell>
                    <Table.Cell>{vehicle.dealerNumber}</Table.Cell>
                    <Table.Cell>{vehicle.dealerName}</Table.Cell>
                    <Table.Cell>{vehicle.auctionName}</Table.Cell>
                    <Table.Cell>{vehicle.status}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={keepDuplicateExternalVehicle(
                          vehicle.vin,
                          vehicle.dealerNumber
                        )}
                      >
                        Continue
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={this.removeDuplicateExternalVehicle(
                          vehicle.vin,
                          vehicle.dealerNumber
                        )}
                      >
                        Delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    data: { duplicateExternalVehicles },
    form: {
      npForm: { values }
    }
  } = state;
  return {
    duplicateExternalVehicles,
    values
  };
};

export default compose(connect(mapStateToProps))(DuplicateExternalVehicleModal);
