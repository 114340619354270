import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class ExceptionsList extends Component {
  render() {
    const { onSelectException, exceptions, exceptionType } = this.props;
    const listRow = ListRow(onSelectException, exceptionType);

    const dealerHeaderRow = (
      <Table.Row>
        <Table.HeaderCell>Dealer Name</Table.HeaderCell>
        <Table.HeaderCell>Dealer Number</Table.HeaderCell>
        <Table.HeaderCell>Grace Period</Table.HeaderCell>
        <Table.HeaderCell>Exception End Date</Table.HeaderCell>
        <Table.HeaderCell>Created By</Table.HeaderCell>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.HeaderCell>Last Updated By</Table.HeaderCell>
        <Table.HeaderCell>Last Updated At</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );
    const groupHeaderRow = (
      <Table.Row>
        <Table.HeaderCell>Group Name</Table.HeaderCell>
        <Table.HeaderCell>Group ID</Table.HeaderCell>
        <Table.HeaderCell>Grace Period</Table.HeaderCell>
        <Table.HeaderCell>Exception End Date</Table.HeaderCell>
        <Table.HeaderCell>Created By</Table.HeaderCell>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.HeaderCell>Last Updated By</Table.HeaderCell>
        <Table.HeaderCell>Last Updated At</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );
    const auctionHeaderRow = (
      <Table.Row>
        <Table.HeaderCell>Auction Location</Table.HeaderCell>
        <Table.HeaderCell>Grace Period</Table.HeaderCell>
        <Table.HeaderCell>Exception Reason</Table.HeaderCell>
        <Table.HeaderCell>Exception End Date</Table.HeaderCell>
        <Table.HeaderCell>Created By</Table.HeaderCell>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.HeaderCell>Last Updated By</Table.HeaderCell>
        <Table.HeaderCell>Last Updated At</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    const headerRow = () => {
      switch (exceptionType) {
        case 'DEALER':
          return dealerHeaderRow;
        case 'GROUP':
          return groupHeaderRow;
        case 'AUCTION':
          return auctionHeaderRow;
      }
    };
    const headerTitle = () => {
      switch (exceptionType) {
        case 'DEALER':
          return 'Dealer Exceptions';
        case 'GROUP':
          return 'Group Exceptions';
        case 'AUCTION':
          return 'Auction Exceptions';
      }
    };

    return (
      <div>
        <Header>{headerTitle()}</Header>
        <div>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={exceptions}
            headerRow={headerRow()}
            size="small"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(ExceptionsList);
