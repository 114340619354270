import { gql } from 'react-apollo';

const featureFlagQuery = gql`
  query featureFlagQuery($size: Int, $offset: Int) {
    featureFlags(offset: $offset, size: $size) {
      id
      description
      flagName
      checked
    }
  }
`;

export default featureFlagQuery;
