import { gql } from 'react-apollo';

const decodeVinQuery = gql`
  query decodeVinQuery($vin: String!) {
    decodeVin(vin: $vin) {
      vin
      make
      model
      year
      errors
    }
  }
`;

export default decodeVinQuery;
