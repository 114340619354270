import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class DealershipBlacklistsList extends Component {
  render() {
    const { onSelectDealershipBlacklist, dealershipBlacklists } = this.props;
    const listRow = ListRow(onSelectDealershipBlacklist);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Dealer Number</Table.HeaderCell>
        <Table.HeaderCell>Expiration Date</Table.HeaderCell>
        <Table.HeaderCell>Notes</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Dealership Blacklist</Header>
        <div>
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={dealershipBlacklists}
            headerRow={headerRow}
            size="tiny"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(DealershipBlacklistsList);
