import React, { Component } from 'react';

import {
  Form,
  Button,
  Icon,
  Select,
  Checkbox,
  TextArea
} from 'semantic-ui-react';
import './preferencesStyle.css';

import { isEmpty } from 'lodash';
import localStore from 'store';
import PreferencePatterns from '../../../constants/PreferencePatterns';
import { isValidFacts } from '../../../utilities/preferenceUtil';
import { returnErrMsg } from '../../../utilities/preferenceUtil';

let ruleTypeSelected = false;
class Rule extends Component {
  state = {
    operatorRuleType: '',
    operatorType: '',
    facts: [],
    errors: {}
  };

  swapHandle = (arr, idx1, idx2) => {
    let temp = arr[idx1];
    arr[idx1] = arr[idx2];
    arr[idx2] = temp;
  };

  onChangeHandler = event => {
    event.preventDefault();

    const { refId } = this.props;
    let preference = localStore.get('preference');
    let { ruleList } = preference;
    let ruleItem = ruleList.find(rule => rule.uid === refId);
    const factValue = event.target.value;
    ruleItem.facts = !isEmpty(factValue)
      ? factValue.split(',').map(item => item)
      : [];
    ruleTypeSelected = false;
    localStore.set('preference', preference);
    this.setState({});
  };

  componentDidMount() {
    const { parentRender } = this.props;

    parentRender();
  }

  onSelectHandler = field => (e, data) => {
    e.preventDefault();

    const { refId } = this.props;
    let preference = localStore.get('preference');
    let { ruleList } = preference;
    let ruleItem = ruleList.find(rule => rule.uid === refId);

    if (field === 'ruleType' && !isEmpty(data.value)) {
      ruleItem[field] = data.value;
      ruleItem['operatorType'] = '';
      ruleItem['facts'] = '';
      ruleTypeSelected = true;
    } else {
      ruleItem['operatorType'] = data.value;
    }

    localStore.set('preference', preference);
    this.setState({});
  };

  onCheckHandler = (event, data) => {
    event.preventDefault();

    const { refId } = this.props;
    let preference = localStore.get('preference');
    let { ruleList } = preference;
    let ruleItem = ruleList.find(rule => rule.uid === refId);

    ruleItem.include = data.checked;
    localStore.set('preference', preference);
    this.setState({});
  };

  onDeleteHandler = event => {
    event.preventDefault();

    const { refId, parentId, parentRender } = this.props;
    let preference = localStore.get('preference');
    let { ruleSetList, innerRuleSetList, ruleList } = preference;

    let parent = ruleSetList.find(r => r.uid === parentId);
    if (isEmpty(parent)) {
      parent = innerRuleSetList.find(r => r.uid === parentId);
    }

    parent.items = parent.items.filter(item => item !== refId);
    preference.ruleList = ruleList.filter(rule => rule.uid !== refId);
    localStore.set('preference', preference);
    parentRender();
  };

  onMoveUpHandler = event => {
    event.preventDefault();

    const { refId, parentId, parentRender } = this.props;
    let preference = localStore.get('preference');
    let { ruleSetList, innerRuleSetList } = preference;

    let parent = ruleSetList.find(r => r.uid === parentId);
    if (isEmpty(parent)) {
      parent = innerRuleSetList.find(r => r.uid === parentId);
    }

    let items = parent.items;
    this.swapHandle(items, items.indexOf(refId), items.indexOf(refId) - 1);
    localStore.set('preference', preference);
    parentRender();
  };

  onMoveDownHandler = event => {
    event.preventDefault();

    const { refId, parentId, parentRender } = this.props;
    let preference = localStore.get('preference');
    let { ruleSetList, innerRuleSetList } = preference;

    let parent = ruleSetList.find(r => r.uid === parentId);
    if (isEmpty(parent)) {
      parent = innerRuleSetList.find(r => r.uid === parentId);
    }

    let items = parent.items;
    this.swapHandle(items, items.indexOf(refId), items.indexOf(refId) + 1);
    localStore.set('preference', preference);
    parentRender();
  };

  render() {
    const { errors } = this.state;
    const { refId, first, last, actionSelected } = this.props;
    const preference = localStore.get('preference') || {};
    const { ruleList } = preference;
    const ruleItem = ruleList.filter(
      rule => !isEmpty(refId) && rule.uid === refId
    )[0];

    const preferenceDiscover = localStore.get('preferenceDiscover');
    const { rules } = preferenceDiscover;

    let operatorRule =
      preferenceDiscover.operatorTags.find(discover => {
        return discover.ruleType === ruleItem.ruleType;
      }) || [];

    let operators =
      !isEmpty(operatorRule) &&
      operatorRule.value.map(val => ({
        key: val,
        text: val,
        value: val
      }));

    const { ruleType, operatorType, include, facts } = ruleItem || {};

    return (
      !isEmpty(ruleItem) && (
        <div className="ruleSetContainer" style={{ marginBottom: '30px' }}>
          <div className="ruleSetItemElem">
            <br />
            <Button color="red" inverted icon onClick={this.onDeleteHandler}>
              <Icon name="delete" />
            </Button>
          </div>
          <div className="ruleSetItemElem">
            Rule Type
            <br />
            <Form.Field required>
              <Select
                placeholder="Select Rule Type"
                options={rules}
                closeOnBlur
                value={ruleType}
                onChange={this.onSelectHandler('ruleType')}
                required
                style={{
                  border:
                    ruleType === '' && this.props.isSubmitting
                      ? '1px solid red'
                      : ''
                }}
              />
              {ruleType === '' && this.props.isSubmitting && (
                <div style={{ color: 'red' }}>This field is required</div>
              )}
            </Form.Field>
          </div>
          <div className="ruleSetItemElem">
            Operator
            <br />
            <Form.Field required>
              <Select
                placeholder="Select Operator"
                options={operators}
                closeOnBlur
                value={operatorType}
                onChange={this.onSelectHandler('operatorType')}
                style={{
                  border:
                    operatorType === '' && this.props.isSubmitting
                      ? '1px solid red'
                      : ''
                }}
                required
              />
              {operatorType === '' && this.props.isSubmitting && (
                <div style={{ color: 'red' }}>This field is required</div>
              )}
            </Form.Field>
            <div style={{ marginTop: '8px' }}>
              <Checkbox
                label="Include"
                checked={include}
                onChange={this.onCheckHandler}
              />
            </div>
          </div>
          <div className="ruleSetItemElem">
            <Form.Field required error={!isEmpty(errors.facts)}>
              Facts
              <TextArea
                key={refId}
                placeholder="Facts"
                value={facts}
                onChange={this.onChangeHandler}
                style={{
                  border:
                    (facts === '' || !isValidFacts(facts, ruleType)) &&
                    !isEmpty(ruleType) &&
                    !ruleTypeSelected
                      ? '1px solid red'
                      : ''
                }}
              />
              {/*validation of facts in cases onchange and submitting*/}
              {(!isEmpty(facts) || facts.length > 0) &&
                !isValidFacts(facts, ruleType) &&
                !this.props.isSubmitting && (
                  <div style={{ color: 'red' }}>{returnErrMsg(ruleType)}</div>
                )}
              {(!isEmpty(facts) || facts.length > 0) &&
                !isValidFacts(facts, ruleType) &&
                this.props.isSubmitting && (
                  <div style={{ color: 'red' }}>{returnErrMsg(ruleType)}</div>
                )}
              {(isEmpty(facts) || facts.length === 0) &&
                this.props.isSubmitting && (
                  <div style={{ color: 'red' }}>
                    {PreferencePatterns.MSG_FACT_NULL}
                  </div>
                )}
              {(isEmpty(facts) || facts.length === 0) &&
                !isEmpty(ruleType) &&
                !ruleTypeSelected &&
                !this.props.isSubmitting && (
                  <div style={{ color: 'red' }}>
                    {PreferencePatterns.MSG_FACT_NULL}
                  </div>
                )}
            </Form.Field>
          </div>
          <div className="ruleSetItemElem">
            <br />
            <Button
              className="ml-5px"
              icon
              inverted
              color="green"
              disabled={first}
              onClick={this.onMoveUpHandler}
            >
              <Icon name="angle up" />
            </Button>
            <Button
              icon
              inverted
              color="green"
              disabled={last}
              onClick={this.onMoveDownHandler}
            >
              <Icon name="angle down" />
            </Button>
          </div>
        </div>
      )
    );
  }
}
export default Rule;
