import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { Message } from 'semantic-ui-react';

import Search from '../views/Shared/Search';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import GuaranteePurchasesList from '../views/CancelledGuaranteePurchases/List';
import GuaranteeReinstate from '../views/CancelledGuaranteePurchases/ReinstateGuaranteeForm';
import cancelledPurchasesQuery from '../graphql/cancelledPurchasesQuery';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';
import reinstateGuaranteePurchaseMutation from '../graphql/reinstateGuaranteePurchaseMutation';

class CancelledGuaranteePurchases extends Component {
  state = {
    selectedGuaranteePurchase: null,
    isReinstateModalOpen: false,
    reinstateFormError: null,
    reinstateFormSubmitting: null,
    flashMessage: null
  };

  handleMessageDismiss = () => {
    this.setState({ flashMessage: null });
  };

  onSelectGuarantee = (action, guarantee) => {
    this.setState({
      selectedGuaranteePurchase: guarantee
    });

    switch (action) {
      case 'Reinstate Guarantee':
        this.setState({
          isReinstateModalOpen: true,
          reinstateFormError: null,
          reinstateFormSubmitting: null
        });
        break;
      default:
        this.setState({
          selectedGuaranteePurchase: null
        });
    }
  };

  onSearchSubmitHandler = event => {
    const { vin } = event.target;
    const variableArguments = {
      vin: vin.value.trim() || null
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  onReinstateFormCloseHandler = () => {
    this.setState({
      isReinstateModalOpen: false,
      selectedGuaranteePurchase: null
    });
  };

  onReinstateFormSubmitHandler = values => {
    this.setState({
      reinstateFormSubmitting: true
    });

    const { reinstateMutation } = this.props;
    const reinstateInput = { ...values };
    reinstateMutation({
      variables: { reinstateInput }
    })
      .then(({ data }) => {
        const {
          reinstateGuaranteePurchase: { success, errors }
        } = data;
        if (success) {
          this.props.data.refetch();
          this.setState({
            isReinstateModalOpen: false,
            selectedGuaranteePurchase: null,
            reinstateFormSubmitting: null,
            flashMessage: 'Successfully Reinstate Guarantee Purchase'
          });
        } else {
          this.setState({
            reinstateFormSubmitting: false,
            reinstateFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          reinstateFormSubmitting: false,
          reinstateFormError: 'Error while reinstate Guarantee Purchase'
        });
      });
  };

  render() {
    const {
      selectedGuaranteePurchase,
      isReinstateModalOpen,
      reinstateFormError,
      reinstateFormSubmitting,
      flashMessage
    } = this.state;

    const {
      data: { cancelledPurchases },
      searchFields
    } = this.props;
    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
          />
        )}
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['vin']}
        />
        <GuaranteePurchasesList
          onSelectGuarantee={this.onSelectGuarantee}
          cancelledPurchases={cancelledPurchases}
        />

        {selectedGuaranteePurchase && isReinstateModalOpen && (
          <div>
            <GuaranteeReinstate
              isReinstateModalOpen={isReinstateModalOpen}
              onCloseHandler={this.onReinstateFormCloseHandler}
              onSubmitHandler={this.onReinstateFormSubmitHandler}
              cancelledPurchase={selectedGuaranteePurchase}
              error={reinstateFormError}
              submitting={reinstateFormSubmitting}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields }
  } = state;
  return {
    searchFields: searchFields
  };
};

export default compose(
  withRouter,
  withPagination(cancelledPurchasesQuery, 'cancelledPurchases'),
  graphql(reinstateGuaranteePurchaseMutation, { name: 'reinstateMutation' }),
  LoadingDataEnhancer,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CancelledGuaranteePurchases);
