import { gql } from 'react-apollo';

const getTappyMaintenanceStatusQuery = gql`
  query maintenanceFeatureFlagQuery {
    tappyMaintenanceFlagStatus {
      active
    }
  }
`;

export default getTappyMaintenanceStatusQuery;
