import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { Tab, Loader, Dimmer, Message, Confirm } from 'semantic-ui-react';
import DealerQuote from './DealerQuote';
import EnrollmentStatus from './EnrollmentStatus';
import AutoPsi from './AutoPsi';
import enrollmentStatusQuery from '../graphql/enrollmentStatusQuery';
import validateLocallyLinkedDealerQuery from '../graphql/validateLocallyLinkedDealerQuery';
import createLocallyLinkedDealerMutation from '../graphql/createLocallyLinkedDealerMutation';
import deleteLocallyLinkedDealerMutation from '../graphql/deleteLocallyLinkedDealerMutation';
import client from '../apollo';
import ProductSelection from './ProductSelection';

import { find } from 'lodash';
import moment from 'moment';

class DealerDashboard extends Component {
  state = {
    enrollmentStatus: null,
    auctionAccessNumber: '',
    loading: false,
    flashMessage: '',
    showProductSelectionWindow: false,
    selectedDealerNo: null,
    selectedDealerName: null,
    chosenIndex: '',
    chosenOffer: [],
    isRevise: false,
    handleLink: false,
    handleLinkData: {},
    isUpgrade: false,
    localDealerNo: '',
    localDealerName: '',
    unlinkDealer: false
  };

  isEnrollmentStatusIncludeDealer = (enrollmentStatus, localDealerNo) => {
    return (
      enrollmentStatus.enrollments.filter(
        offer => offer.dealerNo === localDealerNo
      ).length > 0
    );
  };

  removeDealerHandler = () => {
    const { deleteMutation } = this.props;
    const { auctionAccessNumber, dealerNo } = this.state;
    const localDealerNo = dealerNo;
    const input = {
      hundredmil: parseInt(auctionAccessNumber, 10),
      dealerNo: parseInt(localDealerNo, 10)
    };

    deleteMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const {
          deleteLocallyLinkedDealer: { success, errors }
        } = data;
        if (success) {
          this.setState(
            {
              flashMessage: null,
              loading: false,
              unlinkDealer: false
            },
            () => {
              this.displayRemainingDealers();
            }
          );
        } else {
          this.setState({
            flashMessage: errors,
            loading: false,
            unlinkDealer: false
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          flashMessage: error,
          unlinkDealer: false
        });
      });
  };

  addDealerHandler = event => {
    const { localDealerNo, auctionAccessNumber, enrollmentStatus } = this.state;

    if (isEmpty(auctionAccessNumber)) {
      this.setState({ flashMessage: 'HundredMil must be valid' });
    } else {
      if (
        this.isEnrollmentStatusIncludeDealer(
          enrollmentStatus,
          parseInt(localDealerNo, 10)
        ) === true
      ) {
        this.setState({
          flashMessage: 'Dealer is already linked to HundredMil'
        });
        return false;
      }
      this.setState({ loading: true, flashMessage: '' });
      client
        .query({
          query: validateLocallyLinkedDealerQuery,
          variables: { dealerNo: localDealerNo },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          const {
            validateLocallyLinkedDealer: { status, dealerInformation }
          } = data;
          if (status === 'valid') {
            this.setState({
              loading: false,
              localDealerName: dealerInformation.dealerName
            });
          } else {
            this.setState({ loading: false, flashMessage: status });
          }
        });
    }
  };

  onSearchSubmitHandler = event => {
    const { auctionAccessNumber } = this.state;
    this.setState({ loading: true, flashMessage: '', enrollmentStatus: null });
    client
      .query({
        query: enrollmentStatusQuery,
        variables: { auctionAccessNumber: auctionAccessNumber },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const { enrollmentStatus } = data;
        const { handleLink, handleLinkData, isRevise, isUpgrade } = this.state;

        this.setState(
          {
            enrollmentStatus: enrollmentStatus,
            loading: false,
            flashMessage: ''
          },
          () => {
            if (handleLink) {
              this.showProductSelection(
                handleLinkData.dealerNo,
                handleLinkData.dealershipName,
                isRevise,
                isUpgrade
              );
            }
          }
        );
      })
      .catch(error => {
        this.setState({ loading: false, flashMessage: 'Network Error..' });
      });
  };

  displayRemainingDealers = event => {
    const { dealerNo } = this.state;
    let enrollmentStatus = Object.assign([], this.state.enrollmentStatus);
    let enrollments = Object.assign([], enrollmentStatus.enrollments);

    let index = enrollmentStatus.enrollments.indexOf(
      find(enrollments, ['dealerNo', dealerNo])
    );
    enrollments.splice(index, 1);
    enrollmentStatus.enrollments = enrollments;
    this.setState({ enrollmentStatus: enrollmentStatus });
  };

  showProductSelection = (dealerNo, name, isRevise, isUpgrade) => {
    this.setState({
      showProductSelectionWindow: true,
      selectedDealerNo: dealerNo,
      selectedDealerName: name,
      isRevise: isRevise,
      isUpgrade: isUpgrade
    });
  };

  displayDealerConfirm = dealerNo => {
    this.setState({
      unlinkDealer: true,
      dealerNo: dealerNo
    });
  };

  filteredOffers = (dealerNo, enrollmentStatus) => {
    return (
      enrollmentStatus &&
      enrollmentStatus.eligibleOffers.filter(
        offer => offer.dealerNo === dealerNo
      )
    );
  };

  enrollmentDetails = (dealerNo, enrollmentStatus) => {
    return (
      enrollmentStatus &&
      enrollmentStatus.enrollments.filter(
        enrollment => enrollment.dealerNo === dealerNo
      )
    );
  };

  handleRadioButtonChange = chosenIndex => {
    this.setState({ chosenIndex: chosenIndex });
    const { selectedDealerNo, enrollmentStatus } = this.state;
    this.setState({
      chosenOffer: this.filteredOffers(selectedDealerNo, enrollmentStatus)[
        chosenIndex
      ]
    });
  };

  handleProductSelection = (email, phone) => {
    const { selectedDealerNo } = this.state;
    let enrollmentStatus = Object.assign([], this.state.enrollmentStatus);
    let enrollments = Object.assign([], enrollmentStatus.enrollments);

    let enrollment = Object.assign(
      {},
      find(enrollments, ['dealerNo', selectedDealerNo])
    );
    let index = enrollmentStatus.enrollments.indexOf(
      find(enrollments, ['dealerNo', selectedDealerNo])
    );

    enrollment.incompleteEnrollment = {
      dealerNo: selectedDealerNo,
      sendAt: moment(new Date()).format('MM/DD/YYYY')
    };
    enrollments[index] = enrollment;
    enrollmentStatus.email = email;
    enrollmentStatus.phone = phone;
    enrollmentStatus.enrollments = enrollments;

    this.setState({
      showProductSelectionWindow: false,
      enrollmentStatus,
      chosenIndex: '',
      chosenOffer: [],
      selectedDealerNo: '',
      handleLink: false,
      handleLinkData: {}
    });
  };

  handleCancel = () => {
    this.setState({
      showProductSelectionWindow: false,
      chosenIndex: '',
      chosenOffer: [],
      selectedDealerNo: '',
      handleLink: false,
      handleLinkData: {}
    });
    this.onSearchSubmitHandler();
  };

  handlePendingAcceptanceLink = (
    auctionAccessNumber,
    dealerNo,
    dealershipName,
    isRevise
  ) => {
    this.setState(
      {
        auctionAccessNumber: auctionAccessNumber,
        handleLink: true,
        handleLinkData: {
          dealerNo: parseInt(dealerNo, 10),
          dealershipName
        },
        isRevise: isRevise
      },
      () => {
        this.onSearchSubmitHandler();
      }
    );
  };

  handleClose = () => {
    this.setState({ localDealerName: '', unlinkDealer: false });
  };

  handleConfirm = () => {
    const { createMutation } = this.props;
    const { localDealerNo, auctionAccessNumber } = this.state;
    this.setState({ loading: true, localDealerName: '' });

    const input = {
      hundredmil: parseInt(auctionAccessNumber, 10),
      dealerNo: parseInt(localDealerNo, 10)
    };

    createMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const {
          createLocallyLinkedDealer: { success, errors }
        } = data;
        if (success) {
          this.setState(
            {
              flashMessage: null,
              loading: false,
              localDealerNo: ''
            },
            () => {
              this.onSearchSubmitHandler();
            }
          );
        } else {
          this.setState({
            flashMessage: errors,
            loading: false,
            localDealerNo: ''
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          flashMessage: error
        });
      });
  };

  render() {
    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const {
      showProductSelectionWindow,
      selectedDealerNo,
      enrollmentStatus,
      loading,
      flashMessage,
      localDealerNo,
      localDealerName,
      unlinkDealer
    } = this.state;

    const offers = this.filteredOffers(selectedDealerNo, enrollmentStatus);
    const auctionAccessName = enrollmentStatus && enrollmentStatus.name;
    const email = enrollmentStatus && enrollmentStatus.email;
    const phone = enrollmentStatus && enrollmentStatus.phone;
    const enrollment = this.enrollmentDetails(
      selectedDealerNo,
      enrollmentStatus
    );

    const panes = [
      {
        menuItem: 'DEALER QUOTE',
        render: () => (
          <Tab.Pane>
            {' '}
            {flashMessage && (
              <Message
                onDismiss={this.handleDismiss}
                content={flashMessage}
                negative
              />
            )}
            <DealerQuote
              onSearchSubmitHandler={this.onSearchSubmitHandler}
              {...this.state}
              onChangeHandler={onChangeHandler}
              showProductSelection={this.showProductSelection}
              addDealerHandler={this.addDealerHandler.bind(this)}
              displayDealerConfirm={this.displayDealerConfirm}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'AUTO PSI',
        render: () => (
          <Tab.Pane>
            <AutoPsi />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'ENROLLMENT STATUS',
        render: () => (
          <Tab.Pane>
            <EnrollmentStatus
              handlePendingAcceptanceLink={this.handlePendingAcceptanceLink}
            />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div className="dealer-enrollment">
        {loading && (
          <Dimmer active inverted>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {unlinkDealer && (
          <Confirm
            open={true}
            content={`Are you sure you want to unlink this dealer?`}
            onConfirm={this.removeDealerHandler}
            onCancel={this.handleClose}
          />
        )}
        {localDealerName && (
          <Confirm
            open={true}
            content={`Do you want to locally link dealer ${localDealerNo} - ${localDealerName} to ${auctionAccessName}`}
            onConfirm={this.handleConfirm}
            onCancel={this.handleClose}
          />
        )}
        {!showProductSelectionWindow && (
          <Tab panes={panes} className="dealer_quote" />
        )}
        {showProductSelectionWindow && (
          <ProductSelection
            handleRadioButtonChange={this.handleRadioButtonChange}
            handleProductSelection={this.handleProductSelection}
            onSearchSubmitHandler={this.onSearchSubmitHandler}
            handleCancel={this.handleCancel}
            auctionAccessName={auctionAccessName}
            offers={offers}
            phone={phone}
            email={email}
            onChangeHandler={onChangeHandler}
            {...this.state}
            enrollment={enrollment}
          />
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(createLocallyLinkedDealerMutation, { name: 'createMutation' }),
  graphql(deleteLocallyLinkedDealerMutation, { name: 'deleteMutation' })
)(DealerDashboard);
