import gql from 'graphql-tag';

const activeDealerTypeQuery = gql`
  query activeDealerTypeQuery($dealerNo: String) {
    activeDealer(dealerNo: $dealerNo) {
      dealerNo
      dealerName
      active
      subscriptionStatus
      dealerOnIsl
    }
  }
`;

export default activeDealerTypeQuery;
