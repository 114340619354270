import React, { Component } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Message, Dimmer, Loader, Button } from 'semantic-ui-react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import LoadingDataEnhancer from './LoadingData';
import { isEmpty, find } from 'lodash';
import ScrollToTop from 'react-scroll-up';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { utcMoment, dateTimeFormatted } from '../utilities/dateUtils';

import VehicleDetails from '../views/VehicleStatusTracker/VehicleDetails';
import HeaderBlock from '../views/VehicleStatusTracker/HeaderBlock';
import auctionLocationsQuery from '../graphql/auctionLocationsQuery';

const api = axios.create({
  baseURL: process.env.REACT_APP_VST_API_URL,
  timeout: process.env.REACT_APP_VST_TIMEOUT,
  headers: {
    'X-API-KEY': process.env.REACT_APP_VST_API_KEY,
    'content-type': 'application/json'
  }
});

const retryCondition = error => {
  return error.code === 'ECONNABORTED';
};

axiosRetry(api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
  retryCondition
});

class VehicleStatusTracker extends Component {
  state = {
    vehicleRecord: null,
    error: null,
    loading: true,
    activeIndex: [0],
    allClosed: false,
    scrollTop: 0
  };

  handleDismiss = () => {
    this.setState({ flashMessage: null });
  };

  handleScroll = () => {
    this.setState({ scrollTop: 0 });
  };

  closeAll = () => {
    this.setState({ activeIndex: [], allClosed: true });
  };

  openAll = () => {
    this.setState({ activeIndex: [0, 1, 2, 3, 4, 5, 6], allClosed: false });
  };

  handleClick = (e, itemProps) => {
    const { index } = itemProps;
    const { activeIndex } = this.state;
    let newState;

    if (activeIndex.indexOf(index) > -1) {
      newState = activeIndex.filter(i => i !== index);
    } else {
      newState = [...activeIndex, index];
    }

    this.setState({ activeIndex: newState });
  };

  componentDidMount() {
    const purchaseId = this.props.match.params.purchaseId;

    api
      .get('/', {
        params: {
          tappyPurchaseId: purchaseId
        }
      })
      .then(res => {
        const vehicleRecord = res.data.Items[0];
        const count = res.data.Count;
        if (count === 0) {
          this.setState({
            vehicleRecord,
            loading: false,
            error: 'VST Data is not available for this guarantee.'
          });
        } else {
          this.setState({
            vehicleRecord,
            loading: false,
            error: null
          });
        }
      })
      .catch(e => {
        this.setState({ error: e.message, loading: false });
        console.error(e);
      });
  }

  render() {
    const { vehicleRecord, error, loading, activeIndex } = this.state;
    const {
      data: { locations }
    } = this.props;

    const isAuctionEdgeLocation =
      vehicleRecord &&
      !isEmpty(find(locations, ['initials', vehicleRecord.locationCode])) &&
      !isEmpty(
        find(locations, ['initials', vehicleRecord.locationCode])
          .auctionedge_code
      );

    const formatField = field => {
      let value = vehicleRecord[field]
        ? vehicleRecord[field].toString()
        : 'NULL';

      if (value === 'NULL' || value === '-1') {
        value = '';
      }
      return value;
    };

    const expireGuaranteeGreater = vehicleRecord => {
      let expireDateWithSixtyDays = dateTimeFormatted(
        utcMoment(vehicleRecord.expireDate.toString()).add('days', 60)
      );
      let updatedOnFormatted = dateTimeFormatted(
        utcMoment(vehicleRecord.updatedOn)
      );
      return updatedOnFormatted > expireDateWithSixtyDays ? true : false;
    };

    const formatDate = field => {
      let value = vehicleRecord[field]
        ? vehicleRecord[field].toString()
        : 'NULL';

      if (value === 'NULL' || value === '-1') {
        value = '';
      } else {
        value = utcMoment(value).format('MM/DD/YYYY');
      }
      return value;
    };

    const formatDateTime = field => {
      let value = vehicleRecord[field]
        ? vehicleRecord[field].toString()
        : 'NULL';
      if (value === 'NULL' || value === '-1') {
        value = '';
      } else {
        value = moment(value)
          .utc()
          .local()
          .format('MM/DD/YYYY ,h:mm:ss a');
      }
      return value;
    };

    const currencyProps = price => {
      return {
        currency: 'USD',
        style: 'currency',
        minimumFractionDigits: 2,
        value: price
      };
    };

    const negativeCurrency = price => {
      return (
        '$(' +
        parseFloat(price)
          .toFixed(2)
          .replace('-', '')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        ')'
      );
    };

    const formatCurrency = field => {
      let value = vehicleRecord[field]
        ? vehicleRecord[field].toString()
        : 'NULL';

      if (value === 'NULL' || value === '-1') {
        return '';
      } else if (value.includes('-')) {
        return negativeCurrency(value);
      }
      return <FormattedNumber {...currencyProps(value)} />;
    };

    const convertCase = status => {
      return isEmpty(status) ? status : status.toLowerCase();
    };

    const paymentStatus = vehicleRecord => {
      let payment_status = convertCase(vehicleRecord.origVehPaymentStatus);
      let floorplan = vehicleRecord.origVehFloorplanCompCode;
      let floorplan_status = convertCase(
        vehicleRecord.origVehFloorPlanPaymentStatus
      );
      let status = '';
      if (
        payment_status ===
          (convertCase('PAID') || convertCase('DEALER PAID')) &&
        floorplan_status === convertCase('Floorplan Paid (Funded)')
      ) {
        status = 'Floorplan Paid (Funded)';
      } else if (
        payment_status === convertCase('UNPAID') &&
        floorplan_status === convertCase('Floorplan Paid (Unfunded)')
      ) {
        status = 'Floorplan Paid (Unfunded)';
      } else if (
        payment_status === convertCase('UNPAID') &&
        floorplan_status === convertCase('Floorplan Paid (Partially Funded)')
      ) {
        status = 'Floorplan Paid (Partially Funded)';
      } else if (
        payment_status ===
          (convertCase('PAID') || convertCase('DEALER PAID')) &&
        (floorplan === null || floorplan === '-1')
      ) {
        status = 'Dealer Paid';
      } else if (payment_status === convertCase('UNPAID')) {
        status = 'Unpaid';
      } else {
        status = payment_status;
      }
      return status;
    };

    const vehicleNetColourComparison = vehicleRecord => {
      let sumOfAllPrices =
        vehicleRecord.origVehCertificationFee +
        vehicleRecord.origVehOutsideFloorFee +
        vehicleRecord.origVehOVETransactionFee +
        vehicleRecord.origVehBuyersFee +
        vehicleRecord.origVehDealshieldFee +
        vehicleRecord.origVehSalePrice +
        vehicleRecord.origVehTransactionSimulcastFee +
        vehicleRecord.origVehTransactionLateFee +
        vehicleRecord.origVehTransactionFloorPlanFee +
        vehicleRecord.origVehTransactionPSIFee +
        vehicleRecord.origVehElementPremiumBuyFee +
        vehicleRecord.origVehToyotaAdminFee +
        vehicleRecord.origVehTaxFee +
        vehicleRecord.origVehTranPromoDiscountFee +
        vehicleRecord.origVehTranTransportAssistancFee +
        vehicleRecord.origVehTranGoodwillFee +
        vehicleRecord.origVehTranMiscellaneousFee +
        vehicleRecord.origVehDSRebillingAmount +
        vehicleRecord.origVehPSIRebillingAmount;
      return vehicleRecord.origVehNet === sumOfAllPrices ? 'green' : 'red';
    };

    return (
      <div>
        {error && (
          <Message onDismiss={this.handleDismiss} content={error} negative />
        )}
        {loading && (
          <Dimmer inverted active style={{ height: 300 }}>
            <Loader indeterminate>Loading...</Loader>
          </Dimmer>
        )}
        {vehicleRecord && (
          <div>
            <HeaderBlock
              vehicleRecord={vehicleRecord}
              allClosed={this.state.allClosed}
              closeAll={this.closeAll}
              openAll={this.openAll}
              paymentStatus={paymentStatus}
              formatDateTime={formatDateTime}
              expireGuaranteeGreater={expireGuaranteeGreater}
              convertCase={convertCase}
            />
            <VehicleDetails
              vehicleRecord={vehicleRecord}
              isAuctionEdgeLocation={isAuctionEdgeLocation}
              activeIndex={activeIndex}
              paymentStatus={paymentStatus}
              handleClick={this.handleClick}
              formatField={formatField}
              formatDate={formatDate}
              formatDateTime={formatDateTime}
              formatCurrency={formatCurrency}
              vehicleNetColourComparison={vehicleNetColourComparison}
            />
          </div>
        )}
        <ScrollToTop showUnder={160}>
          <Button className={'vst-close-button'}>Back to Top</Button>
        </ScrollToTop>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(auctionLocationsQuery, 'auctionLocations'),
  LoadingDataEnhancer
)(VehicleStatusTracker);
