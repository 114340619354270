import { gql } from 'react-apollo';

const externalVehiclesQuery = gql`
  query externalVehiclesQuery($batchId: Int!, $size: Int, $offset: Int) {
    externalVehicles(batchId: $batchId, offset: $offset, size: $size) {
      id
      dealerNo
      model
      odometerReading
      year
      make
      vin
      dealerNo
      dealerName
      sellerName
      vehiclePurchasePrice
      locationInitials
      automaticPurchaseEmail
      status
      failedReason
      purchasedAt
      userId
      rejectionComment
      buyFee
      vehicleTotal
      approvingUserId
      rejectingUserId
      externalVehiclesBatchId
      secondaryApprovalEligibility
      secondaryApprovingUserId
      secondaryRejectingUserId
      secondaryRejectionComment
    }
  }
`;

export default externalVehiclesQuery;
