import React from 'react';
import { capitalize, includes, isNumber, toNumber } from 'lodash';
import { hasRole } from '../../utilities/user';
import { Table, Button } from 'semantic-ui-react';

const VehicleListRow = (
  approveHandler,
  rejectHandler,
  user,
  review
) => externalVehicle => {
  const {
    id,
    vin,
    year,
    make,
    model,
    odometerReading,
    dealerNo,
    dealerName,
    sellerName,
    vehiclePurchasePrice,
    buyFee,
    vehicleTotal,
    locationInitials,
    automaticPurchaseEmail,
    purchasedAt,
    rejectionComment,
    status,
    approvingUserId,
    rejectingUserId,
    secondaryApprovingUserId,
    secondaryRejectingUserId,
    secondaryRejectionComment
  } = externalVehicle;

  const onApproveHandler = () => {
    approveHandler(externalVehicle);
  };

  const onRejectHandler = () => {
    rejectHandler(externalVehicle);
  };

  const beforePrimaryApproval =
    !isNumber(approvingUserId) &&
    !isNumber(rejectingUserId) &&
    (status === 'unapproved' || status === 'rejected');
  const afterPrimaryApproval =
    isNumber(approvingUserId) && approvingUserId === toNumber(user.id);
  const afterPrimaryRejection =
    isNumber(rejectingUserId) && rejectingUserId === toNumber(user.id);
  const afterSecondaryApproval =
    isNumber(secondaryApprovingUserId) &&
    secondaryApprovingUserId === toNumber(user.id);
  const afterSecondaryRejection =
    isNumber(secondaryRejectingUserId) &&
    secondaryRejectingUserId === toNumber(user.id);

  const afterPrimaryRejectionAnyUser =
    !beforePrimaryApproval &&
    !afterPrimaryApproval &&
    !afterPrimaryRejection &&
    !afterSecondaryApproval &&
    !afterSecondaryRejection;

  const primaryCondition = afterPrimaryApproval
    ? !afterPrimaryApproval
    : afterPrimaryRejection;
  const secondaryCondition = afterSecondaryApproval
    ? !afterSecondaryApproval
    : afterSecondaryRejection;
  const approvalCondition =
    afterSecondaryApproval || afterSecondaryRejection
      ? secondaryCondition
      : primaryCondition;
  const beforePrimaryApprovalForReject =
    !isNumber(approvingUserId) &&
    !isNumber(rejectingUserId) &&
    !isNumber(secondaryApprovingUserId) &&
    !isNumber(secondaryRejectingUserId);

  const showApprove =
    review &&
    (beforePrimaryApproval ||
      approvalCondition ||
      afterPrimaryRejectionAnyUser);
  const showReject =
    review &&
    (beforePrimaryApprovalForReject ||
      (!afterPrimaryApproval &&
        !afterPrimaryRejection &&
        !afterSecondaryApproval &&
        !afterSecondaryRejection));

  const isApprover = hasRole('external_vehicle_approver', 'roles')(user);
  const humanizedStatus = capitalize(
    includes(['approved', 'posted', 'unposted'], status) ? 'approved' : status
  );
  return (
    <Table.Row key={id}>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell>{year}</Table.Cell>
      <Table.Cell>{make}</Table.Cell>
      <Table.Cell>{model}</Table.Cell>
      <Table.Cell>{odometerReading}</Table.Cell>
      <Table.Cell>{dealerNo}</Table.Cell>
      <Table.Cell>{dealerName}</Table.Cell>
      <Table.Cell>{sellerName}</Table.Cell>
      <Table.Cell>{vehiclePurchasePrice}</Table.Cell>
      <Table.Cell>{buyFee}</Table.Cell>
      <Table.Cell>{vehicleTotal}</Table.Cell>
      <Table.Cell>{locationInitials}</Table.Cell>
      <Table.Cell>{automaticPurchaseEmail}</Table.Cell>
      <Table.Cell>{purchasedAt}</Table.Cell>
      <Table.Cell>{rejectionComment}</Table.Cell>
      <Table.Cell>{humanizedStatus}</Table.Cell>
      <Table.Cell>
        {isApprover && (
          <div>
            {showApprove && <Button onClick={onApproveHandler}>Approve</Button>}
            {showReject && <Button onClick={onRejectHandler}>Reject</Button>}
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default VehicleListRow;
