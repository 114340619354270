import { gql } from 'react-apollo';

const updateAuctionAccessBlacklistMutation = gql`
  mutation updateAuctionAccessBlacklist($input: UpdateAuctionAccessBlacklistInput!) {
    updateAuctionAccessBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default updateAuctionAccessBlacklistMutation;


