import React, { Component } from 'react';
import { get, isEmpty } from 'lodash';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Button, Message } from 'semantic-ui-react';

import client from '../../apollo';
import userSearchQuery from '../../graphql/userSearchQuery';
import resetApiMutation from '../../graphql/resetApiTokenMutation';
import Search from '../Shared/Search';
import { setSearch } from '../../data/searchField/actions';
import { setPage } from '../../data/page/actions';
import List from './List';
import Create from './Create';
import Edit from './Edit';

class UserSearch extends Component {
  state = {
    userList: [],
    User: '',
    isCreateUserModalOpen: false,
    isEditUserModalOpen: false,
    selectedUser: null,
    searchValue: '',
    flashMessage: null,
    error: null
  };
  searchUser = event => {
    const { User } = event.target;
    this.setState({ searchValue: User.value });
    this.searchQuery(User.value);
  };

  searchQuery = value => {
    client
      .query({
        query: userSearchQuery,
        variables: {
          searchParam: value
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const userList = get(data, 'searchUser');
        this.setState({
          userList: userList
        });
      });
    this.props.setSearchFields({ User: value });
  };

  openCreateUserForm = () => {
    this.setState({ isCreateUserModalOpen: true });
  };

  openEditUserForm = user => {
    this.setState({ isEditUserModalOpen: true, selectedUser: user });
  };

  onCloseHandler = () => {
    this.setState({ isCreateUserModalOpen: false, isEditUserModalOpen: false });
  };

  resetApiTokenHandler = user => {
    const { resetApiMutation } = this.props;
    const { searchValue } = this.state;
    const input = { id: parseInt(user.id, 10) };

    resetApiMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const {
          adminUserResetToken: { success, errors }
        } = data;
        if (success) {
          this.searchQuery(searchValue);
          this.setState({
            flashMessage: 'Successfully Updated User API Token'
          });
        } else {
          this.setState({
            error: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          error: `Error while updating user API Token ${error}`
        });
      });
  };

  handleDismiss = () => {
    this.setState({ flashMessage: null });
  };

  handleDismissError = () => {
    this.setState({ error: null });
  };

  render() {
    const {
      userList,
      isCreateUserModalOpen,
      isEditUserModalOpen,
      selectedUser,
      searchValue,
      error,
      flashMessage
    } = this.state;
    const { searchFields } = this.props;

    return (
      <div>
        <Search
          searchOptions={['User']}
          onSubmit={this.searchUser}
          searchFields={searchFields}
        />
        <Header>Create New User</Header>
        <Button color="green" onClick={this.openCreateUserForm} inverted>
          Create User
        </Button>
        <br />
        <br />
        {error && (
          <Message
            onDismiss={this.handleDismissError}
            content={error}
            negative
          />
        )}
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}
        {!isEmpty(userList) && (
          <List
            userList={userList}
            openEditUserForm={this.openEditUserForm}
            resetApiTokenHandler={this.resetApiTokenHandler}
          />
        )}
        {isCreateUserModalOpen && (
          <div>
            <Create
              isModalOpen={isCreateUserModalOpen}
              onCloseHandler={this.onCloseHandler}
              searchValue={searchValue}
              searchQuery={this.searchQuery}
            />
          </div>
        )}
        {isEditUserModalOpen && (
          <div>
            <Edit
              isModalOpen={isEditUserModalOpen}
              onCloseHandler={this.onCloseHandler}
              user={selectedUser}
              searchValue={searchValue}
              searchQuery={this.searchQuery}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields }
  } = state;
  return {
    searchFields
  };
};

export default compose(
  withRouter,
  graphql(resetApiMutation, { name: 'resetApiMutation' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserSearch);
