import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Table, Dropdown } from 'semantic-ui-react';
import { find, result } from 'lodash';
import { RETURN_STATUS } from '../../constants';

const ListRow = actionHandler => returnInvoice => {
  const rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 3, text: 'Reinstate Return', value: 'Reinstate Return' },
    { key: 5, text: 'Vehicle Status Tracker', value: 'Vehicle Status Tracker' }
  ];

  const {
    id,
    vin,
    returnInitiatedAt,
    expirationDate,
    returningUsername,
    cancellingUsername,
    cancelledAt,
    reasonForReturn,
    locationInitials,
    returnLocationInitials,
    odometer,
    status,
    purchasePrice,
    dealerNumber
  } = returnInvoice;

  const currencyProps = price => {
    return {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 0,
      value: price
    };
  };

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, returnInvoice);
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell>{dealerNumber}</Table.Cell>
      <Table.Cell>
        {returnLocationInitials ? returnLocationInitials : locationInitials}
      </Table.Cell>
      <Table.Cell>{odometer}</Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(purchasePrice)} />
      </Table.Cell>
      <Table.Cell>{returnInitiatedAt}</Table.Cell>
      <Table.Cell>{expirationDate}</Table.Cell>
      <Table.Cell>
        {result(find(RETURN_STATUS, ['value', status]), 'text') || status}
      </Table.Cell>
      <Table.Cell>{returningUsername}</Table.Cell>
      <Table.Cell>{cancellingUsername}</Table.Cell>
      <Table.Cell>{cancelledAt}</Table.Cell>
      <Table.Cell>{reasonForReturn}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
