import { gql } from 'react-apollo';

const pingRevokeTokenRequestQuery = gql`
  query pingRevokeTokenRequest(
    $app_name: String!
    $token: String!
    $user_id: ID
    $target_resource: String
    $in_error_resource: String
  ) {
    pingRevokeTokenRequest(
      app_name: $app_name
      token: $token
      user_id: $user_id
      target_resource: $target_resource
      in_error_resource: $in_error_resource
    ) {
      success
      error_message
      ping_logout_url
    }
  }
`;

export default pingRevokeTokenRequestQuery;
