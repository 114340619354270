import { gql } from 'react-apollo';

const enrollmentHistoryQuery = gql`
  query enrollmentHistory(
    $offersInitiatedSince: Int
    $singleDealerQuotes: Boolean
    $myQuotes: Boolean
    $groupQuotes: Boolean
    $allStaffs: Boolean
  ) {
    enrollmentHistory(
      offersInitiatedSince: $offersInitiatedSince
      singleDealerQuotes: $singleDealerQuotes
      myQuotes: $myQuotes
      groupQuotes: $groupQuotes
      allStaffs: $allStaffs
    ) {
      enrollments {
        id
        dealerNo
        dealershipName
        sendAtWithTime
        status
        acceptedAt
        voidAt
        offeringUserEmail
        productName
        price
        daysSelected
        daysToExpire
        flatRateOfferBatch {
          auctionAccessNo
          buyerInformation {
            name
            emails
          }
        }
      }
    }
  }
`;

export default enrollmentHistoryQuery;
