import React, { Component } from 'react';
import { compose } from 'recompose';
import client from '../../apollo';
import Validator from 'validatorjs';
import { withRouter } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Modal,
  Checkbox,
  Dropdown,
  Message,
  Loader,
  Dimmer
} from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import { isEmpty, get, map } from 'lodash';
import auctionLocationsQuery from '../../graphql/auctionLocationsQuery';
import vraReasonsQuery from '../../graphql/vraReasonsQuery';
import { TITLE_STATUS_OPTIONS } from '../../constants';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { utcMoment, dateTimeFormatted } from '../../utilities/dateUtils';
import returnInvoiceQuery from '../../graphql/returnInvoiceQuery';

const FIXED_GROUP_NAME_LIST = [
  {
    id: 1,
    groupName: 'High Recon Costs'
  },
  {
    id: 2,
    groupName: 'Denied Arbitration'
  },
  {
    id: 3,
    groupName: 'Mechanical/Structural Issue'
  },
  {
    id: 4,
    groupName: 'Additional Reasons'
  }
];

class ReturnInvoiceEdit extends Component {
  state = {
    id: null,
    returnReasons: [],
    returnReasonsCheckbox: {},
    pendingArbitration: false,
    auctionLocationId: '',
    odometerOnReturn: '',
    mechanicalIssues: false,
    cosmeticIssues: false,
    unableToSell: false,
    changedMind: false,
    other: false,
    noAnswer: false,
    estimatedCost: '',
    refundProcessed: false,
    titleNotificationSent: false,
    titleReceived: false,
    vehicleReceived: false,
    expirationDate: '',
    titleStatus: '',
    userEmail: '',
    loading: true,
    error: null,
    message: null,
    errors: {},
    locations: []
  };

  componentWillMount() {
    const { returnInvoice } = this.props;
    this.locationQuery();
    this.fetchAllReturnReasons();
    if (!isEmpty(returnInvoice)) {
      const {
        id,
        expirationDate,
        estimatedCost,
        userEmail,
        mechanicalIssues,
        cosmeticIssues,
        unableToSell,
        changedMind,
        other,
        noAnswer,
        additionalInformation,
        auctionLocationId,
        odometerOnReturn,
        titleStatus,
        transaction: {
          refundProcessed,
          returnLocation,
          vehicleReceived,
          titleReceived
        }
      } = returnInvoice;

      this.fetchReturnInvoiceById(id);

      this.setState({
        id: parseInt(id, 10),
        expirationDate: moment(expirationDate),
        odometerOnReturn: odometerOnReturn,
        refundProcessed: refundProcessed || false,
        returnLocation: returnLocation.initials,
        vehicleReceived: vehicleReceived || false,
        titleReceived: titleReceived || false,
        estimatedCost: estimatedCost,
        userEmail,
        mechanicalIssues,
        cosmeticIssues,
        unableToSell,
        changedMind,
        other,
        noAnswer,
        additionalInformation,
        auctionLocationId: parseInt(auctionLocationId, 10),
        titleStatus
      });
    }
  }

  locationQuery = () => {
    client
      .query({
        query: auctionLocationsQuery,
        variables: {
          sortBy: 'initials'
        }
      })
      .then(({ data }) => {
        this.setState({
          locations: get(data, 'locations')
        });
      })
      .catch(error => {
        this.setState({ locations: [] });
      });
  };

  matchingGroupName = returnReasonItem => {
    let found = FIXED_GROUP_NAME_LIST.find(
      fixedGroupItem => fixedGroupItem.id === returnReasonItem.groupId
    );
    if (isEmpty(found)) {
      return 'Other Groups';
    }
    return found.groupName;
  };

  fetchAllReturnReasons = () => {
    client
      .query({
        query: vraReasonsQuery,
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        if (isEmpty(data)) {
          this.setState({
            errors: {},
            error: 'Fetch VRA Reasons unsuccessfully.',
            message: null
          });
          return;
        }

        // Map group names to reasons
        const mappedReturnReasonsData = get(data, 'vraReasons')
          .filter(item => item.active)
          .sort((reason1, reason2) => reason1.orderId - reason2.orderId)
          .map(item => ({
            ...item,
            groupName: this.matchingGroupName(item)
          }));

        this.setState({
          returnReasons: mappedReturnReasonsData
        });
      })
      .catch(error => {
        this.setState({
          errors: {},
          error: 'Send the VRA Reasons Update unsuccessfully.',
          message: null
        });
      });
  };

  fetchReturnInvoiceById = id => {
    client
      .query({
        query: returnInvoiceQuery,
        variables: {
          id: id
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        if (isEmpty(data)) {
          this.setState({
            errors: {},
            error: 'Fetch VRA Reason Unsuccessfully.',
            message: null
          });
          return;
        }
        const { pendingArbitration, selectedReasons } = data.returnInvoice;

        const selectedStates = this.mappingCheckboxesReturnReasonsIds(
          selectedReasons
        );

        this.setState({
          ...selectedStates,
          pendingArbitration,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          errors: {},
          error: 'Fetch VRA Reason failed (Network Error).',
          message: null
        });
      });
  };

  // Function to get the list of checkboxes and their states
  getReturnReasonsIdsCheckboxes = (excludes = []) => {
    const selectedIds = [];
    this.state.returnReasons.forEach(item => {
      let isItemChecked = this.state[item.id] || false;

      if (!excludes.includes(item.returnReason) && isItemChecked) {
        selectedIds.push(item.id);
      }
    });
    return selectedIds;
  };

  // Function to set states of checkboxes
  mappingCheckboxesReturnReasonsIds = selectedReasons => {
    let states = {};
    selectedReasons.forEach(id => {
      states[id] = true;
    });
    return states;
  };

  // Function to get the list of checkboxes and their states
  getReturnReasonsCheckboxStates = (excludes = []) => {
    const checkboxStates = {};
    this.state.returnReasons.forEach(item => {
      if (!excludes.includes(item.returnReason)) {
        checkboxStates[item.returnReason] = this.state[item.id] || false;
      }
    });
    return checkboxStates;
  };

  getReasonsSelectedExceptOtherValidation = (includes = []) => {
    // list selected
    let selectedReturnReasonsExceptOther = this.getReturnReasonsCheckboxStates([
      'Other'
    ]);
    let selectedReturnReasonsExceptOtherValues = Object.values(
      selectedReturnReasonsExceptOther
    );

    const returnReasonsCheckboxExceptOtherValidation = [
      ...includes,
      ...selectedReturnReasonsExceptOtherValues
    ];

    return returnReasonsCheckboxExceptOtherValidation.some(reason => reason);
  };

  clearMessages() {
    this.setState({
      errors: {},
      error: null,
      message: null
    });
  }

  verifyFormFields(fields = {}) {
    let validationRules = {
      pendingArbitration: 'boolean',
      additionalInformation: 'string|max:1000'
    };
    let validation = new Validator(
      isEmpty(fields) ? this.state : fields,
      validationRules
    );
    this.clearMessages();

    if (validation.fails()) {
      this.setState({
        ...validation.errors
      });
      return false;
    }
    return true;
  }

  submitForm = () => {
    const { onSubmitHandler } = this.props;
    const {
      id,
      pendingArbitration,
      expirationDate,
      odometerOnReturn,
      refundProcessed,
      auctionLocationId,
      vehicleReceived,
      titleReceived,
      estimatedCost,
      userEmail,
      mechanicalIssues,
      cosmeticIssues,
      unableToSell,
      changedMind,
      noAnswer,
      additionalInformation,
      titleNotificationSent,
      titleStatus
    } = this.state;

    // Validation
    const selectedReturnReasons = this.getReturnReasonsCheckboxStates();
    const other = selectedReturnReasons['Other'];

    const reasonsSelectedExceptOther = this.getReasonsSelectedExceptOtherValidation();

    if (!this.verifyFormFields()) {
      return false;
    } else if (!reasonsSelectedExceptOther && !other) {
      this.setState({
        errors: {},
        error: 'Please select at least one return reason.',
        message: null
      });
      return false;
    } else if (
      !reasonsSelectedExceptOther &&
      other &&
      isEmpty(additionalInformation)
    ) {
      this.setState({
        errors: {},
        error: 'Please input the additional information.',
        message: null
      });
      return false;
    } else {
      const selectedReasons = this.getReturnReasonsIdsCheckboxes();
      let payload = {
        id: parseInt(id, 10),
        pendingArbitration,
        selectedReasons,
        expirationDate: dateTimeFormatted(utcMoment(expirationDate)),
        odometerOnReturn: parseInt(odometerOnReturn, 10),
        refundProcessed,
        auctionLocationId,
        vehicleReceived,
        titleReceived,
        estimatedCost,
        userEmail,
        mechanicalIssues,
        cosmeticIssues,
        unableToSell,
        changedMind,
        other,
        noAnswer,
        additionalInformation,
        titleNotificationSent,
        titleStatus
      };
      onSubmitHandler(payload);

      this.setState({ errors: {}, error: null, message: 'Form Submitted.' });
    }
  };

  render() {
    const {
      isEditModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error
    } = this.props;
    const { locations, loading, errors } = this.state;

    const onChangeHandler = field => event => {
      if (field === 'additionalInformation') {
        this.verifyFormFields({ [field]: event.target.value });
        this.setState({ [field]: event.target.value });
        return;
      }
      if (field !== 'estimatedCost' && field !== 'odometerOnReturn') {
        this.setState({ [field]: event.target.value });
      } else {
        if (event.target.value !== '') {
          this.setState({ [field]: parseFloat(event.target.value) });
        } else {
          this.setState({ [field]: 0 });
        }
      }
    };

    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const date = isEmpty(this.state.expirationDate)
      ? null
      : utcMoment(this.state.expirationDate);

    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD HH:mm:ss') });
    };

    const returnLocationOptions =
      !isEmpty(locations) &&
      locations.map(function(location) {
        return {
          text: location.initials,
          key: location.id,
          value: parseInt(location.id, 10)
        };
      });

    // Group the data by groupId
    const groupedData = this.state.returnReasons.reduce((acc, item) => {
      const { groupId } = item;
      if (!acc[groupId]) {
        acc[groupId] = [];
      }
      acc[groupId].push(item);
      return acc;
    }, {});

    const findGroupNameByGroupId = groupId => {
      let foundItem = FIXED_GROUP_NAME_LIST.find(group => group.id === groupId);
      if (isEmpty(foundItem)) {
        return 'Other groups';
      }
      return foundItem.groupName;
    };

    return (
      <Modal
        open={isEditModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Edit Reasons For Return</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {loading && (
              <Dimmer active inverted>
                <Loader active inline size="large">
                  Loading...
                </Loader>
              </Dimmer>
            )}
            {!loading && (
              <div>
                {this.state.error && (
                  <Message negative>
                    <p>{this.state.error}</p>
                  </Message>
                )}
                {this.state.message && (
                  <Message positive>
                    <p>{this.state.message}</p>
                  </Message>
                )}
                <Form>
                  <Form.Field>
                    <Checkbox
                      label="Pending Arb"
                      checked={this.state.pendingArbitration}
                      onChange={onCheckboxHandler('pendingArbitration').bind(
                        this
                      )}
                    />
                    {errors.pendingArbitration && (
                      <span style={{ color: 'red' }}>
                        {errors.pendingArbitration}
                      </span>
                    )}
                  </Form.Field>
                  <div>
                    {Object.keys(groupedData).map(groupId => (
                      <div key={groupId}>
                        <h4>{findGroupNameByGroupId(groupId)}</h4>
                        <Form.Group>
                          {groupedData[groupId].map(item => (
                            <Form.Field key={item.id}>
                              <Checkbox
                                label={item.returnReason}
                                checked={this.state[item.id] || false}
                                onChange={onCheckboxHandler(item.id).bind(this)}
                              />{' '}
                            </Form.Field>
                          ))}
                        </Form.Group>
                      </div>
                    ))}
                  </div>
                  <Form.Field>
                    <label>Additional Information:</label>
                    <Input
                      name="additionalInformation"
                      value={this.state.additionalInformation}
                      onChange={onChangeHandler('additionalInformation').bind(
                        this
                      )}
                    />
                    {errors.additionalInformation && (
                      <span style={{ color: 'red' }}>
                        {errors.additionalInformation}
                      </span>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Estimated cost to correct above issue(s):</label>
                    <Input
                      name="estimatedCost"
                      value={this.state.estimatedCost}
                      onChange={onChangeHandler('estimatedCost').bind(this)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Odometer Reading(miles):</label>
                    <Input
                      name="odometerOnReturn"
                      value={this.state.odometerOnReturn}
                      onChange={onChangeHandler('odometerOnReturn').bind(this)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Return Location:</label>
                    {!isEmpty(locations) && (
                      <Dropdown
                        options={returnLocationOptions}
                        value={this.state.auctionLocationId}
                        selected={this.state.returnLocation}
                        onChange={onSelectHandler('auctionLocationId')}
                        fluid
                        selection
                        selectOnBlur={false}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Title Status:</label>
                    <Dropdown
                      options={TITLE_STATUS_OPTIONS}
                      value={this.state.titleStatus}
                      selected={this.state.titleStatus}
                      onChange={onSelectHandler('titleStatus')}
                      fluid
                      selection
                      selectOnBlur={false}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>User Email:</label>
                    <Input
                      name="userEmail"
                      value={this.state.userEmail}
                      onChange={onChangeHandler('userEmail').bind(this)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Return Expiration Date:</label>
                    <DatePicker
                      style={{ padding: '0' }}
                      selected={date}
                      onChange={onDateChangeHandler('expirationDate').bind(
                        this
                      )}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="YYYY-MM-DD hh:mm:ss ZZ"
                    />
                  </Form.Field>
                  <Form.Group widths="1">
                    <Form.Field>
                      <Checkbox
                        label="Vehicle Received:"
                        checked={this.state.vehicleReceived}
                        onChange={onCheckboxHandler('vehicleReceived').bind(
                          this
                        )}
                      />{' '}
                      {'  '}
                      <Checkbox
                        label="Title Received:"
                        checked={this.state.titleReceived}
                        onChange={onCheckboxHandler('titleReceived').bind(this)}
                      />{' '}
                      {'  '}
                      <Checkbox
                        label="Refund Processed:"
                        checked={this.state.refundProcessed}
                        onChange={onCheckboxHandler('refundProcessed').bind(
                          this
                        )}
                      />{' '}
                      {'  '}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="1">
                    <Form.Field>
                      <Link to="">New Audit</Link> {'  '}
                      <Link to="">Send Title Notice</Link>
                      {'  '}
                      <Link to="">Send Return Notice</Link> {'  '}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="1">
                    <Form.Field>
                      <Checkbox
                        label="Send an email with the updates?"
                        checked={this.state.titleNotificationSent}
                        onChange={onCheckboxHandler(
                          'titleNotificationSent'
                        ).bind(this)}
                      />{' '}
                      {'  '}
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.submitForm}>
            Update
          </Button>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default compose(withRouter)(ReturnInvoiceEdit);
