import { gql } from 'react-apollo';

const deleteVinBlacklistMutation = gql`
  mutation deleteVinBlacklist($input: DestroyVinBlacklistInput!) {
    deleteVinBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default deleteVinBlacklistMutation;
