import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Message, Confirm, Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import withPagination from './WithPagination';
// import DealerAndAuctionAccessNumberSearch from '../views/Shared/DealerAndAuctionAccessNumberSearch';
import Search from '../views/Shared/Search';
import LoadingDataEnhancer from './LoadingData';
import SubscriptionsQuery from '../graphql/subscriptionsQuery';
import deleteSubscriptionMutation from '../graphql/deleteSubscriptionMutation';
import updateSubscriptionMutation from '../graphql/updateSubscriptionMutation';
import createSubscriptionMutation from '../graphql/createSubscriptionMutation';
import SubscriptionDetails from '../views/UserDigestSettings/SubscriptionDetails';
import SubscriptionEdit from '../views/UserDigestSettings/SubscriptionEdit';
import SubscriptionNew from '../views/UserDigestSettings/SubscriptionNew';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';

class UserDigestSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flashMessage: null,
      isEditModalOpen: false,
      editFormError: null,
      editFormSubmitting: false,
      isDeleteConfirmationOpen: false,
      isNewModalOpen: false,
      newFormError: null,
      newFormSubmitting: false
    };
  }

  onEditFormCloseHandler = () => {
    this.setState({
      editFormError: null,
      editFormSubmitting: null,
      isEditModalOpen: false
    });
  };

  onNewFormCloseHandler = () => {
    this.setState({
      newFormError: null,
      newFormSubmitting: null,
      isNewModalOpen: false
    });
  };

  openNewForm = () => {
    this.setState({ isNewModalOpen: true });
  };

  handleDeleteConfirm = () => {
    const { deleteMutation } = this.props;
    const { selectedSubscription: { id } } = this.state;
    const input = { id: parseInt(id, 10) };

    deleteMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { deleteSubscriber: { success } } = data;
        if (success) {
          this.setState({
            isEditModalOpen: false,
            flashMessage: 'Successfully Deleted Subscription'
          });

          this.props.data.fetchMore({
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              return fetchMoreResult;
            }
          });
          this.props.setPage({});
        } else {
          this.setState({
            isEditModalOpen: false,
            flashMessage: 'Deleting Subscription not Success'
          });
        }
        this.setState({ isDeleteConfirmationOpen: false });
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          flashMessage: 'Deleting Flat Rate not Success'
        });
      });
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  onEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const input = { ...values };
    this.setState({
      editFormSubmitting: true
    });
    updateMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { modifySubscriber: { success, errors } } = data;
        if (success) {
          this.setState({
            editFormSubmitting: false,
            flashMessage: 'Successfully Updated Subscription',
            changed: true
          });
          this.props.data.fetchMore({
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              return fetchMoreResult;
            }
          });
          this.props.setPage({});
          this.onEditFormCloseHandler();
        } else {
          this.setState({
            editFormSubmitting: false,
            editFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: error
        });
      });
  };

  onNewFormSubmitHandler = values => {
    const { createMutation } = this.props;

    const input = { ...values };
    this.setState({
      newFormSubmitting: true
    });

    createMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { createSubscriber: { success, errors } } = data;
        if (success) {
          this.setState({
            newFormSubmitting: false,
            flashMessage: 'Successfully Created Subscription',
            changed: true
          });
          this.props.data.fetchMore({
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              return fetchMoreResult;
            }
          });
          this.onNewFormCloseHandler();
        } else {
          this.setState({
            newFormSubmitting: false,
            newFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: error
        });
      });
  };

  onSearchSubmitHandler = event => {
    const { auctionAccessNumber, dealerNumber } = event.target;
    const dealerNumbers = isEmpty(dealerNumber.value)
      ? null
      : dealerNumber.value;
    const variableArguments = {
      auctionAccessNumber: auctionAccessNumber.value || null,
      dealerNumber: dealerNumbers
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  onSelectSubscription = (action, subscription) => {
    this.setState({
      selectedSubscription: subscription
    });
    switch (action) {
      case 'Edit':
        this.setState({
          isEditModalOpen: true
        });
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      default:
        this.setState({
          selectedSubscription: null
        });
    }
  };

  render() {
    const {
      flashMessage,
      selectedSubscription,
      isEditModalOpen,
      editFormError,
      editFormSubmitting,
      isNewModalOpen,
      newFormError,
      newFormSubmitting
    } = this.state;

    const { data: { subscriptions }, searchFields } = this.props;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['dealerNumber', 'auctionAccessNumber']}
        />
        <br />
        <Button color="green" onClick={this.openNewForm} inverted>
          Add New Subscription
        </Button>
        <br />
        <br />

        <SubscriptionDetails
          onSelectSubscription={this.onSelectSubscription}
          subscriptions={subscriptions}
        />
        {isEditModalOpen && (
          <SubscriptionEdit
            isModalOpen={isEditModalOpen}
            onCloseHandler={this.onEditFormCloseHandler}
            onSubmitHandler={this.onEditFormSubmitHandler}
            error={editFormError}
            submitting={editFormSubmitting}
            subscription={selectedSubscription}
          />
        )}

        {isNewModalOpen && (
          <SubscriptionNew
            isModalOpen={isNewModalOpen}
            onCloseHandler={this.onNewFormCloseHandler}
            onSubmitHandler={this.onNewFormSubmitHandler}
            error={newFormError}
            submitting={newFormSubmitting}
          />
        )}

        <Confirm
          open={this.state.isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const { data: { searchFields } } = state;
  return {
    searchFields: searchFields
  };
};

export default compose(
  withRouter,
  withPagination(SubscriptionsQuery, 'subscriptions'),
  graphql(deleteSubscriptionMutation, { name: 'deleteMutation' }),
  graphql(updateSubscriptionMutation, { name: 'updateMutation' }),
  graphql(createSubscriptionMutation, { name: 'createMutation' }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(UserDigestSettings);
