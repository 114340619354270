import React from 'react';
import { Select } from 'semantic-ui-react';

const SelectDropdown = options => ({
  input,
  label,
  placeholder,
  type,
  meta,
  ...custom
}) => {
  const { touched, error, warning } = meta;
  const hasError = touched && error !== undefined;

  return (
    <span>
      <Select
        search
        options={options}
        {...input}
        error={hasError}
        placeholder={placeholder}
        {...custom}
        className="min-height"
        onChange={(param, data) => input.onChange(data.value)}
      />
      <br />
      {touched &&
        ((error && <span className="error-text">{error}</span>) ||
          (warning && <span className="warn-text">{warning}</span>))}
    </span>
  );
};

export default SelectDropdown;
