import { gql } from 'react-apollo';

const guaranteeHistoryQuery = gql`
  query guaranteeHistoryReport(
    $startDate: String
    $endDate: String
    $size: Int
    $offset: Int
  ) {
    guaranteeHistoryReport(
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      size: $size
    ) {
      transactionDateAndTime
      auctionDate
      action
      cancellationReason
      cancelledBy
      guaranteeStatus
      guaranteeKind
      paymentKind
      guaranteeChannel
      vin
      dsPrice
      dsDays
      dsMiles
      guaranteeId
      expiryDate
      sellingLocation
      buyerName
      buyerNum
      sellerName
      sellerNum
      saleYear
      saleWeek
      saleLaneRun
      yearMakeModel
      carOdometer
      groupName
      cancelledDate
    }
  }
`;

export default guaranteeHistoryQuery;
