import { gql } from 'react-apollo';

const acceptFlatRateOfferBatchMutation = gql`
  mutation acceptFlatRateOfferBatch($input: AcceptFlatRateOfferBatchInput!) {
    acceptFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default acceptFlatRateOfferBatchMutation;
