import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Table, Modal, Button } from 'semantic-ui-react';
import { map, round, toNumber } from 'lodash';

class PurchasePriceExceedingMmrValueModal extends Component {
  state = {
    message: ''
  };

  render() {
    const {
      percentOver,
      mmrCalculatedValue,
      isPurchasePriceExceedingMmrValueModalOpen,
      onPurchasePriceExceedingMmrValueModalCloseHandler,
      onPurchasePriceExceedingMmrValueModalOkHandler
    } = this.props;
    const percentOverValue =
      toNumber(percentOver) > 0 ? toNumber(percentOver) : 125;
    const TableHeader = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>VIN</Table.HeaderCell>
          <Table.HeaderCell>Vehicle Purchase Price</Table.HeaderCell>
          <Table.HeaderCell>MMR</Table.HeaderCell>
          <Table.HeaderCell>% ABOVE MMR</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );

    return (
      <Modal
        open={isPurchasePriceExceedingMmrValueModalOpen}
        closeIcon
        onClose={onPurchasePriceExceedingMmrValueModalCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
        size={'large'}
      >
        <Modal.Header>
          Vehicle Purchase Price is {percentOverValue}% above MMR for VINs:
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Table celled singleLine verticalAlign="top">
              {TableHeader}
              <Table.Body>
                {map(mmrCalculatedValue, vehicle => (
                  <Table.Row key="modalKey">
                    <Table.Cell>{vehicle.vin}</Table.Cell>
                    <Table.Cell>{vehicle.vehiclePurchasePrice}</Table.Cell>
                    <Table.Cell>{vehicle.mmrValue}</Table.Cell>
                    <Table.Cell>
                      {round(vehicle.percentageAboveMmr, 0)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Button onClick={onPurchasePriceExceedingMmrValueModalOkHandler}>
              {' '}
              OK{' '}
            </Button>
            <Button onClick={onPurchasePriceExceedingMmrValueModalCloseHandler}>
              {' '}
              CANCEL
            </Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    data: { mmrCalculatedValue }
  } = state;
  return {
    mmrCalculatedValue
  };
};

export default compose(connect(mapStateToProps))(
  PurchasePriceExceedingMmrValueModal
);
