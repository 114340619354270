import { gql } from 'react-apollo';

const dealerNumbersQuery = gql`
  query dealerAccounts($auctionAccessNumber: String!, $govtLast4: String) {
    dealerAccounts(
      auctionAccessNumber: $auctionAccessNumber
      govtLast4: $govtLast4
    ) {
      id
      name
    }
  }
`;

export default dealerNumbersQuery;
