import { gql } from 'react-apollo';

const bulkRejectPendingOfferMutation = gql`
  mutation bulkRejectPendingOffer($input: BulkRejectPendingOfferInput!) {
    bulkRejectPendingOffer(input: $input) {
      success
      errors
    }
  }
`;

export default bulkRejectPendingOfferMutation;
