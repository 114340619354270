import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Message, Form, Button, Image } from 'semantic-ui-react';
import logo from '../../assets/dealshield-logo.png';

const LoginForm = ({ error, onSubmit }) => (
  <Grid centered columns={2}>
    <Grid.Column>
      <Image src={logo} />

      {error && (
        <Message negative>
          <Message.Header>Error while logging in</Message.Header>
          <p>{error}</p>
          <p>
            If you continue to have issues, please contact us for further
            assistance.
          </p>
        </Message>
      )}
      <Form as="form" onSubmit={onSubmit}>
        <Form.Field>
          <label>OVE / Manheim Username</label>
          <input type="text" name="username" />
        </Form.Field>
        <Form.Field>
          <label>OVE / Manheim Password</label>
          <input type="password" name="password" />
        </Form.Field>
        <Form.Field>
          <p>
            Forgot your OVE / Manheim username or password? &nbsp;
            <a
              href="https://manheim.com/login/forgot_password"
              rel="noopener noreferrer"
              target="_blank"
            >
              Click here
            </a>
          </p>
        </Form.Field>
        <Button fluid type="submit">
          Login
        </Button>
      </Form>
    </Grid.Column>
  </Grid>
);

LoginForm.propTypes = {
  errors: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  errors: ''
};

export default LoginForm;
