import React from 'react';
import { Grid, Header, Button, Icon, Message } from 'semantic-ui-react';

const HeaderBlock = ({
  vehicleRecord,
  allClosed,
  closeAll,
  openAll,
  paymentStatus,
  formatDateTime,
  expireGuaranteeGreater,
  convertCase
}) => {
  const status = convertCase(paymentStatus(vehicleRecord));
  const showWarning = expireGuaranteeGreater(vehicleRecord);

  return (
    <Grid columns="equal" divided="vertically" className="vst-grid">
      <Grid.Row>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Header as="h1" className={'vin-header'}>
            VIN:{vehicleRecord.vin}
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          {!allClosed && (
            <Button onClick={closeAll} className={'vst-close-button'}>
              Close all &nbsp;
              <Icon name="minus" className={'right-icon'} />
            </Button>
          )}
          {allClosed && (
            <Button onClick={openAll} className={'vst-open-button'}>
              Open all &nbsp; <Icon name="plus" className={'right-icon'} />
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className={'payment-status'}
        >
          {(status === convertCase('Floorplan Paid (Funded)') ||
            status === convertCase('Dealer Paid')) && (
            <Header as="h3" className={'green'}>
              <Icon name="checkmark" className={'green'} />
              {status.toUpperCase()}
            </Header>
          )}
          {(status === convertCase('Floorplan Paid (Unfunded)') ||
            status === convertCase('Floorplan Paid (Partially Funded)') ||
            status === convertCase('Unpaid')) && (
            <Header as="h3" className={'red'}>
              <Icon name="warning" className={'red'} />
              {status.toUpperCase()}
            </Header>
          )}
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
          VST Last updated On: {formatDateTime('updatedOn')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
          {showWarning === true && (
            <Message className={'red'}>
              This record may be out of date. It has been more than 60 days
              since guarantee expiration.
            </Message>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default HeaderBlock;
