import { get } from 'lodash';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import localStore from 'store';

import APP_PATH from '../constants/paths';
import sessionQuery from '../graphql/sessionQuery';
import LoginForm from '../views/Login/Form';
import { setUser } from '../data/user/actions';
import { hasRole } from '../utilities/user';
import PingLoginForm from '../views/Login/PingForm';
import pingUrlQuery from '../graphql/pingUrlQuery';
import USER from '../constants/appUser';
import MaintenancePage from '../containers/MaintenancePage';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  handleSubmit = event => {
    event.preventDefault();
    const { username, password } = event.target;
    const { client, history, signInUser } = this.props;

    client
      .query({
        query: sessionQuery,
        variables: { username: username.value, password: password.value }
      })
      .then(({ data }) => {
        const error = get(data, 'session.error');
        if (error) {
          throw Error(error);
        }
        const accessToken = get(data, 'session.accessToken');
        if (!accessToken) {
          throw Error('Unexpected error');
        }
        const user = get(data, 'session.viewer.user');

        if (hasRole('admin', 'roles')(user)) {
          signInUser(user);
          localStore.set('jwt', accessToken);
          history.push(APP_PATH.BASE);
        } else {
          throw Error('You are not authorized to view this page.');
        }
      })
      .catch(error => {
        this.setState({
          error: error.message
        });
      });
  };

  callPingUrl = () => {
    const { client } = this.props;
    const user = USER.APP;

    this.setState({});
    client
      .query({
        query: pingUrlQuery,
        variables: {
          redirect_uri: window.location.origin + APP_PATH.REDIRECT_URL,
          user_type: 'ADMIN',
          app_name: user
        }
      })
      .then(({ data }) => {
        window.location = data.pingUrl.url;
      })
      .catch(error => {
        this.setState({
          formSubmitting: false,
          error: 'Error while calling Ping'
        });
      });
  };

  render() {
    const { pingEnabled, maintenanceStatusEnabled } = this.props;

    if (maintenanceStatusEnabled) {
      return <MaintenancePage />;
    }

    if (pingEnabled === '') {
      return null;
    }
    if (pingEnabled) {
      return <PingLoginForm onSubmit={this.callPingUrl} />;
    }
    return <LoginForm error={this.state.error} onSubmit={this.handleSubmit} />;
  }
}

const mapDispatchToProps = dispatch => ({
  signInUser: user => {
    dispatch(setUser(user));
  }
});

export default compose(
  withApollo,
  withRouter,
  connect(null, mapDispatchToProps)
)(Login);
