import * as actionType from './actionTypes';
import _ from 'lodash';

const initialState = {};

const secondarySearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_SECONDARY_SEARCH:
      return action.payload;
    case actionType.CLEAR_SECONDARY_SEARCH:
      return _.omit(state, action.payload);
    case actionType.CLEAR_ALL_SECONDARY_SEARCH:
      return {};
    default:
      return state;
  }
};

export default secondarySearchReducer;
