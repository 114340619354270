import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import ListRow from './ListRow';
import Filter from './Filter';

class EnrollmentStatusList extends Component {
  render() {
    const {
      enrollments,
      handleSort,
      column,
      direction,
      handlePendingAcceptanceLink
    } = this.props;
    const listRow = ListRow(handlePendingAcceptanceLink);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell
          sorted={column === 'dealerNo' ? direction : null}
          onClick={handleSort('dealerNo')}
        >
          Dealer
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'status' ? direction : null}
          onClick={handleSort('status')}
        >
          Status
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'sendAtWithTime' ? direction : null}
          onClick={handleSort('sendAtWithTime')}
        >
          Quote Sent
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'daysToExpire' ? direction : null}
          onClick={handleSort('daysToExpire')}
        >
          Days to Expiration
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            column === 'flatRateOfferBatch.buyerInformation.name'
              ? direction
              : null
          }
          onClick={handleSort('flatRateOfferBatch.buyerInformation.name')}
        >
          100M
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'productName' ? direction : null}
          onClick={handleSort('productName')}
        >
          Product
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'offeringUserEmail' ? direction : null}
          onClick={handleSort('offeringUserEmail')}
        >
          Quote Originator
        </Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <Filter {...this.props} />
        <Table
          celled
          selectable
          padded
          sortable
          renderBodyRow={listRow}
          tableData={enrollments}
          headerRow={headerRow}
          size="small"
          striped
          compact="very"
          style={{ fontSize: '90%' }}
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(EnrollmentStatusList);
