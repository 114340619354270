import React, { Component } from 'react';
import { toNumber, toString, toSafeInteger } from 'lodash';
import { Form, Button, Modal, Icon, Message } from 'semantic-ui-react';
import Validator from 'validatorjs';
class GuaranteePurchaseExtend extends Component {
  state = {
    id: '',
    extensionDays: 0,
    extensionReason: '',
    errors: {}
  };

  componentDidMount() {
    const { guaranteePurchase } = this.props;
    const { id, extensionDays, extensionReason } = guaranteePurchase;

    this.setState({
      id: toNumber(id),
      extensionDays: toSafeInteger(extensionDays),
      extensionReason: toString(extensionReason)
    });
  }

  render() {
    const {
      guaranteePurchase,
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const validationRules = {
      extensionDays: 'numeric|customDays'
    };

    Validator.register(
      'customDays',
      function(value, requirement, attribute) {
        return value >= 0;
      },
      'Should be a positive number.'
    );

    const submitForm = () => {
      const { id, extensionDays, extensionReason } = this.state;
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        this.setState({ errors: {} });
        onSubmitHandler({
          id: parseInt(id, 10),
          extensionDays: parseInt(extensionDays, 10),
          extensionReason: extensionReason
        });
      }
    };

    const { errors } = this.state;

    const {
      expiresAt,
      guaranteeOffer: { vehicleInformation: { vin } }
    } = guaranteePurchase;

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Guarantee Extension for VIN {vin}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {error && (
              <Message negative>
                <Message.Header>
                  Error while updating guarantee purchase
                </Message.Header>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Current Expiration Date</label>
                  <input name="expiresAt" disabled value={expiresAt} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Extension Days</label>
                  <input
                    type="text"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    name="extensionDays"
                    value={this.state.extensionDays}
                    onChange={onChangeHandler('extensionDays').bind(this)}
                  />
                  {errors.extensionDays && (
                    <span style={{ color: 'red' }}>{errors.extensionDays}</span>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <label>Extension Reason</label>
                  <input
                    name="extensionReason"
                    value={this.state.extensionReason}
                    onChange={onChangeHandler('extensionReason').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GuaranteePurchaseExtend;
