import * as actionType from './actionTypes';

const initialState = [];

const mmrValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_MMR_VALUE:
    const newStateArray = state.filter(
        item =>
          !(
            item.vin === action.payload.vin
          )
      );

      newStateArray.splice(state.length, 0, action.payload);
      return newStateArray;
    default:
      return state;
  }
};

export default mmrValueReducer;
