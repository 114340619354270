import { gql } from 'react-apollo';

const createDealershipBlacklistMutation = gql`
  mutation createDealershipBlacklist($input: CreateDealershipBlacklistInput!) {
    createDealershipBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default createDealershipBlacklistMutation;
