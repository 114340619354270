import { gql } from 'react-apollo';

const dealerGroupQuery = gql`
  query dealerGroupList($groupName: String, $size: Int, $page: Int) {
    dealerGroupList(groupName: $groupName, size: $size, page: $page) {
      groupId
      groupName
      description
      createdAt
      updatedAt
    }
  }
`;

export default dealerGroupQuery;
