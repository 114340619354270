import { gql } from 'react-apollo';

const dealershipBlacklistsQuery = gql`
  query dealershipBlacklists($dealerNumber: String, $size: Int, $offset: Int) {
    dealershipBlacklists(
      dealerNumber: $dealerNumber
      offset: $offset
      size: $size
    ) {
      id
      dealerNo
      expirationDate
      notes
      status
      createdAt
    }
  }
`;

export default dealershipBlacklistsQuery;
