import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header, Form } from 'semantic-ui-react';
import _ from 'lodash';

import { isEmpty } from 'lodash';
import ListRow from './ListRow';

class SubscriptionsList extends Component {
  render() {
    const {
      onSelectSubscription,
      subscriptions,
      isDisable,
      customerNotes,
      dealerInfo
    } = this.props;
    const listRow = ListRow(onSelectSubscription, isDisable);

    const sortByActive = items =>
      [...items].sort((itemA, itemB) => {
        const firstPrior =
          itemA.active.toString() > itemB.active.toString() ? -1 : 1;
        const secondPrior =
          new Date(itemB.startDate) - new Date(itemA.startDate);
        return itemA.active === itemB.active ? secondPrior : firstPrior;
      });
    const subscriptionsSorted = subscriptions;

    const showDealer = () => {
      if (isEmpty(subscriptions)) {
        return '';
      }

      if (isEmpty(subscriptions[0].dealerName)) {
        return `${subscriptions[0].dealerNumber}`;
      }

      return (
        !isEmpty(subscriptions) &&
        `${subscriptions[0].dealerNumber} - ${subscriptions[0].dealerName}`
      );
    };

    const showSubscriptionId = () => {
      return (
        !isEmpty(subscriptions) &&
        `Subscription ID: ${subscriptions[0].subscription_id}`
      );
    };

    const showCustomerNotes = () => {
      if (!customerNotes) {
        return '';
      }
      if (
        customerNotes &&
        (!customerNotes.message || isEmpty(customerNotes.message))
      ) {
        return '';
      }
      return customerNotes.message;
    };
    const isShowCustomerNotes = () => {
      if (isEmpty(showDealer())) {
        return false;
      }
      return true;
    };

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Product Name</Table.HeaderCell>
        <Table.HeaderCell>Price Start Date</Table.HeaderCell>
        <Table.HeaderCell>Price DS</Table.HeaderCell>
        <Table.HeaderCell>Price PSI LPI</Table.HeaderCell>
        <Table.HeaderCell>Notify Email</Table.HeaderCell>
        <Table.HeaderCell>Network Plus Adjustment</Table.HeaderCell>
        <Table.HeaderCell>Subscription Start Date</Table.HeaderCell>
        <Table.HeaderCell>Subscription End Date</Table.HeaderCell>
        <Table.HeaderCell>Ds Paid PSI</Table.HeaderCell>
        <Table.HeaderCell>Options</Table.HeaderCell>
      </Table.Row>
    );

    return (
      <div>
        <div>
          <Header style={headerStyle}>
            <span>Subscriptions Manager</span>
            <span>{dealerInfo}</span>
            <span></span>
            <span>{showSubscriptionId()}</span>
          </Header>
          {isShowCustomerNotes() && (
            <Form style={{ paddingRight: '10px' }}>
              <Form.Group widths={'two'} style={containerStyle}>
                <div style={fieldStyle}>
                  <input
                    style={customerNotesStyle}
                    value={showCustomerNotes()}
                    disabled
                  />
                </div>
              </Form.Group>
            </Form>
          )}
          <Table
            celled
            selectable
            padded
            renderBodyRow={listRow}
            tableData={subscriptionsSorted}
            headerRow={headerRow}
            size="small"
            compact="very"
            style={{ fontSize: '90%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

const headerStyle = {
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto auto'
};
const containerStyle = {
  flexDirection: 'row-reverse',
  textAlign: 'right'
};
const customerNotesStyle = {
  color: 'rgb(34 36 38 / 52%)',
  textAlign: 'right'
};
// with character length is 50 width is 360px is fine but if the length change the width will be change also
const fieldStyle = {
  textAlign: 'right',
  width: '360px'
};
export default compose(withApollo, withRouter)(SubscriptionsList);
