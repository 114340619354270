import ApolloClient, { createNetworkInterface, toIdValue } from 'apollo-client';
import EVENTS from './helpers/customEvents';
import localStore from 'store'; // cross-browser local storage

const networkInterface = createNetworkInterface({
  uri: process.env.REACT_APP_API_URL
});

networkInterface.use([
  {
    applyMiddleware(req, next) {
      if (!req.options.headers) {
        req.options.headers = {};
      }

      const token = localStore.get('jwt');
      req.options.headers.authorization = token ? `Bearer ${token}` : null;
      next();
    }
  }
]);

const unauthorizedHandler = {
  applyAfterware({ response }, next) {
    if (response.status === 401) {
      window.dispatchEvent(EVENTS.unauthorized);
    }
    next();
  }
};

networkInterface.useAfter([unauthorizedHandler]);

const dataIdFromObject = o => o.id;

const client = new ApolloClient({
  networkInterface,
  customResolvers: {
    Query: {
      transaction: (_, args) =>
        toIdValue(
          dataIdFromObject({
            __typename: 'transaction',
            id: args.id
          })
        )
    }
  },
  dataIdFromObject
});

export default client;
