import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isEmpty } from 'lodash';
import { Message } from 'semantic-ui-react';
import { allowAccess } from '../utilities/user';
import requireAuth from '../containers/RequireAuth';
import StoreInsertion from '../containers/StoreInsertion';

const authorize = (allowedRoles, extraProps) => WrappedComponent => {
  class WithAuthorization extends Component {
    render() {
      const { user } = this.props;
      StoreInsertion(this.props, extraProps);

      if (allowAccess(user, allowedRoles)) {
        return <WrappedComponent {...this.props} />;
      } else {
        if (!isEmpty(user)) {
          return (
            <Message
              error
              header="You do not have permissions to view this page."
              list={[
                'Please contact Dealshield support, to enable the right permissions, in case you need to view this page.'
              ]}
            />
          );
        } else {
          return <p />;
        }
      }
    }
  }

  const mapStateToProps = state => {
    return { user: state.data.user };
  };

  return compose(requireAuth, connect(mapStateToProps, null))(
    WithAuthorization
  );
};

export default authorize;
