import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { toNumber } from 'lodash';
import { connect } from 'react-redux';

import { Button, Confirm } from 'semantic-ui-react';

import LoadingDataEnhancer from '../../containers/LoadingData';
import withPagination from '../../containers/WithPagination';
import ExceptionList from './List';

import DealerExceptionForm from './DealerException/Form';

import autoCancellationExceptionListQuery from '../../graphql/autoCancellationExceptionListQuery';
import createAutoCancellationExceptionMutation from '../../graphql/createAutoCancellationExceptionMutation';
import updateAutoCancellationExceptionMutation from '../../graphql/updateAutoCancellationExceptionMutation';
import deleteAutoCancellationExceptionMutation from '../../graphql/deleteAutoCancellationExceptionMutation';
import { setPage } from '../../data/page/actions';

const defaultDealerException = {
  id: 0,
  dealerNumber: '',
  dealerName: '',
  gracePeriod: '',
  exceptionEndTime: '',
  createdBy: '',
  createdAt: '',
  lastUpdatedBy: '',
  lastUpdatedAt: ''
};
const fieldsToCompare = ['gracePeriod', 'exceptionEndTime'];

class DealerExceptions extends Component {
  state = {
    selectedDealerException: null,
    isDeleteConfirmationOpen: false,
    isModalOpen: false,
    formError: null,
    formSubmitting: null,
    flashMessage: null,
    isNegativeFlashMessage: false,
    locations: []
  };

  isChange = (values, selectedDealerException, fieldsToCompare) => {
    for (const field of fieldsToCompare) {
      if (values[field] !== selectedDealerException[field]) {
        return true;
      }
    }
    return false;
  };
  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  onSelectDealerException = (action, dealerException) => {
    this.setState({
      selectedDealerException: dealerException,
      flashMessage: null,
      isNegativeFlashMessage: false
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isModalOpen: true
        });
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      default:
        this.setState({
          selectedDealerException: null
        });
    }
  };

  onFormSubmitHandler = async values => {
    const {
      createAutoCancellationExceptionMutation,
      updateAutoCancellationExceptionMutation
    } = this.props;
    const { selectedDealerException } = this.state;
    this.setState({
      formSubmitting: true
    });
    const action = values['action'];
    try {
      switch (action) {
        case 'Create':
          await this.onExcuteMutationDealerException(
            createAutoCancellationExceptionMutation,
            'createAutoCancellationException',
            values,
            action
          );
          break;
        case 'Update':
          if (this.isChange(values, selectedDealerException, fieldsToCompare)) {
            await this.onExcuteMutationDealerException(
              updateAutoCancellationExceptionMutation,
              'updateAutoCancellationException',
              values,
              action
            );
          } else {
            // unchanged
            this.onFormCloseHandler();
          }
          break;
      }
    } catch (error) {
      this.onFailedSubmitDealerException(
        action,
        Array.isArray(error) ? error : error.message
      );
    }
  };

  handleDeleteConfirm = async () => {
    const {
      selectedDealerException: { id }
    } = this.state;
    const { deleteAutoCancellationExceptionMutation } = this.props;
    const action = 'Delete';
    try {
      await this.onExcuteMutationDealerException(
        deleteAutoCancellationExceptionMutation,
        'deleteAutoCancellationException',
        { id: id },
        action
      );
    } catch (error) {
      this.onFailedSubmitDealerException(
        action,
        Array.isArray(error) ? error : error.message
      );
    }
  };

  onFormCloseHandler = () => {
    this.setState({
      isModalOpen: false,
      selectedGuaranteePurchase: null,
      formError: null,
      formSubmitting: null
    });
  };

  onClickNewDealerException = () => {
    this.setState({
      selectedDealerException: defaultDealerException,
      isModalOpen: true
    });
  };
  successSubmitDealerException = action => {
    this.setState({
      formSubmitting: false,
      formError: null,
      flashMessage: `Successfully ${action}d Dealer Exception.`
    });
  };

  onFailedSubmitDealerException = (action, errorMessage) => {
    if (action === 'Delete') {
      this.setState({
        isDeleteConfirmationOpen: false,
        flashMessage: `Unsuccessfully Deleted Dealer Exception with error: ${errorMessage}`,
        isNegativeFlashMessage: true
      });
    } else {
      this.setState({
        formSubmitting: false,
        formError: errorMessage
      });
    }
  };
  getCreateExpiredVraGracePeriodMutationInput = (action, values) => {
    return {
      exceptionType: 'DEALER',
      exceptionSourceId: toNumber(values['dealerNumber']),
      gracePeriod: toNumber(values['gracePeriod']),
      exceptionEndTime: values['exceptionEndTime'],
      id: values['id'] ? toNumber(values['id']) : 0
    };
  };
  onExcuteMutationDealerException = async (
    mutation,
    mutationResultName,
    values,
    action
  ) => {
    let input = this.getCreateExpiredVraGracePeriodMutationInput(
      action,
      values
    );
    await mutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { success, msg } = data[mutationResultName];
        if (success) {
          this.successSubmitDealerException(action);
          this.onFormCloseHandler();
          if (action === 'Delete') {
            this.setState({
              isDeleteConfirmationOpen: false
            });
          }
          this.props.setPage({});
          this.props.data.refetch();
        } else {
          this.onFailedSubmitDealerException(action, msg);
        }
      })
      .catch(error => {
        this.onFailedSubmitDealerException(
          action,
          Array.isArray(error) ? error : error.message
        );
      });
  };

  componentDidMount() {
    this.props.setPage({});
  }

  render() {
    const {
      data: { autoCancellationExceptionList }
    } = this.props;

    const {
      selectedDealerException,
      isDeleteConfirmationOpen,
      formSubmitting,
      isModalOpen,
      formError,
      flashMessage,
      isNegativeFlashMessage
    } = this.state;
    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
            negative={isNegativeFlashMessage}
          />
        )}
        <Button
          inverted
          color={'green'}
          onClick={this.onClickNewDealerException}
        >
          New Dealer Exception
        </Button>
        <br />
        <br />
        <ExceptionList
          onSelectException={this.onSelectDealerException}
          exceptions={autoCancellationExceptionList}
          exceptionType={'DEALER'}
        />

        {isModalOpen && (
          <DealerExceptionForm
            isModalOpen={isModalOpen}
            onCloseHandler={this.onFormCloseHandler}
            onSubmitHandler={this.onFormSubmitHandler}
            dealerException={selectedDealerException}
            error={formError}
            submitting={formSubmitting}
          />
        )}

        <Confirm
          open={isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { user }
  } = state;
  return {
    user: user
  };
};
export default compose(
  withRouter,
  withPagination(
    autoCancellationExceptionListQuery,
    'autoCancellationExceptionList',
    { exceptionTypes: 'DEALER' }
  ),
  graphql(createAutoCancellationExceptionMutation, {
    name: 'createAutoCancellationExceptionMutation'
  }),
  graphql(updateAutoCancellationExceptionMutation, {
    name: 'updateAutoCancellationExceptionMutation'
  }),
  graphql(deleteAutoCancellationExceptionMutation, {
    name: 'deleteAutoCancellationExceptionMutation'
  }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(DealerExceptions);
