import { ExportToCsv } from 'export-to-csv';

export const generateCSVFile = (data, filename) => {
  const optionsCSV = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    useTextFile: false, // if true return .txt file instead of .csv
    useBom: true,
    useKeysAsHeaders: false,
    filename: filename
  };

  const csvExporter = new ExportToCsv(optionsCSV);

  csvExporter.generateCsv(data);
};
