import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Table, Dropdown, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ACCESS from '../../constants/access';
import { allowAccess } from '../../utilities/user';
import APP_PATH from '../../constants/paths';
import { find, result } from 'lodash';
import { RETURN_STATUS } from '../../constants';

const ListRow = (actionHandler, user) => returnInvoice => {
  let rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 2, text: 'Cancel Return', value: 'Cancel Return' },
    { key: 3, text: 'Status History', value: 'Audit' },
    { key: 4, text: 'Vehicle Status Tracker', value: 'Vehicle Status Tracker' }
  ];

  const {
    id,
    reinstatedAt,
    reinstatedBy,
    transaction: {
      vin,
      dealerNumber,
      dealershipName,
      odometer,
      vehicleReceived,
      titleReceived,
      refundProcessed,
      returnInitiatedAt,
      purchasePrice,
      returnLocation
    },
    expirationDate,
    status,
    returningUser
  } = returnInvoice;
  const currencyProps = price => {
    return {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 0,
      value: price
    };
  };

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, returnInvoice);
  };

  if (allowAccess(user, ACCESS.VEHICLE_RESEND_VRA)) {
    rowMenuOptions.push({
      key: 6,
      text: 'Resend VRA status',
      value: 'Resend VRA status'
    });
  }

  if (returnInvoice.status === 'unselected') {
    rowMenuOptions = rowMenuOptions.filter(item => item.text !== 'Edit');
  }

  return (
    <Table.Row key={id}>
      <Table.Cell>
        <Link
          to={{
            pathname: APP_PATH.RETURN_CERTIFICATE.replace(
              ':returnInvoiceId',
              id
            )
          }}
        >
          {id}
        </Link>
      </Table.Cell>
      <Table.Cell>{vin}</Table.Cell>
      <Table.Cell>{returnLocation && returnLocation.initials}</Table.Cell>
      <Table.Cell>{odometer}</Table.Cell>
      <Table.Cell textAlign="center">
        {vehicleReceived && (
          <Icon color="green" name="checkmark" size="large" />
        )}
        {!vehicleReceived && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {titleReceived && <Icon color="green" name="checkmark" size="large" />}
        {!titleReceived && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {refundProcessed && (
          <Icon color="green" name="checkmark" size="large" />
        )}
        {!refundProcessed && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell>{returnInitiatedAt}</Table.Cell>
      <Table.Cell>{expirationDate}</Table.Cell>
      <Table.Cell>
        {result(find(RETURN_STATUS, ['value', status]), 'text') || status}
      </Table.Cell>
      <Table.Cell>{returningUser && returningUser.username}</Table.Cell>
      <Table.Cell>{reinstatedAt}</Table.Cell>
      <Table.Cell>{reinstatedBy && reinstatedBy.username}</Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(purchasePrice)} />
      </Table.Cell>
      <Table.Cell>{dealerNumber}</Table.Cell>
      <Table.Cell>{dealershipName}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
