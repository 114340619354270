import { gql } from 'react-apollo';

const updateAutoCancellationExceptionMutation = gql`
  mutation updateAutoCancellationException(
    $id: Int
    $gracePeriod: Int
    $exceptionEndTime: String
    $exceptionType: String
    $exceptionReason: String
    $exceptionSourceId: Int
  ) {
    updateAutoCancellationException(
      input: {
        autoCancellationException: {
          id: $id
          gracePeriod: $gracePeriod
          exceptionEndTime: $exceptionEndTime
          exceptionType: $exceptionType
          exceptionReason: $exceptionReason
          exceptionSourceId: $exceptionSourceId
        }
      }
    ) {
      clientMutationId
      data
      errors
      msg
      notice
      success
    }
  }
`;

export default updateAutoCancellationExceptionMutation;
