import gql from 'graphql-tag';

const subscriptionQuery = gql`
  query subscriptionQuery(
    $dealerNumbers: String
    $page: Int
    $size: Int
    $offset: Int
  ) {
    subscription(
      dealerNumbers: $dealerNumbers
      page: $page
      size: $size
      offset: $offset
    ) {
      id
      subscription_id
      dealerName
      dealerNumber
      productId
      productName
      subscriptionType
      priceDs
      pricePsiLpi
      notifyEmail
      networkPlusRate
      active
      startDate
      endDate
      dealerName
      dsPaidPsi
      subscriptionCancellationReason
      priceStartDate
    }
  }
`;

export default subscriptionQuery;
