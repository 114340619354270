import { gql } from 'react-apollo';

const resetFlatRateOfferBatchMutation = gql`
  mutation resetFlatRateOfferBatch($input: ResetFlatRateOfferBatchInput!) {
    resetFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default resetFlatRateOfferBatchMutation;
