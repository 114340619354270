import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Message, Confirm } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import Search from '../views/Shared/Search';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import ReturnInvoicesList from '../views/ReturnInvoices/List';
import ReturnInvoiceCancel from '../views/ReturnInvoices/CancelForm';
import ReturnInvoiceAudit from '../views/ReturnInvoices/Audit';
import ReturnInvoiceEdit from '../views/ReturnInvoices/Edit';

import returnInvoicesQuery from '../graphql/returnInvoicesQuery';
import cancelReturnInvoiceMutation from '../graphql/cancelReturnInvoiceMutation';
import updateReturnInvoiceMutation from '../graphql/updateReturnInvoiceMutation';
import resendVraStatusMutation from '../graphql/resendVraStatusMutation';
import { clearSearch, setSearch } from '../data/searchField/actions';
import { setRefreshSearch } from '../data/refreshSearch/actions';
import APP_PATH from '../constants/paths';
import { setPage } from '../data/page/actions';

class ReturnInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReturnInvoice: null,
      isCancelModalOpen: false,
      resendFormError: null,
      cancelFormError: null,
      cancelFormSubmitting: null,
      flashMessage: null,
      vinSearch: '',
      dealerSearch: '',
      isAuditModalOpen: false,
      isConfirmationOpen: false,
      isEditModalOpen: false,
      editFormError: null,
      editFormSubmitting: null,
      isNegativeFlashMessage: false
    };

    const { clearSearchFields, dispatchRefreshSearch, refreshSearch } = props;

    if (refreshSearch) {
      dispatchRefreshSearch(false);
      clearSearchFields({});
      const variableArguments = {
        vin: null,
        dealerNumbers: []
      };
      this.props.data.refetch({
        ...variableArguments
      });
    }
  }

  handleDismiss = () => {
    this.setState({
      flashMessage: null,
      editFormError: null
    });
  };

  onSelectReturnInvoice = (action, returnInvoice) => {
    this.setState({
      selectedReturnInvoice: returnInvoice
    });

    const { history } = this.props;
    switch (action) {
      case 'Edit':
        this.setState({
          editFormError: null,
          isCancelModalOpen: false,
          editFormSubmitting: null,
          isEditModalOpen: true
        });
        break;
      case 'Cancel Return':
        this.setState({
          isCancelModalOpen: true,
          cancelFormError: null,
          cancelFormSubmitting: null,
          isEditModalOpen: false
        });
        break;
      case 'Vehicle Status Tracker':
        history.push(
          APP_PATH.VEHICLE_STATUS_TRACKER.replace(
            ':purchaseId',
            returnInvoice.guaranteePurchaseId
          )
        );
        break;
      case 'Audit':
        this.setState({
          isAuditModalOpen: true,
          isEditModalOpen: false,
          cancelFormError: null,
          cancelFormSubmitting: null
        });
        break;
      case 'Resend VRA status':
        this.setState({
          isConfirmationOpen: true,
          resendFormError: null,
          isEditModalOpen: false
        });
        break;
      default:
        this.setState({
          selectedReturnInvoice: null
        });
    }
  };

  onCancelFormCloseHandler = () => {
    this.setState({
      isCancelModalOpen: false,
      selectedReturnInvoice: null
    });
  };

  onEditFormCloseHandler = () => {
    this.setState({
      isEditModalOpen: false,
      selectedReturnInvoice: null
    });
  };

  onAuditCloseHandler = () => {
    this.setState({
      isAuditModalOpen: false,
      selectedGuaranteePurchase: null
    });
  };

  handleConfirmationClose = () => {
    this.setState({ isConfirmationOpen: false });
  };

  handleConfirm = () => {
    const {
      selectedReturnInvoice: { id }
    } = this.state;
    const { resendVraMutation } = this.props;
    const input = { returnInvoiceId: id };
    resendVraMutation({
      variables: { input }
    })
      .then(({ data }) => {
        this.setState({
          isConfirmationOpen: false,
          resendFormError: null,
          flashMessage: 'Successfully Resend VRA status'
        });
      })
      .catch(error => {
        this.setState({
          isConfirmationOpen: false,
          resendFormError: 'Error while Resending VRA status'
        });
      });
  };

  onCancelFormSubmitHandler = values => {
    this.setState({
      cancelFormSubmitting: true
    });
    const { cancelMutation } = this.props;
    const cancelInput = { ...values };
    cancelMutation({
      variables: { cancelInput }
    })
      .then(({ data }) => {
        const {
          cancelReturnInvoice: { success, errors }
        } = data;
        this.setState({
          isCancelModalOpen: false,
          selectedReturnInvoice: null,
          cancelFormSubmitting: null,
          flashMessage: success
            ? 'Successfully Cancelled Return Invoice'
            : errors,
          isNegativeFlashMessage: !success
        });
        this.props.data.fetchMore({
          updateQuery: (
            previousResult,
            { fetchMoreResult, queryVariables }
          ) => {
            return fetchMoreResult;
          }
        });
      })
      .catch(error => {
        this.setState({
          cancelFormSubmitting: false,
          cancelFormError: 'Error while cancelling Return Invoice'
        });
      });
  };

  onEditFormSubmitHandler = values => {
    this.setState({
      editFormSubmitting: true
    });
    const { editMutation } = this.props;
    const input = { returnInvoice: { ...values } };
    editMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const {
          updateReturnInvoice: { success, errors }
        } = data;
        if (success) {
          this.setState({
            isEditModalOpen: false,
            selectedReturnInvoice: null,
            editFormSubmitting: null,
            flashMessage: 'Successfully Edited Return Invoice'
          });
          this.props.data.refetch();
          this.onEditFormCloseHandler();
        } else {
          this.setState({
            editFormSubmitting: false,
            editFormError: errors
          });
          this.onEditFormCloseHandler();
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: 'Error updating return invoice'
        });
        this.onEditFormCloseHandler();
      });
  };

  onSearchSubmitHandler = event => {
    const { vin, dealerNumbers, vra } = event.target;
    const dealerNos = isEmpty(dealerNumbers.value)
      ? null
      : [dealerNumbers.value.trim()];
    const variableArguments = {
      vin: vin.value.trim() || null,
      dealerNumbers: dealerNos,
      vra: vra.value || null
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({
      cancelFormSubmitting: true
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  render() {
    const {
      data: { returnInvoices },
      searchFields
    } = this.props;
    const {
      selectedReturnInvoice,
      isCancelModalOpen,
      isAuditModalOpen,
      isEditModalOpen,
      resendFormError,
      cancelFormError,
      editFormError,
      cancelFormSubmitting,
      flashMessage,
      editFormSubmitting,
      isNegativeFlashMessage
    } = this.state;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
            negative={isNegativeFlashMessage}
          />
        )}
        {editFormError && (
          <Message
            onDismiss={this.handleDismiss}
            content={editFormError}
            negative
          />
        )}
        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['vin', 'dealerNumbers', 'vra']}
        />
        <ReturnInvoicesList
          onSelectReturnInvoice={this.onSelectReturnInvoice}
          returnInvoices={returnInvoices}
          user={this.props.user}
        />
        {selectedReturnInvoice && isAuditModalOpen && (
          <div>
            <ReturnInvoiceAudit
              isAuditModalOpen={isAuditModalOpen}
              onCloseHandler={this.onAuditCloseHandler}
              returnInvoice={selectedReturnInvoice}
            />
          </div>
        )}
        {selectedReturnInvoice && isCancelModalOpen && (
          <div>
            <ReturnInvoiceCancel
              isCancelModalOpen={isCancelModalOpen}
              onCloseHandler={this.onCancelFormCloseHandler}
              onSubmitHandler={this.onCancelFormSubmitHandler}
              returnInvoice={selectedReturnInvoice}
              error={cancelFormError}
              submitting={cancelFormSubmitting}
            />
          </div>
        )}
        {selectedReturnInvoice && isEditModalOpen && (
          <div>
            <ReturnInvoiceEdit
              isEditModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              onSubmitHandler={this.onEditFormSubmitHandler}
              returnInvoice={selectedReturnInvoice}
              error={editFormError}
              submitting={editFormSubmitting}
            />
          </div>
        )}
        <Confirm
          open={this.state.isConfirmationOpen}
          onCancel={this.handleConfirmationClose}
          onConfirm={this.handleConfirm}
          error={resendFormError}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  dispatchRefreshSearch: value => {
    dispatch(setRefreshSearch(value));
  },
  clearSearchFields: fields => {
    dispatch(clearSearch());
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields, user, refreshSearch }
  } = state;
  return {
    searchFields: searchFields,
    user: user,
    refreshSearch
  };
};

export default compose(
  withRouter,
  withPagination(returnInvoicesQuery, 'returnInvoices'),
  graphql(cancelReturnInvoiceMutation, { name: 'cancelMutation' }),
  graphql(updateReturnInvoiceMutation, { name: 'editMutation' }),
  graphql(resendVraStatusMutation, { name: 'resendVraMutation' }),
  LoadingDataEnhancer,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReturnInvoices);
