import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Form,
  Button,
  Modal,
  Icon,
  Select,
  Message,
  Input,
  Checkbox
} from 'semantic-ui-react';

import {
  dateTimeFormattedWithOutTimeZone,
  utcMoment
} from '../../../utilities/dateUtils';
import { get, isEmpty, isNumber, toNumber } from 'lodash';
import Validator from 'validatorjs';
import client from '../../../apollo';
import apmsLocationListQuery from '../../../graphql/apmsLocationListQuery';
import ExceptionForm from '../Form';

class AuctionExceptionForm extends ExceptionForm {
  maxLengthReason = 30;

  state = {
    id: '',
    auctionName: '',
    gracePeriod: '',
    exceptionReason: '',
    exceptionEndTime: '',
    exceptionEndTimeString: '',
    exceptionEndTimeTemp: '',
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    selectedLocationOption: null,
    isNoEndDate: false,
    action: 'Create',
    error: null,
    errors: {},
    debounceTimeout: null,
    auctionLocationOptions: []
  };

  componentDidMount() {
    const { auctionException, locations } = this.props;
    const {
      id,
      exceptionSourceId,
      exceptionSourceName,
      gracePeriod,
      exceptionReason,
      exceptionEndTime,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt
    } = auctionException;
    const action = id === 0 ? 'Create' : 'Update';
    const auctionLocationOptions = this.auctionLocationOptions(locations);
    this.setState({
      id: toNumber(id),
      auctionId: exceptionSourceId,
      auctionName: exceptionSourceName,
      gracePeriod: gracePeriod,
      exceptionReason: exceptionReason,
      exceptionEndTime: exceptionEndTime,
      exceptionEndTimeTemp: exceptionEndTime,
      exceptionEndTimeString: this.initExceptionEndTimeString(exceptionEndTime),
      createdBy: createdBy,
      createdAt: createdAt,
      updatedBy: updatedBy,
      updatedAt: updatedAt,
      isNoEndDate: this.initIsNoEndDate(id, exceptionEndTime),
      auctionLocationOptions: auctionLocationOptions,
      auctionLocationOptionsCache: auctionLocationOptions,
      action: action,
      selectedLocationOption: this.initSelectedLocation(
        action,
        exceptionSourceId
      )
    });
  }

  initSelectedLocation = (action, auctionId) => {
    if (action === 'Create') {
      return null;
    } else {
      return auctionId;
    }
  };
  locationSearchQuery = keyword => {
    if (!isEmpty(keyword)) {
      client
        .query({
          query: apmsLocationListQuery,
          variables: {
            name: keyword,
            page: 1,
            size: 200
          }
        })
        .then(({ data }) => {
          let locations = get(data, 'apmsLocationList');
          if (locations) {
            this.setState({
              auctionLocationOptions: this.auctionLocationOptions(locations),
              isLoading: false
            });
          } else {
            this.setState({
              auctionLocationOptions: [],
              isLoading: false
            });
          }
        })
        .catch(error => {
          this.setState({ auctionLocationOptions: [], isLoading: false });
        });
    } else {
      // reset to the location from the load first
      this.setState({
        auctionLocationOptions: this.auctionLocationOptions(
          this.props.locations
        ),
        isLoading: false
      });
    }
  };
  auctionLocationOptions = locations => {
    if (!isEmpty(locations)) {
      return locations.map(function(location) {
        return {
          value: location.id,
          text: location.name
        };
      });
    } else {
      return [];
    }
  };

  handleSelectLocationChange = (event, { value }) => {
    const auction = this.state.auctionLocationOptions.find(
      location => location.value === value
    );
    if (auction) {
      this.setState({
        selectedLocationOption: auction.value, // id
        auctionName: auction.text // name
      });
    } else {
      this.setState({
        selectedLocationOption: null,
        auctionName: null
      });
    }
  };

  handleSearchChange = (e, { searchQuery }) => {
    // Cancel the previous timer (if any)
    if (this.state.debounceTimeout) {
      clearTimeout(this.state.debounceTimeout);
    }

    // Set a new timer to delay the API call by 1 second
    const debounceTimeout = setTimeout(() => {
      this.locationSearchQuery(this.state.searchQuery);
    }, 1000);

    this.setState({
      selectedLocationOption: undefined,
      auctionName: null,
      searchQuery: searchQuery,
      isLoading: true,
      debounceTimeout: debounceTimeout
    });
  };

  onClickSelect = () => {
    const { auctionLocationOptions, auctionLocationOptionsCache } = this.state;
    if (isEmpty(auctionLocationOptions)) {
      this.setState({ auctionLocationOptions: auctionLocationOptionsCache });
    }
  };

  submitForm = () => {
    this.setState({
      error: null,
      errors: {}
    });
    const { onSubmitHandler } = this.props;
    const {
      id,
      auctionId,
      selectedLocationOption,
      gracePeriod,
      exceptionReason,
      exceptionEndTime,
      exceptionEndTimeString,
      action
    } = this.state;

    const validationRules = {
      selectedLocationOption: 'required|numeric',
      gracePeriod: 'required|integer|min: 0|max: 60'
    };

    const customerErrorMessage = {
      'required.selectedLocationOption':
        'The Auction Location field is required',
      'numeric.selectedLocationOption':
        'The Auction Location field is required',
      'required.gracePeriod': 'The Grace Period field is required.',
      'min.gracePeriod': 'Grace Period must be greater than or equal to zero.',
      'max.gracePeriod': 'Grace Period must be less than or equal to 60.'
    };

    const validation = new Validator(
      this.state,
      validationRules,
      customerErrorMessage
    );

    let messErrorDate = this.validateEndDateDate(exceptionEndTimeString);
    if (!isEmpty(messErrorDate) | validation.fails()) {
      let newErrors = {
        ...validation.errors.errors,
        exceptionEndTime: messErrorDate
      };
      this.setState({ errors: newErrors });
      return false;
    } else {
      onSubmitHandler({
        id: parseInt(id, 10),
        auctionId: action === 'Create' ? selectedLocationOption : auctionId,
        gracePeriod: gracePeriod || '',
        exceptionReason: exceptionReason || '',
        exceptionEndTime: isEmpty(exceptionEndTime)
          ? ''
          : dateTimeFormattedWithOutTimeZone(utcMoment(exceptionEndTime)),
        action: action
      });
    }
  };
  render() {
    const { isModalOpen, onCloseHandler, error, submitting } = this.props;

    const {
      action,
      auctionName,
      selectedLocationOption,
      exceptionEndTime,
      gracePeriod,
      exceptionReason,
      isNoEndDate,
      isLoading,
      errors,
      auctionLocationOptions
    } = this.state;
    const exceptionEndTimeConverted = isEmpty(exceptionEndTime)
      ? null
      : utcMoment(exceptionEndTime);
    const countRestOfReason = exceptionReason.length;
    const datePickerClassName = isNoEndDate === true ? 'input-disabled' : '';
    const selectAuctionClassName =
      action === 'Update'
        ? 'selection-override input-disabled'
        : 'selection-override';
    const isDisableSelectAuction = action === 'Update';

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          {action === 'Create' ? action : 'Edit'} Auction Location Exception
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  required
                  error={!isEmpty(errors.selectedLocationOption)}
                >
                  <label>Auction Location</label>
                  {action === 'Update' && (
                    <Select
                      disabled
                      className={selectAuctionClassName}
                      text={auctionName}
                    />
                  )}
                  {action === 'Create' && (
                    <Select
                      search
                      loading={isLoading}
                      disabled={isDisableSelectAuction}
                      className={selectAuctionClassName}
                      options={auctionLocationOptions}
                      value={selectedLocationOption}
                      onInput={this.handleInput(100).bind(this)}
                      onChange={this.handleSelectLocationChange.bind(this)}
                      onSearchChange={this.handleSearchChange.bind(this)}
                      onClick={this.onClickSelect.bind(this)}
                      noResultsMessage={
                        auctionLocationOptions &&
                        auctionLocationOptions.length === 0
                          ? 'No results found.'
                          : null
                      }
                    />
                  )}
                  {errors.selectedLocationOption && (
                    <span style={{ color: 'red' }}>
                      {errors.selectedLocationOption}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.gracePeriod)}>
                  <label>Grace Period</label>
                  <Input
                    name="gracePeriod"
                    value={gracePeriod}
                    onChange={this.onChangeHandler('gracePeriod').bind(this)}
                    onKeyDown={this.onFormatInput}
                    type="number"
                    min={0}
                    max={60}
                  />
                  {errors.gracePeriod && (
                    <span style={{ color: 'red' }}>
                      {errors.gracePeriod &&
                      Array.isArray(errors.gracePeriod) &&
                      errors.gracePeriod.length > 1
                        ? errors.gracePeriod[errors.gracePeriod.length - 1]
                        : errors.gracePeriod}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.exceptionEndTime)}>
                  <label>Exception End Date</label>
                  <Checkbox
                    onClick={this.toggleEndDate}
                    label="No end date"
                    checked={isNoEndDate}
                  />

                  <DatePicker
                    className={datePickerClassName}
                    style={{ padding: '0', background: 'red' }}
                    selected={exceptionEndTimeConverted}
                    onChange={this.onDateChangeHandler('exceptionEndTime').bind(
                      this
                    )}
                    onBlur={this.onDateFocusHandler(
                      'exceptionEndTimeString'
                    ).bind(this)}
                    dateFormat="YYYY-MM-DD"
                    disabled={isNoEndDate}
                    value={isNoEndDate ? '' : null}
                    minDate={this.today}
                  />
                  {errors.exceptionEndTime && (
                    <span style={{ color: 'red' }}>
                      {errors.exceptionEndTime}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Exception Reason</label>
                  <Input
                    name="exceptionReason"
                    value={exceptionReason}
                    onChange={this.onChangeHandler('exceptionReason').bind(
                      this
                    )}
                    onInput={this.handleInput(30).bind(this)}
                  />
                  <span style={{ color: 'gray' }}>
                    {countRestOfReason}/{this.maxLengthReason}
                  </span>
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && (
            <Button color="green" onClick={this.submitForm} inverted>
              <Icon name="checkmark" /> {action}
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AuctionExceptionForm;
