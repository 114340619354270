export default {
  ADMIN_SECTION: {
    matchAll: ['admin']
  },
  GUARANTEE_SECTION: {
    matchAny: ['guarantee_admin_viewer', 'guarantee_admin_editor']
  },
  LOSS_PREVENTION_SECTION: {
    matchAny: ['loss_prevention_viewer']
  },
  FINANCE_SECTION: {
    matchAny: ['finance_admin_viewer', 'ds_fee_administration']
  },
  VIN_BLACKLISTS: {
    matchAny: ['loss_prevention_viewer']
  },
  AUCTIONACCESS_BLACKLISTS: {
    matchAny: ['loss_prevention_viewer']
  },
  DEALERSHIP_BLACKLISTS: {
    matchAny: ['loss_prevention_viewer']
  },
  EXPIRED_VRA_SETTINGS: {
    matchAny: ['loss_prevention_viewer']
  },
  DEALSHIELD360_SECTION: {
    matchAny: ['flat_rate_inquiry_viewer', 'flat_rate_editor']
  },
  DEALER_INFORMATION: {
    matchAll: ['admin']
  },
  MARKET_INDEX: {
    matchAll: ['admin']
  },
  USER_DIGEST_SETTINGS: {
    matchAll: ['admin']
  },
  GUARANTEED_PURCHASES: {
    matchAny: ['guarantee_admin_viewer', 'guarantee_admin_editor']
  },
  RETURN_INVOICES: {
    matchAny: ['guarantee_admin_viewer', 'guarantee_admin_editor']
  },
  DEALSHIELD360_RATES: {
    matchAll: ['admin', 'flat_rate_editor']
  },
  DEALSHIELD360_CREATE_OFFERS: {
    matchAll: ['flat_rate_inquiry_viewer', 'flat_rate_editor']
  },
  DEALSHIELD360_OFFERS: {
    matchAny: ['flat_rate_inquiry_viewer', 'flat_rate_editor']
  },
  EXTERNAL_TRANSACTIONS: {
    matchAll: ['admin']
  },
  VEHICLE_STATUS_TRACKER: {
    matchAll: ['admin']
  },
  NETWORK_PLUS_VEHICLE_ENTRY: {
    matchAll: ['admin', 'external_vehicle_enterer']
  },
  EXTERNAL_VEHICLE_BATCHES: {
    matchAny: ['external_vehicle_enterer', 'external_vehicle_approver']
  },
  UPGRADE_OFFERS: {
    matchAll: ['admin']
  },
  DEALER_DASHBOARD: {
    matchAll: ['admin']
  },
  SURCHARGE_PRICE: {
    matchAll: ['admin']
  },
  VEHICLE_RESEND_VRA: {
    matchAny: ['admin', 'finance_admin_viewer']
  },
  FEATURE_FLAG_SECTION: {
    matchAll: ['admin', 'feature_flag_editor']
  },
  FEATURE_FLAG: {
    matchAll: ['admin', 'feature_flag_editor']
  },
  GUARANTEE_HISTORY: {
    matchAll: ['admin', 'finance_admin_viewer']
  },
  USERS_SECTION: {
    matchAll: ['admin', 'user_admin_editor']
  },
  SUBSCRIPTION: {
    matchAll: ['admin']
  },
  IT_ADMINISTRATION_SECTION: {
    matchAll: ['admin', 'it_support']
  },
  GUARANTEE_CHARGE_SECTION: {
    matchAll: ['admin', 'ds_fee_administration']
  }
};
