import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import moment from 'moment';

const ListRow = () => (dealer, index) => {
  let rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'edit' },
    { key: 2, text: 'Delete', value: 'delete' },
    { key: 3, text: 'Bulk Import Dealer', value: 'bulk import' }
  ];

  const { groupName, description, createdAt, updatedAt } = dealer;

  return (
    <Table.Row key={index}>
      <Table.Cell>{groupName}</Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
      <Table.Cell>{moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
