import { property, pipe, contains } from 'lodash/fp';
import { isEmpty, reduce } from 'lodash';

// 'session.viewer.user.roles'
export const hasRole = (role, rolePath) =>
  pipe(property(rolePath), contains(role));

export const allowAccess = (user, allowedRoles) => {
  if (!isEmpty(allowedRoles.matchAll)) {
    return reduce(
      allowedRoles.matchAll,
      function(allowAccess, role) {
        return allowAccess && hasRole(role, 'roles')(user);
      },
      true
    );
  } else {
    return reduce(
      allowedRoles.matchAny,
      function(allowAccess, role) {
        return allowAccess || hasRole(role, 'roles')(user);
      },
      false
    );
  }
};
