import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class SubscriptionDetails extends Component {
  render() {
    const { onSelectSubscription, subscriptions } = this.props;

    const listRow = ListRow(onSelectSubscription);

    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Mobile Phone</Table.HeaderCell>
        <Table.HeaderCell>First Name</Table.HeaderCell>
        <Table.HeaderCell>Last Name</Table.HeaderCell>
        <Table.HeaderCell>Auction Access Number</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Subscriptions</Header>
        <Table
          celled
          selectable
          padded
          renderBodyRow={listRow}
          tableData={subscriptions}
          headerRow={headerRow}
          size="small"
          striped
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(SubscriptionDetails);
