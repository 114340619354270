import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { get, isEmpty } from 'lodash';
import eatTransactionWithEatEventsQuery from '../../graphql/eatTransactionWithEatEventsQuery';
import client from '../../apollo';

import { generateCSVFile } from '../../utilities/CsvUtil';
import { handleEatExportData } from './EatExportData';

class ExportEAT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eatTransactionWithEatEvents: [],
      eatExportData: [],
      isExport: false,
      exportFileName: 'export_EAT'
    };
  }

  handleDataExportToCSV = () => {
    this.setState({
      isExport: true
    });

    const {
      vin,
      dealerNumber,
      purchaseLocation,
      auction,
      saleDate
    } = this.props;

    client
      .query({
        query: eatTransactionWithEatEventsQuery,
        variables: {
          vin: vin,
          dealerNumber: dealerNumber,
          purchaseLocation: purchaseLocation,
          auction: auction,
          saleDate: saleDate
        }
      })
      .then(({ data }) => {
        const result = get(data, 'eatTransactionWithEatEventData');

        if (!isEmpty(result)) {
          this.setState({
            eatTransactionWithEatEvents: result
          });

          this.setState({
            eatExportData: handleEatExportData(
              this.state.eatTransactionWithEatEvents
            )
          });

          generateCSVFile(this.state.eatExportData, this.state.exportFileName);

          setTimeout(() => {
            this.setState({
              isExport: false
            });
          }, 400);
        }
      });
  };

  render() {
    return (
      <div>
        <Button
          color="blue"
          onClick={this.handleDataExportToCSV}
          disabled={this.state.isExport}
        >
          Export CSV
        </Button>
      </div>
    );
  }
}
export default ExportEAT;
