import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';

import GuaranteeHistory from './GuaranteeHistory';

class GuaranteeHistoryTabs extends Component {
  render() {
    const panes = [
      {
        menuItem: 'Guarantee History Report',
        render: () => (
          <Tab.Pane>
            {' '}
            <GuaranteeHistory />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div>
        {' '}
        <Tab panes={panes} />
      </div>
    );
  }
}

export default GuaranteeHistoryTabs;
