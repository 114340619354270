import Validator from 'validatorjs';

export default function() {
  Validator.setAttributeFormatter(function(attribute) {
    return attribute
      .split(/(?=[A-Z])/)
      .join(' ')
      .toLowerCase();
  });
}
