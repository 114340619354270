import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Button, Message, Confirm } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import LoadingDataEnhancer from './LoadingData';
import MarketIndexQuery from '../graphql/marketIndexQuery';
import UpdateMarketIndexMutation from '../graphql/updateMarketIndexMutation';
import CreateMarketFactorMutation from '../graphql/createMarketFactorMutation';
import UpdateMarketFactorMutation from '../graphql/updateMarketFactorMutation';
import DeleteMarketFactorMutation from '../graphql/deleteMarketFactorMutation';
import MarketIndexDetails from '../views/MarketIndex/MarketIndexDetails';
import MarketFactorsList from '../views/MarketIndex/MarketFactorsList';
import MarketIndexForm from '../views/MarketIndex/MarketIndexForm';
import MarketFactorForm from '../views/MarketIndex/MarketFactorForm';

class MarketIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      editFormError: null,
      editFormSubmitting: false,
      isMarketFactorModalOpen: false,
      marketFactorFormError: null,
      marketFactorFormSubmitting: false,
      selectedMarketFactor: null,
      isDeleteConfirmationOpen: false
    };
  }

  handleDismiss = () => {
    this.setState({ flashMessage: null });
  };

  openEditForm = () => {
    this.setState({ isEditModalOpen: true });
  };

  openMarketFactorForm = () => {
    this.setState({ isMarketFactorModalOpen: true });
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  onEditFormCloseHandler = () => {
    this.setState({
      editFormError: null,
      editFormSubmitting: null,
      isEditModalOpen: false
    });
  };

  onMarketFactorFormCloseHandler = () => {
    this.setState({
      marketFactorFormError: null,
      marketFactorFormSubmitting: false,
      isMarketFactorModalOpen: false,
      selectedMarketFactor: null
    });
  };

  onMarketFactorFormSubmitHandler = values => {
    const { selectedMarketFactor } = this.state;

    if (!isEmpty(selectedMarketFactor)) {
      this.updateMarketFactor(values);
    } else {
      this.createMarketFactor(values);
    }
  };

  createMarketFactor = values => {
    const { createMarketFactorMutation, data: { marketIndex } } = this.props;

    const input = {
      marketFactor: { ...values, marketIndexId: parseInt(marketIndex.id, 10) }
    };
    this.setState({
      marketFactorFormSubmitting: true
    });

    createMarketFactorMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { createMarketFactor: { success, errors } } = data;
        if (success) {
          this.setState({
            marketFactorFormSubmitting: false,
            selectedMarketFactor: null,
            flashMessage: 'Successfully Created market factor'
          });
          this.props.data.refetch();
          this.onMarketFactorFormCloseHandler();
        } else {
          this.setState({
            marketFactorFormSubmitting: false,
            marketFactorFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          marketFactorFormSubmitting: false,
          marketFactorFormError: error
        });
      });
  };

  updateMarketFactor = values => {
    const { updateMarketFactorMutation, data: { marketIndex } } = this.props;
    const { selectedMarketFactor } = this.state;
    const input = {
      marketFactor: {
        ...values,
        id: selectedMarketFactor.id,
        marketIndexId: parseInt(marketIndex.id, 10)
      }
    };

    this.setState({
      marketFactorFormSubmitting: true
    });

    updateMarketFactorMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { updateMarketFactor: { success, errors } } = data;
        if (success) {
          this.setState({
            marketFactorFormSubmitting: false,
            selectedMarketFactor: null,
            flashMessage: 'Successfully Updated market factor'
          });
          this.props.data.refetch();
          this.onMarketFactorFormCloseHandler();
        } else {
          this.setState({
            marketFactorFormSubmitting: false,
            marketFactorFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          marketFactorFormSubmitting: false,
          marketFactorFormError: error
        });
      });
  };

  onEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const input = { marketIndex: { ...values } };
    this.setState({
      editFormSubmitting: true
    });

    updateMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { updateMarketIndex: { success, errors } } = data;
        if (success) {
          this.setState({
            editFormSubmitting: false,
            flashMessage: 'Successfully Updated market index'
          });
          this.props.data.refetch();
          this.onEditFormCloseHandler();
        } else {
          this.setState({
            editFormSubmitting: false,
            editFormError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          editFormSubmitting: false,
          editFormError: error
        });
      });
  };

  handleDeleteConfirm = () => {
    const { deleteMarketFactorMutation } = this.props;
    const { selectedMarketFactor: { id } } = this.state;
    const input = { id: parseInt(id, 10) };

    deleteMarketFactorMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { deleteMarketFactor: { success } } = data;
        if (success) {
          this.setState({
            flashMessage: 'Successfully deleted market factor'
          });

          this.props.data.fetchMore({
            updateQuery: (
              previousResult,
              { fetchMoreResult, queryVariables }
            ) => {
              return fetchMoreResult;
            }
          });
        } else {
          this.setState({
            flashMessage: 'Deleting maket factor failed'
          });
        }
        this.setState({ isDeleteConfirmationOpen: false });
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          flashMessage: 'Deleting maket factor failed'
        });
      });
  };

  onSelectMarketIndex = (action, marketIndex) => {
    switch (action) {
      case 'Edit':
        this.setState({
          isEditModalOpen: true
        });
        break;
      default:
    }
  };

  onSelectMarketFactor = (action, selectedMarketFactor) => {
    this.setState({ selectedMarketFactor });
    switch (action) {
      case 'Edit':
        this.setState({
          isMarketFactorModalOpen: true
        });
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      default:
    }
  };

  render() {
    const {
      flashMessage,
      editFormError,
      isEditModalOpen,
      editFormSubmitting,
      isMarketFactorModalOpen,
      marketFactorFormSubmitting,
      marketFactorFormError,
      selectedMarketFactor
    } = this.state;

    const { data: { marketIndex } } = this.props;
    const marketFactors = marketIndex ? marketIndex.marketFactors : [];

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}

        <MarketIndexDetails
          onSelectMarketIndex={this.onSelectMarketIndex}
          marketIndex={marketIndex}
        />
        <br />
        <Button color="green" onClick={this.openEditForm} inverted>
          Edit Market Index
        </Button>
        <br />
        <br />
        <br />
        <MarketFactorsList
          onSelectMarketFactor={this.onSelectMarketFactor}
          marketFactors={marketFactors}
          openMarketFactorForm={this.openMarketFactorForm}
        />

        {isEditModalOpen && (
          <div>
            <MarketIndexForm
              isModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              onSubmitHandler={this.onEditFormSubmitHandler}
              error={editFormError}
              submitting={editFormSubmitting}
              marketIndex={marketIndex}
            />
          </div>
        )}

        {isMarketFactorModalOpen && (
          <div>
            <MarketFactorForm
              isModalOpen={isMarketFactorModalOpen}
              onCloseHandler={this.onMarketFactorFormCloseHandler}
              onSubmitHandler={this.onMarketFactorFormSubmitHandler}
              error={marketFactorFormError}
              submitting={marketFactorFormSubmitting}
              marketFactor={selectedMarketFactor}
            />
          </div>
        )}

        <Confirm
          open={this.state.isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(MarketIndexQuery),
  graphql(UpdateMarketIndexMutation, { name: 'updateMutation' }),
  graphql(CreateMarketFactorMutation, { name: 'createMarketFactorMutation' }),
  graphql(UpdateMarketFactorMutation, { name: 'updateMarketFactorMutation' }),
  graphql(DeleteMarketFactorMutation, { name: 'deleteMarketFactorMutation' }),
  LoadingDataEnhancer
)(MarketIndex);
