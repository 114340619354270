import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { startCase } from 'lodash';

class SearchField extends Component {
  render() {
    const { handleInputChange, searchOption, value } = this.props;

    return (
      <Form.Field>
        <Form.Input
          name={searchOption}
          placeholder={'Search by ' + startCase(searchOption)}
          value={value}
          onChange={handleInputChange}
        />
      </Form.Field>
    );
  }
}

export default SearchField;
