import { gql } from 'react-apollo';

const dealerListQuery = gql`
  query customerDealerList(
    $dealer_no: String
    $limit: Int
    $page: Int
    $sort: String
  ) {
    customerDealerList(
      dealer_no: $dealer_no
      limit: $limit
      page: $page
      sort: $sort
    ) {
      customerNumber
      customerName
      customerEmail
      customerPhone
      customerCity
      customerState
      customerZip
      address1
      groupId
      groupName
      createdAt
      updatedAt
    }
  }
`;

export default dealerListQuery;
