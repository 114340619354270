import { gql } from 'react-apollo';

const externalAuctionTransactionsEventDataQuery = gql`
  query externalAuctionTransactionsEventDataQuery(
    $eatTransactionId: Int
    $id: Int
  ) {
    externalAuctionTransactionsEventData(
      eatTransactionId: $eatTransactionId
      id: $id
    ) {
      id
      announcement
      transReqDate
      event
      vehicleSalePrice
      buyFee
      dealshieldPrice
      buynet
      buyerAdjustments
      howPaid
      howPaidDescription
      floorPlan
      floorPlanDescription
      howPaid1
      howPaidDescription1
      paymentAmount1
      howPaid2
      howPaidDescription2
      paymentAmount2
      howPaid3
      howPaidDescription3
      paymentAmount3
      dsDepositFlag
      paidDate
      imsVehicleStatus
      vehicleSecured
      imsSecuredDate
      partnerAuctionTitleStatus
      imsTitleStatusFromAuction
      imsTitleReceivedStatus
      imsTitleReceived
      holdType
      titleIssueDate
      imsTitleStatus
      imsTitleNumber
      clearedForSale
      imsRecordDate
      imsTitleStatusLoc
      eatTransactionId
      createdAt
      updatedAt
      vraId
      splitPay
      eatTransaction {
        vin
        saleDate
        auction
        runNumber
        purchaseLocation
        buyer
        buyerName
        seller
        sellerName
        guaranteeDate
        vra
        vraInitiatedDate
        returnLocation
        body
        miles
        channel
        modelYear
        make
        model
        arb
        arbStatus
        arbDate
        salvage
        closedSale
        returnWoNumber
        meridianSoldDate
        uniqueId
      }
    }
  }
`;

export default externalAuctionTransactionsEventDataQuery;
