import { gql } from 'react-apollo';

const deleteAuctionAccessBlacklistMutation = gql`
  mutation deleteAuctionAccessBlacklist($input: DestroyAuctionAccessBlacklistInput!) {
    deleteAuctionAccessBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default deleteAuctionAccessBlacklistMutation;