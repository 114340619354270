import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';

import { withRouter } from 'react-router-dom';
import { Message, Button, Confirm } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import ACCESS from '../constants/access';
import APP_PATH from '../constants/paths';
import client from '../apollo';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import NewFlatRateOffer from '../views/FlatRatesOffers/NewForm';
import NewFlatRateOfferForm from '../views/FlatRatesOffers/NewFlatRateOfferForm';
import EditOfferBatch from '../views/FlatRatesOffers/EditOfferBatch';
import FlatRateOfferBatchesList from '../views/FlatRatesOffers/FlatRateOfferBatchesList';
import createFlatRateOfferMutation from '../graphql/createFlatRateOfferMutation';
import flatRateOfferBatchesQuery from '../graphql/flatRateOfferBatchesQuery';
import flatRateOfferBatchQuery from '../graphql/flatRateOfferBatchQuery';
import resetFlatRateOfferBatchMutation from '../graphql/resetFlatRateOfferBatchMutation';
import confirmFlatRateOfferBatchMutation from '../graphql/confirmFlatRateOfferBatchMutation';
import deleteFlatRateOfferBatchMutation from '../graphql/deleteFlatRateOfferBatchMutation';
import updateFlatRateOfferBatchMutation from '../graphql/updateFlatRateOfferBatchMutation';
import addOfferInFlatRateOfferBatchMutation from '../graphql/addOfferInFlatRateOfferBatchMutation';
import acceptFlatRateOfferBatchMutation from '../graphql/acceptFlatRateOfferBatchMutation';
import rejectFlatRateOfferBatchMutation from '../graphql/rejectFlatRateOfferBatchMutation';

import { allowAccess } from '../utilities/user';
import Search from '../views/Shared/Search';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';

class FlatRateOffers extends Component {
  state = {
    flashMessage: null,
    isNewModalOpen: false,
    submitting: false,
    formError: null,
    selectedFlatRateOfferBatch: null,
    isDeleteConfirmationOpen: false,
    isEditModalOpen: false,
    isNewFlatRateOfferFormOpen: false,
    resetForm: false
  };

  openNewForm = () => {
    this.setState({ isNewModalOpen: true });
  };

  handleDismiss = () => {
    this.setState({ flashMessage: null });
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  onNewFormCloseHandler = () => {
    this.setState({
      formError: null,
      submitting: null,
      isNewModalOpen: false
    });
  };

  onNewFlatRateOfferFormCloseHandler = () => {
    this.setState({
      formError: null,
      submitting: null,
      isNewFlatRateOfferFormOpen: false
    });
  };

  onEditFormCloseHandler = () => {
    this.setState({
      formError: null,
      submitting: null,
      isEditModalOpen: false
    });
  };

  onSelectFlatRateOfferBatch = (action, flatRateOfferBatch) => {
    this.setState({
      selectedFlatRateOfferBatch: flatRateOfferBatch
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isEditModalOpen: true
        });
        break;
      case 'Reset':
        this.resetFlatRateOfferBatch(flatRateOfferBatch);
        break;
      case 'Confirm':
        this.confirmFlatRateOfferBatch(flatRateOfferBatch);
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      case 'AddNewOffer':
        this.setState({
          isNewFlatRateOfferFormOpen: true
        });
        break;
      default:
        this.setState({
          selectedFlatRateOfferBatch: null
        });
    }
  };

  onNewFormSubmitHandler = values => {
    const { createMutation } = this.props;
    const flatRateOfferInput = { ...values };
    this.setState({
      submitting: true
    });
    createMutation({
      variables: { flatRateOfferInput }
    })
      .then(({ data }) => {
        const { createFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.setState({
            submitting: false,
            flashMessage: 'Successfully created 360 Offer'
          });
          this.props.data.refetch();
          this.onNewFormCloseHandler();
        } else {
          this.setState({
            submitting: false,
            formError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          submitting: false,
          formError: error
        });
      });
  };

  onEditFormSubmitHandler = values => {
    const { updateMutation, history } = this.props;
    const { resetForm } = this.state;
    const input = { ...values };
    this.setState({
      submitting: true
    });
    updateMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { updateFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.setState({
            submitting: false,
            isEditModalOpen: false,
            selectedFlatRateOfferBatch: null,
            resetForm: false,
            flashMessage: 'Successfully updated 360 Offer'
          });

          if (resetForm) {
            history.push(APP_PATH.DEALSHIELD360_OFFERS);
          } else {
            this.props.data.refetch();
          }
        } else {
          this.setState({
            submitting: false,
            formError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          submitting: false,
          formError: error
        });
      });
  };

  onRejectHandler = values => {
    const { rejectMutation } = this.props;
    const input = { ...values };
    this.setState({
      submitting: true
    });

    rejectMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { rejectFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.setState({
            submitting: false,
            isEditModalOpen: false,
            selectedFlatRateOfferBatch: null,
            flashMessage: 'Successfully rejected 360 Offer'
          });
          this.props.data.refetch();
        } else {
          this.setState({
            submitting: false,
            formError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          error: 'Error while rejecting 360 Offer'
        });
      });
  };

  onAcceptHandler = values => {
    const { acceptMutation } = this.props;
    const input = { ...values };
    this.setState({
      submitting: true
    });

    acceptMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { acceptFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.setState({
            submitting: false,
            isEditModalOpen: false,
            selectedFlatRateOfferBatch: null,
            flashMessage: 'Successfully accepted 360 Offer'
          });
          this.props.data.refetch();
        } else {
          this.setState({
            submitting: false,
            formError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          error: 'Error while accepting 360 Offer'
        });
      });
  };

  onNewFlatRateOfferFormSubmitHandler = values => {
    const { addOfferMutation } = this.props;
    const input = { ...values };
    this.setState({
      submitting: true
    });

    addOfferMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { addOfferInFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.setState({
            submitting: false,
            isNewFlatRateOfferFormOpen: false,
            selectedFlatRateOfferBatch: null,
            flashMessage: 'Successfully added 360 Offer'
          });
          this.props.data.refetch();
        } else {
          this.setState({
            submitting: false,
            formError: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          error: 'Error while deleting 360 Offer'
        });
      });
  };

  onSearchSubmitHandler = event => {
    const { auctionAccessNumber, dealerNumber } = event.target;
    const dealerNo = isEmpty(dealerNumber.value) ? null : dealerNumber.value;
    const variableArguments = {
      auctionAccessNumber: auctionAccessNumber.value || null,
      dealerNumber: dealerNo
    };

    this.props.data.refetch({
      ...variableArguments
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  deleteFlatRateOfferBatch = () => {
    const { deleteMutation } = this.props;
    const { selectedFlatRateOfferBatch } = this.state;
    const id = selectedFlatRateOfferBatch.id;
    const input = { id };

    deleteMutation({
      variables: { input }
    })
      .then(({ data }) => {
        this.props.data.refetch();
        this.setState({
          selectedFlatRateOfferBatch: null,
          isDeleteConfirmationOpen: false,
          flashMessage: 'Successfully Deleted 360 Offer'
        });
      })
      .catch(error => {
        this.setState({
          isDeleteConfirmationOpen: false,
          error: 'Error while deleting 360 Offer'
        });
      });
  };

  resetFlatRateOfferBatch = flatRateOfferBatch => {
    const { resetMutation } = this.props;
    const id = flatRateOfferBatch.id;
    const input = { id };
    resetMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { resetFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.props.data.refetch();
          this.setState({
            selectedFlatRateOfferBatch: null,
            flashMessage: 'Successfully Reset 360 Offer'
          });
        } else {
          this.setState({
            submitting: false,
            flashMessage: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          error: 'Error while deleting 360 Offer'
        });
      });
  };

  confirmFlatRateOfferBatch = flatRateOfferBatch => {
    const { confrimMutation } = this.props;
    const id = flatRateOfferBatch.id;
    const input = { id };

    confrimMutation({
      variables: { input }
    })
      .then(({ data }) => {
        const { confirmFlatRateOfferBatch: { success, errors } } = data;
        if (success) {
          this.props.data.refetch();
          this.setState({
            selectedFlatRateOfferBatch: null,
            flashMessage: 'Successfully Confirmed 360 Offer'
          });
        } else {
          this.setState({
            submitting: false,
            flashMessage: errors
          });
        }
      })
      .catch(error => {
        this.setState({
          error: 'Error while deleting 360 Offer'
        });
      });
  };

  componentWillMount() {
    const batchId = this.props.match.params.id;
    if (!isEmpty(batchId)) {
      client
        .query({
          query: flatRateOfferBatchQuery,
          variables: {
            id: parseInt(batchId, 10)
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          const { flatRateOfferBatch } = data;
          this.setState({
            isEditModalOpen: true,
            resetForm: true,
            selectedFlatRateOfferBatch: flatRateOfferBatch
          });
        });
    }
  }

  render() {
    const {
      flashMessage,
      isNewModalOpen,
      formError,
      submitting,
      isNewFlatRateOfferFormOpen,
      isEditModalOpen
    } = this.state;

    const { user, data: { flatRateOfferBatches }, searchFields } = this.props;
    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}

        {isNewModalOpen && (
          <div>
            <NewFlatRateOffer
              isModalOpen={isNewModalOpen}
              onCloseHandler={this.onNewFormCloseHandler}
              onSubmitHandler={this.onNewFormSubmitHandler}
              error={formError}
              submitting={submitting}
            />
          </div>
        )}

        {isNewFlatRateOfferFormOpen && (
          <div>
            <NewFlatRateOfferForm
              isModalOpen={isNewFlatRateOfferFormOpen}
              onCloseHandler={this.onNewFlatRateOfferFormCloseHandler}
              onSubmitHandler={this.onNewFlatRateOfferFormSubmitHandler}
              error={formError}
              submitting={submitting}
              flatRateOfferBatch={this.state.selectedFlatRateOfferBatch}
            />
          </div>
        )}

        {isEditModalOpen && (
          <div>
            <EditOfferBatch
              isModalOpen={isEditModalOpen}
              onCloseHandler={this.onEditFormCloseHandler}
              onSubmitHandler={this.onEditFormSubmitHandler}
              error={formError}
              submitting={submitting}
              flatRateOfferBatch={this.state.selectedFlatRateOfferBatch}
              resetForm={this.state.resetForm}
              onRejectHandler={this.onRejectHandler}
              onAcceptHandler={this.onAcceptHandler}
            />
          </div>
        )}
        {user &&
          allowAccess(user, ACCESS.DEALSHIELD360_CREATE_OFFERS) && (
            <Button color="green" onClick={this.openNewForm} inverted>
              {' '}
              Extend DS Offer{' '}
            </Button>
          )}
        <br />
        <br />

        <Search
          onSubmit={this.onSearchSubmitHandler}
          searchFields={searchFields}
          searchOptions={['auctionAccessNumber', 'dealerNumber']}
        />

        <FlatRateOfferBatchesList
          onSelectFlatRateOfferBatch={this.onSelectFlatRateOfferBatch}
          flatRateOfferBatches={flatRateOfferBatches}
        />
        <Confirm
          open={this.state.isDeleteConfirmationOpen}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.deleteFlatRateOfferBatch}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const { data: { searchFields } } = state;
  return {
    searchFields: searchFields
  };
};

export default compose(
  withRouter,
  withPagination(flatRateOfferBatchesQuery, 'flatRateOfferBatches'),
  graphql(createFlatRateOfferMutation, { name: 'createMutation' }),
  graphql(resetFlatRateOfferBatchMutation, { name: 'resetMutation' }),
  graphql(confirmFlatRateOfferBatchMutation, { name: 'confrimMutation' }),
  graphql(deleteFlatRateOfferBatchMutation, { name: 'deleteMutation' }),
  graphql(updateFlatRateOfferBatchMutation, { name: 'updateMutation' }),
  graphql(addOfferInFlatRateOfferBatchMutation, { name: 'addOfferMutation' }),
  graphql(acceptFlatRateOfferBatchMutation, { name: 'acceptMutation' }),
  graphql(rejectFlatRateOfferBatchMutation, { name: 'rejectMutation' }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(FlatRateOffers);
