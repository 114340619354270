import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { utcMoment } from '../../utilities/dateUtils';
import Validator from 'validatorjs';
import _, { isEmpty } from 'lodash';
import {
  Form,
  Button,
  Modal,
  Header,
  Icon,
  Input,
  Confirm,
  Checkbox,
  Select,
  Message
} from 'semantic-ui-react';
import cancelEnrollmentMutation from '../../graphql/cancelEnrollmentMutation';
import modifyEnrollmentMutation from '../../graphql/modifyEnrollmentMutation';
import newEnrollmentMutation from '../../graphql/createNewEnrollmentMutation';

class ReviewForm extends Component {
  state = {
    successfulCompletion: false,
    message: '',
    endDate: '',
    errors: {},
    unsuccessfulCompletion: ''
  };

  render() {
    const {
      dealerNumber,
      dealername,
      contactname,
      contactemail,
      contactphone,
      product1,
      product2,
      cascade,
      purchaseChannel1,
      location1,
      location2,
      psimileage1,
      lpiprice1,
      psiprice1,
      startDate1,
      startDate2,
      endDate1,
      endDate2,
      onCloseHandler,
      redirectToAutoPsiTab,
      modify,
      cancel,
      createNewEnrollment,
      excludeCloseSale,
      productOptions,
      product2Options,
      totalLocations,
      cancelForm,
      modifyCloseHandler
    } = this.props;

    const { errors } = this.state;

    const validationRules = {
      endDate: 'required'
    };

    const cascadeLpiLabels = ['Yes', 'No'];
    const factoryLpiLabels = ['True', 'False'];

    const date = isEmpty(startDate1)
      ? null
      : utcMoment(startDate1).format('YYYY-MM-DD');

    const productNames = {
      sevenDayVarPSI: 'Auto PSI 7 Day',
      fourteenDayVarPSI: 'Auto PSI 14 Day',
      sevenDayFlatPSI: 'Flat Rate Auto PSI 7 Day',
      fourteenDayFlatPSI: 'Flat Rate Auto PSI 14 Day'
    };

    const productList = isEmpty(product2)
      ? product1
      : `${productNames[product1]} , ${productNames[product2]}`;

    const dateList = isEmpty(product2)
      ? startDate1
      : `${startDate1} , ${utcMoment(startDate2).format('YYYY-MM-DD')}`;

    const productHashWithPrice = {
      [product1]: {
        auctionIds: location1,
        startDate: isEmpty(startDate1)
          ? null
          : utcMoment(startDate1).format('YYYY-MM-DD'),
        endDate: isEmpty(endDate1)
          ? null
          : utcMoment(endDate1).format('YYYY-MM-DD'),
        PSIprice: `${psiprice1}`,
        mileage: `${psimileage1 || 0}`,
        LPIprice: `${lpiprice1}`,
        channel: purchaseChannel1
      },
      [product2]: {
        auctionIds: location2,
        startDate: isEmpty(startDate2)
          ? null
          : utcMoment(startDate2).format('YYYY-MM-DD'),
        endDate: isEmpty(endDate2)
          ? null
          : utcMoment(endDate2).format('YYYY-MM-DD'),
        PSIprice: `${psiprice1}`,
        mileage: `${psimileage1 || 0}`,
        LPIprice: `${lpiprice1}`,
        channel: purchaseChannel1
      }
    };

    const productHashWithoutPrice = {
      [product1]: {
        auctionIds: location1,
        startDate: isEmpty(startDate1)
          ? null
          : utcMoment(startDate1).format('YYYY-MM-DD'),
        endDate: isEmpty(endDate1)
          ? null
          : utcMoment(endDate1).format('YYYY-MM-DD'),
        mileage: `${psimileage1 || 0}`,
        channel: purchaseChannel1
      },
      [product2]: {
        auctionIds: location2,
        startDate: isEmpty(startDate2)
          ? null
          : utcMoment(startDate2).format('YYYY-MM-DD'),
        endDate: isEmpty(endDate2)
          ? null
          : utcMoment(endDate2).format('YYYY-MM-DD'),
        mileage: `${psimileage1 || 0}`,
        channel: purchaseChannel1
      }
    };

    const productHash =
      product1 === 'sevenDayFlatPSI' || product1 === 'fourteenDayFlatPSI'
        ? productHashWithPrice
        : productHashWithoutPrice;

    const payload = {
      subscription: {
        dealerNumber: `${dealerNumber}`,
        dealerName: dealername,
        contactName: contactname,
        contactEmail: contactemail,
        contactPhoneNumber: contactphone,
        cascade: cascade,
        excludeCloseSale: excludeCloseSale,
        products: _.omit(productHash, 'null')
      }
    };

    const submitForm = () => {
      if (cancel) {
        const validation = new Validator(this.state, validationRules);
        if (validation.fails()) {
          this.setState({ ...validation.errors });
          return false;
        } else {
          const { cancelEnrollmentMutation } = this.props;
          const cancelEnrollmentInput = {
            subscription: {
              dealerNumber: `${dealerNumber}`,
              endDate: this.state.endDate.format('YYYY-MM-DD')
            }
          };

          cancelEnrollmentMutation({
            variables: { cancelEnrollmentInput }
          }).then(({ data: { cancelSubscription } }) => {
            const { success, errors } = cancelSubscription;
            if (success) {
              this.setState({
                successfulCompletion: true,
                message: `Thank you. Dealer ${dealerNumber} has successfully cancelled enrollment. 
                            Cancellation effective ${this.state.endDate.format(
                              'MM/DD/YYYY'
                            )}`
              });
            } else {
              this.setState({ unsuccessfulCompletion: errors[0] });
            }
          });
          this.setState({ ...validation.errors });
        }
      } else if (modify) {
        const { modifyEnrollmentMutation } = this.props;
        const modifyEnrollmentInput = payload;

        modifyEnrollmentMutation({
          variables: { modifyEnrollmentInput }
        }).then(({ data: { updateSubscription } }) => {
          const { success, errors } = updateSubscription;
          if (success) {
            this.setState({
              successfulCompletion: true,
              message: `Dealer ${dealerNumber} has successfully modified enrollment. 
                          New changes will begin for ${productList} on ${dateList} respectively`
            });
          } else {
            this.setState({ unsuccessfulCompletion: errors[0] });
          }
        });
      } else if (createNewEnrollment) {
        const { newEnrollmentMutation } = this.props;
        const newEnrollmentInput = payload;

        newEnrollmentMutation({
          variables: { newEnrollmentInput }
        }).then(({ data: { newSubscription } }) => {
          const { success, errors } = newSubscription;
          if (success) {
            this.setState({
              successfulCompletion: true,
              message: `Dealer ${dealerNumber} has successfully enrolled in ${productList} with coverage 
                            beginning  ${date}`
            });
          } else {
            this.setState({ unsuccessfulCompletion: errors[0] });
          }
        });
      }
    };

    const handleConfirm = () => {
      this.setState({
        successfulCompletion: false,
        modify: true,
        dealerNo: ''
      });
      modifyCloseHandler();
      cancelForm();
      redirectToAutoPsiTab();
    };

    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD') });
    };

    const endDate = isEmpty(this.state.endDate)
      ? null
      : utcMoment(this.state.endDate);

    return (
      <Modal
        open={true}
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        {this.state.successfulCompletion && (
          <Confirm
            className="confirm-form"
            open={true}
            content={this.state.message}
            onConfirm={handleConfirm}
          />
        )}
        {this.state.unsuccessfulCompletion && (
          <Message negative>
            <p>Received error: {this.state.unsuccessfulCompletion}</p>
          </Message>
        )}
        {modify && (
          <Modal.Header>
            {'Please review your enrollment selections:'}
          </Modal.Header>
        )}
        {cancel && (
          <Modal.Header>
            {'Are you sure that you want to cancel the following enrollment?'}
          </Modal.Header>
        )}
        {createNewEnrollment && (
          <Modal.Header>
            {'Please review your enrollment selections:'}
            <br />
            {
              'If you want to make changes, please press cancel to return to enrollment form'
            }
          </Modal.Header>
        )}
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Header size="small" color="blue" />
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Dealer #</label>
                  <Input
                    name="dealerNumber"
                    value={dealerNumber}
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Dealership Name</label>
                  <Input name="dealername" value={dealername} disabled={true} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Contact Name</label>
                  <Input
                    name="contactname"
                    value={contactname}
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Contact Email</label>
                  <Input
                    name="contactemail"
                    value={contactemail}
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Contact Phone # </label>
                  <Input
                    name="contactphone"
                    value={contactphone}
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Product 1</label>
                  <Select
                    options={productOptions}
                    name="product1"
                    value={product1}
                    className="product-type-selector"
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Auction Location 1</label>
                  <Select
                    options={totalLocations}
                    name="location1"
                    value={location1}
                    multiple
                    selection
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              {product2 && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Product 2</label>
                    <Select
                      options={product2Options}
                      name="product2"
                      value={product2}
                      className="product-type-selector"
                      disabled={true}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              {product2 && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Auction Location 2</label>
                    <Select
                      options={totalLocations}
                      name="location2"
                      value={location2}
                      multiple
                      selection
                      disabled={true}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Cascade to LPI </label>
                  {[{ Yes: 'true' }, { No: 'false' }].map(
                    (cascadeValue, index) => (
                      <Checkbox
                        radio
                        label={cascadeLpiLabels[index]}
                        checked={
                          cascade.toString() ===
                          cascadeValue[cascadeLpiLabels[index]]
                        }
                        disabled={true}
                      />
                    )
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Exclude Factory </label>
                  {[{ True: 'true' }, { False: 'false' }].map(
                    (factoryValue, index) => (
                      <Checkbox
                        radio
                        label={factoryLpiLabels[index]}
                        checked={
                          excludeCloseSale.toString() ===
                          factoryValue[factoryLpiLabels[index]]
                        }
                        disabled={true}
                      />
                    )
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Purchase Channel Preference </label>
                  {['In-Lane', 'Simulcast'].map((channel, index) => (
                    <Checkbox
                      radio
                      label={channel}
                      checked={purchaseChannel1.some(
                        channelName => channelName === channel
                      )}
                      disabled={true}
                    />
                  ))}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>PSI Mileage </label>
                  <Input name="miles" value={psimileage1} disabled={true} />
                </Form.Field>
              </Form.Group>
              {(product1 === 'sevenDayFlatPSI' ||
                product1 === 'fourteenDayFlatPSI') && (
                <p>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>PSI Price </label>
                      <Input
                        name="miles"
                        value={psiprice1 === undefined ? 0 : psiprice1}
                        disabled={true}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>LPI Price </label>
                      <Input name="miles" value={lpiprice1} disabled={true} />
                    </Form.Field>
                  </Form.Group>
                </p>
              )}
              <Form.Group widths="equal">
                <Form.Field>
                  <label>AutoPSI Start Date </label>
                  <Input name="startDate" value={date} disabled={true} />
                </Form.Field>
              </Form.Group>
              {cancel && (
                <Form.Group widths="equal">
                  <Form.Field required error={!isEmpty(errors.endDate)}>
                    <label>Select AutoPSI End Date </label>
                    <DatePicker
                      style={{ padding: '0', width: '100%' }}
                      selected={endDate}
                      minDate={new Date()}
                      onChange={onDateChangeHandler('endDate').bind(this)}
                      dateFormat="YYYY-MM-DD"
                    />
                    {errors.endDate && <span>{errors.endDate}</span>}
                  </Form.Field>
                </Form.Group>
              )}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={submitForm} inverted>
            <Icon name="checkmark" /> Submit
          </Button>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const cancelMutation = graphql(cancelEnrollmentMutation, {
  name: 'cancelEnrollmentMutation'
});

const modifyMutation = graphql(modifyEnrollmentMutation, {
  name: 'modifyEnrollmentMutation'
});

const newMutation = graphql(newEnrollmentMutation, {
  name: 'newEnrollmentMutation'
});

export default compose(
  cancelMutation,
  modifyMutation,
  newMutation
)(ReviewForm);
