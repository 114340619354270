import React, { Component } from 'react';
import { Button, Icon, Modal, Form } from 'semantic-ui-react';
import Validator from 'validatorjs';
import { isEmpty } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import resendFlatRateOfferBatchMutation from '../../graphql/resendFlatRateOfferBatchMutation';

class ResendEmail extends Component {
  state = {
    email: '',
    name: '',
    errors: {},
    flatRateOfferBatchId: ''
  };

  componentDidMount() {
    const { enrollmentStatus, incompleteEnrollment } = this.props;
    if (!isEmpty(enrollmentStatus) && !isEmpty(incompleteEnrollment)) {
      const { name, email } = enrollmentStatus;
      const { flatRateOfferBatchId } = incompleteEnrollment;

      this.setState({
        name,
        email,
        flatRateOfferBatchId
      });
    }
  }

  render() {
    const {
      modalOpen,
      handleClose,
      flashMessageSuccess,
      flashMessageFailure
    } = this.props;

    const { errors, name, email, flatRateOfferBatchId } = this.state;

    const validationRules = {
      email: 'required|email'
    };

    const handleCloseHandler = () => {
      this.setState({
        errors: {},
        email: '',
        name: '',
        flatRateOfferBatchId: ''
      });
      handleClose();
    };

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const submitForm = () => {
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        const input = {
          id: flatRateOfferBatchId,
          email: email
        };
        const { resendMutation } = this.props;
        this.setState({ newFormSubmitting: true });
        resendMutation({
          variables: { input }
        })
          .then(({ data }) => {
            const { resendFlatRateOfferBatch: { success, errors } } = data;
            if (success) {
              handleCloseHandler();
              flashMessageSuccess();
            } else {
              flashMessageFailure(errors);
              handleCloseHandler();
            }
          })
          .catch(error => {
            flashMessageFailure(error);
            handleCloseHandler();
          });
      }
    };

    return (
      <Modal
        open={modalOpen}
        closeIcon
        onClose={handleCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Resend Offer</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Resend offer email to:</label>
                <input placeholder="Name" value={name} />
              </Form.Field>
              <Form.Field required error={!isEmpty(errors.email)}>
                <label>Email:</label>
                <input
                  name="email"
                  value={email}
                  placeholder="email"
                  onChange={onChangeHandler('email').bind(this)}
                />
                {errors.email && <span>{errors.email}</span>}
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={submitForm} inverted>
            <Icon name="checkmark" /> Okay
          </Button>
          <Button onClick={handleCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default compose(
  graphql(resendFlatRateOfferBatchMutation, { name: 'resendMutation' })
)(ResendEmail);
