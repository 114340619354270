import gql from 'graphql-tag';

const updateSubscriptionsMutation = gql`
  mutation updateSubscriptions($subscriptionInput: UpdateSubscriptionsInput!) {
    updateSubscriptions(input: $subscriptionInput) {
      success
      errors
      subscription {
        id
        subscription_id
        dealerName
        dealerNumber
        productId
        productName
        subscriptionType
        priceDs
        pricePsiLpi
        notifyEmail
        networkPlusRate
        active
        sameDayPriceUpdate
        startDate
        endDate
        dsPaidPsi
        subscriptionCancellationReason
      }
    }
  }
`;

export default updateSubscriptionsMutation;
