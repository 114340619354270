import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

import ListRow from './ListRow';

class ExternalVehiclesList extends Component {
  render() {
    const { externalVehicleBatches } = this.props;
    const headerRow = (
      <Table.Row>
        <Table.HeaderCell>BatchId</Table.HeaderCell>
        <Table.HeaderCell>Dealer#</Table.HeaderCell>
        <Table.HeaderCell>Entered User</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Created at</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <div style={{ overflowX: 'auto', whiteSpace: 'no-wrap' }}>
          <Table
            celled
            selectable
            padded
            renderBodyRow={ListRow}
            tableData={externalVehicleBatches}
            headerRow={headerRow}
            size="small"
            striped
          />
        </div>
      </div>
    );
  }
}

export default ExternalVehiclesList;
