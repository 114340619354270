import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import doc from '../assets/doc.png';

import ReactDropzone from 'react-dropzone';
import axios from 'axios';
import localStore from 'store';
import { isEmpty, find } from 'lodash';
import {
  Message,
  Image,
  Button,
  Label,
  Loader,
  Dimmer,
  Dropdown,
  Segment,
  Container,
  Modal,
  Header,
  Icon,
  Grid,
  Checkbox
} from 'semantic-ui-react';

import FileUploaderUploaderList from '../views/FileUploader/List';
import fileUploaderQuery from '../graphql/fileUploaderQuery.js';
import { setPage } from '../data/page/actions';

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      flashMessage: null,
      allFiles: [],
      files: [],
      loading: false,
      selectedOptions: null,
      selectedSource: null,
      selectedOptionsDesc:
        'Select An Upload / Process Function Below. Allowed File Types (.CSV)',
      confirmOpen: false,
      selectSourceType: false,
      optionsKey: null,
      processMessage: true
    };
  }

  setConfirmOpen = e => {
    this.setState({ confirmOpen: e });
  };

  onDrop = files => {
    this.setState({ selectedFile: { name: files[0].name, file: files[0] } });
  };

  onSelectOptions = (e, { value }) => {
    const uploaderOptions = [
      'FeeCodeUploader',
      'IneligibleLanesUploader',
      'HowPaidCodesUploader',
      'BadSellerFileUploader'
    ];
    if (!isEmpty(value)) {
      var data = this.getSelectedItem(this.uploadOptions, value);
      if (data.value === 'SopEventsUploader') {
        this.setState({ selectSourceType: true, processMessage: true });
      } else if (uploaderOptions.includes(data.value)) {
        this.setState({
          selectSourceType: false,
          selectedSource: null
        });
      }
      this.setState({
        selectedOptions: data.value,
        selectedOptionsDesc: data.description_full
      });
    }
  };

  onSelectSource = (e, { value }) => {
    if (!isEmpty(value)) {
      var data = this.getSelectedItem(this.sopOptions, value);
      this.setState({
        selectedSource: data.value
      });
    }
  };

  onSelectProcessMessages = checked => {
    this.setState({
      processMessage: checked
    });
  };

  getSelectedItem = (items, value) => {
    return find(items, ['value', value]);
  };

  sopOptions = [
    {
      key: '1',
      text: 'Auction Edge',
      value: 'AUCTION_EDGE'
    },
    {
      key: '2',
      text: 'Auction Os',
      value: 'AUCTION_OS'
    },
    {
      key: '3',
      text: 'XCIRA',
      value: 'XCIRA'
    }
  ];

  uploadOptions = [
    {
      key: 'options1',
      text: 'Process SOP Event',
      value: 'SopEventsUploader',
      description_full:
        'Use this option to upload an SOP file to APMS for processing.'
    },
    {
      key: 'options2',
      text: 'Add/Update Fee Codes',
      value: 'FeeCodeUploader',
      description_full:
        'Use this option to import fee codes into APMS by using csv file'
    },
    {
      key: 'options3',
      text: 'Add/Update Ineligible Lanes',
      value: 'IneligibleLanesUploader',
      description_full:
        'Use this option to import ineligible lanes into APMS using csv file'
    },
    {
      key: 'options4',
      text: 'Add/Update How Paid Codes',
      value: 'HowPaidCodesUploader',
      description_full:
        'Use this option to import how paid codes into APMS using csv file'
    },
    {
      key: 'options5',
      text: 'Add/Update Bad Seller File',
      value: 'BadSellerFileUploader',
      description_full:
        'Use this option to import bad seller into APMS using csv file'
    }
  ];

  sourceOptions = [
    {
      key: 'source1',
      text: 'Process SOP Event',
      value: 'SopEventsUploader',
      description_full: ''
    }
  ];

  saveFile = () => {
    const uploaderOptions = [
      'FeeCodeUploader',
      'IneligibleLanesUploader',
      'HowPaidCodesUploader',
      'BadSellerFileUploader'
    ];
    const {
      selectedFile,
      selectedOptions,
      selectSourceType,
      selectedSource
    } = this.state;
    if (!isEmpty(selectedFile)) {
      if (!isEmpty(selectedOptions)) {
        if (
          uploaderOptions.includes(selectedOptions) ||
          (selectSourceType && !isEmpty(selectedSource))
        )
          this.setConfirmOpen(true);
        else {
          this.setState({ loading: false, flashMessage: 'Select A Source' });
        }
      } else {
        this.setState({ loading: false, flashMessage: 'Select An Option' });
      }
    } else {
      this.setState({ loading: false, flashMessage: 'Upload A File' });
    }
  };

  uploadImage = () => {
    const {
      selectedFile,
      selectedOptions,
      selectedSource,
      processMessage
    } = this.state;

    if (!isEmpty(selectedOptions) && !isEmpty(selectedFile)) {
      this.setState({ loading: true, flashMessage: '' });

      const formData = new FormData();
      const token = localStore.get('jwt');

      formData.append('file', selectedFile.file);
      formData.append('model', 'UploadedFile');
      formData.append('modelType', selectedOptions);
      if (selectedSource !== null) {
        formData.append('sourceType', selectedSource);
      }
      formData.append('AUTHORIZATION', token ? `${token}` : null);
      formData.append('processMessage', processMessage);

      axios
        .post(process.env.REACT_APP_UPLOAD_API_URL, formData)
        .then(response => {
          const {
            data: { success }
          } = response;
          if (success) {
            this.resetForm();
          } else {
            this.setState({
              loading: false,
              flashMessage: 'Failed To Upload File'
            });
          }
        })
        .catch(e => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  uniqueId = key => {
    return `${key}_${new Date().getTime()}`;
  };

  resetForm = () => {
    this.setState({
      flashMessage: 'Successfully Uploaded file and queued for processing',
      selectedFile: null,
      selectedOptions: null,
      selectedSource: null,
      selectedOptionsDesc: null,
      optionsKey: this.uniqueId(),
      selectSourceType: null,
      processMessage: false,
      loading: false
    });
    this.props.data.refetch();
    this.props.setPage({});
  };

  processFile = () => {
    this.setConfirmOpen(false);
    this.uploadImage();
  };

  render() {
    const {
      data: { fileUploader }
    } = this.props;
    const allFiles = fileUploader;
    const {
      flashMessage,
      selectedFile,
      loading,
      selectedOptionsDesc,
      confirmOpen,
      selectedOptions,
      selectSourceType,
      selectedSource,
      optionsKey,
      processMessage
    } = this.state;
    const fileName = !isEmpty(selectedFile) ? selectedFile.name : '';

    const thumb = {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 2,
      marginRight: 2,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box'
    };

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };

    const img = {
      display: 'block',
      width: 'auto',
      height: '100%'
    };

    return (
      <div className="drop-zone">
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}

        <Modal basic open={confirmOpen} size="small" dimmer="blurring">
          <Header icon>
            <Icon name="info circle" />
            Confirm File Upload / Process
            <br />
            <Message floating>
              <p>
                <b>Note - {selectedOptionsDesc}</b>
              </p>
            </Message>
          </Header>
          <Modal.Content>
            <p>Uploaded File - {fileName}</p>
            <p>
              Selected Process - <b> {selectedOptions} </b>
            </p>
            {selectedSource && (
              <p>
                Source - <b> {selectedSource} </b>
              </p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              inverted
              onClick={() => this.setConfirmOpen(false)}
            >
              <Icon name="remove" /> No
            </Button>
            <Button color="green" inverted onClick={this.processFile}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Segment>
          <br />
          <br />
          <Container>
            <Message
              icon="upload"
              header="File Processor & Uploader"
              content="Utilize this page to upload and process CSV files on APMS"
            />
            <ReactDropzone
              accept=".csv"
              onDrop={this.onDrop}
              multiple={false}
              className="react-drop-zone react-drop-zone-small"
            >
              Click Here To Upload File (CSV File)
            </ReactDropzone>
          </Container>
          <Container>
            <Message>
              Select An Upload / Process Function Below. Allowed File Types
              (.CSV)
            </Message>

            <Grid container columns={2}>
              <Grid.Row>
                <br />
                {selectedFile && (
                  <Label>
                    <div style={thumb}>
                      <div style={thumbInner}>
                        <Image src={doc} style={img} />
                      </div>
                    </div>
                    <br />
                    {selectedFile.name}
                  </Label>
                )}
              </Grid.Row>
              <br />
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>Select Upload Process for CSV* file:</p>
                </Grid.Column>
                <Grid.Column>
                  <Dropdown
                    key={optionsKey}
                    fluid
                    basic
                    placeholder="Select Upload Type"
                    selection
                    value={this.selectedOptions}
                    options={this.uploadOptions}
                    onChange={this.onSelectOptions}
                  />
                  <br />
                  <p>
                    {!isEmpty(selectedOptions) && (
                      <div>
                        <Icon name="attention" />
                        {selectedOptionsDesc}
                      </div>
                    )}
                  </p>
                  <p>
                    {!isEmpty(selectedOptions) &&
                      selectedOptions === 'BadSellerFileUploader' && (
                        <div>
                          <span style={{ fontWeight: 'bold', color: 'red' }}>
                            NOTICE:
                          </span>
                          <br />
                          <span style={{ color: 'red' }}>
                            If you upload the bad seller file during business
                            hours, the file will only be processed at 11 PM EST
                          </span>
                        </div>
                      )}
                  </p>
                </Grid.Column>
              </Grid.Row>

              {selectSourceType && (
                <Grid container columns={2}>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>Select File Source:</p>
                    </Grid.Column>
                    <Grid.Column>
                      <Dropdown
                        fluid
                        placeholder="Select Source"
                        selection
                        options={this.sopOptions}
                        onChange={this.onSelectSource}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <br />
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>Process Messages:</p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Checkbox
                        fluid
                        selection
                        defaultChecked
                        label=" Use this option to process SOP request"
                        value={processMessage}
                        onChange={(e, data) =>
                          this.onSelectProcessMessages(data.checked)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Grid>
            <br />

            <Button color="green" onClick={this.saveFile} inverted>
              Process File
            </Button>

            {loading && (
              <Dimmer active inverted>
                <Loader size="large">Loading</Loader>
              </Dimmer>
            )}
          </Container>
          <br />
          <br />
          <Container>
            <FileUploaderUploaderList allFiles={allFiles} />
          </Container>
        </Segment>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPage: fields => {
    dispatch(setPage());
  }
});

export default compose(
  withRouter,
  withPagination(fileUploaderQuery, 'fileUploader'),
  LoadingDataEnhancer,
  connect(
    null,
    mapDispatchToProps
  )
)(FileUploader);
