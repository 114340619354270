module.exports = {
  ACTION_TYPES: [
    { key: '0', value: null, text: '' },
    { key: '1', value: 'Performance Alert', text: 'Performance Alert' },
    { key: '2', value: 'Rate Change', text: 'Rate Change' },
    {
      key: '3',
      value: 'Network Plus Addition',
      text: 'Network Plus Addition'
    },
    { key: '4', value: 'Parameter Change', text: 'Parameter Change' }
  ],
  LV_TIER_OPTIONS: [
    { key: '0', value: null, text: '' },
    { key: '1', value: '3', text: '3' },
    { key: '2', value: '5', text: '5' },
    { key: '3', value: '7', text: '7' }
  ],
  INELIGIBILITY_CONDITIONS: [
    { key: '0', value: null, text: '' },
    {
      key: '1',
      value: 0,
      text: 'there is no DealShield 360 rate available for this dealer number'
    },
    {
      key: '2',
      value: 1,
      text:
        "this dealer's purchase volume is below the threshold to qualify for the program"
    },
    {
      key: '3',
      value: 2,
      text: "this dealer's program price exceeds the program threshold"
    },
    {
      key: '4',
      value: 3,
      text:
        'this dealer is a Major Dealer; please have their designated Manheim Sales Rep contact us to discuss'
    }
  ],
  COUNTRY_OPTIONS: [
    {
      key: '1',
      value: 'United States of America',
      text: 'United States of America'
    },
    { key: '2', value: 'Canada', text: 'Canada' }
  ],
  FLAT_RATES_MILES: [
    { key: '1', value: 360, text: '360' },
    { key: '2', value: 500, text: '500' }
  ],
  FLAT_RATES_DAYS: [
    { key: '1', value: 21, text: '21 D' },
    { key: '2', value: 30, text: '30 D' }
  ],
  CANCELATION_REASONS: [
    { key: 1, text: 'At buyer request', value: 'At buyer request' },
    {
      key: 2,
      text: 'Vehicle transaction not completed',
      value: 'Vehicle transaction not completed'
    },
    {
      key: 3,
      text: 'Vehicle transaction unwound by auction',
      value: 'Vehicle transaction unwound by auction'
    },
    {
      key: 4,
      text: 'Cancelling to apply other guarantee',
      value: 'Cancelling to apply other guarantee'
    },
    {
      key: 5,
      text: 'Guarantee applied to wrong customer',
      value: 'Guarantee applied to wrong customer'
    },
    {
      key: 6,
      text: 'Cancelling duplicate purchase',
      value: 'Cancelling duplicate purchase'
    },
    {
      key: 7,
      text: 'Vehicle price exceeds market threshold',
      value: 'Vehicle price exceeds market threshold'
    },
    {
      key: 8,
      text: 'Guarantee price exceeds threshold',
      value: 'Guarantee price exceeds threshold'
    },
    {
      key: 9,
      text: 'Common buyer/seller ownership or representation',
      value: 'Common buyer/seller ownership or representation'
    },
    {
      key: 10,
      text: 'Collusive bidding suspected',
      value: 'Collusive bidding suspected'
    }
  ],
  REINSTATEMENT_REASONS: [
    {
      key: 1,
      text: 'System Cancelled In Error',
      value: 'System Cancelled In Error'
    },
    {
      key: 2,
      text: 'User Cancelled In Error',
      value: 'User Cancelled In Error'
    },
    {
      key: 3,
      text: 'Arbitration Reversed',
      value: 'Arbitration Reversed'
    },
    {
      key: 4,
      text: 'Cancelled Due To Incorrect Info',
      value: 'Cancelled Due To Incorrect Info'
    },
    {
      key: 5,
      text: 'Courtesy',
      value: 'Courtesy'
    },
    {
      key: 6,
      text: 'Other',
      value: 'Other'
    }
  ],
  GUARANTEE_KIND_OPTIONS: [
    { key: '1', value: 'purchase_protect', text: 'Purchase Protect' },
    { key: '2', value: 'market_protect', text: 'Market Protect' },
    { key: '3', value: 'extended_protect', text: 'Extended Protect' }
  ],
  MILES_SELECTED_OPTIONS: [
    { key: '1', value: 250, text: '250M' },
    { key: '2', value: 500, text: '500M' },
    { key: '3', value: 360, text: '360M' },
    { key: '4', value: 600, text: '600M' },
    { key: '5', value: 800, text: '800M' }
  ],
  DAYS_SELECTED_OPTIONS: [
    { key: '1', value: 7, text: '7 Days' },
    { key: '2', value: 14, text: '14 Days' },
    { key: '3', value: 21, text: '21 Days' },
    { key: '4', value: 30, text: '30 Days' }
  ],
  RETURN_CANCELATION_REASONS: [
    { key: 1, text: 'At your request', value: 'At your request' },
    {
      key: 2,
      text: 'Because the vehicle was not received by the expiration date',
      value: 'Because the vehicle was not received by the expiration date'
    },
    {
      key: 3,
      text:
        'Because negotiable title for this vehicle was not received by the expiration date',
      value:
        'Because negotiable title for this vehicle was not received by the expiration date'
    }
  ],
  CANCELLATION_NOTE_LAST_PART:
    'the DealShield Vehicle Return Authorization for the vehicle referenced has been cancelled.',
  DEFAULT_NETWORK_PLUS_SURCHARGE: 35,
  DEFAULT_EXPIRY_DAYS: 30,
  DEFAULT_PAGE_SIZE: 25,
  DEFAULT_SMALL_PAGE_SIZE: 50,
  COLOR_CODE_MAPPINGS: {
    '#F58673': 'red',
    '#FAF7EC': 'white',
    '#9BD093': 'green'
  },
  MARKET_FACTOR_COLORS: [
    { key: 1, text: 'Red', value: '#F58673' },
    { key: 2, text: 'White', value: '#FAF7EC' },
    { key: 3, text: 'Green', value: '#9BD093' }
  ],
  MARKET_FACTOR_STATUS: [
    { key: 1, text: 'Poor', value: 'poor' },
    { key: 2, text: 'Neutral', value: 'neutral' },
    { key: 3, text: 'Good', value: 'good' }
  ],
  GREEN_AUTO_IMS_STATUS: [
    'Cleared for Sale',
    'Pickup Accepted',
    'Sold / Funds Sent',
    'Sold / Funds Not Sent'
  ],
  EMAIL_FREQUENCY_OPTIONS: [
    { key: 1, text: 'Daily Digest (Recommended)', value: 'DAILY' },
    { key: 2, text: 'Weekly Digest', value: 'WEEKLY' },
    { key: 3, text: 'Immediate', value: 'IMMEDIATE' },
    { key: 4, text: 'No Notifications', value: 'NONE' }
  ],
  APPROVAL_STATUS: [
    { key: 0, text: 'All', value: 'ALL' },
    { key: 1, text: 'rejected', value: 'rejected' },
    { key: 2, text: 'unapproved', value: 'unapproved' },
    { key: 3, text: 'unposted', value: 'unposted' },
    { key: 4, text: 'posted', value: 'posted' }
  ],
  OFFERS_INITIATED_SINCE: [
    { key: '1', value: 0, text: 'Today' },
    { key: '2', value: 7, text: 'One Week' },
    { key: '3', value: 30, text: 'Past 30 days' },
    { key: '4', value: 90, text: 'Past 90 days' }
  ],
  TITLE_STATUS_OPTIONS: [
    { key: 0, value: 0, text: 'Havent received yet' },
    {
      key: 1,
      value: 1,
      text: 'In hand (will be returned with car)'
    },
    { key: 2, value: 2, text: 'With Floor Plan' },
    { key: 3, value: 3, text: 'Still at Auction' }
  ],
  RETURN_STATUS: [
    { value: 'created', text: 'New' },
    { value: 'processed', text: 'Active' },
    { value: 'cancelling', text: 'Cancelling' },
    { value: 'cancelled', text: 'Cancelled' }
  ],
  CANCELLED_PURCHASE_STATUS: [
    { value: 'unselected', text: 'Cancelled' },
    { value: 'reinstating', text: 'Reinstating' }
  ]
};
