export default {
  pathList: [
    '/tappy/auctions',
    '/tappy/blacklists/vin',
    '/tappy/blacklists/dealer',
    '/tappy/blacklists/auction_access',
    '/tappy/dealers',
    '/tappy/eligibility/verify',
    '/tappy/floorplans/branches',
    '/tappy/floorplans/companies',
    '/tappy/guarantees',
    '/tappy/network_plus',
    '/tappy/returns',
    '/tappy/subscriptions/dealer',
    '/tappy/subscriptions/uid',
    '/tappy/subscriptions/discover',
    '/tappy/subscriptions',
    '/tappy/transaction/fetch_external_id',
    '/replayChargeEvent/externalAuction/{vin}',
    '/replayChargeEvent/externalAuction'
  ]
};
