import React, { Component } from 'react';
import {
  Form,
  Checkbox,
  Button,
  Modal,
  Icon,
  Select,
  Message
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';
import { CANCELATION_REASONS } from '../../constants';

class PendingGuaranteeReject extends Component {
  state = {
    guaranteePurchaseId: null,
    rejectionReason: null,
    ineligibleForRepurchase: false,
    skipApplyingCredit: false,
    errors: {}
  };

  componentDidMount() {
    const { pendingGuarantee } = this.props;
    const { guaranteePurchaseId } = pendingGuarantee;

    this.setState({ guaranteePurchaseId });
  }

  render() {
    const {
      pendingGuarantee,
      isPendingRejectModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const { errors } = this.state;

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const onCheckboxHandler = field => (event, data) => {
      this.setState({ [field]: data.checked });
    };

    const validationRules = {
      rejectionReason: 'required'
    };

    const submitForm = e => {
      const {
        guaranteePurchaseId,
        rejectionReason,
        ineligibleForRepurchase,
        skipApplyingCredit
      } = this.state;

      const validation = new Validator(this.state, validationRules);

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          guaranteePurchaseId,
          rejection: {
            rejectionReason,
            ineligibleForRepurchase,
            skipApplyingCredit
          }
        });
        this.setState({ ...validation.errors });
      }
    };

    const {
      vehicleInformation: { vin }
    } = pendingGuarantee;

    return (
      <Modal
        open={isPendingRejectModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Reject Pending Purchase for VIN {vin}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form widths="equal">
              <Form.Field required error={!isEmpty(errors.rejectionReason)}>
                <label>Reason</label>
                <Select
                  placeholder="Select rejection reason"
                  options={CANCELATION_REASONS}
                  closeOnBlur
                  value={this.state.rejectionReason}
                  onChange={onSelectHandler('rejectionReason').bind(this)}
                />
                {errors.rejectionReason && (
                  <span className="error-text">{errors.rejectionReason}</span>
                )}
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field>
                  <Checkbox
                    label="Ineligible for repurchase"
                    checked={this.state.ineligibleForRepurchase}
                    onChange={onCheckboxHandler('ineligibleForRepurchase').bind(
                      this
                    )}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="Skip Applying Credit"
                    checked={this.state.skipApplyingCredit}
                    onChange={onCheckboxHandler('skipApplyingCredit').bind(
                      this
                    )}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Submit
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default PendingGuaranteeReject;
