import React, { Component } from 'react';
import { Button, Grid, Header, Message, Tab } from 'semantic-ui-react';
import client from '../apollo';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { isEmpty } from 'lodash';

import AuctionExceptions from '../views/ExpiredVraSettings/AuctionExceptions';
import DealerExceptions from '../views/ExpiredVraSettings/DealerExceptions';
import GroupExceptions from '../views/ExpiredVraSettings/GroupExceptions';

import GlobalPeriodEdit from '../views/ExpiredVraSettings/ModalForm/Edit';

import autoCancellationExceptionListQuery from '../graphql/autoCancellationExceptionListQuery';
import updateAutoCancellationExceptionMutation from '../graphql/updateAutoCancellationExceptionMutation';

import LoadingDataEnhancer from './LoadingData';

class ExpiredVraSettingsTabs extends Component {
  state = {
    selectedGlobalGracePeriod: {},
    selectedGlobalReinstateGracePeriod: {},
    isEditGlobalPeriodModalOpen: false,
    isEditGlobalReinstatePeriodModalOpen: false,
    editGlobalPeriodFormError: null,
    editGlobalPeriodFormSubmitting: null,
    editGlobalReinstatePeriodFormError: null,
    editGlobalReinstatePeriodFormSubmitting: null,
    error: null,
    submitting: null,
    flashMessage: null
  };

  editGlobalGracePeriodHandler = () => {
    this.setState({ isEditGlobalPeriodModalOpen: true });
  };

  editGlobalReinstatePeriodHandler = () => {
    this.setState({ isEditGlobalReinstatePeriodModalOpen: true });
  };

  onGlobalPeriodEditFormCloseHandler = () => {
    this.setState({
      editGlobalPeriodFormError: null,
      editGlobalPeriodFormSubmitting: null,
      isEditGlobalPeriodModalOpen: false
    });
  };

  onGlobalReinstatePeriodEditFormCloseHandler = () => {
    this.setState({
      editGlobalReinstatePeriodFormError: null,
      editGlobalReinstatePeriodFormSubmitting: null,
      isEditGlobalReinstatePeriodModalOpen: false
    });
  };

  onGlobalPeriodEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const input = { ...this.state.selectedGlobalGracePeriod, ...values };

    // clean old status
    this.setState({
      editGlobalPeriodFormSubmitting: true,
      editGlobalPeriodFormError: null
    });

    updateMutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { updateAutoCancellationException: { success, msg } } = data;
        if (success) {
          this.setState({
            editGlobalPeriodFormSubmitting: false,
            flashMessage: 'Successfully Updated Global Grace Period.',
            editGlobalPeriodFormError: null
          });
          this.onGlobalPeriodEditFormCloseHandler();
          this.fetchAllGlobalReturn();
        } else {
          this.setState({
            editGlobalPeriodFormSubmitting: false,
            flashMessage: null,
            editGlobalPeriodFormError: 'Failed To Update Global Grace Period.'
          });
        }
      })
      .catch(error => {
        this.setState({
          editGlobalPeriodFormSubmitting: false,
          flashMessage: null,
          editGlobalPeriodFormError: 'Failed To Update Global Grace Period.'
        });
      });
  };

  onGlobalReinstatePeriodEditFormSubmitHandler = values => {
    const { updateMutation } = this.props;
    const input = {
      ...this.state.selectedGlobalReinstateGracePeriod,
      ...values
    };

    this.setState({
      editGlobalReinstatePeriodFormSubmitting: true,
      editGlobalReinstatePeriodFormError: null
    });

    updateMutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { updateAutoCancellationException: { success, msg } } = data;
        if (success) {
          this.setState({
            editGlobalReinstatePeriodFormSubmitting: false,
            flashMessage: 'Successfully Updated Global Reinstated Grace Period.'
          });
          this.onGlobalReinstatePeriodEditFormCloseHandler();
          this.fetchAllGlobalReturn();
        } else {
          this.setState({
            editGlobalReinstatePeriodFormError:
              'Failed To Update Global Reinstated Period.',
            flashMessage: null,
            editGlobalReinstatePeriodFormSubmitting: false
          });
        }
      })
      .catch(error => {
        this.setState({
          editGlobalReinstatePeriodFormSubmitting: false,
          flashMessage: null,
          editGlobalReinstatePeriodFormError:
            'Failed To Update Global Reinstated Period.'
        });
      });
  };

  findAPeriodByType(periods, type) {
    for (let period of periods) {
      if (period.exceptionType === type) {
        return period;
      }
    }
    return null;
  }

  fetchAllGlobalReturn() {
    client
      .query({
        query: autoCancellationExceptionListQuery,
        variables: {
          exceptionTypes: 'GLOBAL_RETURN,GLOBAL_REINSTATED_RETURN'
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const { autoCancellationExceptionList } = data;
        if (!isEmpty(autoCancellationExceptionList)) {
          let globalGracePeriod = this.findAPeriodByType(
            autoCancellationExceptionList,
            'GLOBAL_RETURN'
          );
          if (isEmpty(globalGracePeriod)) {
            this.setState({
              flashMessage: null,
              editGlobalPeriodFormError: 'Not Found Global Period.'
            });
          }

          let globalGraceReinstatedPeriod = this.findAPeriodByType(
            autoCancellationExceptionList,
            'GLOBAL_REINSTATED_RETURN'
          );
          if (isEmpty(globalGracePeriod)) {
            this.setState({
              flashMessage: null,
              editGlobalReinstatePeriodFormError:
                'Not Found Global Reinstated Period.'
            });
          }

          this.setState({
            selectedGlobalGracePeriod: globalGracePeriod || {},
            selectedGlobalReinstateGracePeriod:
              globalGraceReinstatedPeriod || {}
          });
        }
      })
      .catch(error => {
        this.setState({
          flashMessage: null,
          editGlobalPeriodFormError: 'Retrieve Global Period Failed.',
          editGlobalReinstatePeriodFormError:
            'Retrieve Global Reinstated Period Failed.'
        });
      });
  }

  componentDidMount() {
    this.fetchAllGlobalReturn();
  }

  render() {
    const {
      isEditGlobalPeriodModalOpen,
      isEditGlobalReinstatePeriodModalOpen,
      editGlobalPeriodFormError,
      editGlobalPeriodFormSubmitting,
      editGlobalReinstatePeriodFormError,
      editGlobalReinstatePeriodFormSubmitting,
      selectedGlobalGracePeriod,
      selectedGlobalReinstateGracePeriod,
      flashMessage
    } = this.state;

    const panes = [
      {
        menuItem: 'Auction Exceptions',
        render: () => (
          <Tab.Pane>
            {' '}
            <AuctionExceptions />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Dealer Exceptions',
        render: () => (
          <Tab.Pane>
            <DealerExceptions />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Group Exceptions',
        render: () => (
          <Tab.Pane>
            <GroupExceptions />
          </Tab.Pane>
        )
      }
    ];

    const handleDismiss = () => {
      this.setState({ flashMessage: null });
    };

    return (
      <div>
        {flashMessage && (
          <Message onDismiss={handleDismiss} content={flashMessage} positive />
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header size="large">Expired VRA Settings</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header size="tiny">Global Grace Period:</Header>
            </Grid.Column>
            <Grid.Column width={2}>
              {!isEmpty(selectedGlobalGracePeriod) &&
                selectedGlobalGracePeriod.gracePeriod}{' '}
              days
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                color="green"
                onClick={this.editGlobalGracePeriodHandler}
                inverted
              >
                Edit Grace Period
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header size="tiny">Global Reinstated VRA Grace Period:</Header>
            </Grid.Column>
            <Grid.Column width={2}>
              {!isEmpty(selectedGlobalReinstateGracePeriod) &&
                selectedGlobalReinstateGracePeriod.gracePeriod}{' '}
              days
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                color="green"
                onClick={this.editGlobalReinstatePeriodHandler}
                inverted
              >
                Edit Grace Period
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>{' '}
        {isEditGlobalPeriodModalOpen && (
          <div>
            <GlobalPeriodEdit
              modalType={'UPDATE_GLOBAL_PERIOD'}
              onCloseHandler={this.onGlobalPeriodEditFormCloseHandler}
              onSubmitHandler={this.onGlobalPeriodEditFormSubmitHandler}
              error={editGlobalPeriodFormError}
              submitting={editGlobalPeriodFormSubmitting}
              globalExpiredVraSettings={selectedGlobalGracePeriod}
            />
          </div>
        )}
        {isEditGlobalReinstatePeriodModalOpen && (
          <div>
            <GlobalPeriodEdit
              modalType={'UPDATE_GLOBAL_REINSTATE_PERIOD'}
              onCloseHandler={this.onGlobalReinstatePeriodEditFormCloseHandler}
              onSubmitHandler={
                this.onGlobalReinstatePeriodEditFormSubmitHandler
              }
              error={editGlobalReinstatePeriodFormError}
              submitting={editGlobalReinstatePeriodFormSubmitting}
              globalExpiredVraSettings={selectedGlobalReinstateGracePeriod}
            />
          </div>
        )}
        <Tab panes={panes} />
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(updateAutoCancellationExceptionMutation, { name: 'updateMutation' }),
  LoadingDataEnhancer
)(ExpiredVraSettingsTabs);
