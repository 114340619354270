import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  Form,
  Segment,
  Table,
  Header,
  Modal,
  Select,
  Input,
  Icon,
  Button,
  Message,
  Loader,
  Dimmer
} from 'semantic-ui-react';
import { get } from 'lodash';
import client from '../../apollo';
import Validator from 'validatorjs';

import SearchGuaranteeChargeQuery from '../../graphql/SearchGuaranteeChargeQuery';
import GuaranteeChargeMutation from '../../graphql/GuaranteeChargeMutation';
import Product from '../../constants/product';

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flashMessage: null,
      vin: '',
      dealerNo: '',
      auctionLocation: '',
      guaranteeDate: '',
      dsPrice: '',
      additionalNotes: '',
      consignmentID: '',
      product: null,
      feeType: null,
      adjustmentReason: null,
      guaranteeOfferId: '',
      guaranteeStatus: '',
      purchaseprice: '',
      dealerName: '',
      disable: true,
      confirmModal: false,
      disableSubmit: false,
      oldDsPrice: '',
      successMessage: null,
      errors: {},
      loading: false
    };
  }

  onSearchSubmitHandler = event => {
    const { dealerNo, vin } = this.state;
    if (isEmpty(dealerNo) || isEmpty(vin)) {
      alert('Dealer Number / VIN cannot be blank');
      return false;
    }
    client
      .query({
        query: SearchGuaranteeChargeQuery,
        variables: { dealer_no: dealerNo, vin: vin }
      })
      .then(({ data }) => {
        const result = get(data, 'searchGuaranteeCharge');
        if (isEmpty(result)) {
          this.setState({
            flashMessage: 'The data is not available',
            successMessage: null
          });
          this.changeStateValues();
        } else {
          this.setState({
            disable: false,
            auctionLocation: isEmpty(
              result[0].auctionInformation.auctionLocation
            )
              ? ''
              : result[0].auctionInformation.auctionLocation.name,
            guaranteeDate: isEmpty(result[0].guaranteedAt)
              ? ''
              : moment(new Date(result[0].guaranteedAt)).format('DD-MM-YYYY'),
            dsPrice: result[0].guaranteePrice,
            oldDsPrice: result[0].guaranteePrice,
            consignmentID:
              result[0].consignmentId === null ? '' : result[0].consignmentId,
            product: result[0].product,
            guaranteeOfferId: result[0].id,
            guaranteeStatus: result[0].guaranteeStatus,
            dealerName: result[0].dealerName,
            purchasePrice: result[0].purchasePrice,
            feeType: null,
            adjustmentReason: null,
            errors: {},
            flashMessage: null,
            successMessage: null
          });
        }
      });
  };

  onChangeHandler = field => event => {
    this.setState({ [field]: event.target.value });
  };

  onSelectHandler = field => (event, data) => {
    this.setState({ [field]: data.value });
  };

  handleDismiss = () => {
    this.setState({ flashMessage: null, successMessage: null });
  };

  onCloseHandler = () => {
    this.setState({ confirmModal: false });
  };

  changeStateValues = () => {
    this.setState({
      auctionLocation: '',
      guaranteeDate: '',
      dsPrice: '',
      additionalNotes: '',
      consignmentID: '',
      product: null,
      guaranteeOfferId: '',
      guaranteeStatus: '',
      feeType: null,
      adjustmentReason: null,
      dealerName: '',
      purchasePrice: '',
      oldDsPrice: '',
      errors: {},
      disable: true,
      disableSubmit: false
    });
  };

  render() {
    const {
      flashMessage,
      vin,
      dealerNo,
      errors,
      successMessage,
      loading
    } = this.state;

    const feeTypeOptions = [
      { key: 1, text: 'Issue Charge', value: 'charge' },
      { key: 2, text: 'Issue Credit', value: 'credit' }
    ];

    const adjustmentReasonOptions = [
      { key: 1, text: 'Bundle Pricing', value: 'Bundle Pricing' },
      {
        key: 2,
        text: 'Credit Failed to Post – API Failure',
        value: 'Credit Failed to Post – API Failure'
      },
      {
        key: 3,
        text: 'Credit Failed to Post – Cancelled Consignment',
        value: 'Credit Failed to Post – Cancelled Consignment'
      },
      {
        key: 4,
        text: 'Credit Failed to Post – Other/ Unknown',
        value: 'Credit Failed to Post – Other/ Unknown'
      },
      { key: 5, text: 'Courtesy Credit', value: 'Courtesy Credit' },
      { key: 6, text: 'Excessive Return Fee', value: 'Excessive Return Fee' },
      {
        key: 7,
        text: 'Fee Failed to Post – API Failure',
        value: 'Fee Failed to Post – API Failure'
      },
      {
        key: 8,
        text: 'Fee Failed to Post – Other/ Unknown',
        value: 'Fee Failed to Post – Other/ Unknown'
      },
      { key: 9, text: 'Incorrect Rate', value: 'Incorrect Rate' },
      { key: 10, text: 'Other', value: 'Other' }
    ];

    const validationRules = {
      dsPrice: 'required|numeric|customPrice',
      product: 'required',
      feeType: 'required',
      adjustmentReason: 'required',
      additionalNotes: 'maxLengthAdditonalNotes'
    };

    Validator.register(
      'maxLengthAdditonalNotes',
      function(value, requirement, attribute) {
        return value.length < 500;
      },
      'Additional Notes cannot exceed 500 characters.'
    );

    Validator.register(
      'customPrice',
      function(value, requirement, attribute) {
        return value > 0;
      },
      'Price cannot be zero or below.'
    );

    const submitForm = e => {
      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else if (this.state.guaranteeStatus === 'pending') {
        this.setState({
          flashMessage: `${this.state.feeType} is not allowed as the Guarantee is in Pending status`,
          errors: {}
        });
        return false;
      } else {
        this.setState({
          confirmModal: true,
          errors: {}
        });
      }
    };

    const handleConfirm = () => {
      this.setState({
        confirmModal: false,
        disableSubmit: true,
        loading: true
      });
      const { guaranteeChargeMutation } = this.props;
      const input = {
        pushCharge: {
          offerId: `${this.state.guaranteeOfferId}`,
          feeType: `${this.state.feeType}`,
          adjustmentReason: `${this.state.adjustmentReason}`,
          dsPrice: `${this.state.dsPrice}`,
          additionalNotes: `${this.state.additionalNotes}`,
          product: `${this.state.product}`
        }
      };

      guaranteeChargeMutation({
        variables: { input }
      }).then(({ data: { createPushCharge } }) => {
        const { success, errors } = createPushCharge;
        if (success) {
          this.setState({
            successMessage: 'Successfully posted to G2GWS',
            vin: '',
            dealerNo: '',
            loading: false,
            flashMessage: null
          });
          this.changeStateValues();
        } else {
          this.setState({
            flashMessage: `Errors: ${errors}`,
            disableSubmit: false,
            loading: false,
            successMessage: null
          });
        }
      });
    };

    const closeModal = () => {
      this.setState({ confirmModal: false });
    };

    return (
      <div>
        {loading && (
          <Dimmer active inverted>
            <Loader size="large"> Loading </Loader>{' '}
          </Dimmer>
        )}{' '}
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            negative
          />
        )}
        {successMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={successMessage}
            positive
          />
        )}
        <Modal
          open={this.state.confirmModal}
          closeIcon
          onClose={this.onCloseHandler}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
        >
          <Modal.Header>Review {this.state.feeType}</Modal.Header>
          <Modal.Description style={{ paddingLeft: '25px' }}>
            <Form>
              <Header size="small" color="blue" />
              <Form.Group>
                <Form.Field>
                  <label>VIN</label>
                  <Input name="vin" value={this.state.vin} disabled={true} />
                </Form.Field>
                <Form.Field>
                  <label>Initial DS Price</label>
                  <Input
                    name="dsPrice"
                    value={this.state.oldDsPrice}
                    disabled={true}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Adjustment Amount</label>
                  <Input
                    name="dsPrice"
                    value={this.state.dsPrice}
                    disabled={true}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Fee Type</label>
                  <Input
                    name="feeType"
                    value={this.state.feeType}
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                paddingLeft: '13px'
              }}
            >
              {this.state.dsPrice >= 500 && (
                <span style={{ color: this.state.dsPrice >= 500 ? 'red' : '' }}>
                  The DS price exceeds $500.&nbsp;
                </span>
              )}
              Click 'Continue' to submit {this.state.feeType} or Click 'Dismiss'
              to make changes.
            </span>
            <br />
            <br />
          </Modal.Description>
          <Modal.Actions>
            <Button color="green" inverted onClick={handleConfirm}>
              <Icon name="checkmark" /> Continue
            </Button>
            <Button color="red" inverted onClick={closeModal}>
              <Icon name="remove" /> Dismiss
            </Button>
          </Modal.Actions>
        </Modal>
        <Segment placeholder="true">
          <Form>
            <Form.Group widths="two">
              <Form.Input
                name="vin"
                placeholder="Vin"
                value={vin}
                onChange={this.onChangeHandler('vin')}
              />
              <Form.Input
                name="dealerNo"
                placeholder="5M"
                value={dealerNo}
                onChange={this.onChangeHandler('dealerNo')}
              />
              <Form.Button
                color="green"
                onClick={event => {
                  this.onSearchSubmitHandler();
                }}
                inverted
              >
                Search
              </Form.Button>
            </Form.Group>
            <div>
              <Table
                celled
                selectable
                padded
                size="large"
                compact="very"
                style={{ fontSize: '90%' }}
                striped
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Dealer Name</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Dealer Name"
                        name="dealerName"
                        value={this.state.dealerName}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Auction Location</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Auction Location"
                        name="auctionLocation"
                        value={this.state.auctionLocation}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Guarantee_Offer_Id</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Guarantee_Offer_Id"
                        name="guaranteeOfferId"
                        value={this.state.guaranteeOfferId}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Guarantee Date</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Guarantee Date"
                        name="guaranteeDate"
                        value={this.state.guaranteeDate}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Guarantee Status</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Guarantee Status"
                        name="guaranteeStatus"
                        value={this.state.guaranteeStatus}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Vehicle Purchase Price</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Vehicle Purchase Price"
                        name="purchasePrice"
                        value={this.state.purchasePrice}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      DS Price<span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="DS Price"
                        name="dsPrice"
                        onChange={this.onChangeHandler('dsPrice')}
                        value={this.state.dsPrice}
                        disabled={this.state.disable}
                      />
                      {errors.dsPrice && (
                        <span style={{ color: 'red' }}>{errors.dsPrice}</span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Consignment ID</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Consignment ID"
                        name="consignmentID"
                        value={this.state.consignmentID}
                        disabled={true}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      DS Product<span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Product"
                        options={Product.PRODUCTS}
                        name="product"
                        value={this.state.product}
                        onChange={this.onSelectHandler('product')}
                        disabled={this.state.disable}
                      />
                      {errors.product && (
                        <span style={{ color: 'red' }}>{errors.product}</span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Fee Type<span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Fee Type"
                        options={feeTypeOptions}
                        name="feeType"
                        value={this.state.feeType}
                        onChange={this.onSelectHandler('feeType')}
                        disabled={this.state.disable}
                      />
                      {errors.feeType && (
                        <span style={{ color: 'red' }}>{errors.feeType}</span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Adjustment Reason
                      <span style={{ color: 'red' }}>{' * '}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder="Select Adjustment Reason"
                        options={adjustmentReasonOptions}
                        name="adjustmentReason"
                        value={this.state.adjustmentReason}
                        onChange={this.onSelectHandler('adjustmentReason')}
                        disabled={this.state.disable}
                      />
                      {errors.adjustmentReason && (
                        <span style={{ color: 'red' }}>
                          {errors.adjustmentReason}
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Additional Notes</Table.Cell>
                    <Table.Cell>
                      <textarea
                        placeholder="Additional Notes"
                        name="additionalNotes"
                        onChange={this.onChangeHandler('additionalNotes')}
                        value={this.state.additionalNotes}
                        disabled={this.state.disable}
                      />
                      {errors.additionalNotes && (
                        <span style={{ color: 'red' }}>
                          {errors.additionalNotes}
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            {!this.state.disable && (
              <p>
                <Button
                  color="green"
                  inverted
                  onClick={submitForm}
                  disabled={this.state.disableSubmit}
                >
                  <Icon name="checkmark" /> Submit
                </Button>
              </p>
            )}
          </Form>
        </Segment>
      </div>
    );
  }
}

const guaranteeChargeMutation = graphql(GuaranteeChargeMutation, {
  name: 'guaranteeChargeMutation'
});

export default compose(guaranteeChargeMutation)(SearchForm);
