import gql from 'graphql-tag';

const createAutoCancellationExceptionMutation = gql`
  mutation createAutoCancellationException(
    $gracePeriod: Int
    $exceptionEndTime: String
    $exceptionType: String
    $exceptionReason: String
    $exceptionSourceId: Int
  ) {
    createAutoCancellationException(
      input: {
        autoCancellationException: {
          gracePeriod: $gracePeriod
          exceptionEndTime: $exceptionEndTime
          exceptionType: $exceptionType
          exceptionReason: $exceptionReason
          exceptionSourceId: $exceptionSourceId
        }
      }
    ) {
      success
      msg
    }
  }
`;

export default createAutoCancellationExceptionMutation;
