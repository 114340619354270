import { gql } from 'react-apollo';

const filteredFlatRatesQuery = gql`
  query filteredFlatRates(
    $auctionAccessNumber: Int!
    $miles: Int!
    $dealerNumbers: [String]
    $psi: Int
    $daysSelected: Int
    $daysPsi: Int
    $govtLast4: String
  ) {
    apmsFlatRates(
      auctionAccessNumber: $auctionAccessNumber
      miles: $miles
      psi: $psi
      daysSelected: $daysSelected
      daysPsi: $daysPsi
      dealerNumbers: $dealerNumbers
    ) {
      dealerNo
      targetPrice
      id
    }
    buyerInformation(
      auctionAccessNumber: $auctionAccessNumber
      govtLast4: $govtLast4
    ) {
      id
      name
      emails
      cellPhone
    }
  }
`;

export default filteredFlatRatesQuery;
