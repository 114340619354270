import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { isEmpty } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { utcMoment } from '../../utilities/dateUtils';
import moment from 'moment';

const DateRangeSearch = ({
  onDateChangeHandler,
  onSearchHandler,
  clearDates,
  searchFields,
  value,
  handleDateChangeRaw
}) => {
  var value1;
  var startdate = isEmpty(value['startDate'])
    ? (value1 = searchFields['startDate'])
    : (value1 = value['startDate']);
  return (
    <div>
      <Form onSubmit={onSearchHandler}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Start Date</label>
            <DatePicker
              style={{ padding: '0', width: '100%' }}
              selected={
                isEmpty(value['startDate'])
                  ? searchFields['startDate']
                  : value['startDate']
              }
              onChange={date => onDateChangeHandler('startDate', date)}
              dateFormat="YYYY-MM-DD"
              name="startDate"
            />
          </Form.Field>
          <Form.Field>
            <label>End Date</label>
            <DatePicker
              style={{ padding: '0', width: '100%' }}
              selected={
                isEmpty(value['endDate'])
                  ? searchFields['endDate']
                  : value['endDate']
              }
              onChange={date => onDateChangeHandler('endDate', date)}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeRaw={handleDateChangeRaw}
              required
            />
          </Form.Field>
          <Form.Field>
            <label>&nbsp;&nbsp;</label>
            <Button color="green" id="searchButton" inverted>
              Search
            </Button>
            <Button color="green" type="button" onClick={clearDates} inverted>
              Clear
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <br />
    </div>
  );
};

export default DateRangeSearch;
