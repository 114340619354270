import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import Clipboard from 'react-clipboard.js';

import { Message, Table, Button, Icon } from 'semantic-ui-react';
import { Field, formValueSelector, change } from 'redux-form';
import {
  get,
  range,
  map,
  toString,
  trim,
  toNumber,
  isNaN,
  isNumber,
  toSafeInteger,
  capitalize,
  includes,
  find,
  isEmpty
} from 'lodash';

import client from '../../apollo';
import TextInput from '../NetworkPlusVehicles/TextInput';
import DateInput from '../NetworkPlusVehicles/DateInput';
import SelectDropdown from '../NetworkPlusVehicles/SelectDropdown';
import LoadingDataEnhancer from '../../containers/LoadingData';
import ftbLocationsQuery from '../../graphql/ftbLocationsQuery';
import decodeVinQuery from '../../graphql/decodeVinQuery';
import dealerInformationQuery from '../../graphql/dealerInformationQuery';

const yearOptions = () => {
  const yearOptionGen = year => {
    return { key: year, value: year, text: toString(year) };
  };
  const years = range(moment().year() + 1, 1996);
  return map(years, yearOptionGen);
};

const locationOptionGen = ftbLocation => {
  const { id, name } = ftbLocation;
  return { key: id, value: name, text: toString(name) };
};

const fieldRequired = value => (value ? undefined : '* Required');
const validDealer = value => (value ? undefined : 'Invalid Dealer');
const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

const hasLength = len => value =>
  value && value.length !== len ? `Must be ${len} characters` : undefined;
const hasLength17 = hasLength(17);

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength4 = maxLength(4);
const maxLength5 = maxLength(5);
const maxLength6 = maxLength(6);

const shouldBeNumber = value =>
  value && isNaN(toNumber(value)) ? 'Must be a number' : undefined;
const shouldBe5mil = value =>
  value && !/^5[0-9]{6,6}$/i.test(value)
    ? 'Must be a Dealer Number'
    : undefined;
const shouldBeEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

const purchaseDateValidation = (vehicles, index, dispatch) => value => {
  if (value) {
    if (moment(value).isBefore(moment().subtract(19, 'days'))) {
      return 'Date older than 19 days';
    }

    if (moment(value).isAfter(moment())) {
      return 'Future date entered';
    }
  }
  return undefined;
};

class EditVehicleFieldRow extends Component {
  state = {
    vinError: '',
    dealerErr: undefined,
    gettingInfo: ''
  };

  onSuccess = () => {
    this.setState({ gettingInfo: 'VIN copied' });
  };

  render() {
    const {
      fields,
      vehicles,
      dispatch,
      onApproveHandler,
      onRejectHandler,
      onUpdateHandler,
      data: { ftbLocations }
    } = this.props;

    const getText = () => {
      const { vehicles } = this.props;
      const vins_array = [];
      vehicles &&
        vehicles.map(vehicle => {
          return vins_array.push(vehicle.vin);
        });
      return vins_array.join('\n');
    };
    const locationOptions = map(ftbLocations, locationOptionGen);
    const calculateSum = (vehicles, index, dispatch) => (
      event,
      newValue,
      previousValue,
      name
    ) => {
      const vehicle = vehicles[index];
      const vehiclePurchasePrice = vehicle.vehiclePurchasePrice;
      const buyFee = vehicle.buyFee;
      const totalVal =
        toSafeInteger(buyFee) + toSafeInteger(vehiclePurchasePrice);

      if (buyFee || vehiclePurchasePrice) {
        dispatch(
          change(
            'npEditForm',
            `vehicles[${index}].vehicleTotal`,
            toString(totalVal)
          )
        );
      }
    };

    const getDealerName = (vehicles, index, dispatch) => {
      const vehicle = vehicles[index];
      const dealerNumber = vehicle.dealerNo;
      if (dealerNumber) {
        dispatch(change('npEditForm', `vehicles[${index}].dealerName`, ''));
        this.setState({
          vinError: '',
          gettingInfo: 'Fetching Dealer Name ...',
          dealerErr: ''
        });
        client
          .query({
            query: dealerInformationQuery,
            variables: { dealerNumber: dealerNumber }
          })
          .then(({ data }) => {
            this.setState({ vinError: '', gettingInfo: '', dealerErr: '' });
            const dealerName = get(data, 'dealerInformation.dealerName');
            if (dealerName) {
              dispatch(
                change(
                  'npEditForm',
                  `vehicles[${index}].dealerName`,
                  dealerName
                )
              );
            } else {
              this.setState({ dealerErr: 'Invalid Dealer' });
              dispatch(
                change('npEditForm', `vehicles[${index}].dealerName`, '')
              );
            }
          })
          .catch(error => {
            this.setState({ dealerErr: 'Invalid Dealer' });
          });
      }
    };
    const parseValueToInt = (value, name) => {
      if (value && isNumber(value)) {
        return parseInt(value, 10);
      } else {
        return value;
      }
    };
    const trimValue = (value, name) => {
      if (value) {
        return trim(value.toUpperCase());
      } else {
        return value.toUpperCase();
      }
    };
    const handleDismiss = () => {
      this.setState({ vinError: '', gettingInfo: '', dealerErr: undefined });
    };

    const decodeVin = (vehicles, index, dispatch) => {
      const vehicle = vehicles[index];
      const { vin } = vehicle;
      if (vin && vin.length > 0) {
        this.setState({ vinError: '', gettingInfo: 'Fetching VIN info ...' });
        client
          .query({
            query: decodeVinQuery,
            variables: { vin: vin }
          })
          .then(({ data }) => {
            this.setState({ gettingInfo: '' });
            const { decodeVin: { year, make, model, errors } } = data;

            const currentYearValue = vehicle.year;
            if (!(currentYearValue && currentYearValue > 0)) {
              dispatch(
                change('npEditForm', `vehicles[${index}].year`, year || '')
              );
            }
            const currentMakeValue = vehicle.make;
            if (!(currentMakeValue && currentMakeValue.length > 0)) {
              dispatch(
                change('npEditForm', `vehicles[${index}].make`, make || '')
              );
            }
            const currentModelValue = vehicle.model;
            if (!(currentModelValue && currentModelValue.length > 0)) {
              dispatch(
                change('npEditForm', `vehicles[${index}].model`, model || '')
              );
            }

            if (errors) {
              this.setState({ vinError: errors });
            }
          })
          .catch(error => {
            this.setState({
              vinError: `Error fetching info for ${vin} - ${error}`
            });
          });
      }
    };

    const vinQuery = (vehicles, index, dispatch) => event => {
      decodeVin(vehicles, index, dispatch);
    };

    const dealerQuery = (vehicles, index, dispatch) => event => {
      getDealerName(vehicles, index, dispatch);
    };

    const showApprove = (vehicles, index) => {
      const { user } = this.props;
      const vehicle = vehicles[index];
      const {
        status,
        approvingUserId,
        rejectingUserId,
        secondaryApprovingUserId,
        secondaryRejectingUserId
      } = vehicle;

      const beforePrimaryApproval =
        !isNumber(approvingUserId) &&
        !isNumber(rejectingUserId) &&
        (status === 'unapproved' || status === 'rejected');
      const afterPrimaryApproval =
        isNumber(approvingUserId) && approvingUserId === toNumber(user.id);
      const afterPrimaryRejection =
        isNumber(rejectingUserId) && rejectingUserId === toNumber(user.id);
      const afterSecondaryApproval =
        isNumber(secondaryApprovingUserId) &&
        secondaryApprovingUserId === toNumber(user.id);
      const afterSecondaryRejection =
        isNumber(secondaryRejectingUserId) &&
        secondaryRejectingUserId === toNumber(user.id);

      const afterPrimaryRejectionAnyUser =
        !beforePrimaryApproval &&
        !afterPrimaryApproval &&
        !afterPrimaryRejection &&
        !afterSecondaryApproval &&
        !afterSecondaryRejection;

      const primaryCondition = afterPrimaryApproval
        ? !afterPrimaryApproval
        : afterPrimaryRejection;
      const secondaryCondition = afterSecondaryApproval
        ? !afterSecondaryApproval
        : afterSecondaryRejection;
      const approvalCondition =
        afterSecondaryApproval || afterSecondaryRejection
          ? secondaryCondition
          : primaryCondition;

      const enteredVehicles = find(vehicles, ['userId', toNumber(user.id)]);
      const show = isEmpty(enteredVehicles) ? true : false;

      return (
        show &&
        (beforePrimaryApproval ||
          approvalCondition ||
          afterPrimaryRejectionAnyUser)
      );
    };

    const showReject = (vehicles, index) => {
      const { user } = this.props;
      const vehicle = vehicles[index];
      const {
        approvingUserId,
        rejectingUserId,
        secondaryApprovingUserId,
        secondaryRejectingUserId
      } = vehicle;

      const beforePrimaryApproval =
        !isNumber(approvingUserId) &&
        !isNumber(rejectingUserId) &&
        !isNumber(secondaryApprovingUserId) &&
        !isNumber(secondaryRejectingUserId);
      const afterPrimaryApproval =
        isNumber(approvingUserId) && approvingUserId === toNumber(user.id);
      const afterPrimaryRejection =
        isNumber(rejectingUserId) && rejectingUserId === toNumber(user.id);
      const afterSecondaryApproval =
        isNumber(secondaryApprovingUserId) &&
        secondaryApprovingUserId === toNumber(user.id);
      const afterSecondaryRejection =
        isNumber(secondaryRejectingUserId) &&
        secondaryRejectingUserId === toNumber(user.id);

      const enteredVehicles = find(vehicles, ['userId', toNumber(user.id)]);
      const show = isEmpty(enteredVehicles) ? true : false;

      return (
        show &&
        (beforePrimaryApproval ||
          (!afterPrimaryApproval &&
            !afterPrimaryRejection &&
            !afterSecondaryApproval &&
            !afterSecondaryRejection))
      );
    };

    const updateHandler = (vehicles, index) => () => {
      const vehicle = vehicles[index];
      onUpdateHandler(vehicle);
    };

    const approveHandler = (vehicles, index) => () => {
      const vehicle = vehicles[index];
      onApproveHandler(vehicle);
    };

    const rejectHandler = (vehicles, index) => () => {
      const vehicle = vehicles[index];
      onRejectHandler(vehicle);
    };

    const failedReason = (vehicles, index) => {
      const vehicle = vehicles[index];
      return vehicle.failedReason;
    };

    const vehicleStatus = (vehicles, index) => {
      const vehicle = vehicles[index];
      const { status } = vehicle;
      const humanizedStatus = capitalize(
        includes(['approved', 'posted', 'unposted'], status)
          ? 'approved'
          : status
      );
      return humanizedStatus;
    };

    const needsSecondaryApproval = vehicles.filter(
      item =>
        item.secondaryApprovalEligibility &&
        !isNumber(item.secondaryApprovingUserId) &&
        !isNumber(item.secondaryRejectingUserId)
    );

    const TableHeader = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            VIN
            <Clipboard option-text={() => getText()} onSuccess={this.onSuccess}>
              <Icon name="clipboard" style={{ cursor: 'pointer' }} />
            </Clipboard>
          </Table.HeaderCell>
          <Table.HeaderCell>Year</Table.HeaderCell>
          <Table.HeaderCell>Make</Table.HeaderCell>
          <Table.HeaderCell>Model</Table.HeaderCell>
          <Table.HeaderCell>Odometer</Table.HeaderCell>
          <Table.HeaderCell>Dealer#</Table.HeaderCell>
          <Table.HeaderCell>Dealer Name</Table.HeaderCell>
          <Table.HeaderCell>Seller Name</Table.HeaderCell>
          <Table.HeaderCell style={{ width: '20px' }}>
            Purchase Price
          </Table.HeaderCell>
          <Table.HeaderCell>Buy Fee</Table.HeaderCell>
          <Table.HeaderCell>Vehicle Total</Table.HeaderCell>
          <Table.HeaderCell>Location</Table.HeaderCell>
          <Table.HeaderCell>Email Address</Table.HeaderCell>
          <Table.HeaderCell>Guarantee Purchase Date</Table.HeaderCell>
          <Table.HeaderCell>Rejection Comments</Table.HeaderCell>
          {!isEmpty(needsSecondaryApproval) && (
            <Table.HeaderCell>Secondary Rejection Comments</Table.HeaderCell>
          )}
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Failed Reason</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );

    return (
      <div
        style={{
          overflowX: 'scroll',
          overflowY: 'hidden',
          paddingBottom: '50px',
          whiteSpace: 'no-wrap'
        }}
      >
        {this.state.vinError && (
          <Message
            error
            content={this.state.vinError}
            onDismiss={handleDismiss}
          />
        )}
        {this.state.dealerErr && (
          <Message
            error
            visible
            content={this.state.dealerErr}
            onDismiss={handleDismiss}
          />
        )}
        {this.state.gettingInfo && (
          <Message
            info
            content={this.state.gettingInfo}
            onDismiss={handleDismiss}
          />
        )}
        {!isEmpty(needsSecondaryApproval) && (
          <Message info content={'Requires secondary approval'} />
        )}
        <Table
          celled
          singleLine
          verticalAlign="top"
          style={{ width: '2700px' }}
        >
          {TableHeader}
          <Table.Body>
            {fields.map((vehicle, index) => (
              <Table.Row key={index}>
                <Table.Cell className="table-field" style={{ width: '485px' }}>
                  <Field
                    name={`${vehicle}.vin`}
                    placeholder="VIN"
                    type="text"
                    component={TextInput}
                    validate={[fieldRequired, alphaNumeric, hasLength17]}
                    onBlur={vinQuery(vehicles, index, dispatch)}
                    parse={trimValue}
                  />
                </Table.Cell>
                <Table.Cell className="year" style={{ width: '10px' }}>
                  <Field
                    name={`${vehicle}.year`}
                    placeholder="Year"
                    component={SelectDropdown(yearOptions())}
                    validate={[fieldRequired]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.make`}
                    placeholder="Make"
                    component={TextInput}
                    validate={[fieldRequired]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.model`}
                    placeholder="Model"
                    component={TextInput}
                    validate={[fieldRequired]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.odometerReading`}
                    placeholder="Odometer"
                    component={TextInput}
                    validate={[shouldBeNumber, fieldRequired, maxLength6]}
                    parse={parseValueToInt}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.dealerNo`}
                    placeholder="Dealer#"
                    component={TextInput}
                    onBlur={dealerQuery(vehicles, index, dispatch)}
                    validate={[shouldBeNumber, fieldRequired, shouldBe5mil]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field" style={{ width: '420px' }}>
                  <Field
                    name={`${vehicle}.dealerName`}
                    placeholder="Dealer Name"
                    component={TextInput}
                    disabled
                    validate={[validDealer]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.sellerName`}
                    placeholder="Seller Name"
                    component={TextInput}
                    validate={[fieldRequired]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.vehiclePurchasePrice`}
                    placeholder="Purchase Price"
                    component={TextInput}
                    onBlur={calculateSum(vehicles, index, dispatch)}
                    validate={[shouldBeNumber, fieldRequired, maxLength5]}
                    parse={parseValueToInt}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.buyFee`}
                    placeholder="Buy Fee"
                    component={TextInput}
                    onBlur={calculateSum(vehicles, index, dispatch)}
                    validate={[shouldBeNumber, fieldRequired, maxLength4]}
                    parse={parseValueToInt}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.vehicleTotal`}
                    placeholder="Vehicle Total"
                    component={TextInput}
                    validate={[shouldBeNumber, fieldRequired, maxLength5]}
                    parse={parseValueToInt}
                  />
                </Table.Cell>
                <Table.Cell style={{ width: '300px' }}>
                  <Field
                    name={`${vehicle}.locationInitials`}
                    placeholder="Location"
                    component={SelectDropdown(locationOptions)}
                    validate={[fieldRequired]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field" style={{ width: '320px' }}>
                  <Field
                    name={`${vehicle}.automaticPurchaseEmail`}
                    placeholder="Email"
                    component={TextInput}
                    validate={[shouldBeEmail, fieldRequired]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field">
                  <Field
                    name={`${vehicle}.purchasedAt`}
                    placeholder="Guarantee Purchase Date"
                    component={DateInput}
                    validate={[fieldRequired]}
                    warn={[purchaseDateValidation(vehicles, index, dispatch)]}
                  />
                </Table.Cell>
                <Table.Cell className="table-field" style={{ width: '420px' }}>
                  <Field
                    name={`${vehicle}.rejectionComment`}
                    placeholder="Rejection Comment"
                    component={TextInput}
                  />
                </Table.Cell>
                {!isEmpty(needsSecondaryApproval) && (
                  <Table.Cell
                    className="table-field"
                    style={{ width: '420px' }}
                  >
                    <Field
                      name={`${vehicle}.secondaryRejectionComment`}
                      placeholder="Secondary Rejection Comment"
                      component={TextInput}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>
                  <p>{vehicleStatus(vehicles, index)}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{failedReason(vehicles, index)}</p>
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <Button onClick={updateHandler(vehicles, index)}>
                      Save
                    </Button>
                    {showApprove(vehicles, index) && (
                      <Button onClick={approveHandler(vehicles, index)}>
                        Approve
                      </Button>
                    )}
                    {showReject(vehicles, index) && (
                      <Button onClick={rejectHandler(vehicles, index)}>
                        Reject
                      </Button>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const formSelector = formValueSelector('npEditForm');
  return {
    vehicles: formSelector(state, 'vehicles'),
    user: state.data.user
  };
};

export default compose(
  graphql(ftbLocationsQuery),
  connect(mapStateToProps),
  LoadingDataEnhancer
)(EditVehicleFieldRow);
