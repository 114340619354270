import React from 'react';
import { Table, Dropdown, Icon } from 'semantic-ui-react';

const ListRow = actionHandler => flatRateOfferBatch => {
  const {
    id,
    createdAt,
    offeringUsersEmails,
    approved,
    state,
    auctionAccessNo,
    buyerInformation: { name, emails },
    offeredDealerNumbers,
    salesRepEmail,
    expirationDate,
    acceptingUserName
  } = flatRateOfferBatch;

  const rowMenuOptions =
    state === 'void'
      ? [
          { key: 6, text: 'View', value: 'Edit' },
          { key: 5, text: 'Delete', value: 'Delete' }
        ]
      : [
          { key: 1, text: 'Reset', value: 'Reset' },
          { key: 2, text: 'Confirm', value: 'Confirm' },
          { key: 3, text: 'Edit', value: 'Edit' },
          { key: 4, text: 'New Offer', value: 'AddNewOffer' },
          { key: 5, text: 'Delete', value: 'Delete' }
        ];
  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, flatRateOfferBatch);
  };

  const formatedEmails = emails => {
    return `${emails && emails.replace(/,/g, ', ')}`;
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{createdAt}</Table.Cell>
      <Table.Cell>
        {offeringUsersEmails && formatedEmails(offeringUsersEmails)}
      </Table.Cell>
      <Table.Cell>
        {approved && <Icon color="green" name="checkmark" size="large" />}
        {!approved && <Icon color="red" name="close" size="large" />}
      </Table.Cell>
      <Table.Cell>{state}</Table.Cell>
      <Table.Cell>{auctionAccessNo}</Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{emails && formatedEmails(emails)}</Table.Cell>
      <Table.Cell>{offeredDealerNumbers.join(',')}</Table.Cell>
      <Table.Cell>{salesRepEmail && formatedEmails(salesRepEmail)}</Table.Cell>
      <Table.Cell>{acceptingUserName}</Table.Cell>
      <Table.Cell>{expirationDate}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
