import React from 'react';
import { Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { utcMoment, dateTimeFormatted12Hour } from '../../utilities/dateUtils';

const ListRow = (handlePendingAcceptanceLink, startLoading) => enrollments => {
  const {
    id,
    dealerNo,
    dealershipName,
    sendAtWithTime,
    status,
    acceptedAt,
    voidAt,
    offeringUserEmail,
    productName,
    price,
    daysSelected,
    daysToExpire,
    flatRateOfferBatch: { auctionAccessNo, buyerInformation: { name, emails } }
  } = enrollments;

  const showProductSelectionHandler = e => {
    e.preventDefault();
    handlePendingAcceptanceLink(
      auctionAccessNo,
      dealerNo,
      dealershipName,
      true
    );
  };

  const productNameWithDefault = isEmpty(productName)
    ? `DealShield ${daysSelected}-Day`
    : productName;
  const daysToExpireWithDefault = isEmpty(daysToExpire) ? 'N/A' : daysToExpire;
  return (
    <Table.Row key={id}>
      <Table.Cell>
        {dealerNo} {dealershipName && ` - ${dealershipName}`}
      </Table.Cell>
      <Table.Cell>
        <span>
          {status}
          <br /> {acceptedAt}
          {!acceptedAt && voidAt}
        </span>
      </Table.Cell>
      <Table.Cell>
        {dateTimeFormatted12Hour(utcMoment(sendAtWithTime))}
      </Table.Cell>
      <Table.Cell>{daysToExpireWithDefault}</Table.Cell>
      <Table.Cell>
        <span>
          {name} <br />{' '}
          <a
            href={`mailto:${emails}?subject=Regarding: ${
              productNameWithDefault
            } offer to ${dealershipName}`}
          >
            {emails}
          </a>
        </span>
      </Table.Cell>
      <Table.Cell>
        {status === 'Pending Acceptance' && (
          <a onClick={showProductSelectionHandler} href="">
            {`${productNameWithDefault} @ ${price}`}
          </a>
        )}
        {!(status === 'Pending Acceptance') &&
          `${productNameWithDefault} @ ${price}`}
      </Table.Cell>
      <Table.Cell>
        <a href={'mailto:' + offeringUserEmail}>{offeringUserEmail}</a>
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
