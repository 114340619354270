import React from 'react';
import logo from '../../assets/dealshield-logo.png';

const Header = ({ notification }) => {
  return (
    <header>
      <img src={logo} alt="DealShield" />
      <h1>Vehicle Return Authorization</h1>
      <h2>
        <strong>
          AUCTION INSTRUCTIONS: STOCK IN UNDER DROP NOTICE FOR WORK ORDER
        </strong>
        <p>PLEASE RETURN VEHICLE TO VEHICLE ENTRY/CHECK-IN</p>
        {notification.active && (
          <strong style={{ color: 'red' }}>{notification.content}</strong>
        )}
      </h2>
    </header>
  );
};

export default Header;
