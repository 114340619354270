import { createAction } from 'redux-actions';
import * as actionType from './actionTypes';

const addDupeEntryVehicle = createAction(actionType.ADD_DUPE_ENTRY_VEHICLE);
const removeDupeEntryVehicle = createAction(
  actionType.REMOVE_DUPE_ENTRY_VEHICLE
);
const clearDupeEntryVehicle = createAction(actionType.CLEAR_DUPE_ENTRY_VEHICLE);

export { addDupeEntryVehicle, removeDupeEntryVehicle, clearDupeEntryVehicle };
