import { gql } from 'react-apollo';

const validateLocallyLinkedDealerQuery = gql`
  query($dealerNo: String!) {
    validateLocallyLinkedDealer(dealerNo: $dealerNo) {
      dealerInformation {
        id
        dealerNo
        dealerName
      }
      status
    }
  }
`;

export default validateLocallyLinkedDealerQuery;
