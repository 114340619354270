import React, { Component } from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import client from '../apollo';
import _, { isEmpty } from 'lodash';
import localStore from 'store';

import EnrollmentStatusList from '../views/EnrollmentStatus/List';
import enrollmentHistoryQuery from '../graphql/enrollmentHistoryQuery';
class EnrollmentStatus extends Component {
  state = {
    loading: false,
    offersInitiatedSince: 0,
    singleDealerQuotes: true,
    myQuotes: true,
    groupQuotes: false,
    allStaffs: false,
    enrollments: [],
    column: 'sendAtWithTime',
    direction: 'descending'
  };

  handleSort = clickedColumn => () => {
    const { column, enrollments, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        enrollments: _.sortBy(enrollments, [clickedColumn]),
        direction: 'ascending'
      });
      return;
    }

    let en = Object.assign([], enrollments);
    const dir = direction === 'ascending' ? 'descending' : 'ascending';
    this.setState({
      enrollments: en.reverse(),
      direction: dir
    });
    localStore.set('enrollmentStatusSorting', {
      column: clickedColumn,
      direction: dir
    });
  };

  submitFilter = () => {
    const {
      offersInitiatedSince,
      singleDealerQuotes,
      myQuotes,
      groupQuotes,
      allStaffs
    } = this.state;

    localStore.set('enrollmentStatusFilter', {
      offersInitiatedSince,
      singleDealerQuotes,
      myQuotes,
      groupQuotes,
      allStaffs
    });
    this.fetchEnrollmentHistory();
  };

  fetchEnrollmentHistory() {
    const {
      offersInitiatedSince,
      singleDealerQuotes,
      groupQuotes,
      myQuotes,
      allStaffs
    } = this.state;
    this.setState({
      loading: true
    });

    client
      .query({
        query: enrollmentHistoryQuery,
        variables: {
          offersInitiatedSince,
          singleDealerQuotes,
          groupQuotes,
          myQuotes,
          allStaffs
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const { enrollmentHistory: { enrollments } } = data;
        this.setState({
          enrollments: enrollments,
          loading: false,
          column: 'sendAtWithTime',
          direction: 'descending'
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          flashMessage: 'Network Error..'
        });
      });
  }

  componentDidMount() {
    const enrollmentStatusFilter = localStore.get('enrollmentStatusFilter');

    if (!isEmpty(enrollmentStatusFilter)) {
      const {
        offersInitiatedSince,
        singleDealerQuotes,
        myQuotes,
        groupQuotes,
        allStaffs
      } = enrollmentStatusFilter;

      this.setState(
        {
          offersInitiatedSince,
          singleDealerQuotes,
          myQuotes,
          groupQuotes,
          allStaffs
        },
        () => {
          this.fetchEnrollmentHistory();
        }
      );
    } else {
      this.fetchEnrollmentHistory();
    }
  }

  onCheckboxHandler = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onSelectHandler = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  render() {
    const { enrollments, loading } = this.state;
    const { handlePendingAcceptanceLink } = this.props;
    return (
      <div className="dealer-enrollment">
        {' '}
        {loading && (
          <Dimmer active inverted>
            <Loader size="large"> Loading </Loader>{' '}
          </Dimmer>
        )}{' '}
        <EnrollmentStatusList
          enrollments={enrollments}
          handleSort={this.handleSort}
          onSelectHandler={this.onSelectHandler}
          submitFilter={this.submitFilter}
          onCheckboxHandler={this.onCheckboxHandler}
          handlePendingAcceptanceLink={handlePendingAcceptanceLink}
          {...this.state}
        />{' '}
      </div>
    );
  }
}

export default EnrollmentStatus;
