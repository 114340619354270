import { gql } from 'react-apollo';

const confirmFlatRateOfferBatchMutation = gql`
  mutation confirmFlatRateOfferBatch($input: ConfirmFlatRateOfferBatchInput!) {
    confirmFlatRateOfferBatch(input: $input) {
      success
      errors
    }
  }
`;

export default confirmFlatRateOfferBatchMutation;
