import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';

import ReturnInvoices from './ReturnInvoices';
import CanceledReturnInvoices from './CanceledReturnInvoices';

class ReturnInvoicesTabs extends Component {
  render() {
    const panes = [
      {
        menuItem: 'Return Invoices',
        render: () => (
          <Tab.Pane>
            {' '}
            <ReturnInvoices />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Canceled Return Invoices',
        render: () => (
          <Tab.Pane>
            <CanceledReturnInvoices />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div>
        {' '}
        <Tab panes={panes} />
      </div>
    );
  }
}

export default ReturnInvoicesTabs;
