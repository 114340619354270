import { gql } from 'react-apollo';

const preferencesDiscoverQuery = gql`
  query preferencesDiscoverQuery {
    preferencesDiscover {
      rules {
        key
        text
        value
      }
      operatorTags {
        value
        ruleType
      }
      operators {
        key
        text
        value
      }
      defaultPreferencesRules {
        key
        text
        value
      }
    }
  }
`;

export default preferencesDiscoverQuery;