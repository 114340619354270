import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Header } from 'semantic-ui-react';

import ListRow from './ListRow';

class PendingGuaranteesList extends Component {
  render() {
    const {
      onSelectGuarantee,
      pendingGuarantees,
      bulkSelectHandler,
      isEligibleForBulkUpdate,
      handleSort,
      column,
      direction
    } = this.props;
    const listRow = ListRow(
      onSelectGuarantee,
      bulkSelectHandler,
      isEligibleForBulkUpdate
    );
    const headerRow = (
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>Dealer #</Table.HeaderCell>
        <Table.HeaderCell>Auction Access #</Table.HeaderCell>
        <Table.HeaderCell>Auction Code</Table.HeaderCell>
        <Table.HeaderCell>VIN</Table.HeaderCell>
        <Table.HeaderCell>Vehicle</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Status</Table.HeaderCell>
        <Table.HeaderCell>Vehicle Price</Table.HeaderCell>
        <Table.HeaderCell>Odometer Reading</Table.HeaderCell>
        <Table.HeaderCell>Guarantee Price</Table.HeaderCell>
        <Table.HeaderCell>Last Updated</Table.HeaderCell>
        <Table.HeaderCell>Provided Email (optional)</Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'pendingReasonNotice' ? direction : null}
          onClick={handleSort('pendingReasonNotice')}
        >
          Pending Reason
        </Table.HeaderCell>
        <Table.HeaderCell>If Bid?</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <div>
        <Header>Pending Offers</Header>
        <div>
          <Table
            celled
            selectable
            padded
            sortable
            renderBodyRow={listRow}
            tableData={pendingGuarantees}
            headerRow={headerRow}
            size="small"
            compact="very"
            style={{ fontSize: '80%' }}
            striped
          />
        </div>
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(PendingGuaranteesList);
