import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql, withApollo } from 'react-apollo';
import {
  Message,
  Container,
  Input,
  Dropdown,
  Grid,
  Form,
  TextArea,
  Button,
  Segment,
  Dimmer,
  Loader,
  Header
} from 'semantic-ui-react';
import _, { isEmpty, startsWith } from 'lodash';
import LoadingDataEnhancer from '../../containers/LoadingData';
import SearchResonse from './searchResponse';
import supportRequestMutation from '../../graphql/supportRequestMutation';
import apmsPath from '../../constants/apmsPath';
import { connect } from 'react-redux';

class SupportRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        errorMsg: '',
        errorFields: []
      },
      loading: false,
      path: '',
      body: '',
      method: 'GET',
      pathNotValid: false,
      bodyNotValid: false,
      type: 'apms_process',
      response: {
        responseCode: '',
        requestUrl: '',
        responseBody: ''
      },
      errorMsg: '',
      parameters: {
        param_1: { key: '', value: '' }
      }
    };
    this.baseState = this.state;
  }

  addParameter = () => {
    let { parameters } = this.state;
    const count = Object.keys(parameters).length;
    if (count < 5) {
      let parameter_list = { ...parameters };
      parameter_list['param_' + count + 1] = { key: '', value: '' };
      this.setState({ parameters: parameter_list });
    }
  };

  parameterChangeKey = (e, { key, value }) => {
    let { parameters } = this.state;
    let new_parameters = { ...parameters };
    new_parameters[e.target.name]['key'] = value;
    this.setState({ parameters: new_parameters });
  };

  parameterChangeValue = (e, { key, value }) => {
    let { parameters } = this.state;
    let new_parameters = { ...parameters };
    new_parameters[e.target.name]['value'] = value;
    this.setState({ parameters: new_parameters });
  };

  textChange = (e, { key, value }) => {
    this.setState({
      body: value
    });
  };

  updatePath = (e, { key, value }) => {
    this.validate({ name: 'path', value: value });
    this.setState({
      path: value
    });
  };

  updateMethod = (e, { key, value }) => {
    this.setState({
      method: value
    });
  };

  updateType = (e, { key, value }) => {
    this.setState({
      type: value
    });
  };

  flattenParams = params => {
    let paramters = '';
    const list = Object.keys(params);
    list.forEach(i => {
      if (!isEmpty(params[i].key) && !isEmpty(params[i].value)) {
        paramters = paramters + '&' + params[i].key + '=' + params[i].value;
      }
    });
    return paramters;
  };

  checkValidation = () => {
    this.resetError();
    const { path, body } = this.state;
    const checkPath = this.validate({ name: 'path', value: path });
    const checkBody = this.validate({ name: 'body', value: body });

    if (checkPath && checkBody) {
      return true;
    }
    this.resetResponse();
    return false;
  };

  resetError = () => {
    this.setState({ errorMsg: '', pathNotValid: false, bodyNotValid: false });
  };

  resetResponse = () => {
    this.setState({
      response: this.baseState.response
    });
  };

  processBody = body => {
    try {
      const obj = JSON.parse(body);
      return JSON.stringify(obj);
    } catch (error) {
      return '';
    }
  };

  isBodyValid = body => {
    if (!isEmpty(this.processBody(body))) {
      return true;
    }
    return false;
  };

  validate = arg => {
    switch (arg.name) {
      case 'body': {
        if (!isEmpty(arg.value) && !this.isBodyValid(arg.value)) {
          this.setState({ bodyNotValid: true });
          return false;
        }
        this.setState({ bodyNotValid: false });
        break;
      }
      case 'path': {
        if (isEmpty(arg.value)) {
          this.setState({ pathNotValid: true, errorMsg: '' });
          return false;
        } else if (!startsWith(arg.value, '/')) {
          this.setState({
            pathNotValid: true,
            errorMsg: 'Path Must Begin With /'
          });
          return false;
        } else {
          this.setState({ pathNotValid: false, errorMsg: '' });
          return true;
        }
        this.setState({ pathNotValid: false });
        break;
      }
      default: {
        if (isEmpty(arg.value) || arg.value === '' || arg.value === undefined) {
          this.setState({ bodyNotValid: true });
          return false;
        }
      }
    }
    return true;
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.checkValidation()) {
      this.setState({ loading: true });
      const { postSupportRequest } = this.props;
      const { path, method, body, parameters, type } = this.state;
      let params = this.flattenParams(parameters);
      const parsedBody = this.processBody(body);

      postSupportRequest({
        variables: {
          input: {
            support_request: {
              requestParameter: params,
              requestPath: path,
              type: type,
              requestBody: parsedBody,
              requestMethod: method
            }
          }
        }
      })
        .then(({ data }) => {
          const {
            postSupportRequest: { support_request }
          } = data;
          this.setState({ response: support_request, loading: false });
        })
        .catch(error => {
          this.setState({
            errorMsg: 'Error Processing API Request.',
            loading: false
          });
        });
    } else {
      this.setState({
        errorMsg: 'Complete Missing Form Fields',
        loading: false
      });
    }
  };

  missingFields = () => {
    const {
      errors: { errorFields }
    } = this.state;
    let error = errorFields.map((param, index) => <li>{param}</li>);
    return <ul>{error}</ul>;
  };

  listSelectablePath = () => {
    let path = apmsPath.pathList.map((param, index) => (
      <option key={param} value={param}>
        {param}
      </option>
    ));
    return <datalist id="path">{path}</datalist>;
  };

  listParamter = () => {
    const { parameters, loading } = this.state;
    const params_keys = Object.keys(parameters);
    const list_paramters = params_keys.map((param, index) => {
      const key = `${param}_${index}`;

      return (
        <Form.Group key={key + '_group'}>
          <Form.Input
            placeholder="Key"
            name={param}
            key={key + '_key'}
            onChange={this.parameterChangeKey}
            value={parameters[param].key}
            disabled={loading}
          />
          <Form.Input
            placeholder="Value"
            name={param}
            key={key + '_value'}
            onChange={this.parameterChangeValue}
            value={parameters[param].value}
            disabled={loading}
          />
          {index === 0 && (
            <Form.Button
              content="+"
              onClick={this.addParameter}
              disabled={loading}
            />
          )}
        </Form.Group>
      );
    });
    return list_paramters;
  };

  clearForm = () => {
    this.setState(this.baseState);
    this.setState({
      parameters: {
        param_1: { key: '', value: '' }
      }
    });
  };

  requestType = [
    { key: 1, text: 'Assurance Manager Processor', value: 'apms_process' },
    { key: 2, text: 'Assurance Manager Event Processor', value: 'apms_event' }
  ];

  requestMethod = [
    { key: 1, text: 'GET', value: 'GET' },
    { key: 2, text: 'POST', value: 'POST' },
    { key: 3, text: 'DELETE', value: 'DELETE' },
    { key: 4, text: 'PUT', value: 'PUT' }
  ];

  render() {
    const {
      method,
      response,
      errorMsg,
      path,
      body,
      type,
      pathNotValid,
      bodyNotValid,
      loading
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Message>
            <Message.Header>Support Request Tool</Message.Header>
            <p>
              Use the tool below to post request to APMS manually. For
              additional information on request path and Payload, use the
              provided document link.
            </p>
            <br />
            <p>
              <a
                target="_blank"
                href="https://pages.ghe.coxautoinc.com/InventorySolutions-DealShield/Tappy-Integration-Doc/"
              >
                Access Request Document
              </a>
            </p>
          </Message>
        </Container>

        <br />

        <Grid columns={2} padded divided>
          <Grid.Column>
            <Container>
              <container>
                <Dimmer active={loading} verticalAlign="top">
                  <Header as="h3" inverted>
                    <Loader active inline="centered" size="large" />
                    <br />
                    Processing...
                  </Header>

                  <Button primary onClick={this.clearForm}>
                    Cancel Request
                  </Button>
                </Dimmer>
              </container>

              {!isEmpty(errorMsg) && (
                <Message negative>
                  <b>
                    <p>{errorMsg}</p>
                  </b>
                </Message>
              )}
              <br />
              <Grid container columns={2}>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <b>Select Host</b>
                  </Grid.Column>
                  <Grid.Column>
                    <Dropdown
                      value={type}
                      options={this.requestType}
                      onChange={this.updateType}
                      disabled={loading}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column
                    width={3}
                    verticalAlign="middle"
                    columns={2}
                    padded="vertically"
                  >
                    <b>Request Path</b>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      fluid
                      error={pathNotValid}
                      list="path"
                      labelPosition="left"
                      placeholder="Request Path"
                      onChange={this.updatePath}
                      value={path}
                      disabled={loading}
                    />
                    {this.listSelectablePath()}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={3} verticalAlign="middle" columns={2}>
                    <b>Method</b>
                  </Grid.Column>
                  <Grid.Column>
                    <Dropdown
                      button
                      options={this.requestMethod}
                      text={method}
                      onChange={this.updateMethod}
                      value={method}
                      disabled={loading}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={3} verticalAlign="top">
                    <b>Parameters</b>
                  </Grid.Column>
                  <Grid.Column>
                    <Form>{this.listParamter()}</Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3} columns={2}>
                    <b>Body (JSON)</b>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {bodyNotValid && <p>* Payload Format Not Valid (JSON) </p>}
                    <Form>
                      <TextArea
                        style={{ height: '200px' }}
                        placeholder="request body"
                        onChange={this.textChange}
                        value={body}
                        disabled={loading}
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={3} columns={2} />
                  <Grid.Column>
                    <div>
                      <Button
                        content="Clear"
                        secondary
                        onClick={this.clearForm}
                        disabled={loading}
                      />
                      <Button
                        content="Send APMS Request"
                        primary
                        onClick={this.handleSubmit}
                        disabled={loading}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <br />
            </Container>
          </Grid.Column>
          <Grid.Column>
            <SearchResonse response={response} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withApollo,
  graphql(supportRequestMutation, { name: 'postSupportRequest' })
)(SupportRequestForm);
