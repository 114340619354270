import { gql } from 'react-apollo';

const updateReturnInvoiceMutation = gql`
  mutation updateReturnInvoice($input: UpdateReturnInvoiceInput!) {
    updateReturnInvoice(input: $input) {
      success
      errors
    }
  }
`;

export default updateReturnInvoiceMutation;
