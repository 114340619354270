import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import Validator from 'validatorjs';
import _, { isEmpty, map } from 'lodash';
import {
  Form,
  Checkbox,
  Button,
  Modal,
  Icon,
  Message
} from 'semantic-ui-react';

import createUserMutation from '../../graphql/createUserMutation';
import updateUserMutation from '../../graphql/updateUserMutation';
import Roles from '../../constants/roles';

class UserForm extends Component {
  constructor(props) {
    super(props);
    const user = props.user;
    this.state = {
      id: null || (user && parseInt(user.id, 10)),
      userName: '' || (user && user.username),
      email: '' || (user && user.email),
      locationInitials: '' || (user && user.locationInitials),
      active: user && user.active,
      errors: {},
      error: null,
      flashMessage: null,
      roleSet: [],
      legacySiteDisabled: false || (user && user.legacySiteDisabled),
      api_user: false || (user && user.roles.includes('api_user')),
      flat_rate_inquiry_viewer:
        false || (user && user.roles.includes('flat_rate_inquiry_viewer')),
      dealer_transaction_viewer:
        true || (user && user.roles.includes('dealer_transaction_viewer')),
      dealer_score_viewer:
        true || (user && user.roles.includes('dealer_score_viewer')),
      dealcheck_viewer:
        false || (user && user.roles.includes('dealcheck_viewer')),
      buyer_user: false || (user && user.roles.includes('buyer_user')),
      invoiceable: false || (user && user.roles.includes('invoiceable')),
      super_user: false || (user && user.roles.includes('super_user')),
      admin: false || (user && user.roles.includes('admin')),
      feature_flag_editor:
        false || (user && user.roles.includes('feature_flag_editor')),
      guarantee_admin_viewer:
        false || (user && user.roles.includes('guarantee_admin_viewer')),
      guarantee_admin_editor:
        false || (user && user.roles.includes('guarantee_admin_editor')),
      user_admin_editor:
        false || (user && user.roles.includes('user_admin_editor')),
      loss_prevention_viewer:
        false || (user && user.roles.includes('loss_prevention_viewer')),
      finance_admin_viewer:
        false || (user && user.roles.includes('finance_admin_viewer')),
      flat_rate_editor:
        false || (user && user.roles.includes('flat_rate_editor')),
      vehicle_creator:
        false || (user && user.roles.includes('vehicle_creator')),
      external_vehicle_enterer:
        false || (user && user.roles.includes('external_vehicle_enterer')),
      external_vehicle_approver:
        false || (user && user.roles.includes('external_vehicle_approver')),
      ds_fee_administration:
        false || (user && user.roles.includes('ds_fee_administration')),
      it_support: false || (user && user.roles.includes('it_support'))
    };
  }

  componentWillMount() {
    if (
      (!this.state.admin || this.state.admin === undefined) &&
      (!this.state.super_user || this.state.super_user === undefined)
    ) {
      this.setState({ roleSet: Roles.COMBINED_ROLES });
    } else if (!this.state.super_user || this.state.super_user === undefined) {
      this.setState({ roleSet: Roles.SUPER_USER_SPECIFIC_ROLES });
    } else if (!this.state.admin || this.state.admin === undefined) {
      this.setState({ roleSet: Roles.ADMIN_SPECIFIC_ROLES });
    }
  }

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      user,
      searchValue,
      searchQuery
    } = this.props;
    const { errors, error, flashMessage, roleSet } = this.state;

    const onChangeHandler = field => (event, data) => {
      const target = event.target;
      const value = data === undefined ? target.value : data.checked;
      if (data && data.checked) {
        checkRole(data.value);
      } else if (data && !data.checked) {
        addRole(data.value);
      }
      this.setState({ [field]: value });
    };

    const checkRole = role => {
      if (role === 'super_user') {
        this.setState({
          roleSet: roleSet.filter(
            item => !Roles.SUPER_USER_SPECIFIC_ROLES.includes(item)
          )
        });
      } else if (role === 'admin') {
        this.setState({
          roleSet: roleSet.filter(
            item => !Roles.ADMIN_SPECIFIC_ROLES.includes(item)
          )
        });
      }
    };

    const addRole = role => {
      if (role === 'super_user') {
        this.setState({ roleSet: Roles.COMBINED_ROLES });
        Roles.COMBINED_ROLES.map(role => this.setState({ [role]: false }));
      } else if (role === 'admin') {
        this.setState({ roleSet: Roles.ADMIN_SPECIFIC_ROLES });
        Roles.ADMIN_SPECIFIC_ROLES.map(role =>
          this.setState({ [role]: false })
        );
      }
    };

    const validationRules = {
      email: 'required|email',
      userName: 'required'
    };

    const onSubmitHandler = () => {
      const values = _.omit(this.state, [
        'error',
        'errors',
        'flashMessage',
        'roleSet'
      ]);
      const { createMutation } = this.props;
      const input = { user: { ...values } };

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        createMutation({
          variables: { input }
        })
          .then(({ data }) => {
            const {
              adminCreateUser: { success, errors }
            } = data;
            if (success) {
              this.setState({
                flashMessage: 'Successfully Created User'
              });
              searchQuery(searchValue);
            } else {
              this.setState({
                error: errors
              });
            }
          })
          .catch(error => {
            this.setState({
              error: `Error while creating user ${error}`
            });
          });
      }
    };

    const onEditHandler = () => {
      const values = _.omit(this.state, [
        'error',
        'errors',
        'flashMessage',
        'roleSet'
      ]);
      const { updateMutation } = this.props;
      const input = { user: { ...values } };

      const validation = new Validator(this.state, validationRules);
      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        updateMutation({
          variables: { input }
        })
          .then(({ data }) => {
            const {
              adminUpdateUser: { success, errors }
            } = data;
            if (success) {
              this.setState({
                flashMessage: 'Successfully Edited User'
              });
              searchQuery(searchValue);
            } else {
              this.setState({
                error: errors
              });
            }
          })
          .catch(error => {
            this.setState({
              error: `Error while editing user ${error}`
            });
          });
      }
    };

    const handleDismiss = () => {
      this.setState({ flashMessage: null });
    };

    const handleDismissError = () => {
      this.setState({ error: null });
    };

    return (
      <Modal
        closeIcon
        open={isModalOpen}
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>
          {isEmpty(user) ? 'Create User' : 'Edit User'}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {error && (
              <Message
                onDismiss={handleDismissError}
                content={error}
                negative
              />
            )}
            {flashMessage && (
              <Message
                onDismiss={handleDismiss}
                content={flashMessage}
                positive
              />
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.userName)}>
                  <label>Username</label>
                  <input
                    name="userName"
                    value={this.state.userName}
                    onChange={onChangeHandler('userName').bind(this)}
                  />
                  {errors.userName && (
                    <span style={{ color: 'red' }}>{errors.userName}</span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.email)}>
                  <label>Email</label>
                  <input
                    name="email"
                    value={this.state.email}
                    onChange={onChangeHandler('email').bind(this)}
                  />
                  {errors.email && (
                    <span style={{ color: 'red' }}>{errors.email}</span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Location Initials</label>
                  <input
                    name="userName"
                    value={this.state.locationInitials}
                    onChange={onChangeHandler('locationInitials').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Active</label>
                  <Checkbox
                    name="active"
                    value="active"
                    checked={this.state.active}
                    onChange={onChangeHandler('active').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Legacy Site Disabled</label>
                  <Checkbox
                    name="legacySiteDisabled"
                    value="legacySiteDisabled"
                    checked={this.state.legacySiteDisabled}
                    onChange={onChangeHandler('legacySiteDisabled').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Roles</label>
                  {map(Roles.ROLES, (role, index) => (
                    <Checkbox
                      key={index}
                      label={role}
                      name={role}
                      value={role}
                      checked={this.state[role]}
                      onChange={onChangeHandler(role).bind(this)}
                      disabled={roleSet.includes(role)}
                    />
                  ))}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={isEmpty(user) ? onSubmitHandler : onEditHandler}
            inverted
          >
            <Icon name="checkmark" /> Submit
          </Button>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default compose(
  graphql(createUserMutation, { name: 'createMutation' }),
  graphql(updateUserMutation, { name: 'updateMutation' })
)(UserForm);
