import { gql } from 'react-apollo';

const updateDealershipBlacklistMutation = gql`
  mutation updateDealershipBlacklist($input: UpdateDealershipBlacklistInput!) {
    updateDealershipBlacklist(input: $input) {
      success
      errors
    }
  }
`;

export default updateDealershipBlacklistMutation;
