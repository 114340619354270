import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { Button, Modal, Icon, Message } from 'semantic-ui-react';

class PendingGuaranteeDetails extends Component {
  render() {
    const {
      pendingGuarantee: {
        guaranteePrice,
        purchasePrice,
        vehicleFullDescription,
        pendingComments,
        vehicleInformation: { odometerReading, vin },
        auctionInformation: { locationInitials, laneNumber, runNumber }
      },
      isDetailsModalOpen,
      onCloseHandler,
      invalidatePendingGuarantee,
      allowPendingGuarantee,
      error,
      submitting
    } = this.props;

    return (
      <div>
        <Modal
          open={isDetailsModalOpen}
          closeIcon
          onClose={onCloseHandler}
          closeOnEscape={false}
          closeOnRootNodeClick={false}
        >
          <Modal.Header>Pending Offer Details</Modal.Header>
          <Modal.Content image scrolling>
            <Modal.Description>
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Header size="tiny">Price:</Header>
                  </Grid.Column>
                  <Grid.Column>{guaranteePrice}</Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header size="tiny">Location:</Header>
                  </Grid.Column>
                  <Grid.Column>{locationInitials}</Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header size="tiny">Lane-Run:</Header>
                  </Grid.Column>
                  <Grid.Column>
                    {laneNumber} - {runNumber}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header size="tiny">Vehicle Purchase Price:</Header>
                  </Grid.Column>
                  <Grid.Column>{purchasePrice}</Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header size="tiny">Vehicle:</Header>
                  </Grid.Column>
                  <Grid.Column>
                    {vehicleFullDescription},
                    {odometerReading} miles, VIN {vin}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header size="tiny">Comments:</Header>
                  </Grid.Column>
                  <Grid.Column>{pendingComments}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {submitting && (
              <Button loading positive>
                Submitting...
              </Button>
            )}
            {!submitting && (
              <Button
                color="green"
                onClick={invalidatePendingGuarantee}
                inverted
              >
                <Icon name="checkmark" /> Invalidate
              </Button>
            )}
            {!submitting && (
              <Button color="green" onClick={allowPendingGuarantee} inverted>
                <Icon name="checkmark" /> Allow
              </Button>
            )}

            <Button onClick={onCloseHandler}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default PendingGuaranteeDetails;
