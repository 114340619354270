import { gql } from 'react-apollo';

const returnInvoiceAuditsQuery = gql`
  query returnInvoiceAudits(
    $returnInvoiceId: Int!
    $startDate: String
    $endDate: String
  ) {
    returnInvoiceAudits(
      returnInvoiceId: $returnInvoiceId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      whodunnit
      event
      versionAuthor
      itemType
      changeset
      timeStamp
    }
  }
`;

export default returnInvoiceAuditsQuery;
