export default {
  ROLES: [
    'api_user',
    'flat_rate_inquiry_viewer',
    'dealer_transaction_viewer',
    'dealer_score_viewer',
    'dealcheck_viewer',
    'buyer_user',
    'invoiceable',
    'super_user',
    'admin',
    'feature_flag_editor',
    'guarantee_admin_viewer',
    'guarantee_admin_editor',
    'user_admin_editor',
    'loss_prevention_viewer',
    'finance_admin_viewer',
    'flat_rate_editor',
    'vehicle_creator',
    'external_vehicle_enterer',
    'external_vehicle_approver',
    'ds_fee_administration',
    'it_support'
  ],
  SUPER_USER_SPECIFIC_ROLES: [
    'admin',
    'vehicle_creator',
    'external_vehicle_enterer',
    'external_vehicle_approver'
  ],
  ADMIN_SPECIFIC_ROLES: [
    'feature_flag_editor',
    'guarantee_admin_viewer',
    'guarantee_admin_editor',
    'user_admin_editor',
    'loss_prevention_viewer',
    'finance_admin_viewer',
    'flat_rate_editor',
    'ds_fee_administration',
    'it_support'
  ],
  COMBINED_ROLES: [
    'admin',
    'vehicle_creator',
    'external_vehicle_enterer',
    'external_vehicle_approver',
    'feature_flag_editor',
    'guarantee_admin_viewer',
    'guarantee_admin_editor',
    'user_admin_editor',
    'loss_prevention_viewer',
    'finance_admin_viewer',
    'flat_rate_editor',
    'ds_fee_administration'
  ]
};
