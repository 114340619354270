import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Validator from 'validatorjs';

import flatRatesFromBatchesQuery from '../../graphql/flatRatesFromBatchesQuery';
import { FLAT_RATES_MILES } from '../../constants';

import { Form, Button, Modal, Icon, Message, Select } from 'semantic-ui-react';

class NewFlatRateOfferForm extends Component {
  state = {
    price: null,
    flatRateId: '',
    milesSelected: '',
    errors: {}
  };

  render() {
    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting,
      data: { flatRates }
    } = this.props;

    const { errors, flatRateId, milesSelected, price } = this.state;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };

    const flatRateOptions =
      flatRates &&
      flatRates.map(function(flatRate) {
        return {
          text: flatRate.dealerNo,
          key: flatRate.id,
          value: parseInt(flatRate.id, 10)
        };
      });

    const validationRules = {
      flatRateId: 'required',
      milesSelected: 'required',
      price: 'required|numeric'
    };

    const submitForm = () => {
      const { price, milesSelected, flatRateId } = this.state;

      const {
        flatRateOfferBatch: { id }
      } = this.props;

      const validation = new Validator(this.state, validationRules);
      validation.setAttributeNames({ flatRateId: 'dealer no' });

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      } else {
        onSubmitHandler({
          id: parseInt(id, 10),
          price,
          milesSelected,
          flatRateId
        });
        this.setState({ ...validation.errors });
      }
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Edit Dealshield 360 offer</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field error={!isEmpty(errors.flatRateId)}>
                  <label>Dealer No</label>
                  <Select
                    options={flatRateOptions}
                    closeOnBlur
                    value={flatRateId}
                    onChange={onSelectHandler('flatRateId').bind(this)}
                  />
                  {errors.flatRateId && <span>{errors.flatRateId}</span>}
                </Form.Field>

                <Form.Field error={!isEmpty(errors.milesSelected)}>
                  <label>Miles:</label>
                  <Select
                    options={FLAT_RATES_MILES}
                    closeOnBlur
                    value={milesSelected}
                    onChange={onSelectHandler('milesSelected').bind(this)}
                  />
                  {errors.milesSelected && <span>{errors.milesSelected}</span>}
                </Form.Field>

                <Form.Field error={!isEmpty(errors.price)}>
                  <label>Price</label>
                  <Form.Input
                    name="price"
                    placeholder="Price"
                    value={price}
                    onChange={onChangeHandler('price').bind(this)}
                  />
                  {errors.price && <span>{errors.price}</span>}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}

          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const data = graphql(flatRatesFromBatchesQuery, {
  options: props => {
    const {
      flatRateOfferBatch: { id }
    } = props;
    return { variables: { flatRateOfferBatchId: parseInt(id, 10) } };
  }
});

export default compose(
  withRouter,
  data
)(NewFlatRateOfferForm);
